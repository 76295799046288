import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => {
  // console.debug(theme);
  return createStyles({
    formControl: {
      display: 'block',
      minWidth: 500,
      [breakpoints.down('sm')]: {
        minWidth: 100
      }
    },
    dialogActions: {
      margin: spacing(2)
    },
    label: {
      marginBottom: spacing(2)
    }
  });
});

export default useStyles;
