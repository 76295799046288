import { get, isNull, set } from 'lodash';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button, Grid } from '@material-ui/core';
import useValidationSchema from './_form';
import useAlerts from 'components/Alerts/useAlerts';
import useLoader from 'components/Loader/useLoader';
import useUserInfo from 'utils/hooks/useUserInfo';
import { delivered, resendSecondKey } from './_api';
import InputField from 'components/form/Input/Input';
import { DEFAULT_HIDE_DURATION } from 'constants/constants';
import { IKeyConfirmationDialog, IKeyConfirmationFormValues, IDeliveredResponse } from './_types';
import Login from 'modules/Login/Login';
import DialogTitleWithClose from 'components/DialogTitleWithClose/DialogTitleWithClose';
import { useHistory } from 'react-router';
import useTransfer from 'utils/hooks/useTransfer';

const KeyConfirmationDialog: React.FC<IKeyConfirmationDialog> = ({
  setKeysConfirmation,
  getTransferInfo,
  firstKey,
  transferIdAndCodeObject,
  showOptionalLogin,
  optionalLogin,
  secondKeyTranslationString,
  setSecondKeyTranslationString,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const KeyConfirmationDialogSchema = useValidationSchema();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { toggleLoader } = useLoader();
  const { userIsAuthorized } = useUserInfo();
  const { transferIsTotpMethod, getSecondPartOfTheKeyForForm } = useTransfer();
  const [showButtonForResend, setShowButtonforResend] = useState<boolean>(false);
  const [deliveryResponse, storeDeliveryResponse] = useState<IDeliveredResponse | null>(null);
  const [timeToResendAllow, setTimeToResendAllow] = useState<number>(0);
  const [showLoginUrl, setShowLoginUrl] = useState<boolean | null>(null);
  const [loginRequired, setLoginRequired] = useState<boolean | null>(null);
  let initialValues = {
    firstKey: isNull(firstKey) ? '' : firstKey,
    secondKey: '',
  };

  const proccessDeliveredResponse = (deliveredResponse: IDeliveredResponse) => {
    if (get(deliveredResponse, 'success', false)) {
      const isTotp = transferIsTotpMethod(deliveredResponse);
      setShowButtonforResend(
        !isTotp && get(deliveredResponse, 'sent', 0) < get(deliveredResponse, 'limit', 0)
      );
      setTimeToResendAllow(get(deliveredResponse, 'nextSendSeconds', 0));
    }
  };

  const processResendSecondKey = async () => {
    const deliveredResponse = await resendSecondKey(
      get(transferIdAndCodeObject, 'transfer'),
      get(transferIdAndCodeObject, 'code', null)
    );
    proccessDeliveredResponse(deliveredResponse);
  };

  const delivery = async () => {
    const deliveredResponse = await delivered(
      get(transferIdAndCodeObject, 'transfer'),
      get(transferIdAndCodeObject, 'code', null),
      false
    );

    setSecondKeyTranslationString(getSecondPartOfTheKeyForForm(deliveredResponse));
    proccessDeliveredResponse(deliveredResponse);
    storeDeliveryResponse(deliveredResponse);
  };

  const resolveMessage = (reason: string) => {
    const messageAndHideDuration = { hideDuration: DEFAULT_HIDE_DURATION };
    let value = '';
    if (reason === '') {
      value = 'invalid_key_or_blocked';
    } else {
      if (reason === 'Download.Error.NotRecipient') {
        value = 'unauthorized';
      } else if (reason === 'Download.Error.InvalidKey') {
        value = 'invalid_key';
      } else {
        value = reason;
      }

      switch (value) {
        case 'invalid-request':
          set(messageAndHideDuration, 'message', 'files.downloadFiles.missingParams');
          break;
        case 'invalid-key':
          set(messageAndHideDuration, 'message', 'files.downloadFiles.invalidKey');
          break;
        case 'blocked':
          set(messageAndHideDuration, 'message', 'files.downloadFiles.blockedTransfer');
          set(messageAndHideDuration, 'hideDuration', 20000);
          break;
        case 'missing-params':
          set(messageAndHideDuration, 'message', 'files.downloadFiles.missingParams');
          break;
        case 'server-error':
          set(messageAndHideDuration, 'message', 'files.downloadFiles.serverError');
          break;
        case 'transfer-expired':
          set(messageAndHideDuration, 'message', 'files.downloadFiles.transferExpired');
          break;
        case 'unauthorized':
          set(messageAndHideDuration, 'message', 'files.downloadFiles.unauthorized');
          set(messageAndHideDuration, 'hideDuration', 20000);
          break;
        case 'invalid-key-or-blocked':
          set(messageAndHideDuration, 'message', 'files.downloadFiles.badKeyOrBlockedTransfer');
          set(messageAndHideDuration, 'hideDuration', 20000);
          break;
        default:
          set(messageAndHideDuration, 'message', null);
          break;
      }

      return messageAndHideDuration;
    }
  };

  const onSubmit = async (
    values: IKeyConfirmationFormValues,
    actions: FormikHelpers<IKeyConfirmationFormValues>
  ) => {
    toggleLoader();

    const firstPartOfKeyFormat = get(deliveryResponse, 'firstPartOfKeyFormat', '');
    const secondPartOfKeyFormat = get(deliveryResponse, 'secondPartOfKeyFormat', '');

    if (firstPartOfKeyFormat != null) {
      const re1 = new RegExp(firstPartOfKeyFormat);
      if (!re1.test(get(values, 'firstKey', ''))) {
        toggleLoader(false);
        return addErrorAlert(t('files.downloadFiles.firstPartOfKeyWrongFormat'));
      }
    }

    if (secondPartOfKeyFormat != null) {
      const re2 = new RegExp(secondPartOfKeyFormat);
      if (!re2.test(get(values, 'secondKey', ''))) {
        toggleLoader(false);
        return addErrorAlert(t('files.downloadFiles.secondPartOfKeyWrongFormat'));
      }
    }

    try {
      let isTotp = false;
      const deliveredResponse = await delivered(
        get(transferIdAndCodeObject, 'transfer'),
        get(transferIdAndCodeObject, 'code', null),
        true
      );
      if (get(deliveredResponse, 'success', false)) {
        isTotp = transferIsTotpMethod(deliveredResponse);
      }
      await getTransferInfo(get(values, 'firstKey', ''), get(values, 'secondKey', ''), isTotp);
      setKeysConfirmation(true);
      addSuccessAlert(t('files.downloadFiles.successFullyConfirmed'));
    } catch (error) {
      const reason = get(error, 'errorMessage');
      const messageAndHideDuration = resolveMessage(reason);
      const alertMessage = get(messageAndHideDuration, 'message', null);
      if (alertMessage !== null && alertMessage !== undefined) {
        addErrorAlert(
          t(alertMessage),
          get(messageAndHideDuration, 'hideDuration', DEFAULT_HIDE_DURATION)
        );
      }
    }
    toggleLoader(false);
  };

  useEffect(() => {
    delivery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowLoginUrl(showOptionalLogin && !userIsAuthorized);

    if (!showButtonForResend) {
      return;
    }
    if (!timeToResendAllow) return;
    const interval = setInterval(() => {
      setTimeToResendAllow(timeToResendAllow - 1);
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeToResendAllow, userIsAuthorized]);

  const showLoginDialog = () => {
    setLoginRequired(true);
  };

  const closeLoginDialogAfterLogin = () => {
    setLoginRequired(false);
  };

  const onClose = () => {
    history.push('/incoming-files');
  };

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={KeyConfirmationDialogSchema}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Dialog maxWidth="sm" aria-labelledby="confirmation-dialog-title" open={true}>
            <DialogTitleWithClose
              title={`${t('files.downloadFiles.transferId')}: ${get(
                transferIdAndCodeObject,
                'transfer'
              )}`}
              closeDialogFn={onClose}
            />
            <DialogContent>
              <form onSubmit={handleSubmit}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12}>
                    <InputField
                      name="firstKey"
                      label={t('files.downloadFiles.firstKey')}
                      disabled={!isNull(firstKey)}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <InputField name="secondKey" label={secondKeyTranslationString} />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      fullWidth={true}
                      disabled={isSubmitting}
                    >
                      {t('files.downloadFiles.submit')}
                    </Button>
                  </Grid>
                  {showButtonForResend && (
                    <Grid item={true} xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        onClick={() => processResendSecondKey()}
                        disabled={timeToResendAllow > 0}
                      >
                        {`${t('files.downloadFiles.resendKey')} ${
                          timeToResendAllow > 0
                            ? t('files.downloadFiles.timdeDelay', { n: timeToResendAllow })
                            : ''
                        }`}
                      </Button>
                    </Grid>
                  )}
                  {showLoginUrl && (
                    <Grid item={true} xs={12}>
                      <Button
                        variant="text"
                        color="primary"
                        fullWidth={true}
                        onClick={() => showLoginDialog()}
                      >
                        {t('login.loginAction')}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </form>
            </DialogContent>
            <DialogActions />
          </Dialog>
        )}
      </Formik>
      {loginRequired && (
        <Login
          email={optionalLogin || ''}
          password={''}
          isDialog={true}
          closeLoginDialog={closeLoginDialogAfterLogin}
        />
      )}
    </>
  );
};

export default KeyConfirmationDialog;
