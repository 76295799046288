import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette, breakpoints, spacing, shadows }: Theme) =>
  createStyles({
    container: {
      paddingLeft: 0,
      paddingRight: 0
    },
    whiteBox: {
      background: '#fff',
      boxShadow: shadows[1],
      padding: spacing(2),
      [breakpoints.up('sm')]: {
        padding: spacing(3)
      },
      [breakpoints.up('md')]: {
        padding: spacing(4)
      },
      '& a': {
        color: palette.primary.main
      }
    },
    dense: {
      padding: spacing(1),
      [breakpoints.up('sm')]: {
        padding: spacing(2)
      }
    }
  })
);

export default useStyles;
