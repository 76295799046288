import { Theme } from '@material-ui/core';
import useConfig from './useConfig';
import { get } from 'lodash';
import { themeByConfig } from 'theme';
import { IFrontendConfigStyle } from 'modules/Login/_types';

const useStyleByAppVersion = () => {
  const { getConfigValue } = useConfig();

  const defaultValues = {
    appName: 'Dr.Sejf',
    appDescriptionKey: 'common.mainTitle.medical',
    faq: 'faq_drsejf',
    termsOfUse: 'terms_of_use_drsejf',
    logo: 'img/logo_drsejf.svg',
    primaryColor: '#086bbc'
  };

  let configValues: IFrontendConfigStyle | undefined = undefined;

  const loadConfig = (): boolean => {
    const useStyle = getConfigValue('useStyle');
    if (useStyle !== undefined) {
      configValues = getConfigValue(`style.${useStyle}`);
      return true;
    }
    return false;
  };

  const getValue = (variable: string, breakFce: boolean = false): string => {
    if (configValues === undefined) {
      const loaded = loadConfig();
      if (loaded && !breakFce) {
        return getValue(variable, true);
      } else {
        return get(defaultValues, variable, '');
      }
    } else {
      return get(configValues, variable, '');
    }
  };

  const setApplicationStyle = (setTheme: React.Dispatch<React.SetStateAction<Theme>>): void => {
    const appName = getValue('appName');
    if (appName !== undefined) {
      document.title = appName;
    } else {
      document.title = 'Dr.Sejf';
    }
    const primaryColor = getValue('primaryColor');
    if (primaryColor !== undefined) {
      setTheme(themeByConfig(primaryColor));
    }
  };

  const getLogo = (): string => `${process.env.PUBLIC_URL}${getValue('logo')}`;

  const getFaqPage = (): string => getValue('faq');

  const getTermOfUsePage = (): string => getValue('termsOfUse');

  const getMainTitle = (): string => getValue('appDescriptionKey');

  const getPrivacyFile = (): string => getValue('privacy');

  return {
    setApplicationStyle,
    getLogo,
    getFaqPage,
    getTermOfUsePage,
    getMainTitle,
    getPrivacyFile
  };
};

export default useStyleByAppVersion;
