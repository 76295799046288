import BasicGrid from 'components/BasicGrid/BasicGrid';
import useLoader from 'components/Loader/useLoader';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { get, includes, isArray, pick, sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFileUpload from 'utils/hooks/useFileUpload';
import useRemoteUpload from 'utils/hooks/useRemoteUpload';
import { searchRemote } from './_api';
import { IRemoteSourceDocumentItem, IRemoteSourceLevel } from './_types';

const Level: React.FC<any> = ({
  rowData,
  levelNumber,
  remoteSource,
  onSelectItems,
  defaultLevelItems = null,
  previousLevelsData = [],
}) => {
  const { t } = useTranslation();
  const { getLevel, isLastLevel, replaceFormattedFields, getRemoteFileDescription } =
    useRemoteUpload();
  const { getGridColumns, getDateFieldsThatShouldBeFormatted } = useFileUpload();
  const { toggleLoader } = useLoader();
  const [levelItems, setLevelItems] = useState<null | any[]>(defaultLevelItems);

  const level = getLevel(remoteSource.levels, levelNumber) as IRemoteSourceLevel;
  const levelIsLast = isLastLevel(remoteSource.levels, level);

  const fieldsWithFormattedDates = getDateFieldsThatShouldBeFormatted(
    level.itemParams,
    level.itemMergedParams
  );

  const previousLevelsDataWithActualLevel =
    rowData && !includes(previousLevelsData, { ...rowData, level: levelNumber - 1 })
      ? [...previousLevelsData, { ...rowData, level: levelNumber - 1 }]
      : previousLevelsData;

  const sortedMergedColumns = getGridColumns(
    sortBy(
      [
        ...(isArray(level.itemParams) ? level.itemParams : []),
        ...(isArray(level.itemMergedParams) ? level.itemMergedParams : []),
      ],
      ['order']
    ),
    levelIsLast
  );

  useEffect(() => {
    const searchLevelItems = async () => {
      try {
        toggleLoader();
        const searchParamsNames = level.searchParams.map((searchParam: any) => searchParam.name);
        const selectedSearchParams = pick(rowData, searchParamsNames);
        const request = {
          searchParams: selectedSearchParams,
          source: get(remoteSource, 'name'),
          timeFormat: 'HH:mm:ss',
          dateFormat: 'dd.MM.yyyy',
          dateTimeFormat: 'dd.MM.yyyy HH:mm:ss',
          level: levelNumber,
          itemParams: null,
          itemFilterMode: null,
        };

        const remoteResponse = (await searchRemote(
          request,
          (window as any).axiosSource.token
        )) as IRemoteSourceDocumentItem;
        if (isArray(get(remoteResponse, 'items'))) {
          setLevelItems(
            replaceFormattedFields(get(remoteResponse, 'items'), fieldsWithFormattedDates)
          );
        }
        toggleLoader(false);
      } catch (e) {
        toggleLoader(false);
        console.debug(e);
      }
    };

    if (!isArray(defaultLevelItems)) {
      searchLevelItems();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return levelItems === null ? null : isArray(levelItems) ? (
    <BasicGrid
      data={levelItems}
      maxWidth={false}
      disabledContainer={true}
      columns={sortedMergedColumns}
      topMargin={false}
      options={{
        selection: levelIsLast ? true : false,
        selectionProps: (rowData: any) => ({
          disabled: rowData.alreadySelectedForUpload === true,
        }),
        paging: true,
        emptyRowsWhenPaging: false,
        pageSize: 20,
        pageSizeOptions: [10, 20, 50, 100],
        search: false,
        toolbar: true,
      }}
      actions={
        levelIsLast
          ? [
              {
                icon: AssignmentTurnedInIcon,
                iconProps: {
                  color: 'primary',
                  fontSize: 'large',
                },
                tooltip: t('files.upload.remote.select'),
                onClick: (_event: any, files: any) => {
                  onSelectItems(
                    _event,
                    files.map((file: any) => ({
                      ...file,
                      previousLevels: previousLevelsDataWithActualLevel,
                      levelNumber,
                      description: getRemoteFileDescription(
                        [...previousLevelsDataWithActualLevel, { ...file, level: levelNumber }],
                        level.itemDescriptionParams
                      ),
                    })),
                    level.itemDescriptionParams,
                    level.itemUniqueParams
                  );
                },
                position: 'toolbarOnSelect',
              },
              {
                icon: AssignmentTurnedInIcon,
                iconProps: {
                  color: 'primary',
                  fontSize: 'large',
                },
                tooltip: t('files.upload.remote.select'),
                onClick: (_event: any, file: any) => {
                  onSelectItems(
                    _event,
                    [
                      {
                        ...file,
                        previousLevels: previousLevelsDataWithActualLevel,
                        levelNumber,
                        description: getRemoteFileDescription(
                          [...previousLevelsDataWithActualLevel, { ...file, level: levelNumber }],
                          level.itemDescriptionParams
                        ),
                      },
                    ],
                    level.itemDescriptionParams,
                    level.itemUniqueParams
                  );
                },
                position: 'row',
              },
            ]
          : []
      }
      detailPanel={
        levelIsLast
          ? []
          : [
              {
                icon: ChevronRightIcon,
                tooltip: t('files.upload.remote.showItems'),
                render: (rowData: any) => {
                  const nextLevelNumber = get(level, 'level') + 1;
                  return (
                    <Level
                      rowData={rowData}
                      levelNumber={nextLevelNumber}
                      remoteSource={remoteSource}
                      isLastLevel={levelIsLast}
                      onSelectItems={onSelectItems}
                      previousLevelsData={previousLevelsDataWithActualLevel}
                    />
                  );
                },
              },
            ]
      }
    />
  ) : (
    <>{t('files.upload.remote.duplicitiesRemoved')}</>
  );
};

export default Level;
