import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => {
  // console.debug(theme);
  const errorColor = theme.palette.error.main;
  return createStyles({
    error: {
      color: errorColor,
      fontSize: '0.75rem',
      marginBottom: theme.spacing(1)
    },
    errorLabel: {
      color: errorColor
    },
    inline: {
      display: 'inline-block'
    }
  });
});

export default useStyles;
