import * as yup from 'yup';
import useValidators from 'components/form/validations/useValidators';

const useValidationSchema = () => {
  const { required, number } = useValidators();

  const SearchBlockedRecipientsValidationSchema = yup.object().shape({
    transferId: required(number(yup.number())),
  });

  const UnblockTransferValidationSchema = yup.object().shape({
    transferId: required(number(yup.number())),
    emailCode: required(yup.string()),
    smsCode: required(yup.string()),
  });

  const UnblockTransferNoSmsRequiredValidationSchema = yup.object().shape({
    transferId: required(number(yup.number())),
    emailCode: required(yup.string()),
  });

  return {
    SearchBlockedRecipientsValidationSchema,
    UnblockTransferValidationSchema,
    UnblockTransferNoSmsRequiredValidationSchema,
  };
};

export default useValidationSchema;
