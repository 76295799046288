import { isArray } from 'lodash';
import { IAction } from 'store/_types';
import {
  ALERT_ADD,
  ALERT_REMOVE,
  IS_LOADING,
  CONFIG_BACKEND_STORE,
  ORGANIZATION_DIALOG_IS_OPENED,
  TOGGLE_NOTIFICATIONS_POPUP,
  STORE_NOTIFICATIONS,
  LANGUAGE_STORE,
  CONFIG_FRONTEND_STORE,
  VERSION_STORE,
  RESUMABLE_UPLOAD_TICK,
  RESUMABLE_UPLOAD_STATE,
  STORE_RECIPIENTS,
  FORCE_LOADING_ON,
  FORCE_LOADING_OFF,
  STORE_ADDITIONAL_INFO,
} from 'constants/constants';
import { IAppReducerInitialState } from './_types';
import { INotification } from 'modules/Notifications/_types';

const initialState: IAppReducerInitialState = {
  files: [],
  alerts: [],
  language: '',
  recipients: [],
  isLoading: false,
  notifications: [],
  configBackend: {},
  configFrontend: {},
  version: undefined,
  unreadNotifications: 0,
  showCancelButton: false,
  resumableUploadTick: false,
  resumableUploadStarted: false,
  hasUnreadNotifications: false,
  notificationsPopupIsOpened: false,
  organizationDialogIsOpened: false,
  additionalInfo: null,
};

const appReducer = (state = initialState, { type, payload }: IAction) => {
  switch (type) {
    case IS_LOADING:
      const loaderIsCurrentlyForced = state.isLoading === FORCE_LOADING_ON;
      const forcedLoaderShouldBeRemoved = payload.isLoading === FORCE_LOADING_OFF;

      return {
        ...state,
        isLoading:
          loaderIsCurrentlyForced && forcedLoaderShouldBeRemoved
            ? false // was forced and now unforcing -> set to false
            : loaderIsCurrentlyForced && !forcedLoaderShouldBeRemoved
            ? FORCE_LOADING_ON // was forced but not unforcing -> keep forced
            : !loaderIsCurrentlyForced && forcedLoaderShouldBeRemoved
            ? false // Should not happen but setting false just to be sure
            : payload.isLoading, // wasn't forced -> set whatever comes in
        showCancelButton: payload.showCancelButton,
      };
    case ALERT_REMOVE:
      return {
        ...state,
        alerts: state.alerts.filter((alert) => alert.id !== payload),
      };
    case ALERT_ADD: {
      return {
        ...state,
        alerts: [...state.alerts, payload],
      };
    }
    case ORGANIZATION_DIALOG_IS_OPENED: {
      return {
        ...state,
        organizationDialogIsOpened: payload,
      };
    }
    case CONFIG_BACKEND_STORE: {
      return {
        ...state,
        configBackend: payload,
      };
    }
    case CONFIG_FRONTEND_STORE: {
      return {
        ...state,
        configFrontend: payload,
      };
    }
    case LANGUAGE_STORE: {
      return {
        ...state,
        language: payload,
      };
    }
    case VERSION_STORE: {
      return {
        ...state,
        version: payload,
      };
    }
    case TOGGLE_NOTIFICATIONS_POPUP: {
      return {
        ...state,
        notificationsPopupIsOpened: payload,
      };
    }
    case RESUMABLE_UPLOAD_TICK: {
      return {
        ...state,
        resumableUploadTick: payload,
      };
    }
    case RESUMABLE_UPLOAD_STATE: {
      return {
        ...state,
        resumableUploadStarted: payload,
      };
    }
    case STORE_NOTIFICATIONS: {
      const notifications = payload;
      const filteredUnreadNotifications = isArray(notifications)
        ? notifications.filter((notification: INotification) => !notification.viewed)
        : [];
      return {
        ...state,
        notifications,
        unreadNotifications: filteredUnreadNotifications.length,
        hasUnreadNotifications: filteredUnreadNotifications.length > 0,
      };
    }
    case STORE_RECIPIENTS: {
      const recipients = payload;
      return {
        ...state,
        recipients,
      };
    }
    case STORE_ADDITIONAL_INFO: {
      return {
        ...state,
        additionalInfo: payload,
      };
    }
    default:
      return state;
  }
};

export default appReducer;
