import { isObject } from 'lodash';
import { useDispatch } from 'react-redux';

import { IUser } from 'modules/Login/_types';
import { getUser } from 'modules/Login/_api';
import { StoreUser } from 'modules/Login/_redux';

const useFetchAndStoreUser = () => {
  const dispatch = useDispatch();

  const fetchAndStoreUser = async () => {
    const user: IUser = await getUser();
    if (isObject(user)) {
      dispatch(StoreUser(user));
      return user;
    } else {
      return Promise.reject(user);
    }
  };

  return { fetchAndStoreUser };
};

export default useFetchAndStoreUser;
