import { api } from 'utils/api';
import { get, isObject } from 'lodash';

export const loginUser = (values: any): Promise<any> =>
  api()
    .post(`/api/login`, values)
    .then(({ data }) => {
      if (get(data, 'success')) {
        return data;
      } else {
        return false;
      }
    })
    .catch((error) => Promise.reject(get(error, 'data', '')));

export const confirmLoginUser = (values: any): Promise<any> =>
  api()
    .post(`/api/confirm-login`, values)
    .then(({ data }) => {
      if (get(data, 'success')) {
        return data;
      } else {
        return false;
      }
    })
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const getUser = (): Promise<any> =>
  api()
    .post(`/api/user`)
    .then(({ data }) => (isObject(data) ? data : false))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const logoutUser = (): Promise<any> =>
  api()
    .post(`/api/logout`)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const headersOperation = (url: string): Promise<any> =>
  api()
    .get(url)
    .then(() => true)
    .catch((error) => Promise.reject(get(error, 'response.data', '')));
