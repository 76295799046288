import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Tooltip } from '@material-ui/core';

import { MTableBodyRow } from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import CreateIcon from '@material-ui/icons/Add';
import PeopleIcon from '@material-ui/icons/People';
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { isArray, get, isObject, split, compact, find } from 'lodash';

import useStyles from './_styles';
import Title from 'components/Title/Title';
import RecipientForm from './RecipientForm';
import GroupForm from './GroupForm';
import GroupManageForm from './GroupManageForm';
import usePhone from 'utils/hooks/usePhone';
import Confirm from 'components/Confirm/Confirm';
import useUserInfo from 'utils/hooks/useUserInfo';
import useAlerts from 'components/Alerts/useAlerts';
import useLoader from 'components/Loader/useLoader';
import BasicGrid from 'components/BasicGrid/BasicGrid';
import {
  getRecipients,
  deleteUserRecipient,
  deleteOrganizationRecipient,
  getUserGroups,
  deleteGroup,
  listGroupContacts,
} from './_api';
import { IRecipient, IGroup } from './_types';
import {
  ClearRemoteSearchAdditionalInfo,
  StoreRecipients,
  StoreInternalContacts,
  StoreGroupContacts,
} from './_redux';
import {
  ORGANIZATION_SHARED_CONTACTS,
  ORGANIZATION_PRIVATE_CONTACTS,
  DEFAULT_PAGE_SIZE_OPTIONS,
  DEFAULT_PAGE_SIZE,
  PERMISSION_ADMINISTRATING,
  ORGANIZATION_PERMISSION_DELETE_ORGANIZATION_CONTACT,
} from 'constants/constants';
import SelectedPersonIcon from '@material-ui/icons/Person';
import PersonFromDomainIcon from '@material-ui/icons/FolderShared';
import useConfig from 'utils/hooks/useConfig';
import { IInternalContacts } from './_types';

const InternalContacts: React.FC<IInternalContacts> = ({
  selectionOnly = false,
  closeRecipientRegistry,
  selectRecipients,
  remianingRecipients,
  selectionDisabled = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [recipientFormVisible, toggleRecipientForm] = useState(false);
  const [groupFormVisible, toggleGroupForm] = useState(false);
  const [editedRecipient, setEditedRecipient] = useState<IRecipient | boolean>(false);
  const [editedGroup, setEditedGroup] = useState<IGroup | boolean>(false);
  const [deletedRecipient, setDeletedRecipient] = useState<IRecipient | boolean>(false);
  const [deletedGroup, setDeletedGroup] = useState<IGroup | boolean>(false);
  const [managedGroup, setGroupForUserManagement] = useState<IGroup | boolean>(false);
  const [recipients, fetchRecipients] = useState<IRecipient[]>([]);
  const [groups, fetchGroups] = useState<IGroup[]>([]);
  const { toggleLoader } = useLoader();
  const {
    userId,
    user,
    actualOrganization,
    userIsOrgAdmin,
    userCanAddAsOrganizationContact,
    userHasPermissions,
    userHasOrganizationPermission,
  } = useUserInfo();
  const classes = useStyles();
  const { splitPhoneNumber } = usePhone();
  const { getConfigValue } = useConfig();
  const { t } = useTranslation();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const [bulkRecipientAddDisabled, toggleBulkRecipientAddDisabled] = useState<boolean>(false);
  const [lastSelectionChange, setLastSelectionChange] = useState<Date | null>(null);
  const [remainingRecipient, setRemainingRecipient] = useState<number>(0);
  const gridShouldPaginate = getConfigValue('pagination.contacts.paginate');
  const pageSize = getConfigValue('pagination.contacts.pageSize') || DEFAULT_PAGE_SIZE;
  const pageSizeOptions =
    getConfigValue('pagination.contacts.pageSizeOptions') || DEFAULT_PAGE_SIZE_OPTIONS;

  // Max recipients from config
  const maxRecipients = get(user, 'limits.MaxRecipients', 5);

  const actOrgId = get(user, 'actualOrganization.id', null);

  const organisationFlags = get(actualOrganization, 'flags', 0);

  const organisationHasFlagForSharedContacts =
    (organisationFlags & ORGANIZATION_SHARED_CONTACTS) === ORGANIZATION_SHARED_CONTACTS;

  const organisationDoesNotAllowPrivateContacts =
    (organisationFlags & ORGANIZATION_PRIVATE_CONTACTS) === ORGANIZATION_PRIVATE_CONTACTS;

  const userCanEditOrganizationContact =
    (actualOrganization != null && userIsOrgAdmin && organisationHasFlagForSharedContacts) ||
    userHasPermissions(PERMISSION_ADMINISTRATING) ||
    userHasOrganizationPermission(ORGANIZATION_PERMISSION_DELETE_ORGANIZATION_CONTACT);

  const userCanAddPrivateContacts = !organisationDoesNotAllowPrivateContacts;

  const showAddRecipientButton = userCanAddAsOrganizationContact || userCanAddPrivateContacts;

  const closeRecipientForm = () => {
    toggleRecipientForm(false);
    setEditedRecipient(false);
  };

  const closeGroupForm = () => {
    toggleGroupForm(false);
    setEditedGroup(false);
  };

  const closeDeletedRecipientDialog = () => setDeletedRecipient(false);
  const closeDeletedGroupDialog = () => setDeletedGroup(false);

  const prepareAndSetRecipientForEdit = (recipient: IRecipient) => {
    let recipientWithSplittedPhone: IRecipient | boolean = false;
    if (isObject(recipient)) {
      const [prefix, phoneNumber] = splitPhoneNumber(get(recipient, 'phone'));
      recipientWithSplittedPhone = {
        ...recipient,
        prefix,
        phoneNumber,
      };
    }
    setEditedRecipient(recipientWithSplittedPhone);
    toggleRecipientForm(true);
  };

  const prepareAndSetGroupForEdit = (group: IGroup) => {
    if (isObject(group)) {
      setEditedGroup(group);
      toggleGroupForm(true);
    }
  };

  const reloadGroupsAndSetGroupForEdit = async (group: IGroup) => {
    try {
      if (isObject(group)) {
        const groups = await getAllGroups();
        const newGrgoup = find(groups, { id: get(group, 'id') });
        setGroupForUserManagement(newGrgoup);
        return newGrgoup;
      }
    } catch (e) {}
  };

  const getAllRecipients = async () => {
    if (!selectionOnly) {
      toggleLoader();
    }
    try {
      const fetchedRecipients = await getRecipients(userId);
      if (isArray(fetchedRecipients)) {
        fetchRecipients(fetchedRecipients);
        dispatch(StoreInternalContacts(fetchedRecipients));
      }
    } catch (e) {
      console.debug(e);
    }
    if (!selectionOnly) {
      toggleLoader(false);
    }
  };

  const getAllGroups = async () => {
    toggleLoader();
    let fetchedGroups;
    try {
      const fetchedGroupsResponse = await getUserGroups(userId);
      if (isArray(fetchedGroupsResponse)) {
        fetchedGroups = fetchedGroupsResponse;
        fetchGroups(fetchedGroupsResponse);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
    return fetchedGroups;
  };

  useEffect(() => {
    getAllRecipients();
    getAllGroups();

    setRemainingRecipient(remianingRecipients !== undefined ? remianingRecipients : maxRecipients);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteSingleRecipient = async () => {
    try {
      if (get(deletedRecipient, 'isUserContact') === true) {
        await deleteUserRecipient({ sender: userId, recipient: get(deletedRecipient, 'userId') });
      } else {
        await deleteOrganizationRecipient({
          organization: actOrgId,
          recipient: get(deletedRecipient, 'userId'),
        });
      }
      await getAllRecipients();
      addSuccessAlert(t('recipientRegistry.deletingRecipientSucceeded'));
    } catch (e) {
      addErrorAlert(t('recipientRegistry.deletingRecipientFailed'));
    }
    closeDeletedRecipientDialog();
  };

  const deleteSingleGroup = async () => {
    try {
      await deleteGroup({ id: get(deletedGroup, 'id') });

      await getAllGroups();
      addSuccessAlert(t('recipientRegistry.contactGroup.dialog.deletingSucceeded'));
    } catch (e) {
      addErrorAlert(t('recipientRegistry.contactGroup.dialog.deletingFailed'));
    }
    closeDeletedGroupDialog();
  };

  const soActions = [
    {
      icon: AssignmentIndIcon,
      iconProps: {
        color: 'primary',
        fontSize: 'large',
      },
      tooltip: t('recipientRegistry.selectRecipient'),
      onClick: (_event: any, recipient: IRecipient) => {
        if (recipient && closeRecipientRegistry) {
          dispatch(ClearRemoteSearchAdditionalInfo());
          selectRecipients([recipient]);
          closeRecipientRegistry();
        }
      },
      position: 'row',
    },
    {
      icon: AssignmentTurnedInIcon,
      iconProps: {
        color: 'primary',
        fontSize: 'large',
      },
      disabled: bulkRecipientAddDisabled,
      tooltip: t(
        bulkRecipientAddDisabled
          ? 'recipientRegistry.recipientsLimitExceeded'
          : 'recipientRegistry.addAllRecipients'
      ),
      onClick: (_event: any, recipients: IRecipient[]) => {
        selectRecipients(recipients);
        dispatch(ClearRemoteSearchAdditionalInfo());
        if (closeRecipientRegistry) {
          closeRecipientRegistry();
        }
      },
      position: 'toolbarOnSelect',
    },
  ];

  const getGroupRecipients = async (groupId: number) => {
    let contacts = [];
    try {
      contacts = await listGroupContacts(groupId);
    } catch (e) {
      console.debug(e);
    }
    return contacts;
  };

  return (
    <>
      <div className={`${classes.title} ${classes.sectionMarginTop}`}>
        <Title title={t('recipientRegistry.contacts')} gutterBottom={false} />
      </div>

      {isArray(recipients) && (
        <BasicGrid
          data={recipients}
          columns={[
            {
              field: 'isUserByDomains',
              cellStyle: { padding: '0 16px' },
              render: (user: IRecipient) => {
                const isUserContact = get(user, 'isUserContact', false);
                return (
                  <>
                    {isUserContact ? (
                      <Tooltip title={t('recipientRegistry.isUserContact') || ''}>
                        <SelectedPersonIcon color="secondary" />
                      </Tooltip>
                    ) : (
                      <Tooltip title={t('recipientRegistry.isOrgContact') || ''}>
                        <PersonFromDomainIcon color="primary" />
                      </Tooltip>
                    )}
                  </>
                );
              },
            },
            { title: t('recipientRegistry.recipient.name'), field: 'name' },
            { title: t('recipientRegistry.recipient.email'), field: 'email' },
            { title: t('recipientRegistry.recipient.phone'), field: 'phone' },
            { title: t('recipientRegistry.recipient.note'), field: 'note' },
          ]}
          options={{
            selection: !selectionDisabled,
            ...(gridShouldPaginate ? { paging: true, pageSize, pageSizeOptions } : {}),
          }}
          actions={
            selectionOnly
              ? soActions
              : compact([
                  showAddRecipientButton
                    ? {
                        name: 'create',
                        icon: CreateIcon,
                        iconProps: {
                          color: 'primary',
                          fontSize: 'large',
                        },
                        isFreeAction: true,
                        tooltip: t('recipientRegistry.addNewRecipient'),
                        onClick: (_event: any, rowData: IRecipient) => toggleRecipientForm(true),
                      }
                    : null,
                  {
                    icon: AssignmentIndIcon,
                    iconProps: {
                      color: 'primary',
                      fontSize: 'large',
                    },
                    tooltip: t('recipientRegistry.selectRecipient'),
                    onClick: (_event: any, recipient: IRecipient) => {
                      if (recipient) {
                        const phone = split(recipient.phone, ' ', 2);
                        history.push(
                          `/?email=${recipient.email}&prefix=${phone[0]}&phoneNumber=${phone[1]}#upload`
                        );
                      }
                    },
                    position: 'row',
                  },
                  {
                    name: 'edit',
                    icon: EditIcon,
                    iconProps: {
                      color: 'primary',
                      fontSize: 'large',
                    },
                    tooltip: t('recipientRegistry.editRecipient'),
                    onClick: (_event: any, rowData: IRecipient) =>
                      prepareAndSetRecipientForEdit(rowData),
                    position: 'row',
                  },
                  {
                    name: 'delete',
                    icon: DeleteIcon,
                    iconProps: {
                      color: 'primary',
                      fontSize: 'large',
                    },
                    position: 'row',
                    tooltip: t('recipientRegistry.deleteRecipient'),
                    onClick: (_event: any, rowData: IRecipient) => setDeletedRecipient(rowData),
                  },
                  {
                    icon: AssignmentTurnedInIcon,
                    iconProps: {
                      color: 'primary',
                      fontSize: 'large',
                    },
                    disabled: bulkRecipientAddDisabled,
                    tooltip: t(
                      bulkRecipientAddDisabled
                        ? 'recipientRegistry.recipientsLimitExceeded'
                        : 'recipientRegistry.sendToSelectedRecipients'
                    ),
                    onClick: (_event: any, recipients: IRecipient[]) => {
                      dispatch(StoreRecipients(recipients));
                      history.push(`/#upload`);
                    },
                    position: 'toolbarOnSelect',
                  },
                  !selectionOnly
                    ? {
                        name: 'create',
                        icon: CreateIcon,
                        iconProps: {
                          color: 'primary',
                          fontSize: 'large',
                        },
                        position: 'toolbarOnSelect',
                        tooltip: t('recipientRegistry.addNewRecipient'),
                        onClick: (_event: any, rowData: IRecipient) => toggleRecipientForm(true),
                      }
                    : null,
                ])
          }
          components={
            selectionOnly
              ? {}
              : {
                  Row: (props: any) => {
                    const propsCopy = { ...props };
                    const isUserContact = get(propsCopy, 'data.isUserContact', false);
                    propsCopy.actions.find((a: any) => a.name === 'edit').disabled =
                      !isUserContact && !userCanEditOrganizationContact;
                    propsCopy.actions.find((a: any) => a.name === 'delete').disabled =
                      !isUserContact && !userCanEditOrganizationContact;
                    return <MTableBodyRow {...propsCopy} />;
                  },
                }
          }
          onSelectionChange={(rows: any) => {
            setLastSelectionChange(new Date());
            toggleBulkRecipientAddDisabled(rows.length > remainingRecipient);
          }}
          shouldRerender={lastSelectionChange}
          remaining={remainingRecipient}
        />
      )}

      <div className={`${classes.title} ${classes.sectionMarginTop}`}>
        <Title title={t('recipientRegistry.contactGroup.title')} gutterBottom={false} />
      </div>

      <div className={classes.groupsWrapper}>
        <BasicGrid
          data={groups}
          columns={[
            {
              field: 'isGroupForOrganisation',
              cellStyle: { padding: '0 16px' },
              render: (group: IGroup) => {
                const ifGroupForOrganisation = get(group, 'organization');
                return (
                  <>
                    {ifGroupForOrganisation ? (
                      <Tooltip
                        title={t('recipientRegistry.contactGroup.isGroupForOrganisation') || ''}
                      >
                        <PersonFromDomainIcon color="primary" />
                      </Tooltip>
                    ) : (
                      <Tooltip title={t('recipientRegistry.contactGroup.isGroupForUserOnly') || ''}>
                        <SelectedPersonIcon color="secondary" />
                      </Tooltip>
                    )}
                  </>
                );
              },
            },
            { title: t('recipientRegistry.contactGroup.name'), field: 'name' },
            {
              title: t('recipientRegistry.contactGroup.count'),
              field: 'count',
              render: (group: IGroup) => {
                return `${get(group, 'actualNumberOfContacts')}/${get(
                  group,
                  'maxNumberOfContacts'
                )}`;
              },
            },
          ]}
          actions={compact([
            !selectionOnly
              ? {
                  name: 'create',
                  icon: CreateIcon,
                  iconProps: {
                    color: 'primary',
                    fontSize: 'large',
                  },
                  isFreeAction: true,
                  tooltip: t('recipientRegistry.contactGroup.addNewGroup'),
                  onClick: (_event: any, rowData: IRecipient) => toggleGroupForm(true),
                }
              : null,
            !selectionOnly
              ? {
                  name: 'users',
                  icon: PeopleIcon,
                  iconProps: {
                    color: 'primary',
                    fontSize: 'large',
                  },
                  tooltip: t('recipientRegistry.contactGroup.manageUsers'),
                  onClick: (_event: any, rowData: IGroup) => setGroupForUserManagement(rowData),
                  position: 'row',
                }
              : null,
            (rowData: any) => {
              const groupHasNoRecipients =
                rowData.actualNumberOfContacts === '0' || rowData.actualNumberOfContacts === 0;
              const groupHasTooManyRecipients = rowData.actualNumberOfContacts > remainingRecipient;
              return {
                icon: AssignmentIndIcon,
                iconProps: {
                  color: 'primary',
                  fontSize: 'large',
                },
                tooltip: t(
                  groupHasTooManyRecipients
                    ? 'recipientRegistry.contactGroup.groupHasTooManyRecipients'
                    : groupHasNoRecipients
                    ? 'recipientRegistry.contactGroup.groupHasNoRecipients'
                    : 'recipientRegistry.selectGroup'
                ),
                disabled: groupHasNoRecipients || groupHasTooManyRecipients,
                onClick: async (_event: any, group: IGroup) => {
                  toggleLoader();
                  const groupContacs = await getGroupRecipients(get(group, 'id'));
                  if (groupContacs) {
                    dispatch(
                      StoreGroupContacts({ items: groupContacs, groupId: get(group, 'id') })
                    );
                  }
                  toggleLoader(false);
                  if (selectionOnly) {
                    selectRecipients(groupContacs);
                    if (closeRecipientRegistry) {
                      closeRecipientRegistry();
                    }
                  } else {
                    dispatch(StoreRecipients(groupContacs));
                    history.push(`/#upload`);
                  }
                },
                position: 'row',
              };
            },
            {
              name: 'edit',
              icon: EditIcon,
              iconProps: {
                color: 'primary',
                fontSize: 'large',
              },
              tooltip: t('recipientRegistry.contactGroup.editGroup'),
              onClick: (_event: any, rowData: IGroup) => prepareAndSetGroupForEdit(rowData),
              position: 'row',
            },
            {
              name: 'delete',
              icon: DeleteIcon,
              iconProps: {
                color: 'primary',
                fontSize: 'large',
              },
              position: 'row',
              tooltip: t('recipientRegistry.contactGroup.deleteGroup'),
              onClick: (_event: any, rowData: IGroup) => setDeletedGroup(rowData),
            },
          ])}
          components={
            selectionOnly
              ? {}
              : {
                  Row: (props: any) => {
                    const propsCopy = { ...props };
                    const organization = get(propsCopy, 'data.organization', false);
                    const editOrDeleteDisabled =
                      selectionOnly ||
                      (organization &&
                        !userIsOrgAdmin &&
                        !userHasPermissions(PERMISSION_ADMINISTRATING) &&
                        !userHasOrganizationPermission(
                          ORGANIZATION_PERMISSION_DELETE_ORGANIZATION_CONTACT
                        ))
                        ? true
                        : false;
                    propsCopy.actions.find((a: any) => a.name === 'edit').disabled =
                      editOrDeleteDisabled;

                    propsCopy.actions.find((a: any) => a.name === 'delete').disabled =
                      editOrDeleteDisabled;
                    return <MTableBodyRow {...propsCopy} />;
                  },
                }
          }
        />
      </div>

      {recipientFormVisible && (
        <RecipientForm
          closeDialog={closeRecipientForm}
          recipient={editedRecipient}
          isEdit={isObject(editedRecipient)}
          getAllRecipients={getAllRecipients}
          forcedSharedContact={!userCanAddPrivateContacts}
        />
      )}
      {groupFormVisible && (
        <GroupForm
          closeDialog={closeGroupForm}
          group={editedGroup}
          userId={userId}
          isEdit={isObject(editedGroup)}
          getAllGroups={getAllGroups}
        />
      )}
      {deletedRecipient && (
        <Confirm
          title={t('recipientRegistry.reallyDelete')}
          content={t('recipientRegistry.reallyDeleteUser', { name: get(deletedRecipient, 'name') })}
          onClose={closeDeletedRecipientDialog}
          onConfirm={deleteSingleRecipient}
        />
      )}
      {deletedGroup && (
        <Confirm
          title={t('recipientRegistry.reallyDelete')}
          content={t('recipientRegistry.reallyDeleteGroup', { name: get(deletedGroup, 'name') })}
          onClose={closeDeletedGroupDialog}
          onConfirm={deleteSingleGroup}
        />
      )}
      {managedGroup && (
        <GroupManageForm
          closeDialog={() => setGroupForUserManagement(false)}
          group={managedGroup}
          reloadGroupsAndSetGroupForEdit={reloadGroupsAndSetGroupForEdit}
          recipients={recipients}
        />
      )}
    </>
  );
};

export default InternalContacts;
