import * as yup from 'yup';
import useValidators from 'components/form/validations/useValidators';

export const useValidationSchemaRecipient = () => {
  const { required, email, number } = useValidators();

  const RecipientFormSchema = yup.object().shape({
    name: required(yup.string()),
    email: email(required(yup.string())),
    prefix: required(yup.string()),
    phoneNumber: required(number(yup.number())),
  });

  return RecipientFormSchema;
};

export const useValidationSchemaGroup = () => {
  const { required } = useValidators();

  const GroupFormSchema = yup.object().shape({
    name: required(yup.string()),
  });

  return GroupFormSchema;
};
