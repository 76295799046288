import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => {
  return createStyles({
    bottomWrapper: {
      marginTop: spacing(3),
      marginLeft: -spacing(3),
      marginRight: -spacing(3),

      [breakpoints.down('sm')]: {
        marginLeft: -spacing(1),
        marginRight: -spacing(1),
      },
    },
    bottom: {
      // width: '100%',
      display: 'block',
      height: 0,
      paddingBottom: '38.05%',
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
      backgroundRepeat: 'no-repeat',
    },
    bottomText: {
      display: 'none',
    },
    bannerLabel: {
      textAlign: 'right',
      marginRight: spacing(),
    },
    embed: {
      margin: '20px auto',
      maxWidth: '70%',
    },
    embedContainer: {
      position: 'relative',
      paddingBottom: '56.25%',
      height: 0,
      overflow: 'hidden',
      maxWidth: '100%',
      '& embed, & iframe, & object': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
    },
    embedContainerNotFullscreen: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 20,
    },
  });
});

export default useStyles;
