import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { lime, lightGreen, green, teal, amber } from '@material-ui/core/colors';
const useStyles = makeStyles(({ spacing, palette }: Theme) => {
  return createStyles({
    titleFiles: {
      marginTop: spacing(3),
    },
    menuIcon: {
      marginRight: spacing(1),
    },
    sender: {
      marginBottom: spacing(3),
    },
    senderItem: {
      marginLeft: spacing(1),
    },
    senderLabel: {
      fontWeight: 'bold',
    },
    // 0
    lime: {
      color: palette.getContrastText(lime[500]),
      backgroundColor: lime[500],
    },
    // 1
    lightGreen: {
      color: palette.getContrastText(lightGreen[500]),
      backgroundColor: lightGreen[500],
    },
    // 2
    green: {
      color: palette.getContrastText(green[500]),
      backgroundColor: green[500],
    },
    // 3
    teal: {
      color: palette.getContrastText(teal[500]),
      backgroundColor: teal[500],
    },
    // 4
    amber: {
      color: palette.getContrastText(teal[500]),
      backgroundColor: amber[500],
    },
  });
});

export default useStyles;
