import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    appError: {
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    title: {
      marginBottom: spacing(1)
    },
    actions: {
      justifyContent: 'center',
      paddingBottom: spacing(2)
    }
  })
);

export default useStyles;
