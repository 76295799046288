import { get, isObject } from 'lodash';
import { api } from 'utils/api';

export const getSessions = () =>
  api()
    .post(`/api/list-active-sessions`)
    .then(({ data }) => {
      if (isObject(data)) {
        return data;
      } else {
        return null;
      }
    })
    .catch((error) => Promise.reject(get(error, 'response.data', '')));
