import { get } from 'lodash';
import { api } from 'utils/api';

export const getOrganizationNameByInvitation = (invitation: any) =>
  api()
    .post(`/api/get-organization-name`, invitation)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const confirmInvitation = (invitation: any) =>
  api()
    .post(`/api/invitation-confirm`, { type: 'oinv', ...invitation })
    .then(({ data }) => data)
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

//

// {"type":"oinv","hash":"b8ad096821ff4272833c09b93a6c3f5a","organization":1,"userId":"1078"}
