import * as yup from 'yup';
import useValidators from 'components/form/validations/useValidators';

const useValidationSchema = () => {
  const { required, email } = useValidators();

  const LoginFormSchema = (validateLoginAsEmail = false) =>
    yup.object().shape({
      email: validateLoginAsEmail ? email(required(yup.string())) : required(yup.string()),
      password: required(yup.string())
    });

  return LoginFormSchema;
};

export default useValidationSchema;
