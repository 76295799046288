import React, { useState } from 'react';
import { get } from 'lodash';
import useStyles from './_styles';
import { IInputField } from './_types';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { FieldProps, Field, FieldConfig } from 'formik';
import { TextFieldProps } from '@material-ui/core/TextField';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const Input: React.FC<FieldProps<any> & IInputField> = ({
  field,
  form: { touched, errors },
  endAdornment = null,
  passwordRevealAllowed = false,
  ...props
}) => {
  const classes = useStyles();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  let type = get(props, 'type', 'text');
  let passwordAdornment = null;
  if (passwordRevealAllowed) {
    type = isPasswordVisible ? 'text' : 'password';
    passwordAdornment = (
      <InputAdornment position="end">
        <IconButton
          className={classes.passwordButton}
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
        >
          {isPasswordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
      </InputAdornment>
    );
  }

  const recievedAdornment = endAdornment ? (
    <InputAdornment position="end">{endAdornment}</InputAdornment>
  ) : null;

  return (
    <TextField
      {...field}
      {...props}
      type={type}
      id={field.name}
      helperText={touched[field.name] ? errors[field.name] : ''}
      error={touched[field.name] && Boolean(errors[field.name])}
      fullWidth={true}
      InputProps={{ endAdornment: passwordAdornment || recievedAdornment || null }}
    />
  );
};

const InputField: React.FC<IInputField & TextFieldProps & FieldConfig> = (props) => (
  <Field {...props} component={Input} />
);

export default InputField;
