import React from 'react';
import { ICheckboxField } from './_types';
import { FieldProps, Field, FieldConfig } from 'formik';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';

import useStyles from './_styles';

const CustomCheckbox: React.FC<FieldProps<any> & ICheckboxField> = ({
  field,
  form: { touched, errors },
  inline = false,
  ...props
}) => {
  const classes = useStyles();
  const hasError = touched[field.name] && Boolean(errors[field.name]);

  return (
    <>
      <div {...(inline ? { className: classes.inline } : {})}>
        <FormControlLabel
          control={<Checkbox {...field} {...props} color="primary" />}
          label={props.label}
          checked={field.value}
          {...(hasError ? { className: classes.errorLabel } : {})}
        />
      </div>
      {hasError ? <div className={classes.error}>{errors[field.name]}</div> : null}
    </>
  );
};

const CheckboxField: React.FC<ICheckboxField & CheckboxProps & FieldConfig> = props => (
  <Field {...props} component={CustomCheckbox} />
);

export default CheckboxField;
