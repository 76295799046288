import axios from 'axios';
import { get, indexOf, set } from 'lodash';
import { getAccessToken, putAccessTokens } from './hooks/useStorage';
import { API_NOT_INVALIDATING_TOKEN, API_WITHOUT_TOKEN } from 'constants/constants';

const getTokensFromHeaders = (headers: any) => {
  const accessTokens = get(headers, 'access-tokens', '');
  if (accessTokens !== '') {
    putAccessTokens(accessTokens);
  }
};

export const api = (settings = {}) => {
  const api = axios.create({
    headers: { 'Content-Type': 'application/json' },
    ...settings,
  });

  api.interceptors.request.use((request) => {
    if (indexOf(API_WITHOUT_TOKEN, request.url) === -1) {
      const token = getAccessToken(indexOf(API_NOT_INVALIDATING_TOKEN, request.url) === -1);
      if (token !== undefined && token != null) {
        const urlWithtoken = `${request.url}?accessToken=${token}`;
        set(request, 'url', urlWithtoken);
      }
    }
    return request;
  });

  api.interceptors.response.use(
    (response) => {
      getTokensFromHeaders(response.headers);
      return response;
    },
    (error) => {
      if (error.response.status === 403) {
        getTokensFromHeaders(error.response.headers);
        return Promise.reject(error.response);
      }
    }
  );

  return api;
};

export const generateCancelToken = () => {
  if (!get(window, 'axiosSource')) {
    const CancelToken = axios.CancelToken;
    const axiosSource = CancelToken.source();
    (window as any).axiosSource = axiosSource;
    return axiosSource;
  } else {
    return false;
  }
};

export const cancelAxiosRequest = () => {
  try {
    if (get(window, 'axiosSource')) {
      get(window, 'axiosSource').cancel('Operation cancelled by the user');
      delete (window as any).axiosSource;
    }
  } catch (e) {
    console.debug(e);
  }
};
