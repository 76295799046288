import { get } from 'lodash';

import { api } from 'utils/api';
import { IBackendConfig, IFrontendConfig } from 'modules/Login/_types';

export const getVersion = (): Promise<any> =>
  api()
    .post(`/api/version`)
    .then(({ data }) => data)
    .catch((error) => Promise.reject());

export const getBackendConfig = (op: string = 'list'): Promise<IBackendConfig> =>
  api()
    .post(`/api/ds-config`, { op })
    .then(({ data }) => (get(data, 'appendix') ? data : false))
    .catch((error) => Promise.reject());

export const getFrontendConfig = (): Promise<IFrontendConfig> =>
  api()
    .get(`/data/config.json`)
    .then(({ data }) => data)
    .catch((error) => Promise.reject());

export const getLanguage = (): Promise<any> =>
  api()
    .post(`/api/lang`)
    .then(({ data }) => data)
    .catch((error) => Promise.reject());

export const autologin = () =>
  api()
    .post(`/api/autologin`)
    .then(({ data }) => data)
    .catch((error) => true);

export const nopAPi = () =>
  api()
    .post(`/api/nop`)
    .then(({ data }) => data)
    .catch((error) => true);
