import {
  STORE_RECIPIENTS,
  STORE_ADDITIONAL_INFO,
  INTERNAL_CONTACTS_STORE,
  GROUP_CONTACTS_STORE,
} from 'constants/constants';
import { IRecipient } from './_types';

export const StoreRecipients = (recipients: IRecipient[]) => ({
  type: STORE_RECIPIENTS,
  payload: recipients,
});

export const StoreRemoteSearchAdditionalInfo = (additionalInfo: any) => ({
  type: STORE_ADDITIONAL_INFO,
  payload: additionalInfo,
});

export const ClearRemoteSearchAdditionalInfo = () => ({
  type: STORE_ADDITIONAL_INFO,
  payload: null,
});

export const StoreInternalContacts = (data: any) => ({
  type: INTERNAL_CONTACTS_STORE,
  payload: data,
});

export const StoreGroupContacts = (data: any) => ({
  type: GROUP_CONTACTS_STORE,
  payload: data,
});
