import { useSelector } from 'react-redux';
import { get, pick, isObject, keys } from 'lodash';

const useConfig = () => {
  const backendConfig = useSelector((state) => get(state, 'app.configBackend'));
  const frontendConfig = useSelector((state) => get(state, 'app.configFrontend'));

  const mixedConfig = {
    ...(isObject(backendConfig) ? pick(backendConfig, ['appendix', 'items']) : {}),
    ...(isObject(frontendConfig) ? frontendConfig : {}),
  };

  const getConfigValue = (key: string) => get(mixedConfig, key);
  const configLoaded = isObject(backendConfig) && keys(backendConfig).length > 0;

  return { configLoaded, getConfigValue };
};

export default useConfig;
