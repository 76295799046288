import React from 'react';
import { get } from 'lodash';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import { Grid, Button, Typography } from '@material-ui/core';

import { sendContactForm } from './_api';
import useValidationSchema from './_form';
import { ContactFormValues } from './_types';
import useUserInfo from 'utils/hooks/useUserInfo';
import useAlerts from 'components/Alerts/useAlerts';
import useLoader from 'components/Loader/useLoader';
import InputField from 'components/form/Input/Input';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';
import useRecaptcha from 'utils/hooks/useRecaptcha';

const ContactForm: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { toggleLoader } = useLoader();
  const ContactFormSchema = useValidationSchema();
  const { user, userIsAuthorized } = useUserInfo();
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const { captchaEnabled, generateCaptchaToken } = useRecaptcha();
  const recaptchaNeeded = captchaEnabled;

  const onSubmit = async (values: ContactFormValues, actions: FormikHelpers<ContactFormValues>) => {
    toggleLoader();

    let recaptcha;
    if (recaptchaNeeded) {
      recaptcha = await generateCaptchaToken('contact');
    }

    try {
      await sendContactForm({ ...values, ...(recaptchaNeeded ? { recaptcha } : {}) });
      addSuccessAlert(t('form.contactForm.sendingMessageSucceeded'));
      history.push('/user-home');
    } catch (e) {
      addErrorAlert(t('form.contactForm.sendingMessageFailed'));
    }
    toggleLoader(false);
  };

  return (
    <Formik
      initialValues={{
        email: userIsAuthorized ? get(user, 'email') : '',
        text: '',
      }}
      validationSchema={ContactFormSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <WhiteBox title={t('form.contactForm.title')}>
          <form onSubmit={handleSubmit}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <InputField
                  name="email"
                  autoComplete="email"
                  label={t('form.contactForm.yourEmail')}
                  disabled={userIsAuthorized}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <InputField
                  name="text"
                  label={t('form.contactForm.yourMessage')}
                  multiline={true}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth={true}
                >
                  {t('form.contactForm.sendMessageAction')}
                </Button>
              </Grid>
              <Grid item={true} xs={12}>
                <Typography variant="caption">{t('form.contactForm.info')}</Typography>
              </Grid>
            </Grid>
          </form>
        </WhiteBox>
      )}
    </Formik>
  );
};

export default ContactForm;
