import { isObject } from 'lodash';
import { api } from 'utils/api';

export const changeTotpRequest = (): Promise<any> =>
  api()
    .post('/api/change-totp-request', {})
    .then(() => true)
    .catch((error) => false);

export const changeTotp = (data: any): Promise<any> =>
  api()
    .post('/api/change-totp', { ...data })
    .then(({ data }) => (isObject(data) ? data : {}))
    .catch((error) => false);

export const updateTotp = (data: any): Promise<any> =>
  api()
    .post('/api/update-totp-settings', { ...data })
    .then(({ data }) => (isObject(data) ? data : {}))
    .catch((error) => false);
