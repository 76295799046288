import { useTranslation } from 'react-i18next';
import React from 'react';
import { Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';
import { ITotpHelpDialog } from './_types';

const TotpHelpDialog: React.FC<ITotpHelpDialog> = ({ closedialog, openDialog }) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog
        maxWidth="lg"
        aria-labelledby="help-dialog-title"
        open={openDialog}
        onClose={closedialog}
      >
        <DialogTitle>{t('profile.totp.dialog.help')}</DialogTitle>
        <DialogContent>
          <p>{t('profile.totp.dialog.helpText.p1')}</p>
          <p>{t('profile.totp.dialog.helpText.p2')}</p>
          <p>{t('profile.totp.dialog.helpText.p3')}</p>
          <p>
            {t('profile.totp.dialog.helpText.p4')} -{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=cs"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Authenticator(GP)
            </a>
            ,&nbsp;
            <a
              href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=cs"
              target="_blank"
              rel="noopener noreferrer"
            >
              Microsoft Authenticator(GP)
            </a>
            ,&nbsp;
            <a
              href="https://play.google.com/store/apps/details?id=org.fedorahosted.freeotp&hl=cs"
              target="_blank"
              rel="noopener noreferrer"
            >
              FreeOTP(GP)
            </a>
            ,&nbsp;
            <a
              href="https://apps.apple.com/cz/app/google-authenticator/id388497605?l=cs"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Authenticator(AS)
            </a>
            ,&nbsp;
            <a
              href="https://apps.apple.com/cz/app/microsoft-authenticator/id983156458?l=cs"
              target="_blank"
              rel="noopener noreferrer"
            >
              Microsoft Authenticator(AS)
            </a>
            {t('profile.totp.dialog.helpText.p4End')}
          </p>
          <p>{t('profile.totp.dialog.helpText.p5')}</p>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default TotpHelpDialog;
