import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 'bold',
      padding: theme.spacing(3),
      paddingBottom: 0
    }
  })
);

export default useStyles;
