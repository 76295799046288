import i18n from 'i18next';

const useI18n = () => {
  const changeLanguage = (appLanguage: string) => {
    i18n
      .changeLanguage(appLanguage)
      .then(t => {
        console.debug('Lnaguage changed to: ' + appLanguage);
      })
      .catch(err => {
        console.error('Something went wrong: ' + err);
      });
  };
  return { changeLanguage };
};

export default useI18n;
