import { useDispatch } from 'react-redux';
import { ToggleLoader } from './_redux';
import { FORCE_LOADING_OFF, FORCE_LOADING_ON } from 'constants/constants';

const useLoader = () => {
  const dispatch = useDispatch();

  const toggleLoader = (loaderState: boolean | string = true, showCancelButton = false) =>
    dispatch(ToggleLoader(loaderState, showCancelButton));

  const forceLoader = (showCancelButton = false) =>
    toggleLoader(FORCE_LOADING_ON, showCancelButton);

  const removeForcedLoader = (showCancelButton = false) =>
    toggleLoader(FORCE_LOADING_OFF, showCancelButton);

  return { toggleLoader, forceLoader, removeForcedLoader };
};

export default useLoader;
