import { get } from 'lodash';
import { api } from 'utils/api';

export const changePassword = (data: any) =>
  api()
    .post(`/api/chpass`, data)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const requestPasswordChange = () =>
  api()
    .post(`/api/change-password-request`, {})
    .then(({ data }) => get(data, 'success'))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));
