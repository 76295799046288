import { isArray, get } from 'lodash';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import React, { useState, useEffect } from 'react';
import { Container, Button, Grid, FormControlLabel, Switch, Tooltip } from '@material-ui/core';

import Title from 'components/Title/Title';
import Confirm from 'components/Confirm/Confirm';
import OrganizationForm from './OrganizationForm';
import useUserInfo from 'utils/hooks/useUserInfo';
import useLoader from 'components/Loader/useLoader';
import useAlerts from 'components/Alerts/useAlerts';
import { IOrganization, IUserGroup } from './_types';
import BasicGrid from 'components/BasicGrid/BasicGrid';
import { getOrganizations, deleteOrganization, revertOrganization, getUserGroups } from './_api';
import { LEVEL_L0, LEVEL_L1 } from 'constants/constants';
import { getUserOrganizations } from 'modules/Statistics/_api';

const ManageOrganizations: React.FC = () => {
  const history = useHistory();
  const [organizationFormVisible, toggleOrganizationForm] = useState(false);
  const [organizations, fetchOrganizations] = useState<IOrganization[]>([]);
  const [userGroups, fetchUserGroups] = useState<IUserGroup[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<IOrganization | boolean>(false);
  const { toggleLoader } = useLoader();
  const { t } = useTranslation();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { userIsAdminOrSuperAdmin } = useUserInfo();

  const closeOrganizationForm = () => toggleOrganizationForm(false);
  const closeSelectedOrganizationDialog = () => setSelectedOrganization(false);

  const editOrganization = (organization: IOrganization) => {
    history.push(`/organization-detail/${get(organization, 'id')}`);
  };

  const getAllOrganizations = async () => {
    toggleLoader();
    if (userIsAdminOrSuperAdmin) {
      try {
        const fetchedOrganizations = await getOrganizations();
        if (isArray(fetchedOrganizations)) {
          fetchOrganizations(fetchedOrganizations);
        }
      } catch (e) {
        console.debug(e);
      }
    } else {
      try {
        const fetchedOrganizations = await getUserOrganizations();
        if (isArray(fetchedOrganizations)) {
          fetchOrganizations(fetchedOrganizations);
        }
      } catch (e) {
        console.debug(e);
      }
    }
    toggleLoader(false);
  };

  const getAllUserGroups = async () => {
    toggleLoader();
    try {
      const fetchedUserGroups = await getUserGroups();
      if (isArray(fetchedUserGroups)) {
        const filteredUserGroups = fetchedUserGroups.filter(
          (userGroup) =>
            userGroup.userGroupId !== LEVEL_L0 &&
            userGroup.userGroupId !== LEVEL_L1 &&
            userGroup.userGroupId !== 'admin'
        );
        fetchUserGroups(filteredUserGroups);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    getAllOrganizations();
    getAllUserGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectOrganization = async (organization: boolean | IOrganization) => {
    toggleLoader();
    try {
      const deleted = get(organization, 'deleted', false);
      const fn = deleted ? revertOrganization : deleteOrganization;
      const response = await fn(organization);
      if (response) {
        await getAllOrganizations();
      }
      addSuccessAlert(
        t(
          deleted
            ? 'manageOrganizations.revertingOrganizationSucceeded'
            : 'manageOrganizations.deletingOrganizationSucceeded'
        )
      );
    } catch (e) {
      addErrorAlert(t('manageOrganizations.deletingOrRevertingOrganizationFailed'));
    }
    closeSelectedOrganizationDialog();
    toggleLoader(false);
  };

  return (
    <>
      <Title title={t('manageOrganizations.organizations')} />

      {userIsAdminOrSuperAdmin ? (
        <Container maxWidth="sm">
          <Grid container={true} justifyContent="center">
            <Grid item={true}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => toggleOrganizationForm(true)}
              >
                {t('manageOrganizations.addNewOrganization')}
              </Button>
            </Grid>
          </Grid>
        </Container>
      ) : null}

      {isArray(organizations) && (
        <BasicGrid
          maxWidth="sm"
          data={organizations}
          columns={[
            { title: t('manageOrganizations.organization.name'), field: 'name' },
            ...(userIsAdminOrSuperAdmin
              ? [
                  {
                    cellStyle: { padding: '0 16px' },
                    field: 'deleted',
                    render: (organization: IOrganization) => {
                      const isActive = !get(organization, 'deleted', false);
                      return (
                        <Tooltip
                          title={
                            isActive
                              ? t('manageOrganizations.organization.setOrganizationAsInActive') ||
                                ''
                              : t('manageOrganizations.organization.setOrganizationAsActive') || ''
                          }
                        >
                          <FormControlLabel
                            control={<Switch checked={isActive} />}
                            onChange={(e, state) => {
                              setSelectedOrganization(organization);
                            }}
                            label={t(
                              isActive
                                ? 'manageOrganizations.active'
                                : 'manageOrganizations.inactive'
                            )}
                          />
                        </Tooltip>
                      );
                    },
                  },
                ]
              : []),
          ]}
          actions={[
            (rowData: IOrganization) => ({
              icon: EditIcon,
              iconProps: {
                color: 'primary',
                fontSize: 'large',
              },
              tooltip: t('manageOrganizations.editOrganization'),
              onClick: (_event: any, rowData: IOrganization) => {
                editOrganization(rowData);
              },
              hidden: false,
            }),
          ]}
        />
      )}

      {organizationFormVisible && (
        <OrganizationForm
          closeDialog={closeOrganizationForm}
          getAllOrganizations={getAllOrganizations}
          userGroups={userGroups}
        />
      )}
      {selectedOrganization && (
        <Confirm
          title={t('manageOrganizations.organizationDialog.setOrganization')}
          content={
            get(selectedOrganization, 'deleted', false) === false
              ? t('manageOrganizations.organizationDialog.reallyUnSetOrganization', {
                  name: get(selectedOrganization, 'name'),
                })
              : t('manageOrganizations.organizationDialog.reallySetOrganization', {
                  name: get(selectedOrganization, 'name'),
                })
          }
          onClose={closeSelectedOrganizationDialog}
          onConfirm={() => selectOrganization(selectedOrganization)}
        />
      )}
    </>
  );
};

export default ManageOrganizations;
