import { useSelector } from 'react-redux';
import { get } from 'lodash';
import useConfig from 'utils/hooks/useConfig';
import { IUser } from 'modules/Login/_types';

const useHomePage = () => {
  const user: IUser = useSelector((state) => get(state, 'user'));
  const { getConfigValue } = useConfig();
  const homeRedirect = getConfigValue('homeRedirect');

  // Registrovaný uživatel - vždy přesměrovat na stránku s dlaždicemi
  // Neregistrovaný - přesměrovat na úvodní pro nepřihlášené uživatele
  // - na stránku dle configu v atributu homeRedirect
  // - pokud není nastaveno, pak na stránku pro zjednodušený upload)

  const homePageUrl =
    '/' + (user.isAuthorized ? 'user-home' : homeRedirect ? homeRedirect : '#upload');
  return homePageUrl;
};

export default useHomePage;
