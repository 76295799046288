import React, { useState } from 'react';
import { get, isObject } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogActions, Button, Grid } from '@material-ui/core';

import useValidationSchema from './_form';
import { Formik, FormikHelpers } from 'formik';
import { updateMainConfigFileSetting } from './_api';
import InputField from 'components/form/Input/Input';
import useAlerts from 'components/Alerts/useAlerts';
import useLoader from 'components/Loader/useLoader';
import { IMainConfigForm, IMainConfigFormValues, IJSONChange } from './_types';
import ReactJson from 'react-json-view';

const TemplateForm: React.FC<IMainConfigForm> = ({
  closeDialog,
  editedSetting,
  getSettingsOfMainConfigFile,
}) => {
  const { t } = useTranslation();
  const MainConfigFileFormSchema = useValidationSchema();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { toggleLoader } = useLoader();
  const [objectValue, setObjectValue] = useState<any>();

  const defaultvalues = {
    key: '',
    value: '',
    usingSubstitutionValue: false,
    valueType: 'string',
  };

  const initialValues = isObject(editedSetting) ? editedSetting : defaultvalues;

  const onJsonChange = (changed: IJSONChange) => {
    setObjectValue(get(changed, 'updated_src', defaultvalues));
  };

  const onSettingEdit = async (
    values: IMainConfigFormValues,
    actions: FormikHelpers<IMainConfigFormValues>
  ) => {
    toggleLoader();
    try {
      let valueType = get(values, 'valueType');
      if (valueType === 'number') {
        valueType = 'int';
      }

      const usingSubstitutionValue = get(values, 'usingSubstitutionValue', false);
      const value =
        objectValue === undefined
          ? usingSubstitutionValue
            ? JSON.stringify(get(values, 'originalValue', {}))
            : valueType === 'string'
            ? `"${get(values, 'value')}"`
            : get(values, 'value')
          : usingSubstitutionValue
          ? JSON.stringify(objectValue)
          : objectValue;

      await updateMainConfigFileSetting({
        key: get(values, 'key'),
        value,
        valueType,
      });
      await getSettingsOfMainConfigFile();
      closeDialog();
      addSuccessAlert(t('mainConfigManage.editSettingSucceeded') as string);
    } catch (e) {
      addErrorAlert(t('mainConfigManage.editSettingFailed') as string);
    }
    toggleLoader(false);
  };

  return (
    <Formik
      onSubmit={onSettingEdit}
      initialValues={initialValues}
      validationSchema={MainConfigFileFormSchema}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          aria-labelledby="confirmation-dialog-title"
          open={true}
          onClose={closeDialog}
        >
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <InputField
                    name="key"
                    label={t('mainConfigManage.dialog.key') as string}
                    disabled={true}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  {get(editedSetting, 'usingSubstitutionValue', false) ? (
                    <ReactJson
                      name={false}
                      displayObjectSize={false}
                      displayDataTypes={false}
                      sortKeys={true}
                      onAdd={onJsonChange}
                      onEdit={onJsonChange}
                      onDelete={onJsonChange}
                      enableClipboard={false}
                      src={get(editedSetting, 'originalValue', {})}
                      style={{ fontSize: 14 }}
                    />
                  ) : (
                    <InputField name="value" label={t('mainConfigManage.dialog.value') as string} />
                  )}
                </Grid>
                <Grid item={true} xs={12}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth={true}
                    disabled={isSubmitting}
                  >
                    {t('mainConfigManage.editMainConfigSettingMessage')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions />
        </Dialog>
      )}
    </Formik>
  );
};

export default TemplateForm;
