import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { get, includes, isString } from 'lodash';

import useAlerts from 'components/Alerts/useAlerts';
import { logoutUser, headersOperation } from 'modules/Login/_api';
import useFetchAndStoreUser from './useFetchAndStoreUser';
import { RemoveUser } from 'modules/Login/_redux';
import { PERMISSION_ADV_SHARE, PERMISSION_SHARE } from 'constants/constants';
import useLoader from 'components/Loader/useLoader';
import useLogin from 'utils/hooks/useLogin';

const useUserLogout = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { toggleLoader } = useLoader();
  const { oauthConfig } = useLogin();
  const { t } = useTranslation();
  const { fetchAndStoreUser } = useFetchAndStoreUser();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const headers = useSelector((state) =>
    get(state, 'app.configBackend.appendix.authentication.headers')
  );

  const userLogout = async (customForwardPath: string | undefined = undefined) => {
    toggleLoader();
    try {
      if (headers) {
        await Promise.all(
          get(headers, 'logout.operations', []).map(async (op: any) => await headersOperation(op))
        );
      }
      const forward = get(headers, 'logout.forward');
      const userLogoutResponse = await logoutUser();

      const user = await fetchAndStoreUser();
      const userPermissions = user.permission;
      const userCanUpload =
        includes(userPermissions, PERMISSION_SHARE) ||
        includes(userPermissions, PERMISSION_ADV_SHARE);

      dispatch(RemoveUser());

      if (get(userLogoutResponse, 'openId') && get(oauthConfig, 'logoutUri')) {
        if (get(oauthConfig, 'autologout')) {
          return (window.location.href = customForwardPath = get(oauthConfig, 'logoutUri'));
        } else {
          return history.push('/oauth-logout');
        }
      }

      if (isString(customForwardPath)) {
        return history.push(customForwardPath);
      }
      toggleLoader(false);

      // If user cannot upload, let's redirect to login automatically
      if (!userCanUpload) {
        return history.push('/login');
      }

      if (forward) {
        window.location.href = forward;
      } else {
        history.push('/home');
      }
      addSuccessAlert(t('login.userLogoutSuccess'));
    } catch (e) {
      addErrorAlert(t('login.userLogoutFailed'));
      return false;
    }
    toggleLoader(false);
  };

  return userLogout;
};

export default useUserLogout;
