import { get } from 'lodash';
import { api } from 'utils/api';
import { IUpdatedRow } from './_types';

export const getRows = () =>
  api()
    .post(`/api/ds-config`, { op: 'list', appendix: false, onlyWritable: true })
    .then(({ data }) => get(data, 'items'))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const updateRow = (data: IUpdatedRow) =>
  api()
    .post(`/api/ds-config`, { op: 'update', item: data })
    .then(({ data }) => get(data, 'success'))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));
