import { get, isArray, isEmpty, sumBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Grid, Button, Container } from '@material-ui/core';
import { parseISO, format } from 'date-fns';
import Title from 'components/Title/Title';
import useTransfer from 'utils/hooks/useTransfer';
import BasicGrid from 'components/BasicGrid/BasicGrid';
import { IFile, ITransferDetail } from 'modules/TransferDetail/_types';
import { getTransferFiles, getTransferDetail } from 'modules/TransferDetail/_api';
import { checkIfLoginRequired } from 'modules/DownloadFiles/_api';
import Login from 'modules/Login/Login';
import LinkDialog from './LinkDialog';
import { createTransferLink } from './_api';
import useAlerts from 'components/Alerts/useAlerts';
import useUrl from 'utils/hooks/useUrl';

const LinkPage: React.FC = () => {
  const { t } = useTranslation();
  const { mapAllUrlParamsToObject } = useUrl();
  const params = mapAllUrlParamsToObject();
  const transferId = parseInt(get(params, 'id', 0), 0);
  const [username, setUsername] = useState<string>('');
  const [loginRequired, setLoginRequired] = useState<boolean | null>(null);
  const [transferDetail, fetchTransferDetail] = useState<ITransferDetail[]>();
  const [fileInfoTitle, setFileInfoTitle] = useState<string>('');
  const [files, fetchFiles] = useState<IFile[]>([]);
  const [hasFiles, setHasFiles] = useState<boolean>(false);
  const { transferHasFiles, formatFileSize, getIconForFileType } = useTransfer();
  const [showLink, setShowLink] = useState<any | boolean>(false);
  const { addErrorAlert } = useAlerts();

  const calculateSize = (bytes: number, decimals: number = 0) => {
    if (bytes === 0) return '0 B';
    var k = 1024,
      dm = decimals <= 0 ? 0 : decimals || 2,
      sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const initialValues = async () => {
    if (transferId !== 0) {
      const loginReuiredResponse = await checkIfLoginRequired(transferId, null);
      const isLoginRequired = get(loginReuiredResponse, 'loginRequired', true);
      setLoginRequired(isLoginRequired);
      setUsername(get(loginReuiredResponse, 'login', ''));
      if (!isLoginRequired) {
        const { files: items } = await getTransferFiles(transferId);
        const transfeDetail = await getTransferDetail(
          transferId,
          undefined,
          undefined,
          undefined,
          'out'
        );
        if (!isEmpty(transfeDetail)) {
          fetchTransferDetail([transfeDetail]);
        }
        setFileInfoTitle(
          ` ${t('files.downloadFiles.fileCount')}: ${items.length} (${calculateSize(
            sumBy(items, (item: IFile) => {
              return get(item, 'size', 0);
            }),
            2
          )}, ${t('files.downloadFiles.expiration')}: ${format(
            parseISO(get(transfeDetail, 'expiration')),
            'd. M. y HH:mm:ss'
          )})`
        );
        fetchFiles(items);
        setHasFiles(transferHasFiles(items));
      }
    }
  };

  const createLink = async () => {
    try {
      const link = await createTransferLink(transferId);
      if (link) {
        setShowLink(link);
      } else {
        throw new Error();
      }
    } catch (e) {
      addErrorAlert(t('files.createTransferLinkFailed'));
    }
  };
  const closeShowLinkDialog = () => setShowLink(false);

  const closeLoginDialog = () => {
    setLoginRequired(false);
    initialValues();
  };

  const transferHasTextMessages = get(transferDetail, '[0].numberOfMessages', 0) > 0;

  useEffect(() => {
    initialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title={t('files.detail.title')} />
      <Title title={`${t('files.detail.transferId')}: ${transferId}`} subtitle={true} />
      {transferId === 0 ? (
        <></>
      ) : (
        <>
          {loginRequired != null ? (
            loginRequired ? (
              <Login
                email={username}
                password={''}
                isDialog={true}
                closeLoginDialog={closeLoginDialog}
              />
            ) : (
              <Grid container={true} spacing={2}>
                {isArray(transferDetail) && !isEmpty(transferDetail) ? (
                  <BasicGrid
                    data={transferDetail}
                    columns={[
                      {
                        title: t('files.downloadFiles.senderName'),
                        field: 'senderName',
                      },
                      {
                        title: t('files.downloadFiles.senderEmail'),
                        field: 'senderEmail',
                      },
                    ]}
                    options={{ search: false, toolbar: false }}
                    topMargin={false}
                  />
                ) : (
                  <></>
                )}
                {hasFiles || transferHasTextMessages ? (
                  <>
                    <Container maxWidth="md" style={{ marginTop: '15px' }}>
                      <Grid container={true} justifyContent="center">
                        <Grid item={true}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth={true}
                            onClick={() => {
                              createLink();
                            }}
                          >
                            {t('files.file.showLink')}
                          </Button>
                        </Grid>
                      </Grid>
                      {hasFiles ? <Title title={fileInfoTitle} subtitle={true} /> : null}
                    </Container>

                    {hasFiles ? (
                      <BasicGrid
                        data={files}
                        columns={[
                          {
                            field: 'fileType',
                            render: (rowData: IFile) =>
                              getIconForFileType(get(rowData, 'fileType')),
                          },
                          {
                            field: 'name',
                          },
                          {
                            field: 'size',
                            render: (rowData: IFile) => formatFileSize(get(rowData, 'size', 0)),
                          },
                        ]}
                        actions={[]}
                        options={{ search: false, toolbar: false, header: false }}
                        topMargin={false}
                      />
                    ) : null}
                    {showLink && (
                      <LinkDialog
                        title={t('files.showLinkLabel')}
                        content={showLink}
                        onClose={closeShowLinkDialog}
                      />
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            )
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default LinkPage;
