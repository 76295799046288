import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

const AutoSubmitForm = () => {
  const { submitForm } = useFormikContext();

  useEffect(() => {
    submitForm();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};

export default AutoSubmitForm;
