import { createTheme } from '@material-ui/core/styles';

export const themeVariables = {
  colors: {
    grey: '#F3F3F3',
    blue: '#086bbc',
  },
};

const theme = {
  palette: {
    primary: {
      main: themeVariables.colors.blue,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: { fontSize: 14 },
    },
  },
};

const themeByConfig = (primaryColor: string) => {
  return createTheme({
    ...theme,
    palette: { primary: { main: primaryColor } },
  });
};
export { themeByConfig };

export default createTheme(theme);
