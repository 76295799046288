import React from 'react';
import { get, isObject } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogActions, Button, Grid } from '@material-ui/core';

import useValidationSchema from './_form';
import { Formik, FormikHelpers } from 'formik';
import { updateTemplate } from './_api';
import InputField from 'components/form/Input/Input';
import { ITemplateFormValues, ITemplateForm } from './_types';
import useAlerts from 'components/Alerts/useAlerts';
import useLoader from 'components/Loader/useLoader';

const TemplateForm: React.FC<ITemplateForm> = ({
  closeDialog,
  editedTemplate,
  getAllTemplates,
  selectedLanguage,
  organizationId,
}) => {
  const { t } = useTranslation();
  const TemplateFormSchema = useValidationSchema();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { toggleLoader } = useLoader();

  let initialValues = isObject(editedTemplate) ? editedTemplate : { key: '', value: '' };

  const onTemplateEdit = async (
    values: ITemplateFormValues,
    actions: FormikHelpers<ITemplateFormValues>
  ) => {
    toggleLoader();
    try {
      isNaN(organizationId)
        ? await updateTemplate({
            key: get(values, 'key', null),
            values: { [selectedLanguage]: get(values, 'value', null) },
            organizationId: null,
          })
        : await updateTemplate({
            key: get(values, 'key', null),
            values: { [selectedLanguage]: get(values, 'value', null) },
            organizationId: organizationId,
          });
      await getAllTemplates();
      closeDialog();
      addSuccessAlert(t('templatesManage.editTemplateSucceeded') as string);
    } catch (e) {
      addErrorAlert(t('templatesManage.editTemplateFailed') as string);
    }
    toggleLoader(false);
  };

  return (
    <Formik
      onSubmit={onTemplateEdit}
      initialValues={initialValues}
      validationSchema={TemplateFormSchema}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          aria-labelledby="confirmation-dialog-title"
          open={true}
          onClose={closeDialog}
        >
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <InputField
                    name="key"
                    label={t('templatesManage.dialog.key') as string}
                    disabled={true}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <InputField
                    name="value"
                    multiline={true}
                    label={t('templatesManage.dialog.value') as string}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth={true}
                    disabled={isSubmitting}
                  >
                    {t('templatesManage.editLanguageMessage') as string}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions />
        </Dialog>
      )}
    </Formik>
  );
};

export default TemplateForm;
