import * as yup from 'yup';
import useValidators from 'components/form/validations/useValidators';

const useValidationSchema = () => {
  const { email, required } = useValidators();

  const ContactFormSchema = yup.object().shape({
    email: email(required(yup.string())),
    text: required(yup.string())
  });

  return ContactFormSchema;
};

export default useValidationSchema;
