import React from 'react';
import { Link } from 'react-router-dom';
import { ILoginForm } from './_types';
import { useTranslation } from 'react-i18next';
import { Grid, Button } from '@material-ui/core';
import InputField from 'components/form/Input/Input';

const LoginForm: React.FC<ILoginForm> = ({
  email,
  handleSubmit,
  labelForEmail,
  isSubmitting,
  canRecoverPassword,
}) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <Grid container={true} spacing={2} justifyContent="center">
        <Grid item={true} xs={12}>
          <InputField name="email" label={labelForEmail} disabled={email !== ''} autoFocus={true} />
        </Grid>
        <Grid item={true} xs={12}>
          <InputField
            name="password"
            label={t('login.password')}
            type="password"
            passwordRevealAllowed={true}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            fullWidth={true}
          >
            {t('login.loginAction')}
          </Button>
        </Grid>
        {canRecoverPassword && (
          <Grid item={true}>
            <Link to="/forgotten-password">{t('login.fogottenPassword')}</Link>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default LoginForm;
