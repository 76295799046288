import * as yup from 'yup';
import useValidators from 'components/form/validations/useValidators';

const useValidationSchema = () => {
  const { required } = useValidators();

  const AddPhoneSchema = yup.object().shape({
    prefix: required(yup.string()),
    phoneNumber: required(yup.string())
  });

  const PhoneVerificationSchema = yup.object().shape({
    smsCode: required(yup.string())
  });

  return { AddPhoneSchema, PhoneVerificationSchema };
};

export default useValidationSchema;
