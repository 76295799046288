import { useHistory } from 'react-router';
import { addDays, format } from 'date-fns';
import { ResumableFile } from 'resumablejs';
import ClearIcon from '@material-ui/icons/Clear';
import PeopleIcon from '@material-ui/icons/People';
import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import UploadIcon from '@material-ui/icons/CloudUpload';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { Formik, FormikHelpers, FieldArray, ErrorMessage } from 'formik';
import { Grid, Button, Fab, Dialog } from '@material-ui/core';
import {
  get,
  set,
  each,
  find,
  uniq,
  keys,
  trim,
  split,
  filter,
  isEmpty,
  isArray,
  isNumber,
  includes,
  isString,
  isObject,
  reverse,
  values,
  omit,
  max,
  orderBy,
  compact,
} from 'lodash';

import { userHasMoreOrganizations } from 'utils/hooks/useUserInfo';
import { ToggleOrganizationDialog } from 'components/OrganizationDialog/_redux';

import Files from './Files';
import useStyles from './_styles';
import Resumable from './Resumable';
import useUrl from 'utils/hooks/useUrl';
import Login from 'modules/Login/Login';
import useValidationSchema from './_form';
import Title from 'components/Title/Title';
import Phone from 'components/form/Phone/Phone';
import UploadFormSubmit from './UploadFormSubmit';
import useUserInfo from 'utils/hooks/useUserInfo';
import useTransfer from 'utils/hooks/useTransfer';
import useAlerts from 'components/Alerts/useAlerts';
import useLoader from 'components/Loader/useLoader';
import InputField from 'components/form/Input/Input';
import { ToggleResumableUploadState } from './_redux';
import useFileUpload from 'utils/hooks/useFileUpload';
import { IRecipient } from 'modules/RecipientRegistry/_types';
import { ClearRemoteSearchAdditionalInfo, StoreRecipients } from 'modules/RecipientRegistry/_redux';
import { IRemoteSourceItem, IRemoteSource } from 'components/RemoteUploadDialog/_types';
import RecipientRegistry from 'modules/RecipientRegistry/RecipientRegistry';
import {
  UploadFormValues,
  ITransferConfirmData,
  IDecryptedToken,
  ITransferUploadInfoResponse,
} from './_types';
import {
  transferStart,
  transferUploadStart,
  transferConfirm,
  transferUploadDone,
  unauthorizedUploadStart,
  unauthorizedUploadDone,
  remoteSourceUpload,
  tokenDecrypt,
  limitsExceededNotification,
  getTransferUploadInfo,
} from './_api';
import { IUser } from 'modules/Login/_types';
import useFetchAndStoreUser from 'utils/hooks/useFetchAndStoreUser';
import Confirm from 'components/Confirm/Confirm';
import useConfig from 'utils/hooks/useConfig';
import usePhone from 'utils/hooks/usePhone';
import {
  DEFAULT_TOKEN_ACTION,
  VALID_TOKEN_ACTIONS,
  TOKEN_ACTION_REMOTE_SOURCE_SELECT,
  TOKEN_ACTION_REMOTE_SOURCE_SEARCH,
  PERMISSION_SHARE,
  PERMISSION_ADV_SHARE,
  TOKEN_ACTION_REMOTE_UPLOAD,
  INTERNAL_CONTACTS_SAVE_MODE_OPTIONAL,
} from 'constants/constants';
import DialogTitleWithClose from 'components/DialogTitleWithClose/DialogTitleWithClose';
import { BottomBanner } from 'components/BottomBanner/BottomBanner';
import useContacts from 'utils/hooks/useContacts';
import useStorage from 'utils/hooks/useStorage';
import useLogin from 'utils/hooks/useLogin';
import LoginSelect from 'modules/Login/LoginSelect';
import useFetchAndStoreBackendConfig from 'utils/hooks/useFetchAndStoreBackendConfig';

let uploadInfoTimeoutToken: any;
let storeContacts: boolean = false;

const CustomArrayError = ({ name }: any) => (
  <ErrorMessage name={name}>{(msg) => <div style={{ color: 'red' }}>{msg}</div>}</ErrorMessage>
);

const Upload: React.FC = () => {
  const { getParamValue, mapAllUrlParamsToObject } = useUrl();
  const { t } = useTranslation();
  const { fetchAndStoreUser } = useFetchAndStoreUser();
  const { UploadFormSchema, UploadLinkFormSchema, UploadFormSchemaUnAuthorized } =
    useValidationSchema();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { getConfigValue, configLoaded } = useConfig();
  const {
    userIsAuthorized,
    user,
    actualOrganization,
    userInsteadOfOrganization,
    userIsNotLoggedIn,
  } = useUserInfo();
  const orgFlags = get(actualOrganization, 'flags');
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [tokenDecryptedForSecondTime, setTokenDecryptedForSecondTime] = useState<boolean>(false);
  const [remoteUploadedFiles, setRemoteUploadedFiles] = useState<IRemoteSourceItem[]>([]);
  const [showRecipientRegistry, toggleRecipientRegistry] = useState<boolean>(false);
  const [showRegistrationDialog, setShowRegistrationDialog] = useState<boolean>(false);
  const [showInternalContactsSaveDialog, setShowInternalContactsSaveDialog] =
    useState<boolean>(false);

  const [recipientsFromStore, setRecipientsFromStore] = useState<any[]>([]);
  const [remoteDialogVisible, toggleRemoteUploadDialog] = useState(false);
  const [uploadedInfoForRemoteUpload, setUploadedInfoForRemoteUpload] =
    useState<ITransferUploadInfoResponse | null>(null);
  const [selectedRemoteSource, setRemoteSource] = useState<IRemoteSource | null>(null);
  const { toggleLoader, forceLoader, removeForcedLoader } = useLoader();
  const { addErrorAlert, addSuccessAlert, addInfoAlert } = useAlerts();
  const { getAllUploadedFilesSize } = useTransfer();
  const history = useHistory();
  const { hash } = useLocation();
  const enableFilesWithoutExtension = getConfigValue('appendix.upload.enableFilesWithoutExtension');
  const disabledExtensions = getConfigValue('appendix.upload.disabledExtensions');
  const enableOnlySelectedRecipients = getConfigValue(
    'appendix.upload.enableOnlySelectedRecipients'
  );

  const enabledDomains = getConfigValue('appendix.upload.enabledDomains');
  const enabledEmails = getConfigValue('appendix.upload.enabledEmails');
  const { get: storageGet } = useStorage();

  const storeRecipients: IRecipient[] = useSelector((state) => get(state, 'app.recipients'));
  const [transferDelayVisible, toggleTransferDelatVisible] = useState<boolean>(false);
  const [anonymizeSnederMailVisible, toggleAnonymizeSnederMailVisible] = useState<boolean>(false);
  const [sharedTransfer, toggleSharedTransfer] = useState<boolean>(false);
  const { anonymizations } = useSelector((state) => get(state, 'user'), shallowEqual);
  const [textMessage, setTextMessage] = useState<string>();
  const { contactsDisabled, internalContactsSaveMode } = useContacts();
  const { hasOnlyOneLoginMethod, theOnlyOneLoginMethodIsLocalForm } = useLogin();
  const hasOnlyLocalForm = hasOnlyOneLoginMethod && theOnlyOneLoginMethodIsLocalForm;
  const [localFormSelectedForLogin, setLocalFormSelectedForLogin] = useState<boolean>(false);
  const { fetchAndStoreBackendConfig } = useFetchAndStoreBackendConfig();

  // Add manually localstorage item called "sejf.debugUpload" with whatever value to allow debug
  const debugUpload = storageGet('debugUpload') !== null;
  const debugToConsole = useCallback(
    (name: string, item: any) => {
      if (debugUpload) {
        console.group(name);
        console.debug(item);
        console.groupEnd();
      }
    },
    [debugUpload]
  );

  useEffect(() => {
    if (!isEmpty(storeRecipients)) {
      const recipientsFromStore = storeRecipients.map((recipient) => {
        const phone = split(recipient.phone, ' ', 2);
        return { email: recipient.email, prefix: phone[0], phoneNumber: phone[1] };
      });
      setRecipientsFromStore(recipientsFromStore);
      dispatch(StoreRecipients([]));
    }

    return () => {
      // On unmount, clear any additional info about automatic search in remote source
      dispatch(ClearRemoteSearchAdditionalInfo());

      // Clear timeout as well
      clearTimeout(uploadInfoTimeoutToken);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    getPrimaryRecipient,
    getOtherRecipients,
    validateUploadDone,
    validateUploadStart,
    validateUploadStartUnAuth,
    convertRecipientForForm,
    getFileExtension,
    recipientIsEmpty,
  } = useFileUpload();

  const { joinPhoneNumber, testIfPhoneHasRightFormat } = usePhone();

  const email = getParamValue('email');
  const prefix = getParamValue('prefix');
  const phoneNumber = getParamValue('phoneNumber');

  let recipientFromUrl = null;
  if (email !== undefined && prefix !== undefined && phoneNumber !== undefined) {
    recipientFromUrl = { email, prefix, phoneNumber };
  }

  // Set all variables neccessary for transfer-upload-done api
  const [transferCode, setTransferCode] = useState<string | null>(null);
  const [localTransferPrefix, setLocalTransferPrefix] = useState(null);
  const [transferID, setTransferID] = useState<number | null>(null);

  const [verifyEmail, setVerifyEmail] = useState(null);
  const [loginRequired, setLoginRequired] = useState<boolean | null>(null);
  const [loginUsername, setLoginUsername] = useState<string>('');
  const [tokenAlreadyDecrypted, setTokenAlreadyDecrypted] = useState<boolean>(false);
  const [decryptedToken, setDecryptedToken] = useState<null | IDecryptedToken>(null);

  // Link or basic upload
  const isLinkMode = window.location.href.indexOf('&mode=link') > -1;

  // Max recipients from config
  const maxRecipients = isLinkMode ? 1 : get(user, 'limits.MaxRecipients', 5);

  // Access ResumableField
  const ResumableField = (window as any).StoredResumableField;

  const urlContainsUploadToken = hash.indexOf('&token=') > -1 && hash.indexOf('#upload') > -1;

  const params = mapAllUrlParamsToObject(hash.replace('#upload&', ''));
  const token = get(params, 'token');
  const operation = get(params, 'op') || DEFAULT_TOKEN_ACTION;

  const isRemoteUploadOperation = operation === TOKEN_ACTION_REMOTE_UPLOAD;

  // Check if user can upload if not logged in
  // If not, redirect to registration page
  useEffect(() => {
    const userPermissions = user.permission;

    const userCanUpload =
      includes(userPermissions, PERMISSION_SHARE) ||
      includes(userPermissions, PERMISSION_ADV_SHARE);
    const userIsNotLoggedInAndCannotUpload = userIsNotLoggedIn && !userCanUpload;

    if (userIsNotLoggedInAndCannotUpload && !token) {
      history.push('/login');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.permission]);

  // When token changes, set everything to default and start from scratch
  useEffect(() => {
    if (urlContainsUploadToken) {
      toggleRemoteUploadDialog(false);
      setTokenAlreadyDecrypted(false);
      setDecryptedToken(null);
      setLoginRequired(null);
      setLoginUsername('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const firstDecrypt = async () => {
    forceLoader();
    await tokenDecrypt(operation, token).then(async (response) => {
      debugToConsole('First decrypt', response);
      const loginRequired = get(response, 'loginRequired');
      const autologged = get(response, 'autologged');
      const login = get(response, 'login');
      if (autologged) {
        await Promise.all([fetchAndStoreUser(), fetchAndStoreBackendConfig()]);
      }
      if (loginRequired) {
        setLoginRequired(loginRequired);
        if (isString(login)) {
          setLoginUsername(login);
        }
      }
    });
    removeForcedLoader();
  };

  // First token decrypt - fire login form if not logged in
  useEffect(() => {
    if (urlContainsUploadToken) {
      if (token && includes(VALID_TOKEN_ACTIONS, operation)) {
        firstDecrypt();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const secondDecrypt = async () => {
    setTokenDecryptedForSecondTime(true);
    forceLoader();
    await tokenDecrypt(operation, token).then(async (response) => {
      const autologged = get(response, 'autologged');
      debugToConsole('Second decrypt', response);
      if (autologged) {
        debugToConsole('Second decrypt', 'Autologged, fetching user and BE config');
        await Promise.all([fetchAndStoreUser(), fetchAndStoreBackendConfig()]);
      }
      setTokenAlreadyDecrypted(true);
      setDecryptedToken(response);
    });
    removeForcedLoader();
  };

  const showLoginSelectDialog = () => {
    setLocalFormSelectedForLogin(true);
  };

  const customCloseLoginDialogFn = () => {
    setLocalFormSelectedForLogin(false);
  };

  // Second token decrypt - get the actual information from token
  useEffect(() => {
    if (urlContainsUploadToken && !tokenAlreadyDecrypted && !tokenDecryptedForSecondTime) {
      // If there is no organisation selected and should be, fire dialog for org select
      if (
        userHasMoreOrganizations(user) &&
        !(get(actualOrganization, 'id') || userInsteadOfOrganization)
      ) {
        dispatch(ToggleOrganizationDialog(true));
      }

      // Kontrolovat jen pokud je nějaká organizace
      const shouldAskForDecryptToken = isEmpty(get(user, 'organizations', []))
        ? userIsAuthorized
        : userIsAuthorized && (get(actualOrganization, 'id') || userInsteadOfOrganization)
        ? true
        : false;
      if (shouldAskForDecryptToken) {
        secondDecrypt();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userIsAuthorized,
    user,
    actualOrganization,
    userInsteadOfOrganization,
    tokenAlreadyDecrypted,
  ]);

  // Third part - use decrypted info
  useEffect(() => {
    if (urlContainsUploadToken && configLoaded) {
      const allowedSources = getConfigValue('appendix.upload.remoteSources');
      const allowedSourcesNames = isArray(allowedSources)
        ? allowedSources.map((source: IRemoteSource) => source.name)
        : [];

      debugToConsole('Allowed sources from config', allowedSources);

      if (operation === TOKEN_ACTION_REMOTE_SOURCE_SELECT) {
        const sources = get(decryptedToken, 'sources');

        debugToConsole('Sources in token', sources);

        debugToConsole('Conditional to add items from sources (all must be true)', {
          decryptionTokenNotNull: decryptedToken !== null,
          sourcesNotNull: sources !== null,
          sourcesNotUndefined: sources !== undefined,
          sourcesIsArray: isArray(sources),
          sourcesLength: sources !== undefined && sources?.length > 0,
          finalConditional:
            decryptedToken !== null &&
            sources !== null &&
            sources !== undefined &&
            isArray(sources) &&
            sources?.length > 0,
        });

        if (
          decryptedToken !== null &&
          sources !== null &&
          sources !== undefined &&
          isArray(sources) &&
          sources?.length > 0
        ) {
          each(sources, (source) => {
            const sourceName = get(source, 'name');

            debugToConsole('Allowed sources contain source from token', {
              sourceName,
              sourceIncludedInAllowedSources: includes(allowedSourcesNames, sourceName),
            });

            if (includes(allowedSourcesNames, sourceName)) {
              // console.debug({
              //   decryptedToken,
              //   source,
              //   currentSource: find(allowedSources, { name: sourceName }),
              // });
              addRemoteFiles(
                get(source, 'items').map((item) => {
                  // Find level based on item index and omit index itself, its useless afterwards
                  const levelForItem = omit(find(source.levels, { $index: item.$index }), '$index');

                  // Max number from the rest of the params = current level
                  const currentLevelIndex = max(values(levelForItem));

                  // Get itemDescriptionParams for the current level from source
                  const itemDescriptionParamsForItem = get(
                    find(get(find(allowedSources, { name: sourceName }), 'levels'), {
                      level: currentLevelIndex,
                    }),
                    'itemDescriptionParams'
                  );

                  // Merge the level info with values and paramNames, so its one object containing all the info
                  const zippedItemWithLevel = keys(levelForItem).map((key) => ({
                    name: key,
                    value: get(item, key),
                    level: get(levelForItem, key),
                  }));

                  // Empty array for all the params
                  const description: string[] = [];

                  // Cycle through the ordered description params and find the values
                  // Based on paramName and level (there could be some params with same name on different levels, so it HAS to be based on both name and level)
                  each(orderBy(itemDescriptionParamsForItem, 'order'), (descriptionParam: any) => {
                    const paramWithLevel = find(zippedItemWithLevel, {
                      name: descriptionParam.paramName,
                      level: descriptionParam.level,
                    });
                    description.push(get(paramWithLevel, 'value'));
                  });

                  return {
                    ...item,
                    description: compact(description).join(' - '), // Finally, join params after removing undefined values
                    source: find(allowedSources, { name: sourceName }),
                  };
                })
              );
            }
          });
        }
      }

      if (operation === TOKEN_ACTION_REMOTE_SOURCE_SEARCH) {
        const searchParams = get(decryptedToken, 'searchParams');
        const sourceName = get(decryptedToken, 'source');
        const sourceObject = find(allowedSources, { name: sourceName });
        if (decryptedToken !== null && isObject(searchParams) && keys(searchParams).length > 0) {
          setRemoteSource({ ...sourceObject, infoFromToken: { token: decryptedToken, operation } });
          toggleRemoteUploadDialog(true);
        }
      }

      if (isRemoteUploadOperation) {
        if (get(decryptedToken, 'success')) {
          transferUploadInfo();
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decryptedToken, configLoaded]);

  useEffect(() => {
    debugToConsole('Remote upload files', remoteUploadedFiles);
  }, [remoteUploadedFiles, debugToConsole]);

  const transferUploadInfo = async (prefixes: string[] = []) => {
    const uploadInfo = await getTransferUploadInfo(
      get(decryptedToken, 'transferId'),
      get(decryptedToken, 'code'),
      prefixes
    );
    if (uploadInfo) {
      setUploadedInfoForRemoteUpload(uploadInfo);
      if (
        isNumber(uploadInfo.numberOfFinishedUploads) &&
        isNumber(uploadInfo.numberOfUploads) &&
        uploadInfo.numberOfFinishedUploads < uploadInfo.numberOfUploads
      ) {
        uploadInfoTimeoutToken = setTimeout(() => transferUploadInfo(prefixes), 3000);
      }
    }
  };

  const addRemoteFiles = (files: IRemoteSourceItem[]) => {
    let remoteFiles = [...remoteUploadedFiles];
    each(files, (file) => {
      if (!includes(remoteFiles, file)) {
        remoteFiles.push(file);
      }
    });
    setRemoteUploadedFiles(remoteFiles);
  };

  const removeFile = (uniqueIdentifier: string, fromResumableOnly = false) => {
    try {
      ResumableField.removeFile(ResumableField.getFromUniqueIdentifier(uniqueIdentifier));
    } catch (e) {
      console.debug(e);
    }
    if (!fromResumableOnly) {
      setUploadedFiles(
        filter(uploadedFiles, (file: ResumableFile) => file.uniqueIdentifier !== uniqueIdentifier)
      );
    }
  };

  const removeRemoteFile = (generatedID: string) => {
    setRemoteUploadedFiles(
      filter(remoteUploadedFiles, (file: IRemoteSourceItem) => file.generatedID !== generatedID)
    );
  };

  const removeAllFiles = () => {
    each(uploadedFiles, (file) => {
      removeFile(file.file.uniqueIdentifier, true);
    });
    setUploadedFiles([]);
    setRemoteUploadedFiles([]);
  };

  // const dumpValuesAndTextMessage = async (values: UploadFormValues) => {
  //   console.debug({ values, textMessage });
  // };

  const uploadForAuthorizedUser = async (
    values: UploadFormValues,
    { setErrors, setFieldTouched, setFieldError }: FormikHelpers<UploadFormValues>
  ) => {
    toggleLoader();

    const invalidRecipientsPhonesIndexes: any[] = [];
    each(values.recipients, (recipient, index) => {
      const phoneIsValid = testIfPhoneHasRightFormat(
        joinPhoneNumber(recipient.prefix, recipient.phoneNumber)
      );
      let emailIsValid = undefined;

      const validationObject = {};

      if (!phoneIsValid) {
        set(validationObject, 'phoneNumber', t('form.validations.phoneNumberInvalid'));
      }

      if (enableOnlySelectedRecipients) {
        emailIsValid = false;
        if (isArray(enabledEmails) && includes(enabledEmails, recipient.email)) {
          emailIsValid = true;
        }
        if (isArray(enabledDomains) && includes(enabledDomains, recipient.email.split('@').pop())) {
          emailIsValid = true;
        }

        if (!emailIsValid) {
          set(
            validationObject,
            'email',
            t('form.validations.emailAddressRestrictedByDomainOrEmail')
          );
        }
      }

      // console.debug({ emailIsValid, validationObject });

      if (!phoneIsValid || (!isLinkMode && emailIsValid === false)) {
        invalidRecipientsPhonesIndexes[index] = validationObject;
      }
    });

    if (invalidRecipientsPhonesIndexes.length) {
      toggleLoader(false);
      setErrors({ recipients: invalidRecipientsPhonesIndexes });
      return false;
    }

    if (anonymizeSnederMailVisible) {
      const anonymizeSender = get(values, 'anonymizeSender', null);
      if (anonymizeSender === null) {
        setFieldTouched('anonymizeSender', true);
        setFieldError('anonymizeSender', t('form.validations.anonymizationProfileEmpty'));
        toggleLoader(false);
        return false;
      }
    }

    const primaryRecipient = getPrimaryRecipient(values.recipients);
    const otherRecipients = getOtherRecipients(values.recipients);

    const organizationId = get(actualOrganization, 'id');

    let code: string, transferId: number;

    // Get transfer ID - transfer-start
    if (isRemoteUploadOperation) {
      code = get(decryptedToken, 'code');
      transferId = get(decryptedToken, 'transferId');
      setTransferCode(code);
      setTransferID(transferId);
    } else {
      const transferStartResponse = await transferStart(user.email, organizationId);
      code = get(transferStartResponse, 'code');
      transferId = get(transferStartResponse, 'transferId');
      setTransferCode(code);
      setTransferID(transferId);
    }

    const totalSizeOfFiles = Number(getAllUploadedFilesSize(uploadedFiles, false));

    // Get local files transfer upload start - transfer-upload-start
    const localStart = await transferUploadStart({
      code,
      transferId,
      files: [],
      totalSizeOfFiles,
      totalNumberOfFiles: uploadedFiles.length,
    });

    const localPrefix = get(localStart, 'prefix');
    setLocalTransferPrefix(localPrefix);

    // Check if transfer started without error
    if (localStart === false || get(localStart, 'success') === false || !localPrefix) {
      toggleLoader(false);
      return addErrorAlert(t('files.upload.transferStartFailed'));
    }

    const localStartValidation = validateUploadStart(
      localStart,
      totalSizeOfFiles,
      values.recipients.length
    );

    if (localStartValidation !== true) {
      await limitsExceededNotification(
        user.userId,
        user.name,
        user.email,
        totalSizeOfFiles,
        organizationId || null
      );
      toggleLoader(false);
      return addErrorAlert(localStartValidation, 12000);
    }

    if (!primaryRecipient) {
      toggleLoader(false);
      return addErrorAlert(t('files.upload.transferStartFailed'));
    }

    // Remote start
    const remoteUploadStarts = {};
    let remoteSourcesHaveError = false;
    let remoteSourcesPrefixes: string[] = [];
    if (remoteUploadedFiles.length) {
      const remoteSources: string[] = uniq(
        remoteUploadedFiles.map((file) => get(get(file, 'source', {}), 'name', ''))
      );

      const responses = await Promise.all(
        remoteSources.map((sourceName) =>
          transferUploadStart({
            code,
            transferId,
            files: [],
            totalSizeOfFiles: null,
            totalNumberOfFiles: null,
          })
        )
      );

      each(remoteSources, (remoteSourceName: string, index: number) => {
        const response = responses[index];
        if (response === false || get(response, 'success') === false || !get(response, 'prefix')) {
          remoteSourcesHaveError = true;
        } else {
          set(remoteUploadStarts, remoteSourceName, response);
          remoteSourcesPrefixes.push(get(response, 'prefix'));
        }
      });
    }

    if (remoteSourcesHaveError) {
      toggleLoader(false);
      return addErrorAlert(t('files.upload.transferStartFailed'));
    } else {
      clearTimeout(uploadInfoTimeoutToken);
      uploadInfoTimeoutToken = setTimeout(() => {
        let prefixes: string[] = [];
        if (localPrefix !== null) {
          prefixes.push(localPrefix);
        }
        if (isArray(remoteSourcesPrefixes) && remoteSourcesPrefixes.length) {
          prefixes = [...prefixes, ...remoteSourcesPrefixes];
        }

        if (isRemoteUploadOperation) {
          transferUploadInfo(prefixes);
        }
      }, 3000);
    }

    const transferDelay = get(values, 'transferDelay', null);
    const transferConfirmData: ITransferConfirmData = {
      transferId,
      note: get(values, 'note'),
      ...(textMessage ? { messages: { '1': textMessage } } : {}),
      primaryRecipient,
      otherRecipients,
      linkMode: isLinkMode,
      sendAt:
        transferDelayVisible && isNumber(transferDelay)
          ? format(addDays(new Date(), transferDelay), 'yyyy-MM-dd HH:mm') + ':00'
          : null,
      sharedTransfer: isNumber(orgFlags) && orgFlags >= 0 ? sharedTransfer : null,
      ...(internalContactsSaveMode === INTERNAL_CONTACTS_SAVE_MODE_OPTIONAL
        ? { storeContacts }
        : {}),
    };

    if (anonymizeSnederMailVisible) {
      const anonymizeSender = get(values, 'anonymizeSender', null);
      if (anonymizeSender !== null) {
        set(transferConfirmData, 'anonymization', anonymizeSender);
      }
    }

    const transferConfirmResponse = await transferConfirm(transferConfirmData);
    if (transferConfirmResponse !== false) {
      const afterConfirmValidation = validateUploadDone(transferConfirmResponse);
      console.debug({ afterConfirmValidation });
    } else {
      toggleLoader(false);
      return addErrorAlert(t('files.upload.transferConfirmationFail'));
    }

    ResumableField.opts.query.transferId = transferId;
    ResumableField.opts.query.code = code;
    ResumableField.opts.query.prefix = localPrefix;

    each(keys(remoteUploadStarts), async (remoteSourceName) => {
      const remoteUploadStart = get(remoteUploadStarts, remoteSourceName);
      const uploadedItemsForSource = filter(
        remoteUploadedFiles,
        (file) =>
          file.source != null && file.source.name != null && file.source.name === remoteSourceName
      );
      if (uploadedItemsForSource) {
        each(uploadedItemsForSource, (item) => {
          item.source = null;
        });
      }

      // Get source levels for file
      const levels = get(
        find(getConfigValue('appendix.upload.remoteSources'), { name: remoteSourceName }),
        'levels'
      );

      // I'm leaving this for future consideration

      // each(uploadedItemsForSource, (file) => {
      //   const pureFile = omit(file, ['$index', 'source']);
      //   console.debug({ file, pureFile });
      // });

      // each(uploadedItemsForSource, (file) => {
      //   // Lets cycle through files, but omit some added params we dont need
      //   const pureFile = omit(file, ['$index', 'tableData', 'source']);

      //   const fileParams = keys(pureFile);

      //   let fileParamsToLevelsMap = {
      //     $index: file.$index,
      //   };

      //   // Lets cycle through all params
      //   each(fileParams, (param) => {
      //     // Store levelNumber when param is found
      //     let foundInLevel = null;

      //     // Lets cycle through levels for remote source
      //     each(levels, (level) => {
      //       // This is level number
      //       const levelNumber = level.level;

      //       // Params for current level
      //       const levelParams = level.itemParams;

      //       // Let's try to find param in this level
      //       const foundParam = find(levelParams, { name: param });

      //       // If param was found, store it in the variable
      //       if (foundParam) {
      //         console.debug({ levels, file, param, foundParam, levelNumber });
      //         foundInLevel = levelNumber;
      //       }
      //     });

      //     // If param was found in some level, then add it to level object
      //     if (foundInLevel) {
      //       set(fileParamsToLevelsMap, param, foundInLevel);
      //     }
      //   });

      //   levelsForFiles.push(fileParamsToLevelsMap);
      // });

      // Levels will be stored here
      const levelsForFiles: any = [];

      // Lets map files to levels
      each(uploadedItemsForSource, (file) => {
        // All params will be stored here, let's start with $index which should be same for file and the level itself
        const levelForFile = {
          $index: file.$index,
        };

        // Only do this if level is 2 (or higher in the future)
        if (file.levelNumber === 2) {
          // Cycle through level 1 and 2
          each([1, 2], (level) => {
            // Get params for the level
            const params = get(find(levels, { level }), 'itemParams');

            // Inject param with according level to the levelForFile object
            each(params, (param) => {
              set(levelForFile, param.name, level);
            });
          });

          // Push it to the levels array
          levelsForFiles.push(levelForFile);
        }
      });

      // Inject params from parent level, if it exists
      const mappedUploadedItemsForSource = uploadedItemsForSource.map((file) => {
        let newFile = { ...file };
        const parentLevel = find(file.previousLevels, { level: 1 });
        if (file.levelNumber === 2 && parentLevel) {
          newFile = { ...parentLevel, ...file };
        }

        // Omit some params we don't want to send
        return omit(newFile, ['tableData', 'previousLevels', 'levelNumber', 'level']);
      });

      const remoteUploadResponse = await remoteSourceUpload({
        items: mappedUploadedItemsForSource as any,
        source: remoteSourceName,
        prefix: get(remoteUploadStart, 'prefix'),
        transferID: transferId,
        code: code,
        levels: urlContainsUploadToken
          ? get(find(decryptedToken?.sources, { name: remoteSourceName }), 'levels', null)
          : levelsForFiles.length
          ? levelsForFiles
          : null,
        uploadParams: urlContainsUploadToken
          ? get(find(decryptedToken?.sources, { name: remoteSourceName }), 'uploadParams', null)
          : null,
      });
      if (remoteUploadResponse === false) {
        addErrorAlert(t('files.upload.transferStartFailed'));
      }
    });

    try {
      dispatch(ToggleResumableUploadState(true));
      ResumableField.upload();
    } catch (e) {
      console.debug(e);
    }
  };

  const checkFileOnUpload = (file: ResumableFile) => {
    const extension = getFileExtension(file.fileName);
    if (!extension && !enableFilesWithoutExtension) {
      addErrorAlert(t('files.upload.cannotUploadWithoutExtension'));
      return false;
    }
    if (extension && includes(disabledExtensions, extension)) {
      addErrorAlert(t('files.upload.extensionForbidden', { extension }));
      return false;
    }
    return true;
  };

  const uploadForUnAuthorizedUser = async (
    values: UploadFormValues,
    { setErrors }: FormikHelpers<UploadFormValues>
  ) => {
    toggleLoader();

    const phoneNumber = joinPhoneNumber(values.prefix as string, values.phoneNumber as string);
    const phoneIsValid = testIfPhoneHasRightFormat(phoneNumber);
    if (!phoneIsValid) {
      toggleLoader(false);
      setErrors({ phoneNumber: t('form.validations.phoneNumberInvalid') });
      return false;
    }

    const transferDelay = get(values, 'transferDelay', null);
    const localStart = await unauthorizedUploadStart(
      get(values, 'recipientEmail'),
      get(values, 'prefix') + ' ' + get(values, 'phoneNumber'),
      get(values, 'note'),
      get(values, 'senderEmail'),
      get(values, 'senderName'),
      textMessage as string,
      transferDelayVisible && isNumber(transferDelay)
        ? format(addDays(new Date(), transferDelay), 'yyyy-MM-dd HH:mm:ss')
        : null
    );

    const loginRequired = get(localStart, 'loginRequired');

    // Check if transfer started without error
    if (localStart === false || get(localStart, 'success') === false) {
      toggleLoader(false);
      return addErrorAlert(t('files.upload.transferStartFailed'));
    }

    if (loginRequired) {
      toggleLoader(false);
      setVerifyEmail(get(localStart, 'login'));
    } else {
      const code: any = get(localStart, 'code');
      const transferId: any = get(localStart, 'transferId');
      setTransferCode(code);
      setTransferID(transferId);

      const totalSizeOfFiles = Number(getAllUploadedFilesSize(uploadedFiles, false));

      const localStartValidation = validateUploadStartUnAuth(localStart, totalSizeOfFiles, 1);
      if (localStartValidation !== true) {
        await limitsExceededNotification(
          null,
          get(values, 'senderName'),
          get(values, 'senderEmail'),
          totalSizeOfFiles,
          null
        );
        toggleLoader(false);
        return addErrorAlert(localStartValidation, 12000);
      }

      ResumableField.opts.query.transferId = transferId;
      ResumableField.opts.query.code = code;

      try {
        dispatch(ToggleResumableUploadState(true));
        ResumableField.upload();
      } catch (e) {
        console.debug(e);
      }
    }
  };

  const closeRecipientDialog = () => toggleRecipientRegistry(false);

  const openUserHome = () => history.push('/redirect-on-user-home/');
  const openRegistration = () => history.push('/registration');
  const closeShowRegistrationDialog = () => {
    setShowRegistrationDialog(false);
    openUserHome();
  };

  const initialRecipient = { email: '', prefix: '+420', phoneNumber: '' };

  const onUploadDone = async () => {
    dispatch(ToggleResumableUploadState(false));
    let hasOpenHomepage = true;
    if (userIsAuthorized) {
      const uploadDoneResponse = await transferUploadDone({
        transferId: Number(transferID),
        code: String(transferCode),
        prefix: String(localTransferPrefix),
      });
      if (uploadDoneResponse !== true) {
        toggleLoader(false);
        return addErrorAlert(t('files.upload.transferUploadDoneFailed'));
      }
    } else {
      const uploadDoneResponse = await unauthorizedUploadDone({
        transferId: Number(transferID),
        code: String(transferCode),
      });
      if (uploadDoneResponse !== true) {
        toggleLoader(false);
        toggleRemoteUploadDialog(false);
        setTokenAlreadyDecrypted(false);
        setDecryptedToken(null);
        setLoginRequired(null);
        setLoginUsername('');
        removeAllFiles();
        ResumableField.cancel();
        return addErrorAlert(t('files.upload.transferUploadDoneFailed'));
      }
      const user: IUser | boolean = await fetchAndStoreUser();
      if (!get(user, 'email', false)) {
        setShowRegistrationDialog(true);
        hasOpenHomepage = false;
      } else if (userHasMoreOrganizations(user)) {
        dispatch(ToggleOrganizationDialog(true));
      }
    }
    toggleLoader(false);
    addSuccessAlert(t(isLinkMode ? 'files.upload.linkCreated' : 'files.upload.uploadDone'));
    if (hasOpenHomepage) {
      openUserHome();
    }
  };

  const closeLoginDialog = () => {
    document.getElementById('upload-submit-button')?.click();
    setVerifyEmail(null);
  };

  const closeLoginDialogAfterLogin = () => {
    setLoginRequired(false);
  };

  const SubmitButtonIcon = isLinkMode ? OpenInBrowserIcon : UploadIcon;
  const filesCombinedLength = uploadedFiles.length + remoteUploadedFiles.length;

  return (
    <>
      <Resumable
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        uploadDone={onUploadDone}
        checkFileOnUpload={checkFileOnUpload}
      />
      <Formik
        initialValues={{
          recipients: !isEmpty(recipientsFromStore)
            ? recipientsFromStore
            : [recipientFromUrl ? recipientFromUrl : initialRecipient],
          note: '',
          ...(!userIsAuthorized
            ? {
                termsOfUse: false,
                senderEmail: '',
                senderName: '',
                recipientEmail: '',
                prefix: '+420',
                phoneNumber: '',
              }
            : {}),
        }}
        validationSchema={
          userIsAuthorized
            ? isLinkMode
              ? UploadLinkFormSchema
              : UploadFormSchema
            : UploadFormSchemaUnAuthorized
        }
        onSubmit={userIsAuthorized ? uploadForAuthorizedUser : uploadForUnAuthorizedUser}
        enableReinitialize={true}
      >
        {({ isSubmitting, handleSubmit, values, setFieldValue, errors, submitForm }) => {
          const recipientsCount =
            values.recipients.length === 1 && recipientIsEmpty(get(values, 'recipients[0]'))
              ? 0
              : values.recipients.length;
          const recipientsLimitExceeded: boolean = recipientsCount >= maxRecipients;
          const remianingRecipients = maxRecipients - recipientsCount;
          return (
            <form onSubmit={handleSubmit}>
              <div>
                <Grid container={true} spacing={2} className={classes.contentCenter}>
                  <Grid item={true} xs={11} sm={6} lg={4} xl={3}>
                    <Title
                      title={
                        <div className={classes.addFromRegistry}>
                          {`1. ${t(
                            userIsAuthorized ? 'files.singleRecipient' : 'files.insertData'
                          )}`}
                        </div>
                      }
                    />
                    {userIsAuthorized ? (
                      <FieldArray
                        name="recipients"
                        render={(arrayHelpers) => (
                          <div>
                            <Dialog
                              aria-labelledby="confirmation-dialog-title"
                              open={showRecipientRegistry}
                              onClose={closeRecipientDialog}
                              fullScreen={true}
                            >
                              <DialogTitleWithClose
                                title={''}
                                closeDialogFn={closeRecipientDialog}
                              />
                              <RecipientRegistry
                                selectionOnly={true}
                                closeRecipientRegistry={closeRecipientDialog}
                                selectRecipients={(recipients: IRecipient[]) => {
                                  // Remove first empty recipient
                                  const existingRecipients = get(values, 'recipients');
                                  let emptyRecipientsIndexes: number[] = [];

                                  // Find empty recipients
                                  if (isArray(existingRecipients)) {
                                    each(existingRecipients, (recipient, index) => {
                                      if (recipientIsEmpty(recipient)) {
                                        emptyRecipientsIndexes.push(index);
                                      }
                                    });
                                  }

                                  // Remove them
                                  // It must be reversed, beacuse indexes shift when you remove them from beginning of array
                                  if (emptyRecipientsIndexes.length) {
                                    each(reverse(emptyRecipientsIndexes), (recipientIndex) => {
                                      arrayHelpers.remove(recipientIndex);
                                    });

                                    // Only show alert when you DO NOT remove only first one
                                    if (
                                      !(
                                        emptyRecipientsIndexes.length === 1 &&
                                        emptyRecipientsIndexes[0] === 0
                                      )
                                    ) {
                                      addInfoAlert(t('files.upload.recipientsRemoved'));
                                    }
                                  }

                                  each(recipients, (recipient: IRecipient) => {
                                    arrayHelpers.push(convertRecipientForForm(recipient));
                                  });
                                }}
                                remianingRecipients={remianingRecipients}
                                selectionDisabled={isLinkMode ? true : false}
                              />
                            </Dialog>
                            {values.recipients && values.recipients.length > 0
                              ? values.recipients.map((recipient, index) => (
                                  <div key={index} className={classes.recipient}>
                                    <Fab
                                      className={classes.deleteRecipient}
                                      aria-label="delete"
                                      size="small"
                                      classes={{ root: classes.deleteRecipientRoot }}
                                      onClick={() => {
                                        if (index === 0 && values.recipients.length === 1) {
                                          arrayHelpers.replace(0, initialRecipient);
                                        } else {
                                          arrayHelpers.remove(index);
                                        }
                                        dispatch(ClearRemoteSearchAdditionalInfo());
                                      }}
                                    >
                                      <ClearIcon classes={{ root: classes.deleteRecipientIcon }} />
                                    </Fab>

                                    {!isLinkMode && (
                                      <div>
                                        <InputField
                                          name={`recipients[${index}].email`}
                                          autoComplete="email"
                                          label={t('recipientRegistry.recipient.email')}
                                        />
                                        <CustomArrayError name={`recipients[${index}].email`} />
                                      </div>
                                    )}
                                    <div className={classes.phone}>
                                      <Phone fieldNamePrefix={`recipients[${index}]`} />
                                      <CustomArrayError name={`recipients[${index}].phoneNumber`} />
                                    </div>
                                  </div>
                                ))
                              : null}

                            <div className={classes.addButton}>
                              {userIsAuthorized && !contactsDisabled ? (
                                <Button
                                  variant="contained"
                                  size="small"
                                  className={classes.addFromRegistryButton}
                                  onClick={() => toggleRecipientRegistry(true)}
                                  disabled={recipientsLimitExceeded}
                                  color="primary"
                                  startIcon={<PeopleIcon />}
                                >
                                  {t('files.addFromRegistry')}
                                </Button>
                              ) : null}
                              {!isLinkMode && (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      email: '',
                                      prefix: '+420',
                                      phoneNumber: '',
                                    })
                                  }
                                  className={classes.addFromRegistryButton}
                                  disabled={recipientsLimitExceeded}
                                >
                                  {recipientsLimitExceeded
                                    ? t('files.upload.maxRecipientsAdded', { n: maxRecipients })
                                    : t('files.addAnotherRecipient')}
                                </Button>
                              )}
                            </div>
                          </div>
                        )}
                      />
                    ) : (
                      <div className={classes.recipient}>
                        <div>
                          <InputField
                            name={`senderEmail`}
                            autoComplete="email"
                            label={t('recipientRegistry.sender.email')}
                          />
                        </div>
                        <div className={classes.phone}>
                          <InputField
                            name={`senderName`}
                            label={t('recipientRegistry.sender.name')}
                          />
                        </div>
                        <div className={classes.phone}>
                          <InputField
                            name={`recipientEmail`}
                            autoComplete="email"
                            label={t('recipientRegistry.recipient.email')}
                          />
                        </div>

                        <div className={classes.phone}>
                          <Phone />
                        </div>
                      </div>
                    )}
                    <div className={`${classes.recipient} ${classes.note}`}>
                      <InputField
                        name="note"
                        label={t('files.upload.note')}
                        // Prevent enter from firing submit
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.keyCode === 13) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </Grid>

                  <Grid item={true} xs={12} md={6} lg={6} xl={6}>
                    <Files
                      files={uploadedFiles}
                      setUploadedFiles={setUploadedFiles}
                      removeFile={removeFile}
                      removeAllFiles={removeAllFiles}
                      remoteFiles={remoteUploadedFiles}
                      addRemoteFiles={addRemoteFiles}
                      removeRemoteFile={removeRemoteFile}
                      remoteDialogVisible={remoteDialogVisible}
                      toggleRemoteUploadDialog={toggleRemoteUploadDialog}
                      selectedRemoteSource={selectedRemoteSource}
                      setRemoteSource={setRemoteSource}
                      textMessage={textMessage}
                      setTextMessage={setTextMessage}
                      isRemoteUploadOperation={isRemoteUploadOperation}
                      uploadedInfoForRemoteUpload={uploadedInfoForRemoteUpload}
                    />
                  </Grid>

                  <Grid item={true} xs={12} md={12} lg={2} xl={3}>
                    <UploadFormSubmit
                      buttonDisabled={
                        isSubmitting ||
                        !(
                          filesCombinedLength > 0 ||
                          trim(textMessage).length ||
                          isRemoteUploadOperation
                        )
                      }
                      title={`3. ${t(isLinkMode ? 'files.createLink' : 'files.sendToReciever')}`}
                      Icon={SubmitButtonIcon}
                      buttonTitle={t(isLinkMode ? 'files.createLink' : 'files.uploadFiles')}
                      showTermsOfUseCheckbox={!userIsAuthorized}
                      transferDelayVisible={transferDelayVisible}
                      toggleTransferDelatVisible={toggleTransferDelatVisible}
                      anonymizations={anonymizations}
                      anonymizeSnederMailVisible={anonymizeSnederMailVisible}
                      toggleAnonymizeSnederMailVisible={toggleAnonymizeSnederMailVisible}
                      sharedTransfer={sharedTransfer}
                      toggleSharedTransfer={toggleSharedTransfer}
                      setFieldValue={setFieldValue}
                      internalContactsSaveMode={internalContactsSaveMode}
                      setShowInternalContactsSaveDialog={setShowInternalContactsSaveDialog}
                    />
                  </Grid>
                </Grid>

                {!userIsAuthorized && (
                  <Grid container={true} spacing={2} className={classes.contentCenter}>
                    <Grid item={true}>
                      <Trans i18nKey="files.infoForUnAuthorizedUser">
                        0 <Link to="/registration">1</Link> 2 <Link to="/terms-of-use">3</Link>
                      </Trans>
                    </Grid>
                  </Grid>
                )}
              </div>
              {showInternalContactsSaveDialog && (
                <Confirm
                  title={t('files.upload.dialog.internalContactsSaveTitle')}
                  content={t('files.upload.dialog.internalContactsSaveMessage')}
                  onClose={() => {
                    storeContacts = false;
                    setShowInternalContactsSaveDialog(false);
                    submitForm();
                  }}
                  onConfirm={() => {
                    storeContacts = true;
                    setShowInternalContactsSaveDialog(false);
                    submitForm();
                  }}
                />
              )}
            </form>
          );
        }}
      </Formik>
      {verifyEmail && (
        <Login
          email={String(verifyEmail)}
          password={''}
          isDialog={true}
          isUnauthorizedUpload={true}
          closeLoginDialog={closeLoginDialog}
        />
      )}
      {loginRequired && (
        <>
          {hasOnlyLocalForm || localFormSelectedForLogin ? (
            <Login
              email={loginUsername}
              password={''}
              isDialog={true}
              loginRequired={loginRequired}
              closeLoginDialog={closeLoginDialogAfterLogin}
              customCloseLoginDialogFn={customCloseLoginDialogFn}
            />
          ) : (
            <LoginSelect
              isDialog={true}
              loginRequired={loginRequired}
              showLoginSelectDialog={showLoginSelectDialog}
            />
          )}
        </>
      )}
      {showRegistrationDialog && (
        <Confirm
          title={t('files.upload.dialog.registrationTitle')}
          content={t('files.upload.dialog.registrationInfoMessage')}
          onClose={closeShowRegistrationDialog}
          onConfirm={openRegistration}
        />
      )}
      <BottomBanner />
      <div className={classes.gdpr}>
        <img src={`${process.env.PUBLIC_URL}/img/gdpr.png`} alt="GDPR" />
      </div>
    </>
  );
};

export default Upload;
