import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing }: Theme) => {
  return createStyles({
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    spacer: {
      padding: '0 !important',
      height: 0,
      overflow: 'hidden'
    }
  });
});

export default useStyles;
