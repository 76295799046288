import { isNumber, each, keys, get } from 'lodash';
import { TOTP_FUNCTIONS_VALUES } from 'constants/constants';

export const getCheckedOptionsFromBinaryMap = (binaryMap: number) => {
  let resultData: any = {};
  if (isNumber(binaryMap)) {
    each(keys(TOTP_FUNCTIONS_VALUES), (functionName) => {
      const value = get(TOTP_FUNCTIONS_VALUES, functionName);
      resultData[functionName] = (binaryMap & value) === value;
    });
  }
  return resultData;
};

export const getBinaryMapFromOptions = (totpFunctions: any[]) => {
  try {
    let binaryMap = 0;
    each(
      keys(totpFunctions),
      (functionName) =>
        (binaryMap += get(totpFunctions, functionName)
          ? get(TOTP_FUNCTIONS_VALUES, functionName)
          : 0)
    );
    return binaryMap;
  } catch (e) {
    return 0;
  }
};
