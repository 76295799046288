import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    content: {
      marginTop: spacing(3),
      marginBottom: spacing(3)
    }
  })
);

export default useStyles;
