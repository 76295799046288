import { RESUMABLE_UPLOAD_TICK, RESUMABLE_UPLOAD_STATE } from 'constants/constants';

export const ToggleResumableUploadState = (state: boolean) => ({
  type: RESUMABLE_UPLOAD_STATE,
  payload: state
});

export const ResumableUploadTick = () => ({
  type: RESUMABLE_UPLOAD_TICK,
  payload: new Date()
});
