import React from 'react';

import TextPage from 'components/TextPage/TextPage';
import useStyleByAppVersion from 'utils/hooks/useStyleByAppVersion';

const Privacy: React.FC = () => {
  const { getPrivacyFile } = useStyleByAppVersion();
  return <TextPage template={getPrivacyFile()} />;
};

export default Privacy;
