import { IAlert } from './_types';
import { ALERT_REMOVE, ALERT_ADD } from 'constants/constants';

export const RemoveAlert = (id: string) => ({
  type: ALERT_REMOVE,
  payload: id,
});

export const AddAlert = (alert: IAlert) => ({
  type: ALERT_ADD,
  payload: alert,
});
