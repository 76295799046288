import React from 'react';
import { ResumableFile } from 'resumablejs';
import { useTranslation } from 'react-i18next';
import { Chip, Tooltip } from '@material-ui/core';
import {
  get,
  isArray,
  each,
  reduce,
  drop,
  head,
  includes,
  isNull,
  isNumber,
  isObject,
} from 'lodash';
import classnames from 'classnames';
import ImageIcon from '@material-ui/icons/Image';
import DicomIcon from '@material-ui/icons/Portrait';
import ArchiveIcon from '@material-ui/icons/Storage';
import DocumentIcon from '@material-ui/icons/Assignment';
import DefaultIcon from '@material-ui/icons/InsertDriveFile';

import { ITransfer } from 'modules/IncomingFiles/_types';
import { ITransferDetail, IOtherTransferRecipent, IFile } from 'modules/TransferDetail/_types';
import {
  FILE_SIZES,
  FILE_TYPE_ARCHIVE,
  FILE_TYPE_IMAGE,
  FILE_TYPE_DICOM,
  FILE_TYPE_DOCUMENT,
  ARCHIVE_EXTS,
  IMAGE_EXTS,
  DOCUMENT_EXTS,
  FIELD_TOTP,
  FIELD_SMS,
} from 'constants/constants';
import { IDeliveredResponse } from 'modules/DownloadFiles/_types';
import usePhone from 'utils/hooks/usePhone';

const useTransfer = () => {
  const { t } = useTranslation();
  const { splitPhoneNumber } = usePhone();

  const isInProgress = (transfer: ITransfer | ITransferDetail | IOtherTransferRecipent) => {
    return (
      isNull(get(transfer, 'recipientEmail', null)) &&
      isNull(get(transfer, 'recipientPhone', null)) &&
      isNull(get(transfer, 'recipientName', null)) &&
      isNull(get(transfer, 'recipientId', null))
    );
  };

  const getRecipientEmail = (transfer: ITransfer | ITransferDetail | IOtherTransferRecipent) =>
    get(transfer, 'recipientEmail', get(transfer, 'recipientPhone')) ||
    t('files.transferInProgress');

  const getAllRecipients = (transferDetail: ITransfer | ITransferDetail) => {
    const otherRecipients = get(transferDetail, 'otherRecipients');
    const recipients = [
      {
        name: get(transferDetail, 'recipientName'),
        contact: getRecipientEmail(transferDetail),
        status: get(transferDetail, 'recipientStatus', 0) * 1,
        phone: splitPhoneNumber(get(transferDetail, 'recipientPhone', ''))[1],
      },
    ];

    if (isArray(otherRecipients)) {
      each(otherRecipients, (recipient) =>
        recipients.push({
          name: get(recipient, 'recipientName'),
          contact: getRecipientEmail(recipient),
          phone: splitPhoneNumber(get(recipient, 'recipientPhone', ''))[1],
          status: get(recipient, 'recipientStatus', 0) * 1,
        })
      );
    }

    return recipients;
  };

  const stringifyAllRecipients = (transfer: ITransfer) => {
    const allRecipients = getAllRecipients(transfer);

    if (isArray(allRecipients)) {
      const stringifiedRecipients = reduce(
        allRecipients,
        (result, value) => {
          return `${result}${value.name} - ${value.contact} - ${value.phone}`;
        },
        ''
      );
      return stringifiedRecipients;
    } else {
      return '';
    }
  };

  const transferHasTexts = (transfer: ITransferDetail) => {
    const countOfMessages = get(transfer, 'numberOfMessages', 0);
    return countOfMessages > 0;
  };

  const transferHasFiles = (files: IFile[]) => (isArray(files) ? files.length > 0 : false);

  const getFileSize = (file: IFile) => get(file, 'size', 0);

  const getAllFilesSize = (files: IFile[]) =>
    reduce(files, (result, file) => result + get(file, 'size', 0), 0);

  const getAllUploadedFilesSize = (
    files: ResumableFile[],
    formatted: boolean = true
  ): string | number => {
    const totalSize = reduce(files, (result, file) => result + get(file, 'file.size', 0), 0);
    return formatted ? formatFileSize(totalSize) : totalSize;
  };

  const formatFileSize = (
    size: number,
    value1024: boolean = true,
    decimals: number | { GB: number; MB: number; KB: number } = 2
  ): string => {
    const limits = get(FILE_SIZES, value1024 ? 1024 : 1000);
    if (size > limits['GB'])
      return (
        (size / limits['GB']).toFixed(
          isNumber(decimals) ? decimals : isObject(decimals) ? get(decimals, 'GB') : 0
        ) + ' GB'
      );
    if (size > limits['MB'])
      return (
        (size / limits['MB']).toFixed(
          isNumber(decimals) ? decimals : isObject(decimals) ? get(decimals, 'MB') : 0
        ) + ' MB'
      );
    if (size > limits['KB'])
      return (
        (size / limits['KB']).toFixed(
          isNumber(decimals) ? decimals : isObject(decimals) ? get(decimals, 'KB') : 0
        ) + ' kB'
      );
    return size + ' B';
  };

  const getIconForFileType = (fileType: string) => {
    switch (fileType) {
      case FILE_TYPE_ARCHIVE:
        return <ArchiveIcon />;
      case FILE_TYPE_IMAGE:
        return <ImageIcon />;
      case FILE_TYPE_DICOM:
        return <DicomIcon />;
      case FILE_TYPE_DOCUMENT:
        return <DocumentIcon />;
      default:
        return <DefaultIcon />;
    }
  };

  const getFilenameExtension = (fileName: string) => fileName.substr(fileName.lastIndexOf('.') + 1);

  const mapUploadedFileToTransferFileType = (uploadedFile: File) => {
    const type = get(uploadedFile, 'type');
    const extension = getFilenameExtension(get(uploadedFile, 'name'));
    if (type.indexOf('image/') > -1 || includes(IMAGE_EXTS, extension)) {
      return FILE_TYPE_IMAGE;
    } else if (includes(ARCHIVE_EXTS, extension)) {
      return FILE_TYPE_ARCHIVE;
    } else if (includes(DOCUMENT_EXTS, extension)) {
      return FILE_TYPE_DOCUMENT;
    } else if (extension === 'dcm') {
      return FILE_TYPE_DICOM;
    } else {
      return '';
    }
  };

  const formatRecipientsIfMultiple = (transfer: ITransfer) => {
    const recipients = getAllRecipients(transfer);
    const firstRecipient = head(recipients);
    const restRecipients = drop(recipients);

    const user = (
      <div>
        {get(firstRecipient, 'name', '')} &lt;{get(firstRecipient, 'contact')}&gt;{` `}
        {restRecipients.length ? (
          <Tooltip
            title={
              <>
                {restRecipients.map((recipient, index) => (
                  <div key={`get(recipient, 'name')-${index}`}>
                    {get(recipient, 'name')} &lt;{get(recipient, 'contact')}&gt;{' '}
                  </div>
                ))}
              </>
            }
          >
            <Chip
              size="small"
              color="primary"
              label={`+${recipients.length - 1} ${t('files.more')}`}
            />
          </Tooltip>
        ) : (
          ''
        )}
      </div>
    );

    return user;
  };

  const formatRecipientStatusIfMultiple = (
    transfer: ITransfer,
    classes: Record<string, string>
  ) => {
    const recipients = getAllRecipients(transfer);
    const firstRecipient = head(recipients);
    const restRecipients = drop(recipients);
    let firstItemStatus = get(firstRecipient, 'status', 0);
    let isSameStatus = true;
    restRecipients.map((recipient: any) => {
      if (get(recipient, 'status', 0) !== firstItemStatus) {
        isSameStatus = false;
      }
      return null;
    });

    const user = (
      <div>
        <Chip
          size="small"
          color="primary"
          label={t(`files.recipientStatus.${firstItemStatus}`)}
          className={classnames({
            [classes.lime]: firstItemStatus === 0,
            [classes.lightGreen]: firstItemStatus === 1,
            [classes.green]: firstItemStatus === 2,
            [classes.teal]: firstItemStatus === 3,
            [classes.amber]: firstItemStatus === 4,
          })}
        />{' '}
        {!isSameStatus && restRecipients.length ? (
          <Tooltip
            title={
              <>
                {recipients.map((recipient, index) => {
                  const status = get(recipient, 'status', 0);
                  return (
                    <div key={`${status}-${index}`} style={{ margin: '6px' }}>
                      {get(recipient, 'name')} &lt;{get(recipient, 'contact')}&gt;{' '}
                      <Chip
                        size="small"
                        color="primary"
                        label={t(`files.recipientStatus.${status}`)}
                        className={classnames({
                          [classes.lime]: status === 0,
                          [classes.lightGreen]: status === 1,
                          [classes.green]: status === 2,
                          [classes.teal]: status === 3,
                          [classes.amber]: status === 4,
                        })}
                      />
                    </div>
                  );
                })}
              </>
            }
          >
            <Chip
              size="small"
              color="primary"
              label={`+${recipients.length - 1} ${t('files.more')}`}
            />
          </Tooltip>
        ) : (
          ''
        )}
      </div>
    );

    return user;
  };

  const getSenderName = (transfer: ITransfer | ITransferDetail | IOtherTransferRecipent) =>
    get(transfer, 'senderName', '') || t('files.transferInProgress');

  const getSenderEmail = (transfer: ITransfer | ITransferDetail | IOtherTransferRecipent) =>
    get(transfer, 'senderEmail', '') || t('files.transferInProgress');

  const getSecondPartOfTheKeyForForm = (deliveredResponse: IDeliveredResponse) => {
    let secondKeyTranslation: string = t('files.downloadFiles.secondKey');
    const secondPartOfKeyChannel = get(deliveredResponse, 'secondPartOfKeyChannel');
    if (secondPartOfKeyChannel) {
      if (secondPartOfKeyChannel === FIELD_SMS) {
        secondKeyTranslation = t('files.downloadFiles.secondKeyFromPhone');
      } else if (secondPartOfKeyChannel === FIELD_TOTP) {
        secondKeyTranslation = t('files.downloadFiles.secondKeyFromTotp');
      } else {
        secondKeyTranslation = t('files.downloadFiles.secondKeyFromEmail');
      }
    }
    return secondKeyTranslation;
  };

  const transferIsTotpMethod = (deliveredResponse: IDeliveredResponse) =>
    get(deliveredResponse, 'secondPartOfKeyChannel') === FIELD_TOTP;

  return {
    getFileSize,
    isInProgress,
    formatFileSize,
    getAllFilesSize,
    getAllRecipients,
    transferHasFiles,
    transferHasTexts,
    getRecipientEmail,
    getIconForFileType,
    transferIsTotpMethod,
    stringifyAllRecipients,
    getAllUploadedFilesSize,
    formatRecipientsIfMultiple,
    getSecondPartOfTheKeyForForm,
    formatRecipientStatusIfMultiple,
    mapUploadedFileToTransferFileType,
    getSenderName,
    getSenderEmail,
  };
};

export default useTransfer;
