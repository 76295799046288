import { isArray, get, each, isObject, keys } from 'lodash';
import { useLocation } from 'react-router';

interface IVerificationUrlParams {
  type: string;
  hash: string;
  user?: number;
}

const useUrl = () => {
  const { search, hash } = useLocation();

  const getHash = () => {
    try {
      const params = mapAllUrlParamsToObject();
      return get(params, 'hash', null);
    } catch (e) {
      return null;
    }
  };

  const urlParamsToObject = (params: string[]) => {
    try {
      let paramsWithValues: IVerificationUrlParams | {} = {};
      if (isArray(params)) {
        each(params, (param: string) => {
          const [paramName, paramValue] = param.split('=');
          paramsWithValues = {
            ...paramsWithValues,
            [paramName]: paramValue === 'true' ? true : paramValue === 'false' ? false : paramValue,
          };
        });
        return paramsWithValues;
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  const mapAllUrlParamsToObject = (url = search.substr(1)) => {
    try {
      const params = url.split('&');
      const paramsWithValues = urlParamsToObject(params);
      return paramsWithValues;
    } catch (e) {
      return {};
    }
  };

  const getParamValue = (param: string) => {
    const params = mapAllUrlParamsToObject();
    return get(params, param);
  };

  const isPage = (slug: string) => hash.indexOf(slug) > -1;
  const isEqualPage = (slug: string) => hash === slug;

  const mapObjectToUrlParams = (paramsObject: any): string => {
    if (!isObject(paramsObject)) {
      return '';
    }
    let url = '';
    each(keys(paramsObject), (paramName: string) => {
      url = `${url}${paramName}=${get(paramsObject, paramName)}&`;
    });
    return url.slice(0, -1);
  };

  return {
    isPage,
    getHash,
    isEqualPage,
    getParamValue,
    urlParamsToObject,
    mapObjectToUrlParams,
    mapAllUrlParamsToObject,
  };
};

export default useUrl;
