import React from 'react';
import { isObject, get, pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogActions, Button, Grid } from '@material-ui/core';

import { useValidationSchemaGroup } from './_form';
import { Formik, FormikHelpers } from 'formik';
import useUserInfo from 'utils/hooks/useUserInfo';
import useLoader from 'components/Loader/useLoader';
import useAlerts from 'components/Alerts/useAlerts';
import { createGroup, editGroup } from './_api';
import InputField from 'components/form/Input/Input';
import CheckboxField from 'components/form/Checkbox/Checkbox';
import { IGroupForm, IGroupFormValues } from './_types';
import DialogTitleWithClose from 'components/DialogTitleWithClose/DialogTitleWithClose';

const GroupForm: React.FC<IGroupForm> = ({
  userId,
  isEdit = false,
  group,
  closeDialog,
  getAllGroups,
}) => {
  const { t } = useTranslation();
  const { toggleLoader } = useLoader();
  const GroupFormSchema = useValidationSchemaGroup();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { userCanAddAsOrganizationContact, actualOrganization } = useUserInfo();

  let initialValues = isObject(group)
    ? {
        ...group,
      }
    : {
        name: '',
      };

  const onGroupAdd = async (
    values: IGroupFormValues,
    { setErrors }: FormikHelpers<IGroupFormValues>
  ) => {
    toggleLoader();

    try {
      let groupValues = {
        name: values.name,
        ...(values.addingOrganization
          ? { organization: get(actualOrganization, 'id') }
          : { user: userId }),
      };

      const response = await createGroup(groupValues);
      if (response) {
        await getAllGroups();
        closeDialog();
        addSuccessAlert(t('recipientRegistry.contactGroup.dialog.addingSucceeded'));
      } else {
        addErrorAlert(t('recipientRegistry.contactGroup.dialog.addingFailed'));
      }
    } catch (e) {
      addErrorAlert(t('recipientRegistry.contactGroup.dialog.addingFailed'));
    }

    toggleLoader(false);
  };

  const onGroupEdit = async (
    values: IGroupFormValues,
    actions: FormikHelpers<IGroupFormValues>
  ) => {
    toggleLoader();
    try {
      const response = await editGroup(pick(values, ['id', 'name']));
      if (response) {
        await getAllGroups();
        closeDialog();
        addSuccessAlert(t('recipientRegistry.contactGroup.dialog.editingSucceeded'));
      } else {
        addErrorAlert(t('recipientRegistry.contactGroup.dialog.editingFailed'));
      }
    } catch (e) {
      addErrorAlert(t('recipientRegistry.contactGroup.dialog.editingFailed'));
    }
    toggleLoader(false);
  };

  return (
    <Formik
      onSubmit={isEdit ? onGroupEdit : onGroupAdd}
      initialValues={initialValues}
      validationSchema={GroupFormSchema}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          aria-labelledby="confirmation-dialog-title"
          open={true}
          onClose={closeDialog}
        >
          <DialogTitleWithClose
            title={t(
              isEdit
                ? 'recipientRegistry.contactGroup.dialog.titleEdit'
                : 'recipientRegistry.contactGroup.dialog.titleAdd'
            )}
            closeDialogFn={closeDialog}
          />
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <InputField name="name" label={t('recipientRegistry.contactGroup.name')} />
                </Grid>
                {!isEdit && userCanAddAsOrganizationContact ? (
                  <Grid item={true} xs={12}>
                    <CheckboxField
                      key="addingOrganization"
                      name="addingOrganization"
                      label={t('recipientRegistry.contactGroup.addGroupForOrganization')}
                    />
                  </Grid>
                ) : null}
                <Grid item={true} xs={12}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth={true}
                    disabled={isSubmitting}
                  >
                    {t(
                      isEdit
                        ? 'recipientRegistry.contactGroup.dialog.edit'
                        : 'recipientRegistry.contactGroup.dialog.submit'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions />
        </Dialog>
      )}
    </Formik>
  );
};

export default GroupForm;
