import * as yup from 'yup';
import useValidators from 'components/form/validations/useValidators';

const useValidationSchema = () => {
  const { required } = useValidators();

  const DatabaseRowFormSchema = yup.object().shape({
    configId: required(yup.string()),
    type: required(required(yup.string())),
    value: required(yup.string())
  });

  return DatabaseRowFormSchema;
};

export default useValidationSchema;
