import React from 'react';
import { useHistory } from 'react-router';
import useHomePage from 'utils/hooks/useHomePage';

const RedirectPage: React.FC = () => {
  const history = useHistory();
  const homeUrlPage = useHomePage();

  history.push(homeUrlPage);
  return null;
};

export default RedirectPage;
