import { get, isArray } from 'lodash';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from '@material-ui/core';

import useStyles from './_styles';
import { organizationSelect } from './_api';
import { StoreUser } from 'modules/Login/_redux';
import useUserInfo from 'utils/hooks/useUserInfo';
import { ToggleOrganizationDialog } from './_redux';
import { USER_SELECTED } from 'constants/constants';
import useLoader from 'components/Loader/useLoader';
import useAlerts from 'components/Alerts/useAlerts';

const OrganizationDialog: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { toggleLoader } = useLoader();
  const organizationDialogIsOpened = useSelector((state) =>
    get(state, 'app.organizationDialogIsOpened')
  );
  const { t } = useTranslation();
  const [selectedOrganization, selectOrganization] = useState('');
  const { organizations, actualOrganization, hasAdminOrPaidRegistration } = useUserInfo();
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const organizationsWithUserOption = [
    ...(isArray(organizations) ? organizations.map(({ id, name }) => ({ id, name })) : []),
    ...(hasAdminOrPaidRegistration && isArray(organizations) && organizations.length > 0
      ? [{ id: USER_SELECTED, name: t('bar.organizationDialog.letUserAccount') }]
      : []),
  ];

  const closeOrganizationDialog = () => {
    dispatch(ToggleOrganizationDialog(false));
  };

  const setOrganization = (e: any) => {
    selectOrganization(e.target.value);
  };

  const sendOrganization = async () => {
    const data =
      selectedOrganization === USER_SELECTED
        ? { userSelected: true }
        : { id: selectedOrganization };
    toggleLoader();
    try {
      const user = await organizationSelect(data);
      dispatch(StoreUser(user));
      closeOrganizationDialog();
      addSuccessAlert(t('bar.organizationDialog.changeSucceeded') as string);
      console.debug(location.pathname);
      const isUrlWithToken =
        location.hash !== null &&
        location.hash.indexOf('&token=') > -1 &&
        location.hash.indexOf('#upload') > -1;

      if (
        location.pathname !== '/download' &&
        !isUrlWithToken &&
        location.pathname !== '/invitation-confirm'
      ) {
        history.push('/');
      }
    } catch (e) {
      addErrorAlert(t('bar.organizationDialog.changeFailed') as string);
    }
    toggleLoader(false);
  };

  return (
    <Dialog
      maxWidth="sm"
      aria-labelledby="confirmation-dialog-title"
      open={organizationDialogIsOpened}
    >
      <DialogTitle id="confirmation-dialog-title">{t('bar.organizationDialog.title')}</DialogTitle>
      <DialogContent>
        {actualOrganization && (
          <Typography variant="body2" className={classes.label}>
            {t('bar.organizationDialog.actualOrganization')}: {actualOrganization.name}
          </Typography>
        )}
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-simple">{t('bar.organizationDialog.placeholder')}</InputLabel>
          <Select
            value={selectedOrganization}
            onChange={setOrganization}
            fullWidth={true}
            inputProps={{
              name: 'age',
              id: 'age-simple',
            }}
          >
            {organizationsWithUserOption.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button
          variant="contained"
          disabled={!selectedOrganization}
          onClick={sendOrganization}
          color="primary"
          fullWidth={true}
        >
          {t('bar.organizationDialog.select')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrganizationDialog;
