import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BasicGrid from 'components/BasicGrid/BasicGrid';

import Title from 'components/Title/Title';
import useLoader from 'components/Loader/useLoader';
import { getMainConfigFile } from './_api';
import { isEmpty, get, forEach, keys } from 'lodash';
import { isObject } from 'util';
import { Typography, Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CodeIcon from '@material-ui/icons/Code';
import EditMainConfigSettingForm from './EditMainConfigSettingForm';
import { IMainConfigDbValue } from './_types';

const MainConfigFile: React.FC = () => {
  const { t } = useTranslation();
  const { toggleLoader } = useLoader();
  const [editFormVisible, toggleEditForm] = useState(false);
  const [dataFromConfigFile, fetchDataFromConfigFile] = useState<IMainConfigDbValue[]>([]);
  const [editedSetting, setEditedSetting] = useState<IMainConfigDbValue | boolean>(false);

  const getSettingsOfMainConfigFile = async () => {
    toggleLoader();
    try {
      const fetchedMainConfigFile = await getMainConfigFile();
      if (isObject(fetchedMainConfigFile)) {
        const settings: IMainConfigDbValue[] = [];
        const keysOfSettings = keys(fetchedMainConfigFile);
        forEach(keysOfSettings, (key) => {
          const value = get(fetchedMainConfigFile, key, '');
          if (typeof value === 'object') {
            if (value instanceof Array) {
              settings.push({
                key,
                originalValue: value,
                value: t('mainConfigManage.substitutionValue.array') as string,
                usingSubstitutionValue: true,
                valueType: 'array',
              });
            } else {
              settings.push({
                key,
                originalValue: value,
                value: t('mainConfigManage.substitutionValue.object') as string,
                usingSubstitutionValue: true,
                valueType: 'section',
              });
            }
          } else {
            const valueType = typeof value;
            settings.push({ key, value, usingSubstitutionValue: false, valueType });
          }
        });

        fetchDataFromConfigFile(settings);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  const prepareAndSetRowForEdit = (setting: IMainConfigDbValue) => {
    setEditedSetting(setting);
    toggleEditForm(true);
  };

  const closeEditForm = () => {
    toggleEditForm(false);
    setEditedSetting(false);
  };

  useEffect(() => {
    getSettingsOfMainConfigFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title={t('mainConfigManage.title') as string} />
      <Grid container={true} spacing={2} justifyContent="center">
        <Typography variant="h6" style={{ color: 'red' }}>
          {t('mainConfigManage.reloadNeeded')}
        </Typography>
      </Grid>
      {dataFromConfigFile && !isEmpty(dataFromConfigFile) ? (
        <>
          <BasicGrid
            data={dataFromConfigFile}
            columns={[
              {
                field: 'key',
                title: t('mainConfigManage.name'),
                render: (rowData: IMainConfigDbValue) => {
                  return (
                    <>
                      {' '}
                      <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        {get(rowData, 'key')}
                      </Typography>
                      {get(rowData, 'usingSubstitutionValue', false) ? (
                        // <Tooltip title={t('mainConfigManage.usingSubstitutionValue') || ""}>
                        <span>
                          <CodeIcon
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                            }}
                          />{' '}
                          <div
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                            }}
                          >
                            {get(rowData, 'value')}
                          </div>
                        </span>
                      ) : (
                        // </Tooltip>
                        <Typography>
                          {get(rowData, 'valueType') === 'boolean'
                            ? String(get(rowData, 'value'))
                            : get(rowData, 'value')}
                        </Typography>
                      )}
                    </>
                  );
                },
              },
            ]}
            actions={[
              {
                icon: EditIcon,
                iconProps: {
                  color: 'primary',
                  fontSize: 'large',
                },
                tooltip: t('mainConfigManage.editMainConfigSettingMessage'),
                onClick: (_event: any, rowData: IMainConfigDbValue) =>
                  prepareAndSetRowForEdit(rowData),
                position: 'row',
              },
            ]}
          />
          {editFormVisible && (
            <EditMainConfigSettingForm
              closeDialog={closeEditForm}
              editedSetting={editedSetting}
              getSettingsOfMainConfigFile={getSettingsOfMainConfigFile}
            />
          )}
        </>
      ) : null}
    </>
  );
};

export default MainConfigFile;
