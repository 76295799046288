import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  SvgIcon,
  Button,
  Tooltip,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { get, isArray, isObject, keys, isNumber, reject, filter } from 'lodash';
import { Bar } from 'react-chartjs-2';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';
import useLoader from 'components/Loader/useLoader';
import { getMonths, getStatistics, getExcelStatistics } from './_api';
import useStyles from './_styles';
import { LEVEL_L0 } from 'constants/constants';
import useAlerts from 'components/Alerts/useAlerts';

const bytesToMB = (value: number) => {
  if (!isNumber(value)) {
    return '';
  } else {
    const mb = value / 1024 / 1024;

    return mb !== 0 && mb < 0.1 ? '< 0.1' : mb.toFixed(1);
  }
};

const isL1OrHigherLevel = (group: string) => group !== null && group.toUpperCase() !== LEVEL_L0;

const Statistics: React.FC = () => {
  const { toggleLoader } = useLoader();
  const { t } = useTranslation();
  const classes = useStyles();
  const [dateArray, setDateArray] = useState<any>([]);
  const [selectedDate, setSelectedDate] = useState(0);
  const [statistics, setStatistics] = useState<any>(null);
  const params = useParams();
  const { addErrorAlert } = useAlerts();
  const organisationId = parseInt(get(params, 'orgId'), 10);

  const changeDate = async (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedDate(event.target.value as number);
    loadStatistics(event.target.value as number);
  };

  const loadStatistics = async (month: number = selectedDate) => {
    toggleLoader();
    try {
      const resp = await getStatistics({
        month: month,
        organizationId: organisationId,
      });
      if (isObject(resp)) {
        setStatistics(resp);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  const getAllMonths = async () => {
    try {
      const resp = await getMonths(organisationId);
      if (isObject(resp)) {
        const items = keys(resp).map((item) => ({
          id: item,
          label: get(resp, item),
        }));
        setDateArray(items);
      }
    } catch (e) {
      console.debug(e);
    }
  };

  const hasUnlimitedTransfers =
    get(statistics, 'organizationStatistics.transfersPerDayLimit') === 0;
  const orgInfo = {
    name: get(statistics, 'organizationInfo.name', ''),
    domains: get(statistics, 'organizationInfo.domains', [])
      .map((item: any) => item.name)
      .join(', '),
    userGroup: get(statistics, 'organizationInfo.userGroup', ''),
    transfersPerDay: hasUnlimitedTransfers
      ? t('statistics.unlimitedTransfers', {
          used: get(statistics, 'organizationStatistics.transfersPerDayUsed'),
        })
      : t(`statistics.transfersPerDay`, {
          count: get(statistics, 'organizationStatistics.transfersPerDayLimit'),
          used: get(statistics, 'organizationStatistics.transfersPerDayUsed'),
        }),
    totalSize:
      get(statistics, 'organizationStatistics.totalSizeLimit') === 0
        ? t('statistics.totalSizeNoLimit', {
            used: bytesToMB(get(statistics, 'organizationStatistics.totalSizeUsed')),
          })
        : t('statistics.totalSizeLimited', {
            used: bytesToMB(get(statistics, 'organizationStatistics.totalSizeUsed')),
            limit: bytesToMB(get(statistics, 'organizationStatistics.totalSizeLimit')),
          }),
  };

  const usersTable = [
    { id: 'name' },
    { id: 'email', align: 'left' },
    {
      id: 'group',
      valueFnc: (group: string) => (isL1OrHigherLevel(group) ? <CheckIcon /> : <ClearIcon />),
    },
    { id: 'numberOfAllTransfers', prefix: 'period.', footer: true },
    { id: 'numberOfActiveTransfers', prefix: 'period.', footer: true },
    { id: 'sizeOfAllTransfers', prefix: 'period.', convert: true, footer: true },
    { id: 'sizeOfActiveTransfers', prefix: 'period.', convert: true, footer: true },
    { id: 'sendSecondPartOfKeyCounter', prefix: 'period.', footer: true },
  ];

  const dataTransfers = {
    labels: [
      t('statistics.sum'),
      ...get(statistics, 'organizationStatistics.users', []).map(
        (user: any) => `${get(user, 'name', '')} (${get(user, 'email', '')})`
      ),
    ],
    datasets: [
      { id: 'numberOfActiveTransfers', backgroundColor: 'rgba(239,126,50,0.9)' },
      { id: 'numberOfAllTransfers', backgroundColor: 'rgba(69,116,198,0.9)' },
    ].map((item) => ({
      label: t(`statistics.${item.id}`),
      backgroundColor: item.backgroundColor,
      borderColor: item.backgroundColor,
      borderWidth: 1,
      hoverBackgroundColor: item.backgroundColor,
      hoverBorderColor: item.backgroundColor,
      data: [
        get(statistics, `organizationStatistics.period.${item.id}`, 0),
        ...get(statistics, 'organizationStatistics.users', []).map((user: any) =>
          get(user, `period.${t(item.id)}`, 0)
        ),
      ],
    })),
  };

  const dataSizes = {
    ...dataTransfers,
    datasets: [
      { id: 'sizeOfActiveTransfers', backgroundColor: 'rgba(239,126,50,0.9)' },
      { id: 'sizeOfAllTransfers', backgroundColor: 'rgba(69,116,198,0.9)' },
    ].map((item) => ({
      label: t(`statistics.${item.id}`),
      backgroundColor: item.backgroundColor,
      borderColor: item.backgroundColor,
      borderWidth: 1,
      hoverBackgroundColor: item.backgroundColor,
      hoverBorderColor: item.backgroundColor,
      data: [
        bytesToMB(get(statistics, `organizationStatistics.period.${item.id}`, 0)),
        ...get(statistics, 'organizationStatistics.users', []).map((user: any) => {
          return bytesToMB(get(user, `period.${item.id}`, 0));
        }),
      ],
    })),
  };

  const options = {
    // indexAxis: 'y',
    // scales: {
    //   xAxes: [
    //     {
    //       ticks: {
    //         beginAtZero: true,
    //       },
    //     },
    //   ],
    // },
  };

  useEffect(() => {
    getAllMonths();
    loadStatistics();
    // eslint-disable-next-line
  }, []);

  const exportExcel = async () => {
    toggleLoader();
    try {
      const response = await getExcelStatistics({
        month: selectedDate,
        organizationId: organisationId,
      });

      const headerWithFilename = get(response, 'headers.content-disposition', '');
      const filename = headerWithFilename.split('=')[1].slice(1, -1);

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      });
      const aEle = document.createElement('a');
      const href = window.URL.createObjectURL(blob);
      aEle.href = href;
      aEle.download = filename;
      document.body.appendChild(aEle);
      aEle.click();
      document.body.removeChild(aEle);
      window.URL.revokeObjectURL(href);
    } catch (e) {
      addErrorAlert(t('statistics.excelError'));
      console.debug(e);
    }
    // console.debug(response.headers);
    toggleLoader(false);
  };

  return (
    <>
      <WhiteBox title={t(`statistics.usage`)} maxWidth={false} dense={true}>
        <Grid container={true} justifyContent="center" alignItems="center">
          <Grid item={true} xs={12}>
            <b>{t(`statistics.organization`)}:</b> {get(orgInfo, 'name')}
          </Grid>
          <Grid item={true} xs={12}>
            <b>{t(`statistics.registredDomains`)}:</b> {get(orgInfo, 'domains')}
          </Grid>
          <Grid item={true} xs={12}>
            <b>{t(`statistics.userGroup`)}:</b> {orgInfo.userGroup} - {orgInfo.transfersPerDay},{' '}
            {orgInfo.totalSize}
          </Grid>
        </Grid>
      </WhiteBox>

      <div className={classes.statistics}>
        <WhiteBox maxWidth={false} dense={true}>
          <Grid container={true} justifyContent="center" alignItems="center">
            <Grid item={true} xs={12}>
              <div className={classes.monthSelect}>
                <FormControl className={classes.monthSelectFormcontrol}>
                  <InputLabel id="date-label">{t(`statistics.date`)}</InputLabel>
                  <Select onChange={changeDate} value={selectedDate} fullWidth={true}>
                    {isArray(dateArray)
                      ? dateArray.map((item) => (
                          <MenuItem key={get(item, 'id')} value={get(item, 'id')}>
                            {get(item, 'label')}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
                <Tooltip title={t('statistics.exportInExcel') || ''}>
                  <Button onClick={exportExcel}>
                    <SvgIcon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <defs>
                          <linearGradient
                            id="j"
                            x1="9.5"
                            x2="23.354"
                            y1="7.5"
                            y2="21.354"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop offset="0" stopOpacity="0.102"></stop>
                            <stop offset="1" stopOpacity="0"></stop>
                          </linearGradient>
                          <linearGradient
                            id="e"
                            x1="-337.186"
                            x2="-336.956"
                            y1="438.871"
                            y2="438.871"
                            gradientTransform="translate(20560.121 -26748.414) scale(60.9756)"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop offset="0" stopColor="#fff"></stop>
                            <stop offset="1"></stop>
                          </linearGradient>
                          <linearGradient
                            id="m"
                            x1="-1.563"
                            x2="25.045"
                            y1="5.962"
                            y2="18.369"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop offset="0" stopColor="#fff" stopOpacity="0.2"></stop>
                            <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
                          </linearGradient>
                          <clipPath id="b">
                            <path d="M0 0h24v24H0z"></path>
                          </clipPath>
                          <clipPath id="c">
                            <path d="M0 0h24v24H0z"></path>
                          </clipPath>
                          <clipPath id="d">
                            <path d="M0 0h24v24H0z"></path>
                          </clipPath>
                          <mask id="g">
                            <g filter="url(#a)">
                              <path fillOpacity="0.2" d="M0 0h24v24H0z"></path>
                            </g>
                          </mask>
                          <mask id="i">
                            <g filter="url(#a)">
                              <path fillOpacity="0.102" d="M0 0h24v24H0z"></path>
                            </g>
                          </mask>
                          <mask id="l">
                            <g filter="url(#a)">
                              <path fillOpacity="0.051" d="M0 0h24v24H0z"></path>
                            </g>
                          </mask>
                          <g id="f" clipPath="url(#b)">
                            <path fill="#fff" d="M0 2.61v.25L14 .25V0zm0 0"></path>
                          </g>
                          <g id="h" clipPath="url(#c)">
                            <path d="M0 21.39L14 24v-.25L0 21.14zm0 0"></path>
                          </g>
                          <g id="k" clipPath="url(#d)">
                            <path fill="url(#e)" d="M14 0L0 2.61v18.78L14 24zm0 0"></path>
                          </g>
                          <filter
                            id="a"
                            width="100%"
                            height="100%"
                            x="0%"
                            y="0%"
                            filterUnits="objectBoundingBox"
                          >
                            <feColorMatrix
                              in="SourceGraphic"
                              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
                            ></feColorMatrix>
                          </filter>
                        </defs>
                        <path fill="#fff" d="M12 3.5h11.5v17H12zm0 0"></path>
                        <path
                          fill="#177848"
                          d="M23.5 21h-10a.498.498 0 01-.5-.5c0-.277.223-.5.5-.5H23V4h-9.5a.498.498 0 01-.5-.5c0-.277.223-.5.5-.5h10c.277 0 .5.223.5.5v17c0 .277-.223.5-.5.5zm0 0"
                        ></path>
                        <path fill="#177848" d="M14 0L0 2.61v18.78L14 24zm0 0"></path>
                        <use mask="url(#g)" xlinkHref="#f"></use>
                        <path
                          fill="#177848"
                          d="M13 5h4v2h-4zm5 0h4v2h-4zm-5 3h4v2h-4zm5 0h4v2h-4zm-5 3h4v2h-4zm5 0h4v2h-4zm-5 3h4v2h-4zm5 0h4v2h-4zm-5 3h4v2h-4zm5 0h4v2h-4zm0 0"
                        ></path>
                        <use mask="url(#i)" xlinkHref="#h"></use>
                        <path
                          fill="url(#j)"
                          d="M23.5 21c.277 0 .5-.223.5-.5V13L14 3v18zm0 0"
                        ></path>
                        <path
                          fill="#fff"
                          d="M7.36 12.5l2.296-4.137-.031-.015-1.746.125L6.5 10.957 5.227 8.664l-1.653.117L5.641 12.5l-2.067 3.719 1.653.117L6.5 14.043l1.379 2.484 1.746.125.031-.015zm0 0"
                        ></path>
                        <use mask="url(#l)" xlinkHref="#k"></use>
                        <path
                          fill="url(#m)"
                          d="M23.5 3H14V0L0 2.61v18.78L14 24v-3h9.5c.277 0 .5-.223.5-.5v-17c0-.277-.223-.5-.5-.5zm0 0"
                        ></path>
                      </svg>
                    </SvgIcon>
                  </Button>
                </Tooltip>
              </div>
            </Grid>
            <Grid item={true} xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="statistics table">
                  <TableHead>
                    <TableRow>
                      {usersTable.map((item) => (
                        <TableCell>{t(`statistics.${item.id}`)}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {get(statistics, 'organizationStatistics.users', []).map((row: any) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        {reject(usersTable, { id: 'name' }).map((item: any) => {
                          const value = item.valueFnc
                            ? item.valueFnc(get(row, item.id))
                            : get(row, `${item.prefix || ''}${item.id}`, '');
                          return (
                            <TableCell align={item.align || 'center'}>
                              {item.convert ? bytesToMB(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell align="left" colSpan={3}>
                        <strong>{t(`statistics.sum`)}</strong>
                      </TableCell>
                      {filter(usersTable, { footer: true }).map((item: any) => {
                        const value = get(statistics, `organizationStatistics.period.${item.id}`);
                        return (
                          <TableCell align="center">
                            <strong>{item.convert ? bytesToMB(value) : value}</strong>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid container={true} justifyContent="center" alignItems="center">
            {get(statistics, 'organizationStatistics.users', []).length ? (
              <>
                <Grid item={true} xs={12} md={6}>
                  <h4 className={classes.graphTitle}>
                    {`${t(`statistics.transfersTitle`)} - ${get(
                      statistics,
                      'organizationStatistics.period.periodName'
                    )}`}
                  </h4>
                  <Bar data={dataTransfers} options={options} />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <h4 className={classes.graphTitle}>
                    {`${t(`statistics.sizesTitle`)} - ${get(
                      statistics,
                      'organizationStatistics.period.periodName'
                    )}`}
                  </h4>
                  <Bar data={dataSizes} options={options} />
                </Grid>
              </>
            ) : null}
          </Grid>
        </WhiteBox>
      </div>
    </>
  );
};

export default Statistics;
