import { get, find } from 'lodash';
import { IAction } from 'store/_types';
import {
  USER_STORE,
  USER_LOGOUT,
  INTERNAL_CONTACTS_STORE,
  GROUP_CONTACTS_STORE,
} from 'constants/constants';
import { IUserReducerInitialState } from './_types';

const initialState: IUserReducerInitialState = {
  isAuthorized: false,
};

const userReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case USER_STORE: {
      return {
        ...state,
        ...action.payload,
        isAuthorized: get(action.payload, 'login') ? true : false,
      };
    }
    case USER_LOGOUT: {
      return initialState;
    }
    case INTERNAL_CONTACTS_STORE: {
      return {
        ...state,
        contacts: { ...get(state, 'contacts', {}), internal: action.payload },
      };
    }
    case GROUP_CONTACTS_STORE: {
      const searchGroup = find(get(state, 'contacts.groups', []), {
        groupId: action.payload.groupId,
      });
      if (searchGroup) {
        return state;
      } else {
        return {
          ...state,
          contacts: {
            ...get(state, 'contacts', {}),
            groups: [...get(state, 'contacts.groups', []), action.payload],
          },
        };
      }
    }
    default:
      return state;
  }
};

export default userReducer;
