import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) =>
  createStyles({
    playground: {
      padding: spacing(3),

      [breakpoints.down('sm')]: {
        padding: spacing(1)
      }
    }
  })
);

export default useStyles;
