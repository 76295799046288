import * as yup from 'yup';

import { PASSWORD_REGEX } from 'constants/constants';
import useValidators from 'components/form/validations/useValidators';

const useValidationSchema = () => {
  const { required, fieldsMatch, matchesRegex, min } = useValidators();

  const NewPasswordFormSchema = yup.object().shape({
    password: matchesRegex(
      min(required(yup.string()), 8),
      PASSWORD_REGEX,
      'validations.passwordSecure'
    ),
    passwordCopy: matchesRegex(
      fieldsMatch(required(yup.string()), 'password', 'validations.fieldsDontMatch'),
      PASSWORD_REGEX,
      'validations.passwordSecure'
    )
  });

  return NewPasswordFormSchema;
};

export default useValidationSchema;
