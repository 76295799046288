import * as yup from 'yup';
import useValidators from 'components/form/validations/useValidators';

const useValidationSchema = () => {
  const { email, required, number, requiredCheckbox } = useValidators();

  const UploadFormSchema = yup.object().shape({
    recipients: yup.array().of(
      yup.object().shape({
        email: required(email(yup.string())),
        prefix: required(yup.string()),
        phoneNumber: required(number(yup.number())),
      })
    ),
  });

  const UploadLinkFormSchema = yup.object().shape({
    recipients: yup.array().of(
      yup.object().shape({
        prefix: required(yup.string()),
        phoneNumber: required(number(yup.number())),
      })
    ),
  });

  const UploadFormSchemaUnAuthorized = yup.object().shape({
    senderEmail: required(email(yup.string())),
    senderName: required(yup.string()),
    recipientEmail: required(email(yup.string())),
    prefix: required(yup.string()),
    phoneNumber: required(number(yup.number())),
    termsOfUse: requiredCheckbox(yup.bool(), 'termsRequiredForUpload'),
  });

  return { UploadFormSchema, UploadLinkFormSchema, UploadFormSchemaUnAuthorized };
};

export default useValidationSchema;
