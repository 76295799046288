import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const size = (length: number | string) => ({ width: length, height: length });

const useStyles = makeStyles(({ breakpoints, shadows, spacing, palette, transitions }: Theme) => {
  return createStyles({
    tile: {
      boxShadow: shadows[1],
      background: '#fff',
      flex: '0 0 auto',
      display: 'flex',
      alignItems: 'flex-start',
      padding: 0,
      color: palette.primary.main,

      [breakpoints.down('sm')]: {
        ...size('42vw'),
        margin: '2vw',
        display: 'flex',
        alignItems: 'center'
      },
      [breakpoints.up('sm')]: {
        ...size(150),
        margin: spacing(1)
      },
      [breakpoints.up('md')]: {
        ...size(200),
        margin: spacing(1.5)
      },
      "@media (min-width: 1400px)": {
        ...size(300),
        margin: spacing(2)
      },

      '&:hover': {
        background: palette.primary.main,
        color: '#fff',
        transition: transitions.create('color', {
          duration: transitions.duration.short,
          easing: transitions.easing.easeOut
        })
      }
    },
    label: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      [breakpoints.down('sm')]: {},
      [breakpoints.up('sm')]: {
        ...size(150)
      },
      [breakpoints.up('md')]: {
        ...size(200)
      },
      "@media (min-width: 1400px)": {
        ...size(300)
      }
    },
    iconWrapper: {
      [breakpoints.down('sm')]: {
        height: 60
      },
      [breakpoints.up('sm')]: {
        height: 100
      },
      [breakpoints.up('md')]: {
        height: 150
      },
      "@media (min-width: 1400px)": {
        height: 200
      }
    },
    labelWrapper: {
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      lineHeight: 1.2,
      maxWidth: '100%',
      justifyContent: 'center',

      [breakpoints.down('sm')]: {
        height: 25,
        fontSize: 12
      },
      [breakpoints.up('sm')]: {
        height: 50
      },
      [breakpoints.up('md')]: {
        height: 50,
        fontSize: 16
      },
      "@media (min-width: 1400px)": {
        height: 100,
        fontSize: 22
      }
    },
    icon: {
      ...size('100%')
    }
  });
});

export default useStyles;
