import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './_styles';
import useStyleByAppVersion from 'utils/hooks/useStyleByAppVersion';

const MainTitle: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { getMainTitle } = useStyleByAppVersion();

  return (
    <Grid container={true} spacing={0}>
      <Grid item={true} xs={12}>
        <Typography variant="h5" classes={{ root: classes.title }}>
          {t(getMainTitle())}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MainTitle;
