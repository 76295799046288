import React from 'react';
import { useHistory } from 'react-router';
import { isObject, get, omit } from 'lodash';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, Button } from '@material-ui/core';

import { setNewPassword } from './_api';
import useUrl from 'utils/hooks/useUrl';
import useValidationSchema from './_form';
import { NewPasswordFormValues } from './_types';
import useLoader from 'components/Loader/useLoader';
import useAlerts from 'components/Alerts/useAlerts';
import InputField from 'components/form/Input/Input';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';
import { VERIFICATION_TYPE_INVITATION_NOT_REGISTERED } from 'constants/constants';

const NewPassword: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { toggleLoader } = useLoader();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const NewPasswordFormSchema = useValidationSchema();
  const { mapAllUrlParamsToObject } = useUrl();
  const params = mapAllUrlParamsToObject();

  const onSubmit = async (
    values: NewPasswordFormValues,
    actions: FormikHelpers<NewPasswordFormValues>
  ) => {
    toggleLoader();

    let requestValues = {
      ...values,
      ...omit(params, 'user'),
      userId: isObject(params) ? get(params, 'user') : null,
    };

    // Organization should be omitted when not oreg type, DS-788
    if (get(params, 'type') !== VERIFICATION_TYPE_INVITATION_NOT_REGISTERED) {
      requestValues = omit(requestValues, 'organization');
    }

    try {
      await setNewPassword(requestValues);
      addSuccessAlert(t('profile.settingNewPassword.ok'));
      history.push('/login');
    } catch (e) {
      addErrorAlert(t('profile.settingNewPassword.nok'));
    }
    toggleLoader(false);
  };

  return (
    <Formik
      initialValues={{
        password: '',
        passwordCopy: '',
      }}
      onSubmit={onSubmit}
      validationSchema={NewPasswordFormSchema}
    >
      {({ isSubmitting, handleSubmit }) => (
        <WhiteBox title={t('profile.newPassword')}>
          <form onSubmit={handleSubmit}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <InputField
                  name="password"
                  label={t('form.password')}
                  type="password"
                  passwordRevealAllowed={true}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <InputField
                  name="passwordCopy"
                  label={t('form.passwordCheck')}
                  type="password"
                  passwordRevealAllowed={true}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth={true}
                >
                  {t('profile.setNewPassword')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </WhiteBox>
      )}
    </Formik>
  );
};

export default NewPassword;
