import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { FormikHelpers, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import LockIcon from '@material-ui/icons/Lock';
import { Grid, Button, Container } from '@material-ui/core';
import useUserInfo from 'utils/hooks/useUserInfo';
import useStyles from './_styles';
import Login from 'modules/Login/Login';
import useUrl from 'utils/hooks/useUrl';
import useValidationSchema from './_form';
import Title from 'components/Title/Title';
import usePhone from 'utils/hooks/usePhone';
import Phone from 'components/form/Phone/Phone';
import { PhoneChangeFormValues } from './_types';
import useLoader from 'components/Loader/useLoader';
import useAlerts from 'components/Alerts/useAlerts';
import InputField from 'components/form/Input/Input';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';
import { requestPhoneChange, changePhone } from './_api';

const PhoneChange: React.FC = () => {
  const { t } = useTranslation();

  const { getHash } = useUrl();
  const hash = getHash();
  const { userIsAuthorized } = useUserInfo();
  const [verificationsCodeSent, setRequestForVerificationsCode] = useState(hash ? true : false);
  const history = useHistory();
  const { toggleLoader } = useLoader();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const PhoneChangeFormSchema = useValidationSchema();
  const classes = useStyles();
  const { joinPhoneNumber, testIfPhoneHasRightFormat } = usePhone();
  const [requireLogin, setRequireLogin] = useState<boolean>(!userIsAuthorized);

  const onSubmit = async (
    values: PhoneChangeFormValues,
    { setErrors }: FormikHelpers<PhoneChangeFormValues>
  ) => {
    toggleLoader();
    const phoneNumber = joinPhoneNumber(values.prefix, values.phoneNumber);
    const phoneIsValid = testIfPhoneHasRightFormat(phoneNumber);

    if (phoneIsValid) {
      try {
        const request = await changePhone({
          ...values,
          // TODO: phone validation and concatenation
          phone: phoneNumber,
        });
        if (request) {
          addSuccessAlert(t('profile.phoneChangeSucceeded'));
          history.push('/user-home');
        }
      } catch (e) {
        addErrorAlert(t('profile.phoneChangeFailed'));
      }
    } else {
      setErrors({ phoneNumber: t('form.validations.phoneNumberInvalid') });
    }

    toggleLoader(false);
  };

  const requestForPhoneChange = async () => {
    toggleLoader();
    try {
      const request = await requestPhoneChange();
      if (request) {
        setRequestForVerificationsCode(true);
        addSuccessAlert(t('profile.requestForPhoneChangeSucceeded'));
      }
    } catch (e) {
      addErrorAlert(t('profile.requestForPhoneChangeFailed'));
    }
    toggleLoader(false);
  };

  const closeLoginDialog = () => {
    setRequireLogin(false);
  };

  return (
    <>
      {requireLogin ? (
        <Login email={''} password={''} isDialog={true} closeLoginDialog={closeLoginDialog} />
      ) : (
        <Formik
          initialValues={{
            smsCode: '',
            emailCode: hash ? hash : '',
            prefix: '+420',
            phoneNumber: '',
          }}
          onSubmit={onSubmit}
          validationSchema={PhoneChangeFormSchema}
        >
          {({ isSubmitting, handleSubmit }) => (
            <>
              <Title title={t('profile.phoneChange')} />

              {!hash && (
                <Container maxWidth="sm">
                  <Grid container={true} spacing={2} justifyContent="center">
                    <Grid item={true}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={requestForPhoneChange}
                        className={classes.requestButton}
                      >
                        {t('profile.requestForNewPassword')}
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              )}

              <WhiteBox>
                <form
                  onSubmit={handleSubmit}
                  {...(!verificationsCodeSent ? { className: classes.lockedForm } : {})}
                >
                  {!verificationsCodeSent && <LockIcon className={classes.lockIcon} />}
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12}>
                      <Phone disabled={!verificationsCodeSent} />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <InputField
                        name="smsCode"
                        label={t('profile.smsCode')}
                        disabled={!verificationsCodeSent}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <InputField
                        name="emailCode"
                        label={t('profile.emailCode')}
                        disabled={!verificationsCodeSent}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!verificationsCodeSent || isSubmitting}
                        fullWidth={true}
                      >
                        {t('profile.changePhoneAction')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </WhiteBox>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default PhoneChange;
