import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ shadows, spacing, palette, transitions, breakpoints }: Theme) => {
  return createStyles({
    recipient: {
      background: '#fff',
      boxShadow: shadows[1],
      padding: spacing(2),
      paddingTop: spacing(1),
      marginBottom: spacing(2),
      position: 'relative',
    },
    note: {
      marginTop: spacing(2),
    },
    deleteRecipient: {
      position: 'absolute',
      right: -12,
      top: -12,
      zIndex: 99,
    },
    deleteRecipientRoot: {
      background: '#000',
      width: 24,
      height: 24,
      minHeight: 24,
      '&:hover': {
        background: palette.primary.main,
      },
    },
    deleteRecipientIcon: {
      fontSize: 18,
      color: '#fff',
    },
    addFromRegistry: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    addFromRegistryButton: {
      marginRight: spacing(1),
      marginBottom: spacing(1),
    },
    submit: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      flexDirection: 'column',
      textAlign: 'center',
      '@media only screen and (min-width : 1280px)': {
        textAlign: 'left',
      },
    },
    submitButton: {
      display: 'flex',
      flexDirection: 'column',
    },
    submitButtonIcon: {
      fontSize: 240,
      maxWidth: '100%',
      maxHeight: '100%',
    },
    files: {
      textAlign: 'center',
      paddingTop: spacing(10),
      paddingBottom: spacing(10),
      transition: transitions.create('padding', {
        duration: transitions.duration.short,
        easing: transitions.easing.easeOut,
      }),
    },
    smallerFiles: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    filesIcon: {
      fontSize: 66,
      color: '#aaa',
    },
    dropZone: {
      cursor: 'pointer',
      marginBottom: spacing(2),
    },
    selectedFiles: {
      display: 'flex',
      alignItems: 'center',
    },
    selectedFilesSize: {
      marginLeft: spacing(),
    },
    phone: {
      marginTop: spacing(),
    },
    fileDetail: {
      paddingLeft: spacing(),
      paddingRight: spacing(),
    },
    fileNameContainer: {
      display: 'flex',
      alignItems: 'center',
      flex: '0 0 75%',
      paddingRight: spacing(),
      marginLeft: spacing(),
    },
    fileName: {
      marginLeft: spacing(),
    },
    fileSize: {
      color: palette.text.secondary,
      display: 'flex',
      alignItems: 'center',
    },
    imagePreview: {
      maxWidth: '100%',
    },
    fileSummary: {
      margin: 0,
      flexWrap: 'wrap',
    },
    fileSummaryExpanded: {
      '& > div:first-child': {
        margin: 0,
      },
    },
    dicomTitle: {
      marginBottom: spacing(),
    },
    addButton: {
      textAlign: 'center',
    },
    progress: {
      flexBasis: '100%',
      display: 'flex',
      marginBottom: spacing(),
      alignItems: 'center',
    },
    progressRoot: {
      width: '100%',
      marginRight: spacing(),
    },
    totalProgress: {
      flexBasis: '100%',
      display: 'flex',
      marginBottom: spacing(),
      alignItems: 'center',
    },
    contentCenter: {
      justifyContent: 'center',
    },
    archiveButton: {
      marginLeft: spacing(),
    },
    archiveButtonIcon: {
      marginRight: spacing(),
    },
    filesTitle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    fileArchiveContainer: {
      display: 'flex',
      alignItems: 'center',
      marginRight: spacing(),
      marginLeft: spacing(),
    },
    fileArchive: {
      display: 'inline-block',
      padding: '4px 8px',
      borderRadius: 4,
    },
    gdpr: { height: 200, marginTop: 30 },
    transferDelay: {
      textAlign: 'center',
    },
    bottomWrapper: {
      marginTop: spacing(3),
      marginLeft: -spacing(3),
      marginRight: -spacing(3),

      [breakpoints.down('sm')]: {
        padding: -spacing(1),
      },
    },
    bottom: {
      // width: '100%',
      display: 'block',
      height: 0,
      paddingBottom: '38.05%',
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
      backgroundRepeat: 'no-repeat',
    },
    bottomText: {
      display: 'none',
    },
  });
});

export default useStyles;
