import { get, isArray } from 'lodash';
import React, { useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Menu, ListItem, ListItemText, Typography } from '@material-ui/core';

import useStyles from './_styles';
import { INotification } from './_types';
import useUserInfo from 'utils/hooks/useUserInfo';
import { getNotifications, readNotifications } from 'modules/Notifications/_api';
import { StoreNotifications, ToggleNotificationsPopup } from 'modules/Notifications/_redux';

const NotificationsPopup: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userIsAuthorized } = useUserInfo();
  const anchor = document.getElementById('bar-btn-notifications');

  const notifications = useSelector((state) => get(state, 'app.notifications'));
  const hasUnreadNotifications = useSelector((state) => get(state, 'app.hasUnreadNotifications'));
  const notificationsPopupIsOpened = useSelector((state) =>
    get(state, 'app.notificationsPopupIsOpened')
  );

  const closeNotificationsPopup = () => {
    dispatch(ToggleNotificationsPopup(false));
  };

  const loadNotifications = async () => {
    try {
      const loadedNotifications = await getNotifications();
      dispatch(StoreNotifications(loadedNotifications));
    } catch (e) {}
  };

  const setNotificationsAsRead = async () => {
    if (hasUnreadNotifications) {
      try {
        await readNotifications();
        await loadNotifications();
      } catch (e) {}
    }
  };

  // Load notifications and set interval to load them every X seconds
  useEffect(() => {
    let notificationsInterval: any;
    if (userIsAuthorized) {
      loadNotifications();
      notificationsInterval = setInterval(async () => {
        try {
          await loadNotifications();
        } catch (e) {
          console.debug(e);
        }
      }, 30000);
    }
    return () => {
      if (notificationsInterval) {
        clearInterval(notificationsInterval);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsAuthorized]);

  useEffect(() => {}, []);

  return (
    <Menu
      autoFocus={false}
      anchorEl={anchor}
      keepMounted={true}
      open={notificationsPopupIsOpened}
      onClose={() => {
        closeNotificationsPopup();
        setNotificationsAsRead();
      }}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{ paper: classes.notifications }}
      MenuListProps={{ dense: true, disablePadding: true }}
    >
      {isArray(notifications) ? (
        notifications.map((notification: INotification) => (
          <ListItem
            key={notification.notificationId}
            divider={true}
            className={!notification.viewed ? classes.unread : undefined}
          >
            <ListItemText disableTypography={true}>
              <Typography variant="subtitle2">
                {format(parseISO(notification.created), 'd. M. y HH:mm:ss')}
              </Typography>
              <Typography variant="body2">{notification.displayMessage}</Typography>
            </ListItemText>
          </ListItem>
        ))
      ) : (
        <ListItem className={classes.noNotifications}>
          {t('notifications.noNotifications')}
        </ListItem>
      )}
    </Menu>
  );
};

export default NotificationsPopup;
