import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
      textAlign: 'left',
    },
    listItemIcon: {
      minWidth: 0,
      marginRight: spacing(),
    },
  })
);

export default useStyles;
