import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 'bold'
    },
    gutterBottom: {
      marginBottom: theme.spacing(2)
    },
    subtitle: {
      marginBottom: theme.spacing(2)
    }
  })
);

export default useStyles;
