import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette, spacing, typography }: Theme) =>
  createStyles({
    progressBarContainer: {
      width: 250,
      marginRight: spacing(2),
      color: palette.text.primary,
      textAlign: 'center',
      fontSize: typography.pxToRem(16)
    },
    progressBarRoot: {
      height: 20,
      background: palette.grey[300]
    },
    progressBar: {
      // color: theme.palette.primary.main
    }
  })
);

export default useStyles;
