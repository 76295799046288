import { get, isArray, compact, each } from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import { IRecipient } from 'modules/RecipientRegistry/_types';
import { LEVEL_L0, LEVEL_L1 } from 'constants/constants';

const usePhone = () => {
  const allowedPhoneMasks = useSelector(
    (state) => get(state, 'app.configBackend.appendix.phone.enabledPhones'),
    shallowEqual
  );

  const allowedPhoneMasksForUser = useSelector((state) => get(state, 'user.phones'), shallowEqual);

  const splitPhoneNumber = (phone: string) => {
    try {
      const parts = phone.split(' ');
      return parts.length === 2 ? parts : ['', ''];
    } catch (e) {
      return ['', ''];
    }
  };

  const getPhonePrefix = (mask: string) => {
    try {
      const parts = mask.split(' ');
      return parts.length === 2 ? parts[0].substr(2) : undefined;
    } catch (e) {
      return undefined;
    }
  };

  const allowedPrefixes = isArray(allowedPhoneMasks)
    ? compact(allowedPhoneMasks.map((mask: string) => getPhonePrefix(mask)))
    : [];

  const joinPhoneNumber = (prefix: string, phoneNumber: string) => `${prefix} ${phoneNumber}`;

  const testIfPhoneHasRightFormat = (phoneNumber: string) => {
    let atLeastOneRegexFits = false;
    each(allowedPhoneMasksForUser, (phoneRegex) => {
      const rx = RegExp(phoneRegex);
      if (rx.test(phoneNumber)) {
        atLeastOneRegexFits = true;
      }
    });
    return atLeastOneRegexFits;
  };

  const phoneCanBeChangedForRecipientLevel = (recipient: IRecipient) => {
    const userLevel = get(recipient, 'userLevel', '');
    return userLevel === LEVEL_L0 || userLevel === LEVEL_L1;
  };

  return {
    joinPhoneNumber,
    allowedPrefixes,
    splitPhoneNumber,
    allowedPhoneMasks,
    testIfPhoneHasRightFormat,
    phoneCanBeChangedForRecipientLevel,
  };
};

export default usePhone;
