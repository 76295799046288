import React, { useState, useEffect } from 'react';
import { CssBaseline } from '@material-ui/core';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { isEmpty, get, split, find, reverse, includes } from 'lodash';

import 'constants/translations/i18n';
import Bar from 'components/Bar/Bar';
import Content from 'components/Content/Content';
import MainTitle from 'components/MainTitle/MainTitle';

import Page404 from 'modules/Page404/Page404';
import Faq from 'modules/Faq/Faq';
import Login from 'modules/Login/Login';
import LoginSelect from 'modules/Login/LoginSelect';
import Terms from 'modules/Terms/Terms';
import About from 'modules/About/About';
import Invite from 'modules/Invite/Invite';
import Upload from 'modules/Upload/Upload';
import AppError from 'modules/App/AppError';
import Profile from 'modules/Profile/Profile';
import Alerts from 'components/Alerts/Alerts';
import Loader from 'components/Loader/Loader';
import UserHome from 'modules/UserHome/UserHome';
import Bootstrap from 'components/Bootstrap/Bootstrap';
import Statistics from 'modules/Statistics/Statistics';
import NewPassword from 'modules/NewPassword/NewPassword';
import ContactForm from 'modules/ContactForm/ContactForm';
import PhoneChange from 'modules/PhoneChange/PhoneChange';
import Registration from 'modules/Registration/Registration';
import Verification from 'modules/Verification/Verification';
import AuthRedirection from 'modules/Redirect/AuthRedirection';
import { OauthLogout } from 'modules/OauthLogout/OauthLogout';
import IncomingFiles from 'modules/IncomingFiles/IncomingFiles';
import OutgoingFiles from 'modules/OutgoingFiles/OutgoingFiles';
import StatisticsDetail from 'modules/Statistics/StatisticsDetail';
import TransferDetail from 'modules/TransferDetail/TransferDetail';
import PasswordChange from 'modules/PasswordChange/PasswordChange';
import NotificationsPopup from 'modules/Notifications/NotificationsPopup';
import InvitationConfirm from 'modules/InvitationConfirm/InvitationConfirm';
import RecipientRegistry from 'modules/RecipientRegistry/RecipientRegistry';
import ForgottenPassword from 'modules/ForogttenPassword/ForgottenPassword';
import OrganizationDialog from 'components/OrganizationDialog/OrganizationDialog';
import ManageOrganizations from 'modules/ManageOrganizations/ManageOrganizations';
import OrganizationDetail from 'modules/ManageOrganizations/OrganizationDetail';
import AddPhone from 'modules/Phone/AddPhone';
import PhoneVerification from 'modules/Phone/PhoneVerification';
import RedirectPage from 'modules/Start/RedirectPage';
import useUrl from 'utils/hooks/useUrl';
import {
  PAGE_SLUG_UPLOAD,
  PAGE_SLUG_VERIFICATION,
  PAGE_SLUG_DOWNLOAD,
  PAGE_SLUG_LINK,
  PERMISSION_ADMINISTRATING,
  PERMISSION_SMS_VERIFICATION,
  PERMISSION_CONFIG,
  PERMISSION_ADV_SHARE,
  PERMISSION_STATISTICS,
  ORGANIZATION_PERMISSION_STATISTICS,
  RETARGETING_SCRIPT_ID,
  CHANGE_PASSWORD_URL,
} from 'constants/constants';
import Administration from 'modules/Administration/Administration';
import Database from 'modules/Administration/Database/Database';
import Templates from 'modules/Administration/Templates/Templates';
import MainConfigFile from 'modules/Administration/MainConfigFile/MainConfigFile';
import DownloadFiles from 'modules/DownloadFiles/DownloadFiles';
import Download from 'modules/DownloadFiles/Download';
import DiomPage from 'modules/DownloadFiles/DicomPage';
import { IStart } from './_types';
import useUserInfo from 'utils/hooks/useUserInfo';
import useStyleByAppVersion from 'utils/hooks/useStyleByAppVersion';
import useConfig from 'utils/hooks/useConfig';
import TestingProccessDialog from './TestingProccessDialog';
import { useCookies } from 'react-cookie';
import Link from 'modules/OutgoingFiles/Link';
import LinkPage from 'modules/OutgoingFiles/LinkPage';
import CookiesBar from 'modules/CookiesBar/CookiesBar';
import Privacy from 'modules/CookiesBar/Privacy';
import GoogleAnalytics from 'modules/GoogleAnalytics/GoogleAnalytics';
import ActiveSessions from 'modules/ActiveSessions/ActiveSessions';
import UnblockTransfer from 'modules/UnblockTransfer/UnblockTransfer';
import Totp from 'modules/Totp/Totp';
import { UploadWithoutHash } from 'modules/Upload/UploadWithoutHash';

const Start: React.FC<IStart> = ({ setTheme }) => {
  const [appLoaded, setAppLoaded] = useState<boolean | undefined>();
  const { isPage } = useUrl();
  const { hash } = useLocation();
  const { setApplicationStyle } = useStyleByAppVersion();
  const pathname = get(useHistory(), 'location.pathname', '/');
  const history = useHistory();
  const {
    user,
    userIsAuthorized,
    userHasPermissions,
    userCanChangePassword,
    organizations,
    dicomViewerEnabled,
    userMustChangeHisPassword,
  } = useUserInfo();
  const { getConfigValue } = useConfig();
  const testingProcess = getConfigValue('testingProcess');
  const [showTestProccessDialog, setShowTestProccessDialog] = useState<boolean>(false);
  const [cookies, setCookie] = useCookies();
  const showCookiesBarConfigValue = getConfigValue('showCookiesBar');
  const [showCookiesBar, setShowCookiesBar] = useState<boolean>(false);
  const [useGA, setUseGA] = useState<boolean>(false);
  const [gaID, setGaID] = useState<string>('');
  const googleAnalytics = getConfigValue('googleAnalytics');
  const seznamRetargeting = getConfigValue('seznamRetargeting');

  const userMustChangePassword = userMustChangeHisPassword();

  const consentCookies = get(cookies, 'cc_cookie.categories', []);
  const hasConsentAboutAnalytics = includes(consentCookies, 'analytics');
  const hasConsentAboutTargeting = includes(consentCookies, 'targeting');

  const checkIfUseGA = () => {
    if (googleAnalytics !== undefined && hasConsentAboutAnalytics) {
      const enabled = get(googleAnalytics, 'enabled', false);
      const googleID = get(googleAnalytics, 'googleID', '');
      if (enabled && googleID !== '') {
        setGaID(googleID);
        setUseGA(true);
      }
    }
  };

  const checkCookies = () => {
    if (testingProcess) {
      const cookie = get(cookies, 'testProccessConfirmed', 'false');
      if (cookie === 'false') {
        setShowTestProccessDialog(true);
      }
    }
    if (showCookiesBarConfigValue) {
      setShowCookiesBar(true);
    }
  };

  const onSubmitDialog = () => {
    const date = new Date();
    date.setDate(date.getDate() + 7);
    // date.setSeconds(date.getSeconds() + 60);
    setCookie('testProccessConfirmed', true, {
      path: '/',
      expires: date,
      sameSite: 'strict',
    });
    setShowTestProccessDialog(false);
  };

  const renderHashedRoutes = () => {
    if (
      hash &&
      !isPage(PAGE_SLUG_UPLOAD) &&
      !isPage(PAGE_SLUG_VERIFICATION) &&
      !isPage(PAGE_SLUG_DOWNLOAD) &&
      !isPage(PAGE_SLUG_LINK)
    ) {
      return <Page404 />;
    }
    return hash ? (
      <>
        {isPage(PAGE_SLUG_UPLOAD) && <Upload />}
        {isPage(PAGE_SLUG_VERIFICATION) && <Verification />}
        {isPage(PAGE_SLUG_DOWNLOAD) && <Download />}
        {isPage(PAGE_SLUG_LINK) && <Link />}
      </>
    ) : pathname === '/' ? (
      renderIfAuthorized(UserHome, [], [])
    ) : null;
  };

  const renderIfAuthorized = (
    Component: React.FC<any>,
    orPermissions: string[],
    andPermissions: string[],
    nameComponent: string = ''
  ) => {
    if (!userIsAuthorized) {
      // Neregistrovaný - přesměrovat na úvodní pro nepřihlášené
      return <RedirectPage />;
    }

    let hasPermission = true;
    if (nameComponent === 'Statistics') {
      hasPermission =
        user.anyOrganizationAdmin ||
        includes(user.permission, PERMISSION_ADMINISTRATING) ||
        includes(user.permission, PERMISSION_STATISTICS) ||
        includes(user.organizationPermissions, ORGANIZATION_PERMISSION_STATISTICS);
    } else if (nameComponent === 'StatisticsDetail') {
      const [orgId] = reverse(split(pathname, '/', 3));
      const id = parseInt(orgId, 10);
      if (id == null || !userIsAuthorized) {
        hasPermission = false;
      }
      if (
        id != null &&
        userIsAuthorized &&
        (includes(user.permission, PERMISSION_ADMINISTRATING) ||
          includes(user.permission, PERMISSION_STATISTICS) ||
          (includes(user.organizationPermissions, ORGANIZATION_PERMISSION_STATISTICS) &&
            id === get(user, 'actualOrganization.id')))
      ) {
        hasPermission = true;
      } else {
        const organizationById =
          id != null && userIsAuthorized ? find(organizations, { id }) : null;
        if (!organizationById || !get(organizationById, 'isOrganizationAdmin', false)) {
          hasPermission = false;
        }
      }
    } else {
      if (!isEmpty(orPermissions)) {
        hasPermission = false;
        orPermissions.map((permission) =>
          userHasPermissions(permission) ? (hasPermission = true) : null
        );
      }
      if (!isEmpty(andPermissions)) {
        hasPermission = true;
        andPermissions.map((perm) => (userHasPermissions(perm) ? null : (hasPermission = false)));
      }
      if (nameComponent === 'administration') {
        hasPermission = hasPermission || user.anyOrganizationAdmin;
      }
    }

    if (hasPermission) {
      if (nameComponent === 'PasswordChange') {
        const appendix = getConfigValue('appendix');
        const changePasswordEnabled = get(appendix, 'authentication.changePasswordEnabled', false);
        if (!changePasswordEnabled || (userIsAuthorized && !userCanChangePassword)) {
          hasPermission = false;
        }
      }

      if (nameComponent === 'Totp') {
        const appendix = getConfigValue('appendix');
        const mfaType = get(appendix, 'authentication.mfa.type', undefined);
        if (mfaType !== 'totp') {
          hasPermission = false;
        }
      }
    }

    if (!hasPermission) {
      // Registrovaný - pokud nemá práva na stránku, přesměrovat na úvodní s dlaždicemi
      return <UserHome />;
    } else {
      return nameComponent === 'RecipientRegistry' ? (
        <RecipientRegistry selectRecipients={null} selectionDisabled={false} />
      ) : (
        <Component />
      );
    }
  };

  const renderIfCanAccessDicomViewer = (Component: React.FC<{}>) => {
    if (dicomViewerEnabled) {
      return <Component />;
    } else {
      history.push('/home');
    }
  };

  const renderIfNotAuthorized = (
    nameComponent: string,
    Component: React.FC<{}>,
    fromAppendix: string
  ) => {
    let can = true;
    if (fromAppendix) {
      const appendix = getConfigValue('appendix');
      can = get(appendix, fromAppendix, false);
    }

    if (userIsAuthorized || !can) {
      // Registrovaný - přesměrovat na úvodní pro přihlášené
      return <RedirectPage />;
    } else {
      return nameComponent === 'Login' ? (
        <Login email="" password="" closeLoginDialog={() => null} />
      ) : (
        <Component />
      );
    }
  };

  const renderIfAllowedInConfig = (Component: React.FC<{}>) => {
    const canRender = getConfigValue('showContactForm');
    if (canRender) {
      return <Component />;
    } else {
      history.push('/home');
    }
  };

  // Append seznam retargeting script
  useEffect(() => {
    if (
      get(seznamRetargeting, 'appendScript') &&
      get(seznamRetargeting, 'id') &&
      hasConsentAboutTargeting
    ) {
      const existingScript = document.getElementById(RETARGETING_SCRIPT_ID);

      if (!existingScript) {
        (window as any).seznam_retargeting_id = get(seznamRetargeting, 'id');
        const script = document.createElement('script');
        script.src = 'https://c.imedia.cz/js/retargeting.js';
        script.id = RETARGETING_SCRIPT_ID;
        document.body.appendChild(script);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seznamRetargeting]);

  useEffect(() => {
    if (appLoaded) {
      setApplicationStyle(setTheme);
      checkCookies();
      checkIfUseGA();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appLoaded]);

  // Redirect to password change if user must change password
  useEffect(() => {
    if (userMustChangePassword && pathname !== CHANGE_PASSWORD_URL) {
      history.push(CHANGE_PASSWORD_URL);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      {showTestProccessDialog === true ? (
        <TestingProccessDialog onSubmitDialog={onSubmitDialog} />
      ) : (
        <>
          <CssBaseline />
          <Bootstrap setAppLoaded={setAppLoaded} appLoaded={appLoaded} />
          <Loader />

          {appLoaded === false && <AppError />}
          {appLoaded && (
            <>
              <Bar />
              <MainTitle />
              <Alerts />
              <OrganizationDialog />
              <NotificationsPopup />
              {showCookiesBar && <CookiesBar />}
              {useGA && <GoogleAnalytics googleID={gaID} />}
              <Content>
                <Switch>
                  <Route path="/faq" component={Faq} />
                  <Route path="/about" component={About} />
                  <Route path="/login" component={LoginSelect} />
                  <Route
                    path="/login-form"
                    render={() => renderIfNotAuthorized('Login', RedirectPage, '')}
                  />
                  <Route path="/link" component={LinkPage} />
                  <Route path="/privacy" component={Privacy} />
                  <Route path="/redirect/:authType?" component={AuthRedirection} />
                  <Route path="/profile" render={() => renderIfAuthorized(Profile, [], [])} />
                  <Route path="/invite" render={() => renderIfAuthorized(Invite, [], [])} />
                  <Route path="/terms-of-use" component={Terms} />
                  <Route path="/home" render={() => renderIfAuthorized(UserHome, [], [])} />
                  <Route path="/user-home" component={UserHome} />
                  <Route path="/oauth-logout" component={OauthLogout} />
                  <Route path="/redirect-on-user-home" component={RedirectPage} />
                  <Route
                    path="/database"
                    render={() =>
                      renderIfAuthorized(
                        Database,
                        [],
                        [PERMISSION_CONFIG, PERMISSION_ADMINISTRATING]
                      )
                    }
                  />
                  <Route path="/download" component={DownloadFiles} />
                  <Route path="/download(.*)" component={Download} />
                  <Route path="/verification(.*)" component={Verification} />
                  <Route path="/link(.*)" component={Link} />
                  <Route path="/upload(.*)" component={UploadWithoutHash} />
                  <Route path="/dicom" render={() => renderIfCanAccessDicomViewer(DiomPage)} />
                  <Route path="/contact-form" render={() => renderIfAllowedInConfig(ContactForm)} />
                  <Route path="/change-phone" component={PhoneChange} />
                  <Route
                    path="/new-password"
                    render={() => renderIfNotAuthorized('', NewPassword, '')}
                  />
                  <Route
                    path="/registration"
                    render={() =>
                      renderIfNotAuthorized('', Registration, 'authentication.registrationEnabled')
                    }
                  />
                  <Route
                    path="/manage-templates/:organizationId?"
                    render={() =>
                      renderIfAuthorized(Templates, [PERMISSION_CONFIG], [], 'administration')
                    }
                  />
                  <Route
                    path="/incoming-files"
                    render={() => renderIfAuthorized(IncomingFiles, [], [])}
                  />
                  <Route
                    path="/outgoing-files"
                    render={() => renderIfAuthorized(OutgoingFiles, [], [])}
                  />
                  <Route
                    path="/statistics"
                    exact={true}
                    render={() =>
                      renderIfAuthorized(Statistics, [PERMISSION_ADMINISTRATING], [], 'Statistics')
                    }
                  />
                  <Route
                    path="/statistics/:orgId/"
                    exact={true}
                    render={() =>
                      renderIfAuthorized(
                        StatisticsDetail,
                        [PERMISSION_ADMINISTRATING],
                        [],
                        'StatisticsDetail'
                      )
                    }
                  />
                  <Route
                    path="/administration"
                    render={() =>
                      renderIfAuthorized(
                        Administration,
                        [PERMISSION_ADMINISTRATING],
                        [],
                        'administration'
                      )
                    }
                  />
                  <Route
                    path="/active-sessions"
                    render={() =>
                      renderIfAuthorized(ActiveSessions, [PERMISSION_ADMINISTRATING], [])
                    }
                  />
                  <Route
                    path="/unblock-transfer"
                    render={() =>
                      renderIfAuthorized(UnblockTransfer, [PERMISSION_ADMINISTRATING], [])
                    }
                  />
                  <Route path="/change-password" component={PasswordChange} />
                  <Route
                    path="/manage-main-config"
                    render={() =>
                      renderIfAuthorized(
                        MainConfigFile,
                        [],
                        [PERMISSION_CONFIG, PERMISSION_ADMINISTRATING]
                      )
                    }
                  />
                  <Route
                    path="/transfer-detail/:id"
                    render={() => renderIfAuthorized(TransferDetail, [], [])}
                  />
                  <Route path="/invitation-confirm" component={InvitationConfirm} />
                  <Route
                    path="/forgotten-password"
                    render={() =>
                      renderIfNotAuthorized(
                        'ForgottenPassword',
                        ForgottenPassword,
                        'authentication.changePasswordEnabled'
                      )
                    }
                  />
                  <Route
                    path="/recipient-registry"
                    render={() =>
                      renderIfAuthorized(
                        RedirectPage,
                        [PERMISSION_ADV_SHARE],
                        [],
                        'RecipientRegistry'
                      )
                    }
                  />
                  <Route
                    path="/manage-organizations"
                    render={() =>
                      renderIfAuthorized(
                        ManageOrganizations,
                        [PERMISSION_ADMINISTRATING],
                        [],
                        'administration'
                      )
                    }
                  />
                  <Route
                    path="/organization-detail/:id"
                    render={() =>
                      renderIfAuthorized(
                        OrganizationDetail,
                        [PERMISSION_ADMINISTRATING],
                        [],
                        'administration'
                      )
                    }
                  />
                  <Route
                    path="/add-phone"
                    render={() => renderIfAuthorized(AddPhone, [PERMISSION_SMS_VERIFICATION], [])}
                  />
                  <Route
                    path="/phone-verification"
                    render={() =>
                      renderIfAuthorized(PhoneVerification, [PERMISSION_SMS_VERIFICATION], [])
                    }
                  />

                  <Route path="/totp" render={() => renderIfAuthorized(Totp, [], [])} />

                  <Route exact={true} path="/" render={renderHashedRoutes} />
                  <Route exact={true} path="/#(.*)" render={renderHashedRoutes} />

                  <Route component={Page404} />
                </Switch>
              </Content>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Start;
