import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing }: Theme) => {
  return createStyles({
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    sectionMarginTop: { marginTop: spacing(3) },
    close: {
      marginBottom: spacing(2),
      marginLeft: spacing(),
    },
    dialogTitle: { display: 'flex', justifyContent: 'space-between' },
    addRecipientForm: {
      marginTop: spacing(2),
      padding: spacing(2),
      background: '#f9f9f9',
    },
    addRecipientSubForm: {
      display: 'flex',
      alignItems: 'center',
    },
    addRecipientFormTitle: {
      whiteSpace: 'nowrap',
      marginRight: spacing(2),
    },
    addRecipientFormButton: {
      marginLeft: spacing(2),
    },
    addRecipientFormSelect: {
      minWidth: 250,
      marginLeft: spacing(2),
    },
    addRecipientButton: {
      marginLeft: spacing(2),
    },
    groupsWrapper: {
      marginBottom: spacing(6),
    },
    tabsHeader: {
      background: '#fff',
    },
    tabRevealed: {
      display: 'block',
    },
    tab: {
      display: 'none',
    },
  });
});

export default useStyles;
