import { useDispatch } from 'react-redux';
import { cs, enGB as en, sk } from 'date-fns/locale';
import { get, isArray, isObject } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { StoreLanguage } from 'modules/Login/_redux';
import { getLanguage } from 'components/Bootstrap/_api';
import useI18n from './useI18n';

const useLanguages = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { changeLanguage } = useI18n();

  const dateFnsLocalesMap = {
    cs,
    en,
    sk,
  };

  const supportedLanguages = useSelector((state) =>
    get(state, 'app.configBackend.appendix.supportedLangs')
  );

  const currentLanguage = useSelector((state) => get(state, 'app.language'));

  const currentDateFnsLocale = get(dateFnsLocalesMap, currentLanguage, cs);

  const mappedLanguagesForSelect = isArray(supportedLanguages)
    ? supportedLanguages.map((language) => ({ id: language, label: t(language) }))
    : [];

  const fetchAndStoreLanguage = async () => {
    const language = await getLanguage();
    if (isObject(language)) {
      const lang = get(language, 'lang');
      dispatch(StoreLanguage(lang));
      changeLanguage(lang);
      return language;
    } else {
      return Promise.reject(language);
    }
  };

  return {
    currentLanguage,
    currentDateFnsLocale,
    supportedLanguages,
    mappedLanguagesForSelect,
    fetchAndStoreLanguage,
  };
};

export default useLanguages;
