import { isNumber, each, keys, get } from 'lodash';
import { ORGANIZATION_VALUES } from 'constants/constants';

export const getCheckedOptionsFromBinaryMap = (binaryMap: number) => {
  let resultData: any = {};
  if (isNumber(binaryMap)) {
    each(keys(ORGANIZATION_VALUES), (name) => {
      const value = get(ORGANIZATION_VALUES, name);
      resultData[name] = (binaryMap & value) === value;
    });
  }
  return resultData;
};

export const getBinaryMapFromOptions = (emailNotifications: any[]) => {
  try {
    let binaryMap = 0;
    each(
      keys(emailNotifications),
      (name) => (binaryMap += get(emailNotifications, name) ? get(ORGANIZATION_VALUES, name) : 0)
    );
    return binaryMap;
  } catch (e) {
    return 0;
  }
};
