import React from 'react';
import Iframe from 'react-iframe';
import { IWebVisionIFrame } from './_types';
import useStyles from './_styles';

const WebVisionIFrame: React.FC<IWebVisionIFrame> = ({ url, closeIFrame }) => {
  const classes = useStyles();

  return (
    <>
      <Iframe url={url} id="webvison" className={classes.iFrame} />
    </>
  );
};

export default WebVisionIFrame;
