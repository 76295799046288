export const IS_LOADING = 'IS_LOADING';
export const FORCE_LOADING_ON = 'FORCE_LOADING_ON';
export const FORCE_LOADING_OFF = 'FORCE_LOADING_OFF';

// Alerts
export const ALERT_ADD = 'ALERT_ADD';
export const ALERT_REMOVE = 'ALERT_REMOVE';
export const ALERT_TYPE_SUCCESS = 'success';
export const ALERT_TYPE_ERROR = 'error';
export const ALERT_TYPE_INFO = 'info';
export const DEFAULT_HIDE_DURATION = 6500;

// User levels
export const LEVEL_L0 = 'L0';
export const LEVEL_L1 = 'L1';

// Bootstrap
export const CONFIG_BACKEND_STORE = 'CONFIG_BACKEND_STORE';
export const CONFIG_FRONTEND_STORE = 'CONFIG_FRONTEND_STORE';
export const VERSION_STORE = 'VERSION_STORE';
export const LANGUAGE_STORE = 'LANGUAGE_STORE';
export const VERSION_COOKIE_NAME = 'VERSION_COOKIE_NAME';
export const VERSION_COOKIE_EXPIRES = 360;

// Login
export const USER_STORE = 'USER_STORE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SELECTED = 'USER_SELECTED';

// User contacts
export const INTERNAL_CONTACTS_STORE = 'INTERNAL_CONTACTS_STORE';
export const GROUP_CONTACTS_STORE = 'GROUP_CONTACTS_STORE';

// Organization dialog
export const ORGANIZATION_DIALOG_IS_OPENED = 'ORGANIZATION_DIALOG_IS_OPENED';

// Notifications
export const STORE_NOTIFICATIONS = 'STORE_NOTIFICATIONS';
export const TOGGLE_NOTIFICATIONS_POPUP = 'TOGGLE_NOTIFICATIONS_POPUP';

// Profile checkboxes
export const NOTIFICATION_DELIVERED = 'notificationDelivered';
export const NOTIFICATION_VIEWED = 'notificationViewed';
export const NOTIFICATION_EXPIRED = 'notificationExpired';
export const NOTIFICATION_BLOCKED_RECIPIENT = 'notificationBlockedRecipient';
export const NOTIFICATION_PHONE_CHANGE = 'notificationPhoneChanged';
export const NOTIFICATION_SEND_STATISTICS = 'notificationSendStatistics';
export const NOTIFICATION_EXTERNAL_USERS = 'notificationExternalUsers';
export const NOTIFICATION_VALUES = {
  [NOTIFICATION_DELIVERED]: 1,
  [NOTIFICATION_VIEWED]: 2,
  [NOTIFICATION_EXPIRED]: 4,
  [NOTIFICATION_PHONE_CHANGE]: 8,
  [NOTIFICATION_SEND_STATISTICS]: 16,
  [NOTIFICATION_BLOCKED_RECIPIENT]: 32,
  [NOTIFICATION_EXTERNAL_USERS]: 64,
};
export const ORGANIZATION_SHARED_TRANSFERS = 1;
export const ORGANIZATION_SHARED_CONTACTS = 2;
export const ORGANIZATION_PRIVATE_CONTACTS = 4;
export const ORGANIZATION_EXTERNAL_CONTACTS = 8;
export const ORGANIZATION_PRIVATE_TRANSFERS = 16;

export const ORGANIZATION_VALUES = {
  [ORGANIZATION_SHARED_TRANSFERS]: 1,
  [ORGANIZATION_SHARED_CONTACTS]: 2,
  [ORGANIZATION_PRIVATE_CONTACTS]: 4,
  [ORGANIZATION_EXTERNAL_CONTACTS]: 8,
  [ORGANIZATION_PRIVATE_TRANSFERS]: 16,
};

// Permissions
export const PERMISSION_WEB = 'WEB';
export const PERMISSION_SHARE = 'SHARE';
export const PERMISSION_ADV_SHARE = 'ADV_SHARE';
export const PERMISSION_LINK_SHARE = 'LINK_SHARE';
export const PERMISSION_SMS_VERIFICATION = 'SMS_VERIFICATION';
export const PERMISSION_EDIT_PROFILE = 'EDIT_PROFILE';
export const PERMISSION_REMOTE_SOURCES = 'REMOTE_SOURCES';
export const PERMISSION_CONFIG = 'CONFIG';
export const PERMISSION_ADMINISTRATING = 'ADMINISTRATING';
export const PERMISSION_SUPER_ADMINISTRATING = 'SUPERADMINISTRATING';
export const PERMISSION_STATISTICS = 'STATISTICS';

//Organization permissions
export const ORGANIZATION_PERMISSION_STATISTICS = 'STATISTICS';
export const ORGANIZATION_PERMISSION_CREATE_ORGANIZATION_CONTACT = 'CREATE_ORGANIZATION_CONTACT';
export const ORGANIZATION_PERMISSION_DELETE_ORGANIZATION_CONTACT = 'DELETE_ORGANIZATION_CONTACT';

// Virtual permissions
export const VIRTUAL_PERMISSION_TRANSFER_ADMINISTRATING = 'TRANSFER_ADMINISTRATING';

// Password
export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(.{3,})$/;

// Invitation default maximal
export const INVITATION_EXPIRATION_DEFAULT_MAXIMAL = 30;

// Phone
export const PHONE_NUMBER_REGEX = /^[0-9]{9}$/;

// Registration
export const REGISTRATION_ERROR_EMAIL_EXISTS = 'User.EmailExist';

export const CHANGE_PASSWORD_URL = '/change-password';

// Verifications locations map
export const PAGE_SLUG_VERIFICATION = '#verification';
export const VERIFICATION_TYPE_PHONE_CHANGE = 'echp';
export const VERIFICATION_TYPE_REGISTRATION = 'reg';
export const VERIFICATION_TYPE_FORGOTTEN_PASSWORD = 'fpass';
export const VERIFICATION_TYPE_CHANGE_PASSWORD = 'cpass';
export const VERIFICATION_TYPE_INVITATION_NOT_REGISTERED = 'oreg';
export const VERIFICATION_TYPE_INVITATION_REGISTERED = 'oinv';
export const VERIFICATION_LOCATIONS = {
  [VERIFICATION_TYPE_PHONE_CHANGE]: '/change-phone',
  [VERIFICATION_TYPE_REGISTRATION]: '/new-password',
  [VERIFICATION_TYPE_INVITATION_NOT_REGISTERED]: '/new-password',
  [VERIFICATION_TYPE_INVITATION_REGISTERED]: '/invitation-confirm',
  [VERIFICATION_TYPE_FORGOTTEN_PASSWORD]: '/new-password',
  [VERIFICATION_TYPE_CHANGE_PASSWORD]: CHANGE_PASSWORD_URL,
};

// Files
export const UPLOAD_SLUG = 'upload';
export const TRANSFERS_IN = 'in';
export const TRANSFERS_OUT = 'out';
export const FILE_SIZES = {
  1024: { GB: 1073741824, MB: 1048576, KB: 1024 },
  1000: { GB: 1e9, MB: 1e6, KB: 1e3 },
};

// Pagination
export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 50];

export const FILE_TYPE_DICOM = 'dicom';
export const FILE_TYPE_IMAGE = 'image';
export const FILE_TYPE_ARCHIVE = 'archive';
export const FILE_TYPE_DOCUMENT = 'document';

// Upload
export const PAGE_SLUG_UPLOAD = '#upload';
export const RESUMABLE_UPLOAD_TICK = 'RESUMABLE_UPLOAD_TICK';
export const RESUMABLE_UPLOAD_STATE = 'RESUMABLE_UPLOAD_STATE';
export const TOKEN_ACTION_REMOTE_SOURCE_SEARCH = 'remoteSourceSearch';
export const TOKEN_ACTION_REMOTE_SOURCE_SELECT = 'remoteSourceSelect';
export const TOKEN_ACTION_REMOTE_UPLOAD = 'remoteUpload';
export const DEFAULT_TOKEN_ACTION = TOKEN_ACTION_REMOTE_SOURCE_SEARCH;
export const VALID_TOKEN_ACTIONS = [
  TOKEN_ACTION_REMOTE_SOURCE_SEARCH,
  TOKEN_ACTION_REMOTE_SOURCE_SELECT,
  TOKEN_ACTION_REMOTE_UPLOAD,
];

// Download
export const PAGE_SLUG_DOWNLOAD = '#download';
export const DOWNLOAD_LOCATION = 'download';

// Link
export const PAGE_SLUG_LINK = '#link';
export const LINK_LOCATION = 'link';

// FILE TYPES
export const DOCUMENT_EXTS = [
  'pdf',
  'rtf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'odt',
  'ods',
  'txt',
  'html',
  'mhtml',
  'css',
];
export const IMAGE_EXTS = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff'];
export const ARCHIVE_EXTS = ['zip', '7z', 'rar', 'tar', 'gz'];

// Remote search date field types
export const REMOTE_FIELD_DATE_TYPE_STRING = 'string';
export const REMOTE_FIELD_DATE_TYPE_DATE = 'date';
export const REMOTE_FIELD_DATE_TYPE_BOOLEAN = 'boolean';
export const REMOTE_FIELD_DATE_TYPE_ENUM = 'enum';

// Users
export const USER_IN_GROUP = 1;
export const USER_NOT_IN_GROUP = 9;

// Media query
export const MOBILE_MENU_MAX_WIDTH = '959px';

// Recipients
export const STORE_RECIPIENTS = 'STORE_RECIPIENTS';
export const STORE_ADDITIONAL_INFO = 'STORE_ADDITIONAL_INFO';

// Local storage
export const API_ACCESSS_TOKENS = 'apiAccessTokens';
export const USED_API_ACCESS_TOKENS = 'usedApiAccessTokens';
export const NAMESPACE = 'sejf';

export const API_WITHOUT_TOKEN = [
  '/api/upload',
  '/api/upload-done',
  '/api/transfer-upload-start',
  '/api/transfer-upload-info',
  '/api/transfer-upload-info-update',
  '/api/transfer-upload-done',
  '/api/monitoring',
  '/api/email-error',
  '/api/server-statistics',
  '/api/version',
  '/data/config.json',
  '/data/lang/cs/translation.json',
  '/data/lang/en/translation.json',
  '/data/lang/sk/translation.json',
];

export const API_NOT_INVALIDATING_TOKEN = ['/api/autologin'];

// Config params
export const BE_CONFIG_EXPIRATION = 'Expiration';
export const BE_CONFIG_MAXTRANSFERSIZE = 'MaxTransferSize';
export const BE_CONFIG_MAXTOTALSIZE = 'MaxTotalSize';
export const BE_CONFIG_TRANSFERSPERDAY = 'TransfersPerDay';
export const BE_VIRTUAL_CONFIG_TRANSFERSPERMONTH = 'TransfersPerMonth';
export const BE_CONFIG_DICOMVIEWER = 'DicomViewer';
export const BE_CONFIG_MAXRECIPIENTS = 'MaxRecipients';
export const BE_CONFIG_MESSAGES = 'Messages';
export const BE_CONFIG_MAXTRANSFERDELAY = 'MaxTransferDelay';

// TOTP flags
export const TOTP_FLAG_2FA = 'totpFalg2fa';
export const TOTP_FLAG_SECOND_TRANSFER_CHANNEL = 'totpFlagSeconfTransferChannel';
export const TOTP_FUNCTIONS_VALUES = {
  [TOTP_FLAG_2FA]: 1,
  [TOTP_FLAG_SECOND_TRANSFER_CHANNEL]: 2,
};

// DOWNLOAD FORM FIELDS
export const FIELD_SMS = 'sms';
export const FIELD_TOTP = 'totp';

// SCRIPTS
export const RETARGETING_SCRIPT_ID = 'retargeting_seznam';

// AUTH METHODS
export const AUTH_METHOD_OPENID = 'openid';

export const AUTH_METHOD_OPENID_STATE_COOKIE_NAME = 'loginCallbackState';
export const AUTH_METHOD_OPENID_FULL_URL_COOKIE_NAME = 'loginCallbackFullUrl';
export const AUTH_METHOD_OPENID_REDIRECT_URI_COOKIE_NAME = 'loginCallbackRedirectUri';

export const INTERNAL_CONTACTS_SAVE_MODE_OPTIONAL = 'OPTIONAL';
