import { get } from 'lodash';
import Mustache from 'mustache';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';

import useStyles from './_styles';
import { ITextPage } from './_types';
import { loadHtmlTemplate } from './_api';
import useLoader from 'components/Loader/useLoader';
import useAppInfo from 'utils/hooks/useAppInfo';
import { Typography } from '@material-ui/core';
import { getTemplatePath } from 'utils/templates';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';

const createMarkup = (html: any) => ({ __html: html });

const TextPage: React.FC<ITextPage> = ({ template }) => {
  const classes = useStyles();
  const { toggleLoader } = useLoader();
  const [html, setHtml] = useState();
  const [error, setError] = useState<any>();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const appInfo = useAppInfo();

  const templatePath = getTemplatePath(language, template);

  const loadTemplate = async () => {
    toggleLoader();
    try {
      const loadedHtml = await loadHtmlTemplate(templatePath);
      setHtml(loadedHtml);
      setError(false);
    } catch (error) {
      const status = get(error, 'response.status', 500);
      setError(t(`textPage.errors.${status}`, `errors.default`));
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WhiteBox maxWidth="lg">
      <div className={classes.textPage}>
        {html && (
          <div dangerouslySetInnerHTML={createMarkup(Mustache.render(String(html), appInfo))}></div>
        )}
        {error && (
          <div>
            <Typography variant="h5">{error}</Typography>
            <Typography>
              {t('textPage.template')}: <strong>{template}</strong>
            </Typography>
            <Typography>
              {t('textPage.language')}: <strong>{language}</strong>
            </Typography>
            <Typography>
              {t('textPage.fullPath')}: <strong>{templatePath}</strong>
            </Typography>
          </div>
        )}
      </div>
    </WhiteBox>
  );
};

export default TextPage;
