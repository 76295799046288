import React from 'react';
import { omit, get, isObject } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogActions, Button, Grid } from '@material-ui/core';

import { useValidationSchemaRecipient } from './_form';
import usePhone from 'utils/hooks/usePhone';
import { Formik, FormikHelpers } from 'formik';
import Phone from 'components/form/Phone/Phone';
import useUserInfo from 'utils/hooks/useUserInfo';
import useLoader from 'components/Loader/useLoader';
import useAlerts from 'components/Alerts/useAlerts';
import { addContact, deleteOrganizationRecipient, deleteUserRecipient } from './_api';
import InputField from 'components/form/Input/Input';
import CheckboxField from 'components/form/Checkbox/Checkbox';
import { IRecipientFormValues, IRecipientForm, IRecipient } from './_types';
import DialogTitleWithClose from 'components/DialogTitleWithClose/DialogTitleWithClose';
import { LEVEL_L0 } from 'constants/constants';

const RecipientForm: React.FC<IRecipientForm> = ({
  closeDialog,
  recipient,
  isEdit,
  getAllRecipients,
  forcedSharedContact,
}) => {
  const { t } = useTranslation();
  const { toggleLoader } = useLoader();
  const { userCanAddAsOrganizationContact, userId, user } = useUserInfo();
  const actOrgId = get(user, 'actualOrganization.id', null);
  const RecipientFormSchema = useValidationSchemaRecipient();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { joinPhoneNumber, testIfPhoneHasRightFormat, phoneCanBeChangedForRecipientLevel } =
    usePhone();

  const isNewRecipient = !isObject(recipient);
  const userLevel = get(recipient, 'userLevel', '');
  const canEditPhone =
    isNewRecipient || phoneCanBeChangedForRecipientLevel(recipient as IRecipient);
  const canEditMail = isNewRecipient || userLevel === LEVEL_L0;

  let initialValues = isObject(recipient)
    ? {
        ...recipient,
        note: recipient.note || '',
      }
    : {
        note: '',
        name: '',
        email: '',
        prefix: '',
        phoneNumber: '',
        addAsOrganizationContact: forcedSharedContact,
      };

  const prepareValues = (values: IRecipientFormValues) => ({
    ...omit(values, ['phoneNumber', 'prefix']),
    // TODO: phone validation and concatenation
    phone:
      values.prefix && values.phoneNumber
        ? joinPhoneNumber(values.prefix, values.phoneNumber)
        : null,
    addAsOrganizationContact:
      isEdit && get(values, 'isUserContact', false)
        ? false
        : isEdit
        ? true
        : get(values, 'addAsOrganizationContact', false),
  });

  const onRecipientAdd = async (
    values: IRecipientFormValues,
    { setErrors }: FormikHelpers<IRecipientFormValues>,
    isEditing = false
  ) => {
    toggleLoader();
    const preparedValues = prepareValues(values);
    const phoneIsValid =
      preparedValues.phone === null ||
      testIfPhoneHasRightFormat(preparedValues.phone) ||
      !canEditPhone;

    if (phoneIsValid) {
      try {
        if (isEditing) {
          if (get(preparedValues, 'isUserContact') === true) {
            await deleteUserRecipient({ sender: userId, recipient: get(preparedValues, 'userId') });
          } else {
            await deleteOrganizationRecipient({
              organization: actOrgId,
              recipient: get(preparedValues, 'userId'),
            });
          }
        }
        const response = await addContact(preparedValues);
        if (response) {
          await getAllRecipients();
          closeDialog();
          addSuccessAlert(
            t(
              isEdit
                ? 'recipientRegistry.editingRecipientSucceeded'
                : 'recipientRegistry.addingRecipientSucceeded'
            )
          );
        }
      } catch (e) {
        addErrorAlert(
          t(
            isEdit
              ? 'recipientRegistry.editingRecipientFailed'
              : 'recipientRegistry.addingRecipientFailed'
          )
        );
      }
    } else {
      setErrors({ phoneNumber: t('form.validations.phoneNumberInvalid') });
    }
    toggleLoader(false);
  };

  const onRecipientEdit = async (
    values: IRecipientFormValues,
    actions: FormikHelpers<IRecipientFormValues>
  ) => {
    await onRecipientAdd(values, actions, true);
  };

  return (
    <Formik
      onSubmit={isEdit ? onRecipientEdit : onRecipientAdd}
      initialValues={initialValues}
      validationSchema={RecipientFormSchema}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          aria-labelledby="confirmation-dialog-title"
          open={true}
          onClose={closeDialog}
        >
          <DialogTitleWithClose
            title={t(
              isEdit
                ? 'recipientRegistry.recipientDialog.editRecipient'
                : 'recipientRegistry.addNewRecipient'
            )}
            closeDialogFn={closeDialog}
          />
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <InputField name="name" label={t('recipientRegistry.recipient.name')} />
                </Grid>
                <Grid item={true} xs={12}>
                  <InputField
                    name="email"
                    label={t('recipientRegistry.recipient.email')}
                    disabled={!canEditMail}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <Phone disabled={!canEditPhone} />
                </Grid>
                <Grid item={true} xs={12}>
                  <InputField name="note" label={t('recipientRegistry.recipient.note')} />
                </Grid>
                {!isEdit && userCanAddAsOrganizationContact && (
                  <Grid item={true} xs={12}>
                    <CheckboxField
                      key="addAsOrganizationContact"
                      name="addAsOrganizationContact"
                      label={t(`recipientRegistry.recipient.addAsOrganizationContact`)}
                      disabled={forcedSharedContact}
                    />
                  </Grid>
                )}
                <Grid item={true} xs={12}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth={true}
                    disabled={isSubmitting}
                  >
                    {t(
                      isEdit
                        ? 'recipientRegistry.recipientDialog.editRecipient'
                        : 'recipientRegistry.recipientDialog.submit'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions />
        </Dialog>
      )}
    </Formik>
  );
};

export default RecipientForm;
