import { get, isObject } from 'lodash';
import { api } from 'utils/api';
import { IDeliveredResponse } from './_types';

export const checkIfLoginRequired = (transferId: number, code: string | null = null) =>
  api()
    .post(`/api/login-required`, { transferId, code })
    .then(({ data }) => (isObject(data) ? data : false))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const logoutActualUser = () =>
  api()
    .post(`/api/logout`)
    .then(() => true)
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const sendSecondPartOfTransferKey = (
  transfer: number,
  transferCode: string | null = null,
  authCode: string
) =>
  api()
    .post(`/api/send-second-part-of-key`, { transfer, transferCode, authCode })
    .then(({ data }) => (isObject(data) ? data : false))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const getTransferFiles = (
  transfer: number,
  code: string | null = null,
  secondPartOfKey: string = '',
  op: string = 'list'
) =>
  api()
    .post(`/api/ds-files`, { transfer, code, secondPartOfKey, op })
    .then(({ data }) => (isObject(data) ? data : {}))
    .catch((error) => Promise.reject(get(error, 'data', '')));

export const getSecretTransferNote = (
  transferId: number,
  code: string | null = null,
  firstPartOfKey: string = '',
  secondPartOfKey: string = ''
) =>
  api()
    .post(`/api/get-secret-transfer-note`, { transferId, code, firstPartOfKey, secondPartOfKey })
    .then(({ data }) => (isObject(data) ? data : false))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const delivered = (
  transfer: number,
  code: string | null = null,
  onlyCheck: boolean | null = null
): Promise<IDeliveredResponse> =>
  api()
    .post(`/api/delivered`, { transfer, code, onlyCheck })
    .then(({ data }) => (isObject(data) ? data : {}))
    .catch(({ data }) => (isObject(data) ? data : {}));

export const resendSecondKey = (
  transfer: number,
  code: string | null = null
): Promise<IDeliveredResponse> =>
  api()
    .post(`/api/resend-second-part-of-key`, { transfer, code })
    .then(({ data }) => (isObject(data) ? data : {}))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const getTransferMessage = (
  messageId: number,
  transferId: number,
  firstPartOfKey: string = '',
  secondPartOfKey: string = '',
  code: string | null = null
): Promise<any> =>
  api()
    .post(`/api/get-transfer-message`, {
      messageId,
      transferId,
      firstPartOfKey,
      secondPartOfKey,
      code,
    })
    .then(({ data }) => data)
    .catch((error: any) => Promise.reject(get(error, 'response.data', '')));
