import React from 'react';
import { filter } from 'lodash';
import { useTranslation } from 'react-i18next';
import useStyles from './_styles';
import PeopleIcon from '@material-ui/icons/People';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import TextFields from '@material-ui/icons/TextFields';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import useUserInfo from 'utils/hooks/useUserInfo';
import Tile from 'components/Tile/Tile';
import {
  PERMISSION_ADMINISTRATING,
  PERMISSION_SUPER_ADMINISTRATING,
  PERMISSION_CONFIG,
  VIRTUAL_PERMISSION_TRANSFER_ADMINISTRATING,
} from 'constants/constants';

const Administration: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { userHasPermissions, userHasVirtualPermissions, user } = useUserInfo();

  const canAdministrateBasic =
    userHasPermissions(PERMISSION_ADMINISTRATING) ||
    userHasPermissions(PERMISSION_SUPER_ADMINISTRATING);

  const canUnblockTransfer =
    userHasPermissions(PERMISSION_ADMINISTRATING) &&
    userHasVirtualPermissions(VIRTUAL_PERMISSION_TRANSFER_ADMINISTRATING);
  const canAdministrateExtended = canAdministrateBasic && userHasPermissions(PERMISSION_CONFIG);
  const tiles = [
    {
      label: t('common.tiles.administration_database') as string,
      Icon: AspectRatioIcon,
      link: '/database',
      visible: canAdministrateExtended,
    },
    {
      label: t('common.tiles.administration_manage-organizations') as string,
      Icon: PeopleIcon,
      link: '/manage-organizations',
      visible: canAdministrateBasic || user.anyOrganizationAdmin,
    },
    {
      label: t('common.tiles.administration_templates') as string,
      Icon: TextFields,
      link: '/manage-templates',
      visible: canAdministrateExtended,
    },
    {
      label: t('common.tiles.administration_main-config') as string,
      Icon: InsertDriveFileIcon,
      link: '/manage-main-config',
      visible: canAdministrateExtended,
    },
    {
      label: t('common.tiles.activeSessions') as string,
      Icon: VpnKeyIcon,
      link: '/active-sessions',
      visible: canAdministrateExtended,
    },
    {
      label: t('common.tiles.unblockTransfer') as string,
      Icon: LockOpenIcon,
      link: '/unblock-transfer',
      visible: canUnblockTransfer,
    },
  ];

  const visibleTiles = filter(tiles, 'visible');

  return (
    <>
      <div className={classes.tiles}>
        {visibleTiles.map((tile) => (
          <Tile {...tile} key={tile.link} />
        ))}
      </div>
    </>
  );
};

export default Administration;
