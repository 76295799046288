import { get } from 'lodash';
import { api } from 'utils/api';

export const requestPhoneChange = () =>
  api()
    .post(`/api/change-phone-request`)
    .then(({ data }) => get(data, 'success'))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const changePhone = (data: any) =>
  api()
    .post(`/api/change-phone`, data)
    .then(({ data }) => get(data, 'success'))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));
