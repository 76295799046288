import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import { Grid, Button } from '@material-ui/core';
import { get } from 'lodash';

import useValidationSchema from './_form';
import { requestNewPassword } from './_api';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import useAlerts from 'components/Alerts/useAlerts';
import useLoader from 'components/Loader/useLoader';
import InputField from 'components/form/Input/Input';
import { ForgottenPasswordFormValues } from './_types';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';

const ForgottenPassword: React.FC = () => {
  const { t } = useTranslation();
  const { toggleLoader } = useLoader();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const ForgottenPasswordFormSchema = useValidationSchema();

  const { captchaEnabled, generateCaptchaToken } = useRecaptcha();

  const onSubmit = async (
    values: ForgottenPasswordFormValues,
    actions: FormikHelpers<ForgottenPasswordFormValues>
  ) => {
    toggleLoader();

    let recaptcha;
    if (captchaEnabled) {
      recaptcha = await generateCaptchaToken('fpassw');
    }

    const resp = await requestNewPassword({
      ...values,
      ...(captchaEnabled ? { recaptcha } : {}),
    });
    const status = get(resp, 'status');
    if (status === 'success') {
      addSuccessAlert(t('login.changingPasswordSucceeded'));
    } else if (
      status === 'error' &&
      get(resp, 'code') === 403 &&
      get(resp, 'data.errorMessage') === 'NoPassword'
    ) {
      addErrorAlert(t('login.changingPasswordNoPass'));
    } else {
      addErrorAlert(t('login.changingPasswordFailed'));
    }
    toggleLoader(false);
  };

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={ForgottenPasswordFormSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <WhiteBox title={t('login.forgottenPassword')}>
          <form onSubmit={handleSubmit}>
            <Grid container={true} spacing={2} justifyContent="center">
              <Grid item={true} xs={12}>
                <InputField name="email" label={t('login.userNameOrEmail')} />
              </Grid>
              <Grid item={true} xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth={true}
                >
                  {t('login.requestNewPassword')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </WhiteBox>
      )}
    </Formik>
  );
};

export default ForgottenPassword;
