import * as yup from 'yup';
import useValidators from 'components/form/validations/useValidators';

const useValidationSchema = () => {
  const { required } = useValidators();

  const PhoneChangeFormSchema = yup.object().shape({
    prefix: required(yup.string()),
    phoneNumber: required(yup.string()),
    smsCode: required(yup.string()),
    emailCode: required(yup.string())
  });

  return PhoneChangeFormSchema;
};

export default useValidationSchema;
