import { get, isFunction } from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import { useHistory } from 'react-router';
import RegistrationIcon from '@material-ui/icons/PersonAdd';
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';

import useStyles from './_styles';
import LoginForm from './LoginForm';
import Login2FaForm from './Login2Faform';
import useValidationSchema from './_form';
import useConfig from 'utils/hooks/useConfig';
import useStorage from 'utils/hooks/useStorage';
import useAlerts from 'components/Alerts/useAlerts';
import useLoader from 'components/Loader/useLoader';
import useLanguages from 'utils/hooks/useLanguages';
import { confirmLoginUser, loginUser } from './_api';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';
import { ILogin, LoginFormValues, IUser } from './_types';
import useUserInfo, { userHasMoreOrganizations } from 'utils/hooks/useUserInfo';
import useFetchAndStoreUser from 'utils/hooks/useFetchAndStoreUser';
import { ToggleOrganizationDialog } from 'components/OrganizationDialog/_redux';
import DialogTitleWithClose from 'components/DialogTitleWithClose/DialogTitleWithClose';
import useFetchAndStoreBackendConfig from 'utils/hooks/useFetchAndStoreBackendConfig';
import ButtonNavLink from 'components/ButtonNavLink/ButtonNavLink';
import { BottomBanner } from 'components/BottomBanner/BottomBanner';
import { CHANGE_PASSWORD_URL } from 'constants/constants';

const Login: React.FC<ILogin> = ({
  email,
  password,
  isDialog = false,
  isUnauthorizedUpload = false,
  closeLoginDialog,
  loginRequired = false,
  customCloseLoginDialogFn,
}) => {
  const { t } = useTranslation();
  const { userCanRegister, userMustChangeHisPassword } = useUserInfo();
  const LoginFormSchema = useValidationSchema();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { fetchAndStoreUser } = useFetchAndStoreUser();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { toggleLoader } = useLoader();
  const { getConfigValue } = useConfig();
  const { fetchAndStoreLanguage } = useLanguages();
  const labelForEmail = getConfigValue('appendix.loginDescription');
  const validateLoginAsEmail = getConfigValue('appendix.validateLoginAsEmail');
  const canRecoverPassword = getConfigValue('appendix.authentication.changePasswordEnabled');
  const history = useHistory();
  const { put } = useStorage();
  const { fetchAndStoreBackendConfig } = useFetchAndStoreBackendConfig();
  const [showConfirmForm, setshowConfirmForm] = useState(false);

  let initialValues = {
    email: email === undefined ? '' : email,
    password: password === undefined ? '' : password,
  };

  const processResponse = async (login: any) => {
    const confirmRequired = get(login, 'confirmRequired', false);

    if (confirmRequired) {
      setshowConfirmForm(true);
    } else {
      if (isDialog) {
        await closeLoginDialog();
      }
      if (!isUnauthorizedUpload) {
        const user: IUser | boolean = await fetchAndStoreUser();
        if (userHasMoreOrganizations(user)) {
          dispatch(ToggleOrganizationDialog(true));
        }

        const userMustChangePassword = userMustChangeHisPassword(user);

        if (userMustChangePassword) {
          history.push(CHANGE_PASSWORD_URL);
        }
      }
      await fetchAndStoreLanguage();
      if (!isDialog) {
        history.push('/user-home');
      }
      await fetchAndStoreBackendConfig();
      addSuccessAlert(t('login.loginSucceeded'));
    }
  };

  const onSubmit = async (values: LoginFormValues, actions: FormikHelpers<LoginFormValues>) => {
    toggleLoader();
    if (showConfirmForm) {
      try {
        const login = await confirmLoginUser({
          code: get(values, 'code'),
          login: get(values, 'email'),
        });
        if (login) {
          put('userHadSmsVerificationPermission', false);
          setshowConfirmForm(false);
          await processResponse(login);
        }
      } catch (e) {
        addErrorAlert(t('login.loginFailed'));
      }
    } else {
      try {
        const login = await loginUser(values);
        if (login) {
          put('userHadSmsVerificationPermission', false);
          await processResponse(login);
        } else {
          addErrorAlert(t('login.loginFailed'));
        }
      } catch (error) {
        const doesNotHaveRights = get(error, 'status') === 1;
        if (doesNotHaveRights) {
          addErrorAlert(t('login.loginFailedNoRights'));
        } else {
          addErrorAlert(t('login.loginFailed'));
        }
      }
    }

    toggleLoader(false);
  };

  const closeDialogFn = isFunction(customCloseLoginDialogFn)
    ? customCloseLoginDialogFn
    : () => {
        if (!loginRequired) {
          console.log('close');
          closeLoginDialog();
        } else {
          console.error('Login is required! Can not close login dialog!');
        }
      };

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={LoginFormSchema(validateLoginAsEmail)}
        enableReinitialize={true}
      >
        {({ isSubmitting, handleSubmit }) => (
          <>
            {isDialog ? (
              <Dialog
                maxWidth="sm"
                aria-labelledby="confirmation-dialog-title"
                open={true}
                onClose={closeDialogFn}
              >
                <DialogTitleWithClose title={t('login.login')} closeDialogFn={closeDialogFn} />
                <DialogContent>
                  {showConfirmForm === true ? (
                    <Login2FaForm handleSubmit={handleSubmit} isSubmitting={isSubmitting} />
                  ) : (
                    <LoginForm
                      email={initialValues.email}
                      handleSubmit={handleSubmit}
                      labelForEmail={labelForEmail}
                      isSubmitting={isSubmitting}
                      canRecoverPassword={canRecoverPassword}
                    />
                  )}
                </DialogContent>
                <DialogActions />
              </Dialog>
            ) : (
              <div className={classes.loginContainer}>
                <div>
                  <WhiteBox>
                    {showConfirmForm === true ? (
                      <Login2FaForm handleSubmit={handleSubmit} isSubmitting={isSubmitting} />
                    ) : (
                      <LoginForm
                        email={initialValues.email}
                        handleSubmit={handleSubmit}
                        labelForEmail={labelForEmail}
                        isSubmitting={isSubmitting}
                        canRecoverPassword={canRecoverPassword}
                      />
                    )}
                  </WhiteBox>
                </div>
                {userCanRegister ? (
                  <Button
                    to="/registration"
                    component={ButtonNavLink}
                    variant="text"
                    classes={{ root: classes.tile, label: classes.label }}
                  >
                    <div>
                      <RegistrationIcon className={classes.icon} />
                    </div>
                    <div className={classes.labelWrapper}>{t('common.tiles.registration')}</div>
                  </Button>
                ) : null}
              </div>
            )}
          </>
        )}
      </Formik>
      {!isDialog && <BottomBanner />}
    </>
  );
};

export default Login;
