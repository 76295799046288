import { api } from 'utils/api';
import { get } from 'lodash';

export const getUserOrganizations = () =>
  api()
    .post(`/api/ds-organization`, '{"op":"list"}')
    .then(({ data }) => (get(data, 'total') ? get(data, 'items') : []))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const getOrganizations = () =>
  api()
    .post(`/api/list-all-organizations`)
    .then(({ data }) => (get(data, 'total') ? get(data, 'items') : []))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const getMonths = (orgId: any) =>
  api()
    .post(`/api/months`, { organizationId: orgId })
    .then(({ data }) => data)
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const getStatistics = (data: any) =>
  api()
    .post(`/api/statistics`, data)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const getExcelStatistics = (data: any) =>
  api({ responseType: 'blob' })
    .post(`/api/excel-statistics`, data)
    .then((response) => response)
    .catch((error) => Promise.reject(get(error, 'response.data', '')));
