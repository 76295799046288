import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    basicGridContainer: {
      marginTop: 24,
    },
    addUserText: {
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
      paddingBottom: spacing(1),
    },
    refreshButton: {
      display: 'none',
    },
    externalUser: {
      color: '#00695f',
    },
    tabsHeader: {
      background: '#fff',
    },
  })
);

export default useStyles;
