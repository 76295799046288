import React from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './_styles';
import { IPhone } from './_types';
import usePhone from 'utils/hooks/usePhone';
import InputField from 'components/form/Input/Input';
import SelectField from 'components/form/Select/Select';

const Phone: React.FC<IPhone> = ({ disabled = false, fieldNamePrefix = '' }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { allowedPrefixes } = usePhone();

  return (
    <div className={classes.phone}>
      <div className={classes.prefix}>
        <SelectField
          name={`${fieldNamePrefix ? fieldNamePrefix + '.' : ''}prefix`}
          label={t('form.prefix') as string}
          items={allowedPrefixes.map((prefix) => ({ id: prefix, label: prefix }))}
          disabled={disabled}
          isCountriesPrefixesList={true}
        />
      </div>
      <InputField
        name={`${fieldNamePrefix ? fieldNamePrefix + '.' : ''}phoneNumber`}
        label={t('form.phone') as string}
        disabled={disabled}
      />
    </div>
  );
};

export default Phone;
