import React from 'react';
import { useHistory } from 'react-router';
import useValidationSchema from './_form';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, Button } from '@material-ui/core';

import { smsVerification } from './_api';
import { IPhoneVerificationFormValues } from './_types';
import InputField from 'components/form/Input/Input';
import useAlerts from 'components/Alerts/useAlerts';
import useLoader from 'components/Loader/useLoader';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';
import useFetchAndStoreUser from 'utils/hooks/useFetchAndStoreUser';
import useUserInfo from 'utils/hooks/useUserInfo';
import { PERMISSION_SMS_VERIFICATION } from 'constants/constants';
import useStorage from 'utils/hooks/useStorage';
import useFetchAndStoreBackendConfig from 'utils/hooks/useFetchAndStoreBackendConfig';

const PhoneVerification: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { toggleLoader } = useLoader();
  const { PhoneVerificationSchema } = useValidationSchema();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { fetchAndStoreUser } = useFetchAndStoreUser();
  const { userHasPermissions } = useUserInfo();
  const { put } = useStorage();
  const { fetchAndStoreBackendConfig } = useFetchAndStoreBackendConfig();

  const onSubmit = async (
    values: IPhoneVerificationFormValues,
    actions: FormikHelpers<IPhoneVerificationFormValues>
  ) => {
    toggleLoader();

    put('userHadSmsVerificationPermission', userHasPermissions(PERMISSION_SMS_VERIFICATION));

    try {
      const response = await smsVerification(values);
      if (response) {
        await fetchAndStoreUser();
        addSuccessAlert(t('phone.verification.succeeded'));
        await fetchAndStoreBackendConfig();
        history.push('/user-home');
      } else {
        addErrorAlert(t('phone.verification.failed'));
      }
    } catch (e) {
      addErrorAlert(t('phone.verification.failed'));
    }

    actions.setSubmitting(false);
    toggleLoader(false);
  };

  const initialValues = {
    smsCode: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={PhoneVerificationSchema}
    >
      {({ isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <WhiteBox title={t('phone.verification.userPhoneVerification')}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <InputField name="smsCode" label={t('phone.verification.smsCode')} />
              </Grid>
              <Grid item={true} xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth={true}
                >
                  {t('common.send')}
                </Button>
              </Grid>
            </Grid>
          </WhiteBox>
        </form>
      )}
    </Formik>
  );
};

export default PhoneVerification;
