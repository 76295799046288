import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@material-ui/core';
import { Formik } from 'formik';

const TestingProccessDialog: React.FC<any> = ({ onSubmitDialog }) => {
  const { t } = useTranslation();

  return (
    <Formik onSubmit={onSubmitDialog} initialValues={{}}>
      {({ isSubmitting, handleSubmit }) => (
        <Dialog
          maxWidth="md"
          aria-labelledby="confirmation-dialog-title"
          open={true}
          onClose={(event: object, reason: string) => {}}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
        >
          <DialogTitle id="confirmation-dialog-title">
            {t('testingProccessDialog.title')}
          </DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <Grid container={true} spacing={2} justifyContent="center">
                <Grid item={true} xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth={true}
                  >
                    {t('testingProccessDialog.submit')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions />
        </Dialog>
      )}
    </Formik>
  );
};

export default TestingProccessDialog;
