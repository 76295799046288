import React from 'react';
import { Container } from '@material-ui/core';

import useStyles from './_styles';
import { IWhiteBox } from './_types';
import Title from 'components/Title/Title';

const WhiteBox: React.FC<IWhiteBox> = ({
  children,
  maxWidth = 'sm',
  title,
  subtitle,
  dense = false,
  disableGutters = true,
}) => {
  const classes = useStyles();
  return (
    <>
      {title && <Title title={title} gutterBottom={subtitle ? false : true} />}
      {subtitle && <Title title={subtitle} subtitle={true} />}
      <Container maxWidth={maxWidth} className={disableGutters ? classes.container : undefined}>
        <div className={`${classes.whiteBox} ${dense ? classes.dense : ''}`}>{children}</div>
      </Container>
    </>
  );
};

export default WhiteBox;
