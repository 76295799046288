import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loader: {
      left: 0,
      top: 0,
      background: 'rgba(255, 255, 255, .6)',
      zIndex: 2000,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      flexDirection: 'column'
    },
    button: {
      marginTop: theme.spacing(3)
    }
  })
);

export default useStyles;
