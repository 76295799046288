import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) =>
  createStyles({
    recipients: {
      marginTop: spacing(3)
    },
    disabledContainer: {
      padding: 0
    },
    mobile: {
      [breakpoints.down('sm')]: {
        padding: spacing(1)
      },
      [breakpoints.down('xs')]: {
        padding: spacing(0)
      }
    }
  })
);

export default useStyles;
