import { Formik } from 'formik';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Container, Grid } from '@material-ui/core';
import { compact, each, filter, get, isArray, keys, set } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import useStyles from './_styles';
import Title from 'components/Title/Title';
import {
  ClearRemoteSearchAdditionalInfo,
  StoreRecipients,
  StoreRemoteSearchAdditionalInfo,
} from './_redux';
import useConfig from 'utils/hooks/useConfig';
import { searchExternalContacts } from './_api';
import useLoader from 'components/Loader/useLoader';
import InputField from 'components/form/Input/Input';
import BasicGrid from 'components/BasicGrid/BasicGrid';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';
import DatePicker from 'components/form/DatePicker/DatePicker';
import { IExternalServiceRegistry, IRecipient } from './_types';
import { generateCancelToken, cancelAxiosRequest } from 'utils/api';
import { IRemoteSourceSearchParam } from 'components/RemoteUploadDialog/_types';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from 'constants/constants';
import useUserInfo from 'utils/hooks/useUserInfo';

const ExternalService: React.FC<IExternalServiceRegistry> = ({
  service,
  selectionOnly,
  closeRecipientRegistry,
  selectRecipients,
  selectionDisabled,
  remianingRecipients,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { user } = useUserInfo();
  const { toggleLoader } = useLoader();
  const { getConfigValue } = useConfig();
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [contacts, setContacts] = useState<[] | null>(null);
  const [bulkRecipientAddDisabled, toggleBulkRecipientAddDisabled] = useState<boolean>(false);
  const gridShouldPaginate = getConfigValue('pagination.contacts.paginate');
  const [remainingRecipient, setRemainingRecipient] = useState<number>(0);
  const [lastSelectionChange, setLastSelectionChange] = useState<Date | null>(null);
  const pageSize = getConfigValue('pagination.contacts.pageSize') || DEFAULT_PAGE_SIZE;
  const pageSizeOptions =
    getConfigValue('pagination.contacts.pageSizeOptions') || DEFAULT_PAGE_SIZE_OPTIONS;

  const maxRecipients = get(user, 'limits.MaxRecipients', 5);

  const searchForExternalContacts = async (searchParams = {}) => {
    const externalContacts = await searchExternalContacts({
      source: service.name,
      searchParams,
    });
    setContacts(externalContacts);
  };

  const hasSearchParams = isArray(service.searchParams) && service.searchParams.length;
  const filteredFields = hasSearchParams ? filter(service.searchParams, { visible: true }) : [];
  const filedsWithValidation = filter(filteredFields, 'minLength');

  const keyboardListener = useCallback((e: any) => {
    if (e.keyCode === 27) {
      cancelAxiosRequest();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', keyboardListener);
    return () => {
      document.removeEventListener('keydown', keyboardListener);
    };
  }, [keyboardListener]);

  useEffect(() => {
    // Search instantly
    if (!hasSearchParams) {
      searchForExternalContacts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Spacer = () => (
    <Grid item={true} xs={12}>
      &nbsp;
    </Grid>
  );

  const onSubmit = async (values: any) => {
    toggleLoader();
    await searchForExternalContacts(values);
    toggleLoader(false);
  };

  useEffect(() => {
    setRemainingRecipient(remianingRecipients !== undefined ? remianingRecipients : maxRecipients);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Store info about recipient and external service
  // This is used to autosearch in external source based on recipient info
  // See DS-833 https://jira.orcz.cz/jira/browse/DS-833
  const storeAdditionalInfoIfNeeded = (recipients: IRecipient[]) => {
    if (!isArray(recipients) || !(recipients.length === 1)) {
      dispatch(ClearRemoteSearchAdditionalInfo());
      return false;
    }
    const recipient = recipients[0];
    if (recipient.additionalInfo && keys(recipient.additionalInfo).length) {
      dispatch(StoreRemoteSearchAdditionalInfo({ recipient, service }));
    } else {
      dispatch(ClearRemoteSearchAdditionalInfo());
    }
  };

  const someValueIsFilled = (values: any) => {
    let someFilled = false;
    each(values, (value: any) => {
      if (value !== '') {
        someFilled = true;
      }
    });
    if (dateFrom !== null) {
      someFilled = true;
    }
    if (dateTo !== null) {
      someFilled = true;
    }
    return someFilled;
  };

  const soActions = [
    {
      icon: AssignmentIndIcon,
      iconProps: {
        color: 'primary',
        fontSize: 'large',
      },
      tooltip: t('recipientRegistry.selectRecipient'),
      onClick: (_event: any, recipient: IRecipient) => {
        if (recipient && closeRecipientRegistry) {
          storeAdditionalInfoIfNeeded([recipient]);
          selectRecipients([recipient]);
          closeRecipientRegistry();
        }
      },
      position: 'row',
    },
    {
      icon: AssignmentTurnedInIcon,
      iconProps: {
        color: 'primary',
        fontSize: 'large',
      },
      disabled: bulkRecipientAddDisabled,
      tooltip: t(
        bulkRecipientAddDisabled
          ? 'recipientRegistry.recipientsLimitExceeded'
          : 'recipientRegistry.addAllRecipients'
      ),
      onClick: (_event: any, recipients: IRecipient[]) => {
        selectRecipients(recipients);

        storeAdditionalInfoIfNeeded(recipients);

        if (closeRecipientRegistry) {
          closeRecipientRegistry();
        }
      },
      position: 'toolbarOnSelect',
    },
  ];

  return (
    <div className={classes.sectionMarginTop}>
      <Title title={service.label} gutterBottom={true} />

      {filteredFields.length ? (
        <WhiteBox maxWidth="lg" disableGutters={false}>
          <Formik
            onSubmit={onSubmit}
            initialValues={{}}
            validate={(values) => {
              const errors = {};
              each(filedsWithValidation, (field: IRemoteSourceSearchParam) => {
                const fieldValue = get(values, field.name);
                if (
                  field.minLength &&
                  fieldValue &&
                  fieldValue.length &&
                  fieldValue.length < field.minLength
                ) {
                  set(
                    errors,
                    field.name,
                    t('form.validations.fieldLength', { n: field.minLength })
                  );
                }
              });
              return errors;
            }}
          >
            {({ isSubmitting, handleSubmit, values }) => {
              const searchDisabled = isSubmitting || !someValueIsFilled(values);
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container={true} spacing={4}>
                    {filteredFields.map((field) => (
                      <React.Fragment key={field.name}>
                        <Grid key={field.name} item={true} xs={12} sm={4}>
                          {get(field, 'type', '') === 'string' ? (
                            <InputField name={field.name} label={field.label} />
                          ) : get(field, 'type', '') === 'date' ? (
                            <DatePicker
                              name={field.name}
                              label={field.label}
                              selectedDate={field.name === 'dateTo' ? dateTo : dateFrom}
                              handleDateChange={field.name === 'dateTo' ? setDateTo : setDateFrom}
                            />
                          ) : (
                            <></>
                          )}
                        </Grid>
                        {field.lineBreak && <Spacer />}
                      </React.Fragment>
                    ))}
                  </Grid>
                  <Spacer />
                  <Grid item={true} xs={12} sm={4}>
                    <Button
                      variant="contained"
                      color="default"
                      fullWidth={true}
                      type="submit"
                      onClick={generateCancelToken}
                      disabled={searchDisabled}
                    >
                      {t('files.upload.remote.search')}
                    </Button>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </WhiteBox>
      ) : null}

      {contacts !== null ? (
        <>
          {isArray(contacts) && contacts.length ? (
            <BasicGrid
              data={contacts}
              columns={[
                { title: t('recipientRegistry.recipient.name'), field: 'name' },
                { title: t('recipientRegistry.recipient.email'), field: 'email' },
                { title: t('recipientRegistry.recipient.phone'), field: 'phone' },
              ]}
              options={{
                selection: !selectionDisabled,
                ...(gridShouldPaginate ? { paging: true, pageSize, pageSizeOptions } : {}),
              }}
              actions={
                selectionOnly
                  ? soActions
                  : compact([
                      {
                        icon: AssignmentIndIcon,
                        iconProps: {
                          color: 'primary',
                          fontSize: 'large',
                        },
                        tooltip: t('recipientRegistry.selectRecipient'),
                        onClick: (_event: any, recipient: IRecipient) => {
                          if (recipient) {
                            storeAdditionalInfoIfNeeded([recipient]);
                            dispatch(StoreRecipients([recipient]));
                            history.push(`/#upload`);
                          }
                        },
                        position: 'row',
                      },
                      {
                        icon: AssignmentTurnedInIcon,
                        iconProps: {
                          color: 'primary',
                          fontSize: 'large',
                        },
                        disabled: bulkRecipientAddDisabled,
                        tooltip: t(
                          bulkRecipientAddDisabled
                            ? 'recipientRegistry.recipientsLimitExceeded'
                            : 'recipientRegistry.sendToSelectedRecipients'
                        ),
                        onClick: (_event: any, recipients: IRecipient[]) => {
                          storeAdditionalInfoIfNeeded(recipients);
                          dispatch(StoreRecipients(recipients));
                          history.push(`/#upload`);
                        },
                        position: 'toolbarOnSelect',
                      },
                    ])
              }
              onSelectionChange={(rows: any) => {
                setLastSelectionChange(new Date());
                toggleBulkRecipientAddDisabled(rows.length > remainingRecipient);
              }}
              shouldRerender={lastSelectionChange}
              remaining={remainingRecipient}
            />
          ) : (
            <Container maxWidth="lg">
              <p>{t('recipientRegistry.notFound')}</p>
            </Container>
          )}
        </>
      ) : null}
    </div>
  );
};

export default ExternalService;
