// @refresh reset
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { ResumableFile } from 'resumablejs';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import UploadIcon from '@material-ui/icons/CloudUpload';
import InfoIcon from '@material-ui/icons/Info';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';
import { get, keys, isObject, noop, isArray, filter, each, isEqual, isNumber } from 'lodash';
import {
  Tooltip,
  IconButton,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableRow,
  TableCell,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core';

import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/table';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/lists';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import { Editor } from '@tinymce/tinymce-react';

import useStyles from './_styles';
import { IFiles } from './_types';
import Title from 'components/Title/Title';
import useConfig from 'utils/hooks/useConfig';
import useTransfer from 'utils/hooks/useTransfer';
import useUserInfo from 'utils/hooks/useUserInfo';
import useFileUpload from 'utils/hooks/useFileUpload';
import { FILE_TYPE_IMAGE, FILE_TYPE_DICOM } from 'constants/constants';
import RemoteUploadDialog from 'components/RemoteUploadDialog/RemoteUploadDialog';
import { IRemoteSource } from 'components/RemoteUploadDialog/_types';
import useLanguages from 'utils/hooks/useLanguages';

const Files: React.FC<IFiles> = ({
  files,
  removeFile,
  remoteFiles,
  removeAllFiles,
  addRemoteFiles,
  removeRemoteFile,
  toggleRemoteUploadDialog,
  remoteDialogVisible,
  selectedRemoteSource,
  setRemoteSource,
  textMessage,
  setTextMessage,
  isRemoteUploadOperation,
  uploadedInfoForRemoteUpload,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user } = useUserInfo();
  const canAddTextMessages = get(user, 'limits.Messages', false) === 'true' ? true : false;
  const { getConfigValue } = useConfig();
  const [loadedDicoms, setLoadedDicoms] = useState<any>();
  // console.debug({ uploadedInfoForRemoteUpload });
  const {
    formatFileSize,
    getIconForFileType,
    getAllUploadedFilesSize,
    mapUploadedFileToTransferFileType,
  } = useTransfer();
  const { getImagePreview, getDicomPreview } = useFileUpload();
  const ResumableField = (window as any).StoredResumableField;
  const totalProgress = ResumableField ? Number(ResumableField.progress() * 100).toFixed(2) : 0;
  const { currentLanguage } = useLanguages();

  const resumableUploadTick = useSelector((state) => get(state, 'app.resumableUploadTick'));
  const uploadStarted = useSelector((state) => get(state, 'app.resumableUploadStarted'));
  noop(resumableUploadTick); // Just for the var not to be unused

  const filesCombinedLength = files.length + remoteFiles.length;

  const remoteSources: IRemoteSource[] = getConfigValue('appendix.upload.remoteSources');

  const remoteUploadInfo = () => {
    if (uploadedInfoForRemoteUpload === null) {
      return '';
    }
    const finishedUploads = uploadedInfoForRemoteUpload.numberOfFinishedUploads;
    const totalUploads = uploadedInfoForRemoteUpload.numberOfUploads;
    const prefixedUploads = uploadedInfoForRemoteUpload.uploads;

    let finishedPrefixedUploads = 0;
    if (prefixedUploads !== undefined && keys(prefixedUploads).length > 0) {
      each(keys(prefixedUploads), (prefixedUpload) => {
        if (get(prefixedUpload, 'completed')) {
          finishedPrefixedUploads++;
        }
      });
    }

    let numberOfFinishedRemoteUploads = finishedUploads - finishedPrefixedUploads;

    if (isNumber(finishedUploads) && isNumber(totalUploads)) {
      return numberOfFinishedRemoteUploads < 1 ? (
        <div>
          {t('files.backgroundUploadInProcess')}
          <CircularProgress color="primary" size={14} style={{ marginLeft: 10 }} />
        </div>
      ) : (
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          {t('files.backgroundUploadDone')}
          <DoneIcon fontSize="small" style={{ marginLeft: 10 }} />
        </div>
      );
    } else {
      return '';
    }
  };

  return (
    <>
      <Title
        title={
          <div className={classes.filesTitle}>
            {`2. ${t('files.attachFiles')}`}
            {isArray(remoteSources)
              ? filter(remoteSources, { show: true, enabled: true }).map((source) => (
                  <Button
                    key={get(source, 'label')}
                    variant="contained"
                    color="default"
                    size="small"
                    className={classes.archiveButton}
                    onClick={() => {
                      setRemoteSource(source);
                      toggleRemoteUploadDialog(true);
                    }}
                    style={{ background: source.backgroundColor, color: source.textColor }}
                  >
                    <AddIcon className={classes.archiveButtonIcon} />
                    {get(source, 'label', '')}
                  </Button>
                ))
              : null}
          </div>
        }
      />
      <div className={classes.dropZone} id="browse-zone">
        <WhiteBox maxWidth={false}>
          <div>
            <div
              className={`${classes.files} ${
                files.length || remoteFiles.length || canAddTextMessages ? classes.smallerFiles : ''
              }`}
            >
              <UploadIcon className={classes.filesIcon} />
              <div>{t('files.clickHereToAddFiles')}</div>
            </div>
          </div>
        </WhiteBox>
      </div>
      {canAddTextMessages ? (
        <WhiteBox maxWidth={false}>
          <Editor
            initialValue={textMessage}
            init={{
              height: 300,
              menubar: 'table edit',
              skin: false,
              content_css: false,
              language_url: `/plugins/tinymce/langs/${currentLanguage}.js`,
              language: currentLanguage,
              plugins: 'table paste lists',
              toolbar:
                'undo redo | formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright | bullist numlist | removeformat',
              paste_enable_default_filters: false,
              paste_word_valid_elements: 'b,strong,i,em,h1,h2,h3,table,a,p',
              content_style: 'body { font-family: Arial,sans-serif; font-size:14px }',
            }}
            onEditorChange={setTextMessage}
          />
        </WhiteBox>
      ) : null}
      {filesCombinedLength ? (
        <Typography variant="h6" className={classes.selectedFiles}>
          {t('files.selectedFiles')}: {filesCombinedLength}{' '}
          {files.length ? (
            <span className={classes.selectedFilesSize}>({getAllUploadedFilesSize(files)})</span>
          ) : null}
          <Tooltip title={t('files.deleteAllFiles') || ''}>
            <IconButton aria-label="delete" onClick={() => removeAllFiles()}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Typography>
      ) : null}
      {uploadStarted && (
        <div className={classes.totalProgress}>
          <LinearProgress
            variant="determinate"
            value={Math.floor(Number(totalProgress))}
            classes={{ root: classes.progressRoot }}
          />{' '}
          {totalProgress}%
        </div>
      )}
      {!remoteDialogVisible
        ? files.map((file: ResumableFile) => {
            const fileType = mapUploadedFileToTransferFileType(get(file, 'file'));
            const isImage = fileType === FILE_TYPE_IMAGE;
            const isDicom = fileType === FILE_TYPE_DICOM;
            const id = file.uniqueIdentifier;
            const isClickable = isImage || isDicom;
            const dicomInfo = get(loadedDicoms, id);
            const hasDicomInfo = isObject(dicomInfo);
            const progress = Number(file.progress(false) * 100).toFixed(2);
            return (
              <Accordion
                TransitionProps={{ unmountOnExit: true }}
                onChange={async (_e, isOpened) => {
                  if (isOpened) {
                    if (isImage) {
                      getImagePreview(id, file.file);
                    }
                    if (isDicom) {
                      if (!get(loadedDicoms, id)) {
                        try {
                          const instance = await getDicomPreview(id, file.file);
                          setLoadedDicoms({ ...loadedDicoms, [id]: instance });
                        } catch (e) {
                          console.debug(e);
                        }
                      }
                    }
                  }
                }}
                key={id}
              >
                <AccordionSummary
                  expandIcon={
                    isClickable ? <ExpandMoreIcon /> : <div style={{ width: 24, height: 24 }}></div>
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  classes={{
                    root: classes.fileDetail,
                    content: classes.fileSummary,
                    expanded: classes.fileSummaryExpanded,
                  }}
                >
                  <Tooltip title={t('files.deleteFile') || ''}>
                    <IconButton aria-label="delete" onClick={() => removeFile(id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <div className={classes.fileNameContainer}>
                    {getIconForFileType(fileType)}
                    <span className={classes.fileName}>{get(file, 'file.name')}</span>
                  </div>
                  <div className={classes.fileSize}>{formatFileSize(get(file, 'file.size'))}</div>
                  {uploadStarted && (
                    <div className={classes.progress}>
                      <LinearProgress
                        variant="determinate"
                        value={Math.floor(Number(progress))}
                        classes={{ root: classes.progressRoot }}
                      />{' '}
                      {progress}%
                    </div>
                  )}
                </AccordionSummary>
                {isClickable && (
                  <AccordionDetails>
                    {isImage && (
                      <img id={`img-${id}`} src="" alt={id} className={classes.imagePreview} />
                    )}
                    {isDicom && (
                      <div>
                        {hasDicomInfo ? (
                          <>
                            <Typography variant="h6" className={classes.dicomTitle}>
                              {t('files.dicomInfo')}:
                            </Typography>
                            <Table size="small">
                              <TableBody>
                                {keys(dicomInfo).map((key: string) => (
                                  <TableRow key={key}>
                                    <TableCell>{t(`files.dicom.${key}`)}:</TableCell>
                                    <TableCell>{get(dicomInfo, key)}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </>
                        ) : (
                          <p>{t('files.dicomCouldNotBeLoaded')}</p>
                        )}
                      </div>
                    )}
                  </AccordionDetails>
                )}
              </Accordion>
            );
          })
        : null}
      {!remoteDialogVisible
        ? remoteFiles.map((file: any) => {
            return (
              <Accordion TransitionProps={{ unmountOnExit: true }} key={file.generatedID}>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  classes={{
                    root: classes.fileDetail,
                    content: classes.fileSummary,
                    expanded: classes.fileSummaryExpanded,
                  }}
                >
                  <Tooltip title={t('files.deleteFile') || ''}>
                    <IconButton
                      aria-label="delete"
                      onClick={() => removeRemoteFile(file.generatedID)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <div className={classes.fileArchiveContainer}>
                    <div
                      className={classes.fileArchive}
                      style={{
                        background: file.source?.backgroundColor,
                        color: file.source?.textColor,
                      }}
                    >
                      {file.source?.label}
                    </div>
                  </div>
                  <div className={classes.fileSize}>{file.description}</div>
                </AccordionSummary>
              </Accordion>
            );
          })
        : null}
      {isRemoteUploadOperation && uploadedInfoForRemoteUpload !== null ? (
        <Accordion TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            classes={{
              root: classes.fileDetail,
              content: classes.fileSummary,
              expanded: classes.fileSummaryExpanded,
            }}
          >
            <Tooltip title={t('files.youCanFinishTransferWithoutWaiting') || ''}>
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
            <div className={classes.fileNameContainer}>{remoteUploadInfo()}</div>
            <div className={classes.fileSize}>
              {isNumber(uploadedInfoForRemoteUpload.uploadedSizeInOtherUploads)
                ? formatFileSize(uploadedInfoForRemoteUpload.uploadedSizeInOtherUploads)
                : null}
            </div>
          </AccordionSummary>
        </Accordion>
      ) : null}
      {remoteDialogVisible && selectedRemoteSource ? (
        <RemoteUploadDialog
          closeRemoteUploadDialog={() => toggleRemoteUploadDialog(false)}
          remoteSource={selectedRemoteSource}
          addRemoteFiles={addRemoteFiles}
          remoteFiles={remoteFiles}
        />
      ) : null}
    </>
  );
};

const areEqual = (prevProps: any, nextProps: any) => {
  const watchedProps = [
    'files',
    'remoteFiles',
    'remoteDialogVisible',
    'selectedRemoteSource',
    'uploadedInfoForRemoteUpload',
  ];
  let shouldRerender = false;
  each(watchedProps, (prop: any) => {
    if (!isEqual(get(prevProps, prop, []), get(nextProps, prop, []))) {
      shouldRerender = true;
    }
  });

  return !shouldRerender;
};

export default React.memo(Files, areEqual);
