import { isArray, get } from 'lodash';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import useStyles from 'modules/TransferDetail/_styles';
import StopSharingIcon from '@material-ui/icons/CloudOff';
import OutgoingFilesIcon from '@material-ui/icons/FindInPage';
import ShowLinkIcon from '@material-ui/icons/OpenInBrowser';
import SenderIcon from '@material-ui/icons/FolderShared';
import { format, parseISO, isFuture, formatDistance } from 'date-fns';

import { stopSharing, createTransferLink } from './_api';
import LinkDialog from './LinkDialog';
import Title from 'components/Title/Title';
import { Tooltip } from '@material-ui/core';
import Confirm from 'components/Confirm/Confirm';
import useTransfer from 'utils/hooks/useTransfer';
import useUserInfo from 'utils/hooks/useUserInfo';
import { TRANSFERS_OUT } from 'constants/constants';
import useAlerts from 'components/Alerts/useAlerts';
import useLoader from 'components/Loader/useLoader';
import BasicGrid from 'components/BasicGrid/BasicGrid';
import { ITransfer } from 'modules/IncomingFiles/_types';
import { getTransfers } from 'modules/IncomingFiles/_api';
import useLanguages from 'utils/hooks/useLanguages';

const OutgoingFiles: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { toggleLoader } = useLoader();
  const classes = useStyles();
  const [transfers, fetchTransfers] = useState<ITransfer[]>([]);
  const [deletedTransfer, setDeletedTransfer] = useState<ITransfer | boolean>(false);
  const [showLink, setShowLink] = useState<any | boolean>(false);
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { currentDateFnsLocale } = useLanguages();

  const {
    formatRecipientsIfMultiple,
    formatRecipientStatusIfMultiple,
    isInProgress,
    stringifyAllRecipients,
  } = useTransfer();
  const { user, userIsAuthorized } = useUserInfo();

  const closeDeletedTransferDialog = () => setDeletedTransfer(false);

  const deleteSingleTransfer = async () => {
    try {
      await stopSharing(get(deletedTransfer, 'transferId'));
      await getAllTransfers();
      addSuccessAlert(t('files.transferSharingStoppedSuccessfull'));
    } catch (e) {
      addErrorAlert(t('files.transferSharingStoppedFailed'));
    }
    closeDeletedTransferDialog();
  };

  const closeShowLinkDialog = () => setShowLink(false);

  const createLink = async (rowData: ITransfer) => {
    try {
      const link = await createTransferLink(get(rowData, 'transferId'));
      await getAllTransfers();
      if (link) {
        setShowLink(link);
      }
    } catch (e) {
      addErrorAlert(t('files.createTransferLinkFailed'));
    }
  };

  const getAllTransfers = async () => {
    toggleLoader();
    try {
      const fetchedTransfers = await getTransfers(TRANSFERS_OUT);
      if (isArray(fetchedTransfers)) {
        fetchTransfers(
          fetchedTransfers.map((transfer) => ({
            ...transfer,
            allRecipients: stringifyAllRecipients(transfer),
            isDisabled: isInProgress(transfer),
            isSender:
              userIsAuthorized &&
              get(transfer, 'senderEmail', null) != null &&
              get(user, 'email', null) != null &&
              get(transfer, 'senderEmail') === get(user, 'email'),
          }))
        );
      }
    } catch {}
    toggleLoader(false);
  };

  useEffect(() => {
    getAllTransfers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title={t('files.outgoingFiles')} />
      {isArray(transfers) && (
        <BasicGrid
          data={transfers}
          columns={[
            {
              title: t('files.transferId'),
              field: 'transferId',
            },
            // pokud "actualOrganization/flags" & 1 == 1, pak je sdileni zasilek zapnute - popis v DS-961
            ...((get(user, 'actualOrganization.flags', 0) & 1) === 1
              ? [
                  {
                    title: t('files.sender.name'),
                    field: 'transferId',
                    render: (transfer: ITransfer) => {
                      console.log(transfer);
                      return get(transfer, 'senderOrganizationId', false) &&
                        (get(transfer, 'flags', 0) & 1) === 1 ? (
                        <>
                          <Tooltip
                            title={
                              t('files.sharedTransferInfo', {
                                sender: get(transfer, 'senderName') || get(transfer, 'senderEmail'),
                              }) || ''
                            }
                          >
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                              <SenderIcon color="primary" style={{ marginRight: '10px' }} />
                              {get(transfer, 'senderName') || get(transfer, 'senderEmail')}
                            </span>
                          </Tooltip>
                        </>
                      ) : (
                        ''
                      );
                    },
                  },
                ]
              : []),
            {
              title: t('files.recipient.nameAndContact'),
              field: 'allRecipients',
              render: (transfer: ITransfer) => formatRecipientsIfMultiple(transfer),
            },
            {
              title: t('files.file.sent'),
              field: 'created',
              render: (transfer: ITransfer) =>
                format(parseISO(get(transfer, 'created')), 'd. M. y HH:mm:ss'),
            },
            {
              title: t('files.file.expiration'),
              field: 'expiration',
              render: (transfer: ITransfer) => {
                const expiration = get(transfer, 'expiration');
                if (!isFuture(parseISO(expiration))) {
                  return null;
                }
                return (
                  <Tooltip
                    title={format(parseISO(get(transfer, 'expiration')), 'd. M. y HH:mm:ss')}
                  >
                    <span>
                      {formatDistance(new Date(parseISO(expiration)), new Date(), {
                        addSuffix: true,
                        locale: currentDateFnsLocale,
                      })}
                    </span>
                  </Tooltip>
                );
              },
            },
            {
              title: t('files.recipient.status'),
              field: 'recipientStatus',
              render: (transfer: ITransfer) => formatRecipientStatusIfMultiple(transfer, classes),
            },
          ]}
          actions={[
            (rowTransfer: ITransfer) => ({
              icon: OutgoingFilesIcon,
              iconProps: {
                color: 'primary',
                fontSize: 'large',
              },
              tooltip: t('files.file.detail'),
              onClick: (_event: any, rowData: ITransfer) => {
                history.push(`/transfer-detail/${rowData.transferId}`);
              },
              hidden: rowTransfer.isDisabled,
            }),
            (rowTransfer: ITransfer) => ({
              icon: StopSharingIcon,
              iconProps: {
                color: 'primary',
                fontSize: 'large',
              },
              tooltip: t('files.file.stopSharing'),
              onClick: (_event: any, rowData: ITransfer) => setDeletedTransfer(rowData),
              hidden: rowTransfer.isDisabled || !rowTransfer.isSender,
            }),
            (rowTransfer: ITransfer) => ({
              icon: ShowLinkIcon,
              iconProps: {
                color: 'primary',
                fontSize: 'large',
              },
              tooltip: t('files.file.showLink'),
              onClick: (_event: any, rowData: ITransfer) => createLink(rowData),
              hidden: !rowTransfer.isLinkTransfer,
            }),
          ]}
        />
      )}
      {deletedTransfer && (
        <Confirm
          title={t('files.reallyStopSharing')}
          content={t('files.reallyStopSharingTransfer')}
          onClose={closeDeletedTransferDialog}
          onConfirm={deleteSingleTransfer}
        />
      )}
      {showLink && (
        <LinkDialog
          title={t('files.showLinkLabel')}
          content={showLink}
          onClose={closeShowLinkDialog}
        />
      )}
    </>
  );
};

export default OutgoingFiles;
