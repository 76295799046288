import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { get, isArray, isObject } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Typography, Container, Grid, Chip } from '@material-ui/core';
import classnames from 'classnames';
import useStyles from './_styles';
import Title from 'components/Title/Title';
import { IFile, ITransferDetail } from './_types';
import useTransfer from 'utils/hooks/useTransfer';
import useLoader from 'components/Loader/useLoader';
import BasicGrid from 'components/BasicGrid/BasicGrid';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';
import { getTransferFiles, getTransferDetail } from './_api';
import FileTree from 'components/FileTree/FileTree';

const TransferDetail: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { toggleLoader } = useLoader();
  const [files, fetchFiles] = useState<IFile[]>([]);
  const [filetree, fetchFiletree] = useState<any>(null);
  const [transferDetail, fetchTransferDetail] = useState<ITransferDetail>();
  const transferId = parseInt(get(params, 'id'), 10);
  const classes = useStyles();

  const {
    getAllRecipients,
    transferHasFiles,
    transferHasTexts,
    getAllFilesSize,
    formatFileSize,
    getSenderName,
    getSenderEmail,
  } = useTransfer();

  const getAllFiles = async () => {
    toggleLoader();
    try {
      const [dsFilesResponse, fetchedTransfer] = await Promise.all([
        getTransferFiles(transferId),
        getTransferDetail(transferId),
      ]);
      const { files, fileTree } = dsFilesResponse;
      if (isArray(files)) {
        fetchFiles(files);
      }
      if (isObject(fileTree)) {
        fetchFiletree(fileTree);
      }
      fetchTransferDetail(fetchedTransfer);
    } catch {}
    toggleLoader(false);
  };

  useEffect(() => {
    getAllFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allRecipients = getAllRecipients(transferDetail as ITransferDetail);
  const senderName = getSenderName(transferDetail as ITransferDetail);
  const senderEmail = getSenderEmail(transferDetail as ITransferDetail);
  const hasFiles = transferHasFiles(files);
  const hasTexts = transferHasTexts(transferDetail as ITransferDetail);

  return (
    <>
      <Title title={t('files.detail.title')} />
      <Title title={`${t('files.detail.transferId')}: ${transferId}`} subtitle={true} />

      <Container maxWidth="lg" className={classes.sender}>
        <Typography variant="h6" gutterBottom={true}>
          {t('files.sender.sender')}
        </Typography>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6} className={classes.senderItem}>
            <Typography variant="subtitle1" className={classes.senderLabel}>
              {t('files.sender.name')}
            </Typography>
            {senderName}
          </Grid>
          <Grid item={true} xs={5}>
            <Typography variant="subtitle1" className={classes.senderLabel}>
              {t('files.sender.email')}
            </Typography>
            {senderEmail}
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg">
        <Typography variant="h6" gutterBottom={true}>
          {t('files.recipients')} ({allRecipients.length})
        </Typography>
      </Container>

      {isArray(allRecipients) && allRecipients.length ? (
        <BasicGrid
          data={allRecipients}
          columns={[
            { title: t('files.recipient.name'), field: 'name' },
            {
              title: t('files.sender.emailOrPhone'),
              field: 'contact',
            },
            {
              title: t('files.recipient.status'),
              field: 'status',
              render: (allRecipient: any) => (
                <Chip
                  size="small"
                  color="primary"
                  label={t(`files.recipientStatus.${get(allRecipient, 'status')}`)}
                  className={classnames({
                    [classes.lime]: get(allRecipient, 'status') === 0,
                    [classes.lightGreen]: get(allRecipient, 'status') === 1,
                    [classes.green]: get(allRecipient, 'status') === 2,
                    [classes.teal]: get(allRecipient, 'status') === 3,
                    [classes.amber]: get(allRecipient, 'status') === 4,
                  })}
                />
              ),
            },
          ]}
          options={{ search: false, toolbar: false }}
          topMargin={false}
        />
      ) : (
        <WhiteBox maxWidth="lg">
          <span>{t('files.noRecipients')}</span>
        </WhiteBox>
      )}

      {console.log({ hasTexts })}
      {hasTexts && (
        <Container maxWidth="lg">
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={5}>
              <Typography variant="h6" gutterBottom={true} className={classes.titleFiles}>
                {t('files.detail.hasMessages')}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      )}
      {hasFiles && (
        <>
          <Container maxWidth="lg">
            <Typography variant="h6" gutterBottom={true} className={classes.titleFiles}>
              {t('files.files')} {hasFiles ? `(${formatFileSize(getAllFilesSize(files))})` : null}
            </Typography>
          </Container>
          <div style={{ marginBottom: 32 }}>
            <Container maxWidth="lg">
              <FileTree fileTree={filetree} />
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default TransferDetail;
