import * as yup from 'yup';
import useValidators from 'components/form/validations/useValidators';

const useValidationSchema = () => {
  const { required } = useValidators();

  const MainConfigFileFormSchema = yup.object().shape({
    key: required(required(yup.string())),
    value: required(yup.string())
  });

  return MainConfigFileFormSchema;
};

export default useValidationSchema;
