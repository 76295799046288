import { api } from 'utils/api';
import { get } from 'lodash';

export const unblockTransfer = (data: any) =>
  api()
    .post(`/api/unblock-transfer-recipient`, data)
    .then(({ data }) => get(data, 'success'))
    .catch((error) => Promise.reject(get(error, 'response.data.success', '')));

export const getBlokedRecipients = (transferId: number) =>
  api()
    .post(`/api/get-blocked-recipients`, { transferId })
    .then(({ data }) => get(data, 'emails', []))
    .catch((error) => Promise.reject(get(error, 'response.data.success', '')));
