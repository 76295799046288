import * as yup from 'yup';
import useValidators from 'components/form/validations/useValidators';

const useValidationSchema = () => {
  const { required, email } = useValidators();

  const OrganizationFormSchema = yup.object().shape({
    name: required(yup.string()),
    userGroup: required(yup.string()),
  });

  const AddByEmailFormSchema = yup.object().shape({
    email: email(required(yup.string())),
    name: yup.string(),
  });

  const DomainFormSchema = yup.object().shape({
    name: required(yup.string()),
  });

  return { OrganizationFormSchema, DomainFormSchema, AddByEmailFormSchema };
};

export default useValidationSchema;
