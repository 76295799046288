import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing }: Theme) => {
  return createStyles({
    table: {
      minWidth: 650,
    },
    graphTitle: {
      textAlign: 'center',
    },
    monthSelect: {
      marginBottom: spacing(2),
      display: 'flex',
      alignItems: 'center',
    },
    monthSelectFormcontrol: {
      minWidth: 200,
    },
    statistics: {
      marginTop: spacing(2),
    },
  });
});

export default useStyles;
