import { Button } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { get, isString, pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import useUrl from 'utils/hooks/useUrl';
import Login from 'modules/Login/Login';
import useUserInfo from 'utils/hooks/useUserInfo';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';
import { confirmInvitation, getOrganizationNameByInvitation } from './_api';
import useLoader from 'components/Loader/useLoader';
import useAlerts from 'components/Alerts/useAlerts';
import useUserLogout from 'utils/hooks/useUserLogout';
import useLogin from 'utils/hooks/useLogin';
import LoginSelect from 'modules/Login/LoginSelect';

const Invite: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useUserInfo();
  const { toggleLoader } = useLoader();
  const history = useHistory();
  const location = useLocation();
  const { mapAllUrlParamsToObject } = useUrl();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const logoutUser = useUserLogout();

  const params = mapAllUrlParamsToObject();
  const invitationIsForLoggedUser = user.userId === parseInt(get(params, 'user'), 10);
  const [organisationName, setOrganisationName] = useState<string>();
  const [invitationExpired, setInvitationExpired] = useState<boolean | undefined>(undefined);
  const invitationWrongFormat =
    !get(params, 'hash') || !get(params, 'organization') || !get(params, 'user');

  const { hasOnlyOneLoginMethod, theOnlyOneLoginMethodIsLocalForm } = useLogin();
  const hasOnlyLocalForm = hasOnlyOneLoginMethod && theOnlyOneLoginMethodIsLocalForm;
  const [localFormSelectedForLogin, setLocalFormSelectedForLogin] = useState<boolean>(false);

  const showLoginSelectDialog = () => {
    setLocalFormSelectedForLogin(true);
  };

  const paramsNamedForApi = {
    ...pick(params, ['organization', 'hash']),
    userId: get(params, 'user'),
  };

  const loadOrganisationName = async () => {
    toggleLoader();
    try {
      const organisationName = await getOrganizationNameByInvitation(paramsNamedForApi);
      if (isString(get(organisationName, 'name'))) {
        setOrganisationName(get(organisationName, 'name'));
      }
    } catch (e) {
      setInvitationExpired(true);
    }
    toggleLoader(false);
  };

  const onUserLogout = async () => {
    await logoutUser(`${location.pathname}${location.search}`);
  };

  const onInvitationConfirm = async () => {
    toggleLoader();
    try {
      await confirmInvitation(paramsNamedForApi);
      history.push('/user-home');
      addSuccessAlert(t('invite.invitationConfirm.confirmationSucceeded'));
    } catch (e) {
      addErrorAlert(t('invite.invitationConfirm.confirmationFailed'));
    }
    toggleLoader(false);
  };

  useEffect(() => {
    if (invitationIsForLoggedUser) {
      loadOrganisationName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationIsForLoggedUser]);

  const customCloseLoginDialogFn = () => {
    setLocalFormSelectedForLogin(false);
  };

  return (
    <>
      {!user.isAuthorized ? (
        <>
          {hasOnlyLocalForm || localFormSelectedForLogin ? (
            <Login
              email=""
              password=""
              isDialog={true}
              closeLoginDialog={() => null}
              loginRequired={false}
              customCloseLoginDialogFn={customCloseLoginDialogFn}
            />
          ) : (
            <LoginSelect
              isDialog={true}
              loginRequired={false}
              showLoginSelectDialog={showLoginSelectDialog}
            />
          )}
        </>
      ) : (
        <WhiteBox title={t('invite.invitationConfirm.title')}>
          {invitationExpired || invitationWrongFormat ? (
            <div>
              <p>{t('invite.invitationConfirm.expired')}</p>
              <Button
                variant="contained"
                onClick={() => history.push('/')}
                color="primary"
                fullWidth={true}
              >
                {t('invite.invitationConfirm.back')}
              </Button>
            </div>
          ) : (
            <div>
              {invitationIsForLoggedUser && (
                <div>
                  <p>
                    {t('invite.invitationConfirm.confirmationText')}:{' '}
                    <strong>{organisationName}</strong>.
                  </p>
                  <Button
                    variant="contained"
                    onClick={onInvitationConfirm}
                    color="primary"
                    fullWidth={true}
                  >
                    {t('invite.invitationConfirm.confirm')}
                  </Button>
                </div>
              )}

              {!invitationIsForLoggedUser && (
                <div>
                  <p>{t('invite.invitationConfirm.inviteForAnotherUser')}</p>
                  <Button
                    variant="contained"
                    onClick={onUserLogout}
                    color="primary"
                    fullWidth={true}
                  >
                    {t('invite.invitationConfirm.logout')}
                  </Button>
                </div>
              )}
            </div>
          )}
        </WhiteBox>
      )}
    </>
  );
};

export default Invite;
