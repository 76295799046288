import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import useConfig from 'utils/hooks/useConfig';

const useRecaptcha = () => {
  const { getConfigValue } = useConfig();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const captchaEnabled = getConfigValue('appendix.www.captcha.enabled');

  const generateCaptchaToken = async (action: string) => {
    try {
      if (executeRecaptcha) {
        return await executeRecaptcha(action);
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  const captchaApiKey = getConfigValue('appendix.www.captcha.publicKey');
  return { captchaEnabled, captchaApiKey, generateCaptchaToken };
};

export default useRecaptcha;
