import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
  return createStyles({
    passwordButton: {
      padding: 0
    }
  });
});

export default useStyles;
