import { useTranslation } from 'react-i18next';
import { isArray, get } from 'lodash';
import { useHistory } from 'react-router';
import React, { useState, useEffect } from 'react';

import useLoader from 'components/Loader/useLoader';
import BasicGrid from 'components/BasicGrid/BasicGrid';
import useUserInfo from 'utils/hooks/useUserInfo';
import { getOrganizations, getUserOrganizations } from './_api';

const Statistics: React.FC = () => {
  const history = useHistory();
  const [organizations, fetchOrganizations] = useState<any>([]);

  const { toggleLoader } = useLoader();
  const { t } = useTranslation();
  const { userIsAdminOrSuperAdmin } = useUserInfo();

  const getAllOrganizations = async () => {
    toggleLoader();
    if (userIsAdminOrSuperAdmin) {
      try {
        const fetchedOrganizations = await getOrganizations();
        if (isArray(fetchedOrganizations)) {
          fetchOrganizations(fetchedOrganizations);
        }
      } catch (e) {
        console.debug(e);
      }
    } else {
      try {
        const fetchedOrganizations = await getUserOrganizations();
        if (isArray(fetchedOrganizations)) {
          fetchOrganizations(fetchedOrganizations);
        }
      } catch (e) {
        console.debug(e);
      }
    }
    toggleLoader(false);
  };

  useEffect(() => {
    getAllOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isArray(organizations) && (
        <BasicGrid
          maxWidth="sm"
          data={organizations}
          columns={[
            { title: t('statistics.organization'), field: 'name' },
            {
              title: t('statistics.active'),
              field: 'deleted',
              sorting: true,
              defaultSort: 'asc',
              render: (organization: any) =>
                get(organization, 'deleted', false) ? t('statistics.no') : t('statistics.yes'),
            },
          ]}
          onRowClick={(e: any, row: any) => {
            history.push(`/statistics/${row.id}`);
          }}
        />
      )}
    </>
  );
};

export default Statistics;
