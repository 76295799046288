import { get } from 'lodash';
import { api } from 'utils/api';

export const addPhone = (data: any): Promise<any> =>
  api()
    .post('/api/add-phone', data)
    .then(() => true)
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const smsVerification = (requestData: any): Promise<any> =>
  api()
    .post('/api/sms-verification', requestData)
    .then(({ data }) => get(data, 'success', false))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));
