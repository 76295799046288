import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .use(XHR)
  .init({
    backend: {
      loadPath: '/data/lang/{{lng}}/{{ns}}.json',
    },
    lng: 'cs',
    fallbackLng: 'cs',
    defaultNS: 'translation',

    interpolation: {
      escapeValue: false,
    },
    compatibilityJSON: 'v3',
    react: {
      useSuspense: false,
      defaultTransParent: 'div',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    },
  });

export default i18n;
