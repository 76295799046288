import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    lockedForm: {
      position: 'relative'
    },
    lockIcon: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 999,
      color: "#adadad"
    },
    requestButton: {
      marginBottom: spacing(3)
    },
    passwordButton: {
      padding: 0
    }
  })
);

export default useStyles;
