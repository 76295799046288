import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Dialog, DialogContent, DialogActions, Button, Grid, DialogTitle } from '@material-ui/core';
import InputField from 'components/form/Input/Input';
import { changeTotp, updateTotp } from './_api';
import { get } from 'lodash';
import useLoader from 'components/Loader/useLoader';
import useAlerts from 'components/Alerts/useAlerts';
import { ISMSConfirmDialog, ISMSConfirmDialogValues } from './_types';

const SMSConfirmDialog: React.FC<ISMSConfirmDialog> = ({
  generateSecretKey,
  closeSmsDialog,
  updateTotpObject,
}) => {
  const { t } = useTranslation();
  const { toggleLoader } = useLoader();
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  let initialValues = {
    smsCode: '',
  };

  const onSubmit = async (
    values: ISMSConfirmDialogValues,
    actions: FormikHelpers<ISMSConfirmDialogValues>
  ) => {
    toggleLoader();
    if (generateSecretKey) {
      const response = await changeTotp({ smsCode: get(values, 'smsCode', '') });
      if (response) {
        closeSmsDialog(response);
        addSuccessAlert(t('profile.totp.dialog.changeTotpSucced'));
      } else {
        addErrorAlert(t('profile.totp.dialog.changeTotpFiled'));
      }
    } else {
      const response = await updateTotp({
        ...updateTotpObject,
        smsCode: get(values, 'smsCode', ''),
      });
      if (response) {
        closeSmsDialog(response);
        addSuccessAlert(t('profile.totp.dialog.updateTotpSettingsSucced'));
      } else {
        addErrorAlert(t('profile.totp.dialog.updateTotpSettingsFailed'));
      }
    }
    toggleLoader(false);
  };

  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={initialValues}>
        {({ isSubmitting, handleSubmit }) => (
          <Dialog maxWidth="sm" aria-labelledby="confirmation-dialog-title" open={true}>
            <DialogTitle>{t('profile.totp.dialog.smsConfirmation')}</DialogTitle>
            <DialogContent>
              <form onSubmit={handleSubmit}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12}>
                    <InputField
                      autoFocus={true}
                      name="smsCode"
                      label={t('profile.totp.dialog.smsCode')}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      fullWidth={true}
                      disabled={isSubmitting}
                    >
                      {t('files.downloadFiles.submit')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </DialogContent>
            <DialogActions />
          </Dialog>
        )}
      </Formik>
    </>
  );
};

export default SMSConfirmDialog;
