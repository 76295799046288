const nAgt = navigator.userAgent;

const getIndex = (name: string) => {
  return nAgt.lastIndexOf(name);
};

const testBrowser = () => {
  let browser = navigator.appName;
  let browserVersion;

  let supported = true;

  if (getIndex('Opera') !== -1) {
    browser = 'Opera';
    browserVersion = '';
    supported = false;
  } else if (getIndex('OPR') !== -1) {
    browser = 'Opera';
    browserVersion = nAgt.substr(getIndex('OPR') + 4, 4);
    supported = Number(browserVersion) > 17;
  } else if (getIndex('Chrome') !== -1) {
    browser = 'Chrome';
    browserVersion = nAgt.substr(getIndex('Chrome') + 7, 4);
    supported = Number(browserVersion) > 30;
  } else if (getIndex('Safari') !== -1) {
    browser = 'Safari';
    browserVersion = nAgt.substr(getIndex('Safari') + 7, 4);
    if (getIndex('Version') !== -1) {
      browserVersion = nAgt.substr(getIndex('Version') + 8, 4);
    }
    supported = Number(browserVersion) > 8;

    // Chrome on iPad identifies itself as Safari. Actual results do not match what Google claims
    //  at: https://developers.google.com/chrome/mobile/docs/user-agent?hl=ja
    //  No mention of chrome in the user agent string. However it does mention CriOS, which presumably
    //  can be keyed on to detect it.
    if (nAgt.indexOf('CriOS') !== -1) {
      //Chrome on iPad spoofing Safari...correct it.
      browser = 'Chrome';
      //Don't believe there is a way to grab the accurate version number, so leaving that for now.
      supported = true;
    }
  } else if (getIndex('Firefox') !== -1) {
    browser = 'Firefox';
    browserVersion = nAgt.substr(getIndex('Firefox') + 8, 4);
    supported = Number(browserVersion) > 30;
  } else if (getIndex('MSIE') !== -1) {
    browser = 'IE';
    browserVersion = nAgt.substr(getIndex('MSIE') + 5, 4);
    supported = false;
  } else if (getIndex('Trident/') !== -1) {
    //IF IE > 10
    browser = 'IE';
    browserVersion = nAgt.substr(getIndex(' rv:') + 4, 4);
  } else {
    browser = 'unknown';
    browserVersion = '';
    supported = false;
  }

  console.log(browser + ' ' + browserVersion + ' is ' + (supported ? '' : 'not ') + 'supported');

  return supported;
};

export default testBrowser;
