import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  Button,
  Grid,
  DialogContentText,
} from '@material-ui/core';
import { IQRCodeDialog } from './_types';

const QRCodeDialog: React.FC<IQRCodeDialog> = ({ qrSecretKey, closedialog, openQRDialog }) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog maxWidth="xs" aria-labelledby="confirmation-dialog-title" open={openQRDialog}>
        <DialogTitle>{t('profile.totp.dialog.qrCode')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('profile.totp.dialog.text')}</DialogContentText>
          <DialogContentText>
            {t('profile.totp.dialog.links')}
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=cs"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Authenticator(GP)
            </a>
            ,&nbsp;
            <a
              href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=cs"
              target="_blank"
              rel="noopener noreferrer"
            >
              Microsoft Authenticator(GP)
            </a>
            ,&nbsp;
            <a
              href="https://play.google.com/store/apps/details?id=org.fedorahosted.freeotp&hl=cs"
              target="_blank"
              rel="noopener noreferrer"
            >
              FreeOTP(GP)
            </a>
            ,&nbsp;
            <a
              href="https://apps.apple.com/cz/app/google-authenticator/id388497605?l=cs"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Authenticator(AS)
            </a>
            ,&nbsp;
            <a
              href="https://apps.apple.com/cz/app/microsoft-authenticator/id983156458?l=cs"
              target="_blank"
              rel="noopener noreferrer"
            >
              Microsoft Authenticator(AS)
            </a>
          </DialogContentText>
          <DialogContentText>{t('profile.totp.dialog.warning')}</DialogContentText>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <img
                  width={300}
                  height={300}
                  src={`data:image/${get(qrSecretKey, 'qrFormat', '')};base64, ${get(
                    qrSecretKey,
                    'qr',
                    ''
                  )}`}
                  alt="QR code"
                />
              </div>
            </Grid>
            <Grid item={true} xs={12}>
              <div>
                <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                  {t('profile.totp.dialog.secretKey')}
                </Typography>
                <Typography variant="subtitle2" style={{ textAlign: 'center' }}>
                  {get(qrSecretKey, 'key', '')}
                </Typography>
              </div>
            </Grid>
            <Grid item={true} xs={12}>
              <Button
                variant="contained"
                type="button"
                color="primary"
                fullWidth={true}
                onClick={closedialog}
              >
                {t('profile.totp.dialog.close')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default QRCodeDialog;
