import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Button } from '@material-ui/core';
import InputField from 'components/form/Input/Input';
import { ILogin2FaForm } from './_types';

const Login2FaForm: React.FC<ILogin2FaForm> = ({ handleSubmit, isSubmitting }) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <Grid container={true} spacing={2} justifyContent="center">
        <Grid item={true} xs={12}>
          <InputField
            autoFocus={true}
            name="code"
            label={t('login.code')}
            type="code"
            inputProps={{ maxLength: 6 }}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            fullWidth={true}
          >
            {t('login.login2FaAction')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Login2FaForm;
