import { get, set } from 'lodash';
import { api } from 'utils/api';

export const getTransferFiles = (
  transfer: number,
  code: string | null = null,
  op: string = 'list'
) => {
  const body = { transfer, op };
  if (code !== null) {
    set(body, 'code', code);
  }
  return api()
    .post(`/api/ds-files`, body)
    .then(({ data }) => ({ files: get(data, 'items'), fileTree: get(data, 'appendix.fileTree') }))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));
};

export const getTransferDetail = (
  transferId: number,
  code: string | null = null,
  op: string = 'list',
  convert: boolean = true,
  type: string = 'out'
) =>
  api()
    .post(`/api/ds-transfer-detail`, { transferId, code, op, convert, type })
    .then(({ data }) => get(data, 'items[0]'))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const fileTree = {
  dirs: {
    'first-level-dir': {
      name: 'first-level-dir',
      path: ['/'],
      files: [
        {
          fileId: 5521,
          transfer: 789,
          name: 'first-1.txt',
          path: 'first-level-dir',
          fileType: 'document',
          size: 603781,
          downloads: 0,
        },
        {
          fileId: 5519,
          transfer: 789,
          name: 'first-2.txt',
          path: 'first-level-dir',
          fileType: 'document',
          size: 603781,
          downloads: 0,
        },
      ],
      dirs: {
        'second-level-dir': {
          name: 'second-level-dir',
          path: ['/', 'first-level-dir'],
          files: [
            {
              fileId: 5526,
              transfer: 789,
              name: 'second-1.txt',
              path: 'first-level-dir/second-level-dir',
              fileType: 'document',
              size: 603792,
              downloads: 0,
            },
            {
              fileId: 5527,
              transfer: 789,
              name: 'second-2.txt',
              path: 'first-level-dir/second-level-dir',
              fileType: 'document',
              size: 603793,
              downloads: 0,
            },
          ],
          dirs: {
            'third-level-dir': {
              name: 'third-level-dir',
              path: ['/', 'first-level-dir', 'second-level-dir'],
              files: [
                {
                  fileId: 5524,
                  transfer: 789,
                  name: 'third-1.txt',
                  path: 'first-level-dir/second-level-dir/third-level-dir',
                  fileType: 'document',
                  size: 603794,
                  downloads: 0,
                },
                {
                  fileId: 5525,
                  transfer: 789,
                  name: 'third-2.txt',
                  path: 'first-level-dir/second-level-dir/third-level-dir',
                  fileType: 'document',
                  size: 603791,
                  downloads: 0,
                },
              ],
            },
          },
        },
        'second-level-dir-2': {
          name: 'second-level-dir',
          path: ['/', 'first-level-dir'],
          files: [
            {
              fileId: 5526,
              transfer: 789,
              name: 'second-1.txt',
              path: 'first-level-dir/second-level-dir',
              fileType: 'document',
              size: 603792,
              downloads: 0,
            },
            {
              fileId: 5527,
              transfer: 789,
              name: 'second-2.txt',
              path: 'first-level-dir/second-level-dir',
              fileType: 'document',
              size: 603793,
              downloads: 0,
            },
          ],
          dirs: {
            'third-level-dir': {
              name: 'third-level-dir',
              path: ['/', 'first-level-dir', 'second-level-dir'],
              files: [
                {
                  fileId: 5524,
                  transfer: 789,
                  name: 'third-1.txt',
                  path: 'first-level-dir/second-level-dir/third-level-dir',
                  fileType: 'document',
                  size: 603794,
                  downloads: 0,
                },
                {
                  fileId: 5525,
                  transfer: 789,
                  name: 'third-2.txt',
                  path: 'first-level-dir/second-level-dir/third-level-dir',
                  fileType: 'document',
                  size: 603791,
                  downloads: 0,
                },
              ],
              dirs: {
                'fourth-level-dir': {
                  name: 'fourth-level-dir',
                  path: ['/', 'first-level-dir', 'second-level-dir', 'third-level-dir'],
                  files: [
                    {
                      fileId: 5524,
                      transfer: 789,
                      name: 'third-1.txt',
                      path: 'first-level-dir/second-level-dir/third-level-dir/fourth-level-dir',
                      fileType: 'document',
                      size: 603794,
                      downloads: 0,
                    },
                    {
                      fileId: 5525,
                      transfer: 789,
                      name: 'third-2.txt',
                      path: 'first-level-dir/second-level-dir/third-level-dir/fourth-level-dir',
                      fileType: 'document',
                      size: 603791,
                      downloads: 0,
                    },
                  ],
                },
              },
            },
          },
        },
        'second-level-dir-3': {
          name: 'second-level-dir',
          path: ['/', 'first-level-dir'],
          files: [
            {
              fileId: 5526,
              transfer: 789,
              name: 'second-1.txt',
              path: 'first-level-dir/second-level-dir',
              fileType: 'document',
              size: 603792,
              downloads: 0,
            },
            {
              fileId: 5527,
              transfer: 789,
              name: 'second-2.txt',
              path: 'first-level-dir/second-level-dir',
              fileType: 'document',
              size: 603793,
              downloads: 0,
            },
          ],
          dirs: {
            'third-level-dir': {
              name: 'third-level-dir',
              path: ['/', 'first-level-dir', 'second-level-dir'],
              files: [
                {
                  fileId: 5524,
                  transfer: 789,
                  name: 'third-1.txt',
                  path: 'first-level-dir/second-level-dir/third-level-dir',
                  fileType: 'document',
                  size: 603794,
                  downloads: 0,
                },
                {
                  fileId: 5525,
                  transfer: 789,
                  name: 'third-2.txt',
                  path: 'first-level-dir/second-level-dir/third-level-dir',
                  fileType: 'document',
                  size: 603791,
                  downloads: 0,
                },
              ],
            },
          },
        },
      },
    },
  },
  files: [
    {
      fileId: 5522,
      transfer: 789,
      name: '1.txt',
      fileType: 'document',
      size: 2767932,
      downloads: 0,
    },
    {
      fileId: 5523,
      transfer: 789,
      name: '2.txt',
      fileType: 'document',
      size: 603775,
      downloads: 0,
    },
    {
      fileId: 5520,
      transfer: 789,
      name: '3.txt',
      fileType: 'document',
      size: 5242880,
      downloads: 0,
    },
    {
      fileId: 5518,
      transfer: 789,
      name: '4.txt',
      fileType: 'document',
      size: 1048576,
      downloads: 0,
    },
    {
      fileId: 5528,
      transfer: 789,
      name: '5.dcm',
      fileType: 'dicom',
      size: 510928,
      downloads: 0,
    },
  ],
};
