import React from 'react';
import TextPage from 'components/TextPage/TextPage';
import useStyleByAppVersion from 'utils/hooks/useStyleByAppVersion';

const Faq: React.FC = () => {
  const { getFaqPage } = useStyleByAppVersion();
  return <TextPage template={getFaqPage()} />;
};

export default Faq;
