import * as yup from 'yup';
import useValidators from 'components/form/validations/useValidators';

const useValidationSchema = () => {
  const { required, requiredCheckbox, email } = useValidators();

  const RegistrationFormSchema = yup.object().shape({
    email: required(email(yup.string())),
    name: required(yup.string()),
    personalData: requiredCheckbox(yup.bool(), 'personalDataRequired'),
    termsOfUse: requiredCheckbox(yup.bool(), 'termsRequired')
  });

  return RegistrationFormSchema;
};

export default useValidationSchema;
