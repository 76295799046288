import { IUser, IBackendConfig, IFrontendConfig } from './_types';
import {
  USER_STORE,
  USER_LOGOUT,
  CONFIG_BACKEND_STORE,
  LANGUAGE_STORE,
  CONFIG_FRONTEND_STORE,
  VERSION_STORE
} from 'constants/constants';

export const StoreUser = (user: IUser | {}) => ({
  type: USER_STORE,
  payload: user
});

export const RemoveUser = () => ({
  type: USER_LOGOUT
});

export const StoreBackendConfig = (config: IBackendConfig) => ({
  type: CONFIG_BACKEND_STORE,
  payload: config
});

export const StoreFrontendConfig = (config: IFrontendConfig) => ({
  type: CONFIG_FRONTEND_STORE,
  payload: config
});

export const StoreVersion = (version: string) => ({
  type: VERSION_STORE,
  payload: version
});

export const StoreLanguage = (language: string) => ({
  type: LANGUAGE_STORE,
  payload: language
});
