import { Button } from '@material-ui/core';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';
import { get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import useLogin from 'utils/hooks/useLogin';

export const OauthLogout: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { oauthConfig } = useLogin();
  return (
    <WhiteBox>
      <p>{t('login.logoutFromSSO')}</p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => (window.location.href = get(oauthConfig, 'logoutUri'))}
        >
          {t('login.confirmLogoutFromSSO')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="inherit"
          style={{ marginLeft: 16 }}
          onClick={() => history.push('/login')}
        >
          {t('login.dontLogoutFromSSO')}
        </Button>
      </div>
    </WhiteBox>
  );
};
