import { get } from 'lodash';

import { api } from 'utils/api';
import { IRemoteSourceSearchData, IRemoteSourceItem, IRemoteSourceDocumentItem } from './_types';

export const searchRemote = async (
  data: IRemoteSourceSearchData,
  cancelToken: any
): Promise<IRemoteSourceItem[] | IRemoteSourceDocumentItem | false> => {
  try {
    return await api()
      .post(`/api/remote-source-search`, { ...data }, { cancelToken })
      .then((response) => (get(response, 'data.success') ? response.data : []))
      .catch((error) => {
        Promise.reject(error);
      });
  } catch (e) {
    return false;
  }
};
