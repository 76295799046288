import classnames from 'classnames';
import React, { forwardRef, useEffect, useState } from 'react';
import { isEqual, get, each, filter } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Container, Grid, MuiThemeProvider } from '@material-ui/core';
import MaterialTable, { Icons, MTableToolbar } from 'material-table';

import { createTheme } from '@material-ui/core/styles';

import Clear from '@material-ui/icons/Clear';
import Search from '@material-ui/icons/Search';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import NextPage from '@material-ui/icons/NavigateNext';
import PrevPage from '@material-ui/icons/NavigateBefore';

import useStyles from './_styles';
import { IBasicGrid } from './_types';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from 'constants/constants';

const tableIcons: Icons = {
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <NextPage {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <PrevPage {...props} ref={ref} />),
};

const BasicGrid: React.FC<IBasicGrid> = ({
  maxWidth = 'lg',
  data,
  components,
  toolBarComponents,
  columns,
  options,
  actions = [],
  topMargin = true,
  disabledContainer = false,
  onSelectionChange = () => false,
  onRowClick,
  tableRef = React.createRef(),
  remaining = 5,
  detailPanel = null,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedRows, setSelectedRows] = useState(0);
  const theme = createTheme({
    palette: {
      primary: {
        main: !remaining || selectedRows <= remaining ? '#4caf50' : '#f50057',
      },

      secondary: {
        main: !remaining || selectedRows <= remaining ? '#4caf50' : '#f50057',
      },
    },
  });

  // This is ojeb. It should be done by checked: true prop (see line cca #90 here, it is commented out)
  // Material-table is broken, the issue has been reported here: https://github.com/mbrn/material-table/issues/1632
  useEffect(() => {
    const initiallyChecked = filter(data, 'checked');
    if (initiallyChecked.length) {
      each(initiallyChecked, (item) => {
        const input: any = document.querySelectorAll(`input[value='${item.$index}'`)[0];
        if (input) {
          input.click();
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <Container
        maxWidth={maxWidth}
        classes={{
          root: classnames(classes.mobile, {
            [classes.recipients]: topMargin,
            [classes.disabledContainer]: disabledContainer,
          }),
        }}
      >
        <MaterialTable
          columns={columns}
          data={data}
          icons={tableIcons}
          tableRef={tableRef}
          options={{
            showTitle: false,
            paging: false,
            searchFieldAlignment: 'left',
            searchFieldStyle:
              selectedRows > 0
                ? {
                    marginLeft: '-176px',
                    marginTop: '32px',
                    marginBottom: '8px',
                    paddingTop: '32px',
                  }
                : {},
            actionsColumnIndex: -1,
            draggable: false,
            emptyRowsWhenPaging: false,
            pageSize: DEFAULT_PAGE_SIZE,
            pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
            selectionProps: (rowData: any) => ({
              color: 'primary',
              // checked: rowData.checked
            }),
            ...options,
          }}
          components={
            toolBarComponents
              ? {
                  Toolbar: (props: any) => (
                    <div style={{ marginRight: '16px' }}>
                      <Grid container={true} spacing={2}>
                        <Grid item={true} xs={5}>
                          <MTableToolbar {...props} />
                        </Grid>
                        <Grid item={true} xs={1} />
                        <Grid item={true} xs={6}>
                          {toolBarComponents}
                        </Grid>
                      </Grid>
                    </div>
                  ),
                }
              : components || {}
          }
          actions={actions}
          localization={{
            header: {
              actions: t('grid.actions') as string,
            },
            toolbar: {
              searchTooltip: t('grid.search') as string,
              searchPlaceholder: t('grid.search') as string,
              nRowsSelected: `${t('grid.rowsSelected')}: {0}` as string,
            },
            body: {
              emptyDataSourceMessage: t('grid.noRecords') as string,
            },
            pagination: {
              labelDisplayedRows: t('grid.labelDisplayedRows') as string,
              labelRowsSelect: t('grid.labelRowsSelect') as string,
              labelRowsPerPage: t('grid.labelRowsPerPage') as string,
              firstAriaLabel: t('grid.firstAriaLabel') as string,
              firstTooltip: t('grid.firstTooltip') as string,
              previousAriaLabel: t('grid.previousAriaLabel') as string,
              previousTooltip: t('grid.previousTooltip') as string,
              nextAriaLabel: t('grid.nextAriaLabel') as string,
              nextTooltip: t('grid.nextTooltip') as string,
              lastAriaLabel: t('grid.lastAriaLabel') as string,
              lastTooltip: t('grid.lastTooltip') as string,
            },
          }}
          onSelectionChange={(rows: any) => {
            setSelectedRows(rows.length);
            onSelectionChange(rows);
          }}
          onRowClick={onRowClick}
          detailPanel={detailPanel}
        />
      </Container>
    </MuiThemeProvider>
  );
};

const areEqual = (prevProps: IBasicGrid, nextProps: IBasicGrid) => {
  const watchedProps = ['data', 'shouldRerender'];
  let shouldRerender = false;
  each(watchedProps, (prop) => {
    if (!isEqual(get(prevProps, prop, []), get(nextProps, prop, []))) {
      shouldRerender = true;
    }
  });

  return !shouldRerender;
};

export default React.memo(BasicGrid, areEqual);
