import React from 'react';
import { useLocation, useHistory } from 'react-router';
import { includes, get, pull, isObject } from 'lodash';

import useUrl from 'utils/hooks/useUrl';
import { VERIFICATION_LOCATIONS } from 'constants/constants';

const Verification: React.FC = () => {
  const history = useHistory();
  const { hash, pathname, search } = useLocation();
  const isVerificationWithHash = includes(hash, 'verification');
  const isVerificationWithoutHash = includes(pathname, '/verification');
  const { urlParamsToObject } = useUrl();

  const isVerification = isVerificationWithHash || isVerificationWithoutHash;

  if (isVerification) {
    try {
      let params: string[] = [];

      // Test url: http://localhost:3000/#verification&type=reg&hash=1234567890abc&user=46
      if (isVerificationWithHash) {
        params = pull(hash.split('&'), '#verification');
      }
      if (isVerificationWithoutHash) {
        params = search.substring(1).split('&');
      }

      const urlParamsObject = urlParamsToObject(params);
      if (isObject(urlParamsObject)) {
        const type = get(urlParamsObject, 'type');
        const location = get(VERIFICATION_LOCATIONS, type);
        if (location) {
          const url = params.join('&');
          history.push(`${location}?${url}`);
        }
      }
    } catch (e) {
      console.debug(e);
    }
  }

  return null;
};

export default Verification;
