import React, { useState } from 'react';
import { parseISO } from 'date-fns';
import { get, keys, isString, includes } from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  Toolbar,
  Button,
  Tooltip,
  Badge,
  Menu,
  MenuItem,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';

import HomeIcon from '@material-ui/icons/Home';
import MailIcon from '@material-ui/icons/Mail';
import WorkIcon from '@material-ui/icons/Work';
import ClearIcon from '@material-ui/icons/Clear';
import ForumIcon from '@material-ui/icons/Forum';
import PersonIcon from '@material-ui/icons/Person';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ArrowLeft from '@material-ui/icons/ArrowBackIos';
import RegistrationIcon from '@material-ui/icons/PersonAdd';
import AssignmentIcon from '@material-ui/icons/AssignmentTurnedIn';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MenuIcon from '@material-ui/icons/Dehaze';

import useStyles from './_styles';
import { IButton } from './_types';
import useUserInfo from 'utils/hooks/useUserInfo';
import useUserLogout from 'utils/hooks/useUserLogout';
import useAppInfo from 'utils/hooks/useAppInfo';
import ButtonNavLink from 'components/ButtonNavLink/ButtonNavLink';
import { ToggleNotificationsPopup } from 'modules/Notifications/_redux';
import { ToggleOrganizationDialog } from 'components/OrganizationDialog/_redux';
import LimitProgressBar from 'components/LimitProgressBar/LimitProgressBar';
import { MOBILE_MENU_MAX_WIDTH } from 'constants/constants';
import useStyleByAppVersion from 'utils/hooks/useStyleByAppVersion';
import useConfig from 'utils/hooks/useConfig';
import useHomePage from 'utils/hooks/useHomePage';
import useStorage from 'utils/hooks/useStorage';

const Bar: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    user,
    totalLimit,
    userTotalUsed,
    userTotalLimit,
    userIsAuthorized,
    hasMoreOrganizations,
    actualOrganization,
    userCanRegister,
    userGroup,
  } = useUserInfo();
  const { get: storageGet, put: storagePut } = useStorage();
  const logoutUser = useUserLogout();
  var limitsExceeded = get(user, 'limitsExceeded', false);
  const limitsExceededDateStr = get(user, 'limitsExceededDate', null);
  if (limitsExceeded && limitsExceededDateStr !== null) {
    const limitsExceededDate = new Date(parseISO(limitsExceededDateStr));
    const diffInMs = Math.abs(new Date().getTime() - limitsExceededDate.getTime());
    const diffInMinutes = diffInMs / (1000 * 60);
    if (diffInMinutes > 1440) {
      limitsExceeded = false;
    }
  }
  const appInfo = useAppInfo();
  const technicianName = get(appInfo, 'technicianName', '');
  const technicianEmail = get(appInfo, 'technicianEmail', '');
  const technicianPhone = get(appInfo, 'technicianPhone', '');
  const useStyle = get(appInfo, 'useStyle', null);
  const whatApp = useStyle ? get(appInfo, `style.${appInfo.useStyle}`) : null;
  const appName = whatApp ? get(whatApp, 'appName', '') : '';

  const showUploadLimitProgress = userIsAuthorized && totalLimit !== null && totalLimit !== 0;
  const showMobileMenu = useMediaQuery(`(max-width:${MOBILE_MENU_MAX_WIDTH})`);

  const { getLogo } = useStyleByAppVersion();
  const logo = getLogo();

  const { getConfigValue } = useConfig();
  const showContactForm = getConfigValue('showContactForm');
  const homeUrlPage = useHomePage();

  const openOrganizationDialog = () => {
    dispatch(ToggleOrganizationDialog(true));
  };

  const openNotificationsPopup = () => {
    dispatch(ToggleNotificationsPopup(true));
  };

  const buttonsNotif: IButton[] = [];

  const location = useLocation();
  const currentFullPath = location.pathname;

  const regexesForBackButton = {
    '[/]unblock-transfer': '/administration',
    '[/]manage-main-config': '/administration',
    '[/]transfer-detail[/][0-9+]': '/outgoing-files',
    '[/]active-sessions': '/administration',
    '[/]manage-templates': '/administration',
    '[/]manage-organizations': '/administration',
    '[/]database': '/administration',
    '[/]download': '/incoming-files',
    '[/]organization-detail[/][0-9+]': '/manage-organizations',
    '[/]statistics[/][0-9+]': '/statistics',
    '[/]manage-templates[/][0-9+]': '/organization-detail',
  };

  let arrowBack = undefined;
  keys(regexesForBackButton).forEach((pathRegex) => {
    const regex = new RegExp(pathRegex);
    if (regex.test(currentFullPath)) {
      // Exception for templates
      if (pathRegex === '[/]manage-templates[/][0-9+]') {
        arrowBack = currentFullPath.replace('manage-templates', 'organization-detail');
      } else {
        arrowBack = get(regexesForBackButton, pathRegex);
      }
    }
  });

  const logoButtonUrl = arrowBack || homeUrlPage;

  const buttons: IButton[] = [
    { key: 'faq', Icon: ForumIcon, link: '/faq', tooltip: t('bar.menu.faq') as string },
    {
      key: 'termsOfUse',
      Icon: AssignmentIcon,
      link: '/terms-of-use',
      tooltip: t('bar.menu.termsOfUse') as string,
    },
    { key: 'about', Icon: BookmarkIcon, tooltip: t('bar.menu.aboutApp') as string, link: '/about' },
  ];

  if (userIsAuthorized) {
    if (hasMoreOrganizations) {
      buttons.push({
        key: 'organizationChange',
        Icon: HomeIcon,
        tooltip: t('bar.menu.organizationChange') as string,
        onClick: openOrganizationDialog,
      });
    }
    buttons.push(
      { key: 'profile', Icon: PersonIcon, label: get(user, 'name'), link: '/profile' },
      {
        key: 'logout',
        Icon: ClearIcon,
        label: t('login.logout') as string,
        onClick: logoutUser as any,
      }
    );
    buttonsNotif.unshift({
      key: 'notifications',
      Icon: NotificationsIcon,
      tooltip: t('bar.menu.notifications') as string,
      onClick: openNotificationsPopup,
    });
  } else {
    if (showContactForm) {
      buttons.push({
        key: 'contactForm',
        Icon: MailIcon,
        label: t('bar.menu.contactUs') as string,
        link: '/contact-form',
      });
    }
    buttons.push({
      key: 'login',
      Icon: PersonIcon,
      label: t('login.loginAction') as string,
      link: '/login',
    });
    if (userCanRegister) {
      buttons.push({
        key: 'register',
        Icon: RegistrationIcon,
        label: t('login.newRegistration') as string,
        link: '/registration',
      });
    }
  }

  const unreadNotifications = useSelector((state) => get(state, 'app.unreadNotifications'));
  const hasUnreadNotifications = useSelector((state) => get(state, 'app.hasUnreadNotifications'));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderButtons = (butts: IButton[]) => {
    return butts.map(({ Icon, label, link, tooltip, key, onClick = () => false }) => (
      <Tooltip title={tooltip || ''} key={key}>
        <Badge
          color="primary"
          badgeContent={unreadNotifications}
          invisible={!hasUnreadNotifications || key !== 'notifications'}
          overlap="circular"
          classes={{ anchorOriginTopRightCircular: classes.badge }}
        >
          <Button
            {...(link
              ? {
                  to: link,
                  component: ButtonNavLink,
                  activeClassName: classes.activeLink,
                }
              : {})}
            classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
            onClick={onClick}
            id={`bar-btn-${key}`} // Notificaiton popup depends on this ID, please see NotificationsPopup.tsx
            className={
              key === 'notifications' && hasUnreadNotifications ? classes.activeLink : undefined
            }
          >
            {key === 'organizationChange' && actualOrganization ? (
              <Tooltip title={actualOrganization.name}>
                <WorkIcon fontSize="small" style={{ position: 'absolute', right: 0, top: 0 }} />
              </Tooltip>
            ) : null}
            <Icon fontSize="large" />
            {label}
          </Button>
        </Badge>
      </Tooltip>
    ));
  };

  const renderMenu = (butts: IButton[]) => {
    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ top: '48px' }}
        >
          {showUploadLimitProgress && (
            <MenuItem key="showProgressBar">
              {<LimitProgressBar used={userTotalUsed} max={userTotalLimit} />}
            </MenuItem>
          )}
          {butts.map(({ Icon, label, link, tooltip, key, onClick = () => false }) => (
            <MenuItem onClick={handleClose} key={key}>
              <Badge
                color="primary"
                badgeContent={unreadNotifications}
                invisible={!hasUnreadNotifications || key !== 'notifications'}
                overlap="circular"
                classes={{ anchorOriginTopRightCircular: classes.badge }}
              >
                <Button
                  {...(link
                    ? {
                        to: link,
                        component: ButtonNavLink,
                        activeClassName: classes.activeLink,
                      }
                    : {})}
                  classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
                  onClick={onClick}
                  id={`bar-btn-${key}`} // Notificaiton popup depends on this ID, please see NotificationsPopup.tsx
                  className={
                    key === 'notifications' && hasUnreadNotifications
                      ? classes.activeLink
                      : undefined
                  }
                >
                  <Icon fontSize="large" />
                  {key === 'organizationChange' && actualOrganization
                    ? actualOrganization.name
                    : label
                    ? label
                    : tooltip || ''}
                </Button>
              </Badge>
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  };

  const showInformationalBar = getConfigValue('informationalBar.showBar');
  const showMoreLink = getConfigValue('informationalBar.showMoreLink');
  const link = getConfigValue('informationalBar.moreLink');
  const showAcceptButton = getConfigValue('informationalBar.showAcceptButton');
  const userGroups = getConfigValue('informationalBar.userGroups') || [];
  const [confirmed, setConfirmed] = useState(storageGet('informationalBarConfirmed') || false);
  const showToUserGroup = userGroups.length > 0 ? includes(userGroups, userGroup) : true;

  return (
    <>
      {showInformationalBar && showToUserGroup && !confirmed ? (
        <>
          <div className={classes.informationalBar}>
            {t('bar.informationalBarText')}
            {showMoreLink && (
              <div>
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {t('bar.informationalBarShowMoreTextButton')}
                </a>
                {showAcceptButton && (
                  <Button
                    style={{ marginLeft: '20px' }}
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                      storagePut('informationalBarConfirmed', true);
                      setConfirmed(true);
                    }}
                  >
                    {t('bar.informationalBarConfirmButton')}
                  </Button>
                )}
              </div>
            )}
          </div>
        </>
      ) : null}
      {limitsExceeded && (
        <div className={classes.danger}>
          <Trans i18nKey="bar.infoAboutLimitsExceeded">
            <p>0</p>
            <Link to="/terms-of-use" className={classes.dangerLink}>
              1
            </Link>
            <p>2</p>
            <div>{{ technicianName }}</div>
            <div>E-mail: {{ technicianEmail }}</div>
            <div>Telefon: {{ technicianPhone }}</div>
            <p>Děkujeme, že používáte službu {{ appName }}</p>
          </Trans>
        </div>
      )}
      <AppBar position="static" classes={{ root: classes.appBarRoot }}>
        <Toolbar classes={{ root: classes.toolbarRoot }}>
          <Link to={logoButtonUrl} className={classes.logoContainer}>
            {isString(arrowBack) ? <ArrowLeft fontSize="large" /> : null}
            <img src={logo} alt="Dr.Sejf logo" className={classes.logo} />
          </Link>
          <div className={classes.barRight}>
            {!showMobileMenu && showUploadLimitProgress && (
              <LimitProgressBar used={userTotalUsed} max={userTotalLimit} />
            )}
            {renderButtons(buttonsNotif)}
            {showMobileMenu ? renderMenu(buttons) : renderButtons(buttons)}
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Bar;
