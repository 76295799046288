import React from 'react';
import { get, isArray, isNumber, pick } from 'lodash';
import { useHistory } from 'react-router';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, Button } from '@material-ui/core';
import { useSelector, shallowEqual } from 'react-redux';

import { updateUser } from './_api';
import { ProfileFormValues } from './_types';
import Phone from 'components/form/Phone/Phone';
import InputField from 'components/form/Input/Input';
import SelectField from 'components/form/Select/Select';
import {
  NOTIFICATION_DELIVERED,
  NOTIFICATION_VIEWED,
  NOTIFICATION_EXPIRED,
  NOTIFICATION_BLOCKED_RECIPIENT,
  NOTIFICATION_PHONE_CHANGE,
  NOTIFICATION_SEND_STATISTICS,
  NOTIFICATION_EXTERNAL_USERS,
} from 'constants/constants';
import usePhone from 'utils/hooks/usePhone';
import useLanguages from 'utils/hooks/useLanguages';
import useAlerts from 'components/Alerts/useAlerts';
import useLoader from 'components/Loader/useLoader';
import CheckboxField from 'components/form/Checkbox/Checkbox';
import useFetchAndStoreUser from 'utils/hooks/useFetchAndStoreUser';
import { getCheckedOptionsFromBinaryMap, getBinaryMapFromOptions } from 'utils/emailNotifications';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';
import useFetchAndStoreBackendConfig from 'utils/hooks/useFetchAndStoreBackendConfig';

const Profile: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { toggleLoader } = useLoader();
  const { splitPhoneNumber } = usePhone();
  const { mappedLanguagesForSelect, fetchAndStoreLanguage } = useLanguages();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { fetchAndStoreUser } = useFetchAndStoreUser();
  const { fetchAndStoreBackendConfig } = useFetchAndStoreBackendConfig();

  const {
    name,
    email,
    userId,
    lang,
    phone,
    emailNotifications,
    requiredEmailNotifications,
    anyOrganizationAdmin,
    otherFlags,
    anonymizations,
  } = useSelector((state) => get(state, 'user'), shallowEqual);
  const [prefix, phoneNumber] = splitPhoneNumber(phone);
  const showAnonymizationCheckbox = isArray(anonymizations) && anonymizations.length;

  const requiredEmailNotificationsFromBinary = getCheckedOptionsFromBinaryMap(
    requiredEmailNotifications
  );

  const onSubmit = async (values: ProfileFormValues, actions: FormikHelpers<ProfileFormValues>) => {
    toggleLoader();

    try {
      const data = {
        ...pick(values, ['name', 'lang', 'userId']),
        // nevím proč to funguje, ale zdá se že to funguje dobře
        // emailNotifications - uživatelské nastavení,
        // requiredEmailNotifications - nastaveno na pracovní místo
        // potřeba vrátit jen hodnoty, které se týkají emailNotifications, i když se překrývají s requiredEmailNotifications
        emailNotifications:
          (getBinaryMapFromOptions(get(values, 'emailNotifications')) &
            ~requiredEmailNotifications) |
          (emailNotifications & requiredEmailNotifications),
        otherFlags: values.anonymization && showAnonymizationCheckbox ? 1 : 0,
      };
      const response = await updateUser(data);
      if (response) {
        await fetchAndStoreUser();
        await fetchAndStoreLanguage();
        await fetchAndStoreBackendConfig();
      }
      addSuccessAlert(t('profile.updateSucceeded'));
      history.push('/user-home');
    } catch (e) {
      addErrorAlert(t('profile.updateFailed'));
    }

    actions.setSubmitting(false);
    toggleLoader(false);
  };

  const allowedNotificationTypes = [
    NOTIFICATION_DELIVERED,
    NOTIFICATION_VIEWED,
    NOTIFICATION_EXPIRED,
    NOTIFICATION_PHONE_CHANGE,
    NOTIFICATION_BLOCKED_RECIPIENT,
  ];

  if (anyOrganizationAdmin) {
    allowedNotificationTypes.push(NOTIFICATION_EXTERNAL_USERS);
    allowedNotificationTypes.push(NOTIFICATION_SEND_STATISTICS);
  }

  const initialValues = {
    name,
    lang,
    email,
    userId,
    prefix,
    phoneNumber,
    emailNotifications: {
      // binární součet přes všechny možnosti notifikací
      ...getCheckedOptionsFromBinaryMap(emailNotifications | requiredEmailNotifications),
    },
    anonymization: isNumber(otherFlags) && (otherFlags & 1) === 1 ? true : false,
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <WhiteBox title={t('profile.userProfile')}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <InputField name="name" label={t('profile.name')} />
              </Grid>
              <Grid item={true} xs={12}>
                <InputField name="email" label={t('profile.email')} disabled={true} />
              </Grid>
              <Grid item={true} xs={12}>
                <Phone disabled={true} />
              </Grid>
              <Grid item={true} xs={12}>
                <InputField name="userId" label={t('profile.id')} disabled={true} />
              </Grid>
              <Grid item={true} xs={12}>
                <SelectField
                  name="lang"
                  label={t('profile.language')}
                  items={mappedLanguagesForSelect}
                />
              </Grid>
              <Grid item={true} xs={12}>
                {allowedNotificationTypes.map((checkbox) => (
                  <CheckboxField
                    key={checkbox}
                    name={`emailNotifications.${checkbox}`}
                    label={t(`profile.checkboxes.${checkbox}`)}
                    // requiredEmailNotifications mají vyšší prioritu, proto jsou disabled
                    disabled={get(requiredEmailNotificationsFromBinary, checkbox)}
                  />
                ))}
                {showAnonymizationCheckbox ? (
                  <CheckboxField
                    name="anonymization"
                    label={t(`profile.checkboxes.anonymization`)}
                  />
                ) : null}
              </Grid>

              <Grid item={true} xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth={true}
                >
                  {t('common.update')}
                </Button>
              </Grid>
            </Grid>
          </WhiteBox>
        </form>
      )}
    </Formik>
  );
};

export default Profile;
