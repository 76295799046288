import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, palette, shadows }: Theme) =>
  createStyles({
    loginContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'stretch',
      flexWrap: 'wrap',
      '@media screen and (min-width: 880px)': {
        flexWrap: 'nowrap',
      },
    },
    tile: {
      boxShadow: shadows[1],
      background: '#fff',
      display: 'flex',
      padding: spacing(3),
      color: palette.primary.main,
      alignItems: 'stretch',
      marginTop: spacing(3),
      '@media screen and (min-width: 880px)': {
        marginTop: 0,
        marginLeft: spacing(2),
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    loginButton: {
      width: 200,
      height: 200,
      paddingLeft: spacing(1),
      paddingRight: spacing(1),
    },
    label: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loginButtonLabel: {
      // justifyContent: 'space-between',
    },
    tileSelectLabel: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    tilesSelect: {
      display: 'flex',
      justifyContent: 'center',
    },
    iconWrapper: {
      height: 70,
      display: 'flex',
      alignItems: 'flex-end',
      paddingBottom: spacing(),
    },
    icon: {
      fontSize: 56,
    },
    labelWrapper: {
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      lineHeight: 1.2,
      maxWidth: '100%',
      justifyContent: 'center',
      fontSize: 22,
    },
    labelWrapperSelect: {
      flexWrap: 'wrap',
      background: '#fb0',
    },
    userLogoutFromSSO: {
      background: palette.info.main,
      color: palette.info.contrastText,
      padding: spacing(),
      textAlign: 'center',
      marginBottom: spacing(2),
      borderRadius: 4,
    },
  })
);

export default useStyles;
