import React, { useState, useEffect } from 'react';
import WebVisionIFrame from './WebVisionIFrame';
import useUrl from 'utils/hooks/useUrl';
import { get, isNull, isEmpty, startsWith, replace, set, head } from 'lodash';
import { useHistory } from 'react-router';
import { nopAPi } from 'components/Bootstrap/_api';
import useStorage from 'utils/hooks/useStorage';
import useConfig from 'utils/hooks/useConfig';
import { LinearProgress, Typography } from '@material-ui/core';
import useStyles from './_styles';
import { useTranslation } from 'react-i18next';

const DiomPage: React.FC = () => {
  const { mapAllUrlParamsToObject } = useUrl();
  const history = useHistory();
  const { getConfigValue } = useConfig();
  const params = mapAllUrlParamsToObject();
  const classes = useStyles();
  const { t } = useTranslation();
  const [showIFrame, setShowIFrame] = useState<boolean>(false);
  const [IFrameUrl, setIFrameUrl] = useState<string>('');
  const [IFrameItems, setIFrameItems] = useState<string>('');
  const [progress, setProgress] = useState<number | null>(null);

  const { getAccessToken } = useStorage();

  let dwContext: any;
  const dwsrc = getConfigValue('dwsrc');

  const canRun = () => {
    const id = get(params, 'id', null);
    const key = get(params, 'key', null);
    const sms = get(params, 'sms', null);
    const cannot = id === null || key === null || sms === null;
    if (cannot) {
      history.push('/redirect-on-user-home');
    }
    return cannot;
  };

  const goBack = (reason: string | null) => {
    const code = get(params, 'code', null);
    const urlParams = `id=${get(params, 'id')}&key=${get(params, 'key')}&sms=${get(params, 'sms')}${
      code !== null ? `&code=${code}` : ''
    }${reason != null ? `&reason=${reason}` : ''}`;
    history.push(`/download?${urlParams}`);
  };

  const closeIFrame = () => {
    setShowIFrame(false);
    goBack(null);
  };

  const initializeAPIContext = () => {
    set(window, 'apdwVersion', () => {
      return { version: '1.0' };
    });

    set(window, 'apdwLogout', () => {
      setShowIFrame(false);
      goBack(null);
    });

    set(window, 'apdwPassBrowserReference', (param: any) => {
      dwContext = param;
      console.log(`DW Viewer version: ${dwContext.dwVersion()}`);
    });

    set(window, 'apdwViewerStatus', (param: any) => {
      var status = JSON.parse(param);
      if (status['status'] === 'READY') {
        dwContext.dwAddItems(IFrameItems);
        const items = JSON.parse(IFrameItems);
        if (!isNull(items) && !isEmpty(items)) {
          const firstItem = head(get(items, 'items', []));
          const patientDwid = firstItem !== undefined ? get(firstItem, 'dwid') : '';
          dwContext.dwShowItems({ level: 'PATIENT', dwid: patientDwid });
        }
      }
    });
  };

  const closeWebSocket = (ws: WebSocket) => {
    ws.close();
  };

  const initializeWebsocket = () => {
    const token = getAccessToken();
    const code = get(params, 'code', null);
    const urlParams = `${token === undefined ? '' : `accessToken=${token}&`}id=${get(
      params,
      'id'
    )}&key=${get(params, 'key')}&sms=${get(params, 'sms')}${code !== null ? `&code=${code}` : ''}`;
    const wsUrl = `${window.location.protocol === 'http:' ? 'ws://' : 'wss://'}${
      window.location.host
    }/api/dicom?${urlParams}`;

    const ws = new WebSocket(wsUrl);
    ws.onmessage = (evt) => {
      const message = get(evt, 'data');
      if (startsWith(message, 'done:')) {
        setIFrameItems(replace(message, 'done: ', ''));
        closeWebSocket(ws);
      } else if (startsWith(message, 'progress:')) {
        setProgress(parseInt(replace(message, 'progress: ', '')));
      } else if (startsWith(message, 'error:')) {
        closeWebSocket(ws);
        goBack('canNotShowViewer');
      }
    };
  };

  useEffect(() => {
    if (!canRun()) {
      initializeWebsocket();
      nopAPi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (IFrameItems !== '') {
      if (IFrameItems.startsWith('http://') || IFrameItems.startsWith('https://')) {
        setIFrameUrl(`${IFrameItems}`);
      } else {
        initializeAPIContext();
        setIFrameUrl(`${window.location.protocol}//${window.location.host}${dwsrc}`);
      }
      setShowIFrame(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IFrameItems]);

  return (
    <>
      {progress !== null ? (
        <>
          <Typography variant="body2">{t('files.downloadFiles.viewerLoading')}</Typography>
          <div className={classes.totalProgress}>
            <LinearProgress
              variant="determinate"
              value={Math.floor(Number(progress))}
              classes={{ root: classes.progressRoot }}
            />{' '}
            {progress}%
          </div>
        </>
      ) : (
        <></>
      )}
      {showIFrame ? <WebVisionIFrame url={IFrameUrl} closeIFrame={closeIFrame} /> : <></>}
    </>
  );
};

export default DiomPage;
