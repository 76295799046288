import { get } from 'lodash';
import { api } from 'utils/api';

export const addContact = (data: any) =>
  api()
    .post(`/api/add-contact`, data)
    .then(({ data }) => get(data, 'success'))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const getRecipients = (sender: number, op: string = 'list') =>
  api()
    .post(`/api/ds-recipient-registry`, { sender, op })
    .then(({ data }) => (get(data, 'total') ? get(data, 'items') : []))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const deleteUserRecipient = (item: any) =>
  api()
    .post(`/api/ds-recipient-registry`, { item, op: 'delete' })
    .then(({ data }) => (get(data, 'total') ? get(data, 'items') : []))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const deleteOrganizationRecipient = (item: any) =>
  api()
    .post(`/api/ds-organization-recipient-registry`, { item, op: 'delete' })
    .then(({ data }) => (get(data, 'total') ? get(data, 'items') : []))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const getUserGroups = (userId: number) =>
  api()
    .post(`/api/ds-contact-group`, { user: userId, op: 'list', convert: true })
    .then(({ data }) => (get(data, 'total') ? get(data, 'items') : []))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const createGroup = (item: any) =>
  api()
    .post(`/api/ds-contact-group`, { item, op: 'create' })
    .then(({ data }) => get(data, 'id'))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const editGroup = (item: any) =>
  api()
    .post(`/api/ds-contact-group`, { item, op: 'update' })
    .then(({ data }) => get(data, 'id'))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const deleteGroup = (item: any) =>
  api()
    .post(`/api/ds-contact-group`, { item, op: 'delete' })
    .then(({ data }) => get(data, 'id'))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const addGroupContact = (item: any) =>
  api()
    .post(`/api/add-group-contact`, item)
    .then(({ data }) => get(data, 'success'))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const removeGroupContact = (contactGroupId: number, contactId: number) =>
  api()
    .post(`/api/ds-contact-group-recipient-registry`, {
      op: 'delete',
      item: { contactGroup: contactGroupId, recipient: contactId },
    })
    .then(({ data }) => data)
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const listGroupContacts = (groupId: number) =>
  api()
    .post(`/api/ds-contact-group-recipient-registry`, { op: 'list', contactGroup: groupId })
    .then(({ data }) => get(data, 'items', []))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const searchExternalContacts = (request: any) =>
  api()
    .post(`/api/external-contacts-search`, request)
    .then(({ data }) => get(data, 'items', []))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));
