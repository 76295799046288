import React, { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import LockIcon from '@material-ui/icons/Lock';
import { Grid, Button, Container } from '@material-ui/core';

import { requestPasswordChange, changePassword } from './_api';
import useValidationSchema from './_form';
import useStyles from './_styles';
import useUrl from 'utils/hooks/useUrl';
import Title from 'components/Title/Title';
import InputField from 'components/form/Input/Input';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';
import useLoader from 'components/Loader/useLoader';
import useAlerts from 'components/Alerts/useAlerts';
import { useHistory } from 'react-router';
import { PasswordChangeFormValues } from './_types';
import useUserInfo from 'utils/hooks/useUserInfo';
import Login from 'modules/Login/Login';
import useFetchAndStoreUser from 'utils/hooks/useFetchAndStoreUser';

const PasswordChange: React.FC = () => {
  const { t } = useTranslation();

  const { getHash } = useUrl();
  const hash = getHash();
  const { userIsAuthorized } = useUserInfo();
  const [verificationsCodeSent, setRequestForVerificationsCode] = useState(hash ? true : false);
  const history = useHistory();
  const { toggleLoader } = useLoader();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const PasswordChangeFormSchema = useValidationSchema();
  const classes = useStyles();
  const [requireLogin, setRequireLogin] = useState<boolean>(!userIsAuthorized);
  const { fetchAndStoreUser } = useFetchAndStoreUser();

  const onSubmit = async (
    values: PasswordChangeFormValues,
    actions: FormikHelpers<PasswordChangeFormValues>
  ) => {
    toggleLoader();
    try {
      await changePassword(values);
      await fetchAndStoreUser();
      addSuccessAlert(t('profile.passwordChangeSucceeded'));
      history.push('/user-home');
    } catch (e) {
      addErrorAlert(t('profile.passwordChangeFailed'));
    }
    toggleLoader(false);
  };

  const requestForPasswordChange = async () => {
    toggleLoader();
    try {
      const request = await requestPasswordChange();
      if (request) {
        setRequestForVerificationsCode(true);
        addSuccessAlert(t('profile.requestForPasswordChangeSucceeded'));
      }
    } catch (e) {
      addErrorAlert(t('profile.requestForPasswordChangeFailed'));
    }
    toggleLoader(false);
  };

  const closeLoginDialog = () => {
    setRequireLogin(false);
  };
  return (
    <>
      {requireLogin ? (
        <Login email={''} password={''} isDialog={true} closeLoginDialog={closeLoginDialog} />
      ) : (
        <Formik
          initialValues={{
            oldPass: '',
            newPass: '',
            newPassCopy: '',
            verificationCode: hash ? hash : '',
          }}
          onSubmit={onSubmit}
          validationSchema={PasswordChangeFormSchema}
        >
          {({ isSubmitting, handleSubmit }) => (
            <>
              <Title title={t('profile.passwordChange')} />

              {!hash && (
                <Container maxWidth="sm">
                  <Grid container={true} spacing={2} justifyContent="center">
                    <Grid item={true}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={requestForPasswordChange}
                        className={classes.requestButton}
                      >
                        {t('profile.requestForPasswordNewPassword')}
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              )}
              <WhiteBox>
                <form
                  onSubmit={handleSubmit}
                  {...(!verificationsCodeSent ? { className: classes.lockedForm } : {})}
                >
                  {!verificationsCodeSent && <LockIcon className={classes.lockIcon} />}
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12}>
                      <InputField
                        name="oldPass"
                        autoComplete="off"
                        label={t('profile.oldPassword')}
                        type="password"
                        disabled={!verificationsCodeSent}
                        passwordRevealAllowed={true}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <InputField
                        name="newPass"
                        label={t('profile.newPassword')}
                        type="password"
                        disabled={!verificationsCodeSent}
                        passwordRevealAllowed={true}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <InputField
                        name="newPassCopy"
                        label={t('profile.newPasswordCheck')}
                        type="password"
                        disabled={!verificationsCodeSent}
                        passwordRevealAllowed={true}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <InputField
                        name="verificationCode"
                        label={t('profile.emailCode')}
                        disabled={!verificationsCodeSent}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!verificationsCodeSent || isSubmitting}
                        fullWidth={true}
                      >
                        {t('profile.changePasswordAction')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </WhiteBox>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default PasswordChange;
