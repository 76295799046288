import { filter, get, isArray } from 'lodash';
import useConfig from './useConfig';

const useContacts = () => {
  const { getConfigValue } = useConfig();
  const contactsConfig = getConfigValue('appendix.contacts');
  const internalContactsEnabled = get(contactsConfig, 'internalContactsEnabled', false);
  const externalContactsEnabled = get(contactsConfig, 'externalContactsEnabled', false);
  const externalServices = get(contactsConfig, 'externalServices');
  const enabledExternalServices = isArray(externalServices)
    ? filter(externalServices, { enabled: true })
    : [];
  const contactsDisabled =
    !internalContactsEnabled &&
    (!externalContactsEnabled || (externalContactsEnabled && enabledExternalServices.length === 0));
  const internalContactsSaveMode = get(contactsConfig, 'internalContactsSaveMode');
  return {
    internalContactsEnabled,
    externalContactsEnabled,
    contactsDisabled,
    enabledExternalServices,
    externalServices: isArray(externalServices) ? externalServices : [],
    hasExternalServices: enabledExternalServices.length > 0,
    internalContactsSaveMode,
  };
};

export default useContacts;
