import React from 'react';
import CloseIcon from '@material-ui/icons/Cancel';
import { DialogTitle, IconButton } from '@material-ui/core';

import useStyles from './_styles';
import { IDialogTitleWithClose } from './_types';

const DialogTitleWithClose: React.FC<IDialogTitleWithClose> = ({ title, closeDialogFn }) => {
  const classes = useStyles();

  return (
    <DialogTitle id="confirmation-dialog-title" classes={{ root: classes.dialogTitleRoot }}>
      <div className={classes.dialogTitle}>
        {title === '' ? <span>&nbsp;</span> : title}

        <IconButton key="close" aria-label="Close" color="inherit" onClick={closeDialogFn}>
          <CloseIcon />
        </IconButton>
      </div>
    </DialogTitle>
  );
};

export default DialogTitleWithClose;
