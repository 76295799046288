import { useSelector } from 'react-redux';
import { get, isArray, isObject, includes, isString, every, isNumber } from 'lodash';

import { IUser } from 'modules/Login/_types';
import {
  ORGANIZATION_SHARED_CONTACTS,
  PERMISSION_ADMINISTRATING,
  PERMISSION_SUPER_ADMINISTRATING,
  ORGANIZATION_PERMISSION_CREATE_ORGANIZATION_CONTACT,
} from 'constants/constants';
import useConfig from './useConfig';

export const userHasMoreOrganizations = (user: IUser | boolean) => {
  if (!isObject(user)) {
    return false;
  }

  const { organizations, hasAdminOrPaidRegistration } = user;

  return (
    isArray(organizations) &&
    (organizations.length > 1 || (organizations.length > 0 && hasAdminOrPaidRegistration))
  );
};

const useUserInfo = () => {
  const user: IUser = useSelector((state) => get(state, 'user'));
  const { organizations, userId } = user;

  const { getConfigValue } = useConfig();
  const hasMoreOrganizations = userHasMoreOrganizations(user);
  const actualOrganization = get(user, 'actualOrganization', null);
  const userInsteadOfOrganization = get(user, 'userInsteadOfOrganization');
  const oAuth = getConfigValue('appendix.authentication.openid');
  const useOauth = get(oAuth, 'gatewayUri') && get(oAuth, 'clientId') && get(oAuth, 'gatewayUri');

  let totalLimit = null;
  try {
    totalLimit = parseInt(get(user, 'limits.MaxTotalSize', 0), 10);
  } catch (e) {}

  const userHasPermission = (permission: string) => includes(get(user, 'permission'), permission);
  const userHasPermissions = (permissions: string | string[]) => {
    if (!isString(permissions) && !isArray(permissions)) {
      return false;
    } else if (isString(permissions)) {
      return userHasPermission(permissions);
    } else if (isArray(permissions)) {
      return every(
        (permissions as string[]).map((permission: string) => userHasPermission(permission))
      );
    }
  };

  const userHasOrganizationPermission = (permission: string) =>
    includes(get(user, 'organizationPermissions'), permission);

  const userHasVirtualPermission = (permission: string) =>
    includes(get(user, 'virtualPermissions'), permission);

  const userHasVirtualPermissions = (permissions: string | string[]) => {
    if (!isString(permissions) && !isArray(permissions)) {
      return false;
    } else if (isString(permissions)) {
      return userHasVirtualPermission(permissions);
    } else if (isArray(permissions)) {
      return every(
        (permissions as string[]).map((permission: string) => userHasVirtualPermission(permission))
      );
    }
  };

  const userIsOrgAdmin = get(user, 'actualOrganizationAdmin', false) === true;

  const userCanAddAsOrganizationContact =
    (actualOrganization != null &&
      userIsOrgAdmin &&
      (get(actualOrganization, 'flags', 0) & ORGANIZATION_SHARED_CONTACTS) ===
        ORGANIZATION_SHARED_CONTACTS) ||
    userHasPermissions(PERMISSION_ADMINISTRATING) ||
    userHasOrganizationPermission(ORGANIZATION_PERMISSION_CREATE_ORGANIZATION_CONTACT);

  let maxTransferDelay;
  try {
    const parsedMaxTransferDelay = parseInt(get(user, 'limits.MaxTransferDelay', 0));
    maxTransferDelay = isNumber(parsedMaxTransferDelay) ? parsedMaxTransferDelay : 0;
  } catch (e) {}

  const userIsNotLoggedIn = get(user, 'groupLevel') === 0;

  const userCanRegister =
    !user.isAuthorized && getConfigValue('appendix.authentication.registrationEnabled');

  const userIsAdminOrSuperAdmin =
    userHasPermissions(PERMISSION_ADMINISTRATING) ||
    userHasPermissions(PERMISSION_SUPER_ADMINISTRATING);

  const userMustLoginViaOauth = useOauth && userIsNotLoggedIn;

  const actualUserWasInvited = get(user, 'invitedToActualOrganization');

  const userMustChangeHisPassword = (_user: IUser = user) => get(_user, 'remainingLogins') === 0;

  return {
    user,
    userId,
    totalLimit,
    organizations,
    userIsOrgAdmin,
    userCanRegister,
    maxTransferDelay,
    userIsNotLoggedIn,
    userHasPermissions,
    userHasOrganizationPermission,
    actualOrganization,
    actualUserWasInvited,
    hasMoreOrganizations,
    userMustLoginViaOauth,
    userIsAdminOrSuperAdmin,
    userInsteadOfOrganization,
    userHasVirtualPermissions,
    userMustChangeHisPassword,
    userCanAddAsOrganizationContact,
    userGroup: get(user, 'group'),
    userIsAuthorized: user.isAuthorized,
    userTotalUsed: get(user, 'totalSizeUsed', 0),
    userTotalLimit: get(user, 'totalSizeLimit', 0),
    userCanChangePassword: get(user, 'canChangePassword', false),
    hasAdminOrPaidRegistration: get(user, 'hasAdminOrPaidRegistration', false),
    dicomViewerEnabled: user.dicomViewerEnabled,
  };
};

export default useUserInfo;
