import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

const GoogleAnalytics: React.FC<any> = ({ googleID }) => {
  const initialize = () => {
    ReactGA.initialize(googleID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
};

export default GoogleAnalytics;
