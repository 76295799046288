import { without, set, get as lodashGet } from 'lodash';
import { API_ACCESSS_TOKENS, NAMESPACE, USED_API_ACCESS_TOKENS } from 'constants/constants';

const ls = window.localStorage;
const initialValue = undefined;
const AsyncLock = require('async-lock');
const lock = new AsyncLock();

const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max);
};

export const getUsedTokens = () => {
  try {
    const item = ls.getItem(USED_API_ACCESS_TOKENS);
    const tokens = item ? JSON.parse(item) : initialValue;
    if (tokens !== undefined) {
      return tokens;
    }
  } catch (error) {
    console.error(error);
  }

  return [];
};

export const putUsedToken = (value: string | undefined) => {
  if (value !== undefined) {
    const valueToStore: Array<string> = value.split(',');
    const usedTokens = getUsedTokens();
    let newValeForStore: Array<string> = [];
    if (usedTokens !== undefined) {
      if (usedTokens.length + valueToStore.length > 60) {
        for (let index = 0; index < usedTokens.length + valueToStore.length - 60; index++) {
          usedTokens.shift();
        }
      }
      newValeForStore = [...usedTokens, ...valueToStore];
    } else {
      newValeForStore = [...valueToStore];
    }
    ls.setItem(USED_API_ACCESS_TOKENS, JSON.stringify(newValeForStore));
  }
};

export const getAccessToken = (addTokenToUsed: boolean = true): string | undefined => {
  let selectedToken: string | undefined = undefined;
  lock.acquire(
    'accessToken',
    (done: any) => {
      const response = { err: undefined, ret: undefined };
      try {
        const item = ls.getItem(API_ACCESSS_TOKENS);
        const tokens = item ? JSON.parse(item) : initialValue;
        if (tokens !== undefined) {
          const token = tokens[getRandomInt(tokens.length)];
          const tokenswithoutUsed = without(tokens, token);
          ls.setItem(API_ACCESSS_TOKENS, JSON.stringify(tokenswithoutUsed));
          set(response, 'ret', token);
        }
      } catch (error) {
        console.error(error);
      }
      done(lodashGet(response, 'err'), lodashGet(response, 'ret'));
    },
    (err: any, ret: string | undefined) => {
      if (err !== undefined) {
        console.error(err);
      }
      selectedToken = ret;
    }
  );

  if (checkIfTokenCanBeUsed(selectedToken)) {
    if (addTokenToUsed) putUsedToken(selectedToken);
    return selectedToken;
  } else {
    return getAccessToken(addTokenToUsed);
  }
};

const checkIfTokenCanBeUsed = (value: string | undefined) => {
  try {
    const usedTokens = getUsedTokens();
    return value !== undefined ? !(usedTokens.indexOf(value) > -1) : true;
  } catch (error) {
    console.error(error);
  }
  return true;
};

export const putAccessTokens = (value: string) => {
  lock.acquire(
    'accessToken',
    function (done: any) {
      try {
        const valueToStore: Array<string> = value.split(',');
        ls.setItem(API_ACCESSS_TOKENS, JSON.stringify(valueToStore));
      } catch (error) {
        console.error(error);
      }
      done();
    },
    function (err: any, ret: any) {
      if (err !== undefined) {
        console.error(err);
      }
      if (ret !== undefined) {
        console.debug(ret);
      }
    },
    {}
  );
};

const useStorage = () => {
  const put = (key: string, value: any) => ls.setItem(`${NAMESPACE}.${key}`, value);
  const get = (key: string) => ls.getItem(`${NAMESPACE}.${key}`);
  const remove = (key: string) => ls.removeItem(`${NAMESPACE}.${key}`);
  const clear = () => ls.clear();

  return { put, get, remove, clear, putAccessTokens, getAccessToken };
};

export default useStorage;
