import { isNumber, each, keys, get } from 'lodash';
import { NOTIFICATION_VALUES } from 'constants/constants';

export const getCheckedOptionsFromBinaryMap = (binaryMap: number) => {
  let resultData: any = {};
  if (isNumber(binaryMap)) {
    each(keys(NOTIFICATION_VALUES), notificationName => {
      const value = get(NOTIFICATION_VALUES, notificationName);
      resultData[notificationName] = (binaryMap & value) === value;
    });
  }
  return resultData;
};

export const getBinaryMapFromOptions = (emailNotifications: any[]) => {
  try {
    let binaryMap = 0;
    each(
      keys(emailNotifications),
      notificationName =>
        (binaryMap += get(emailNotifications, notificationName)
          ? get(NOTIFICATION_VALUES, notificationName)
          : 0)
    );
    return binaryMap;
  } catch (e) {
    return 0;
  }
};
