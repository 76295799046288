import { api } from 'utils/api';
import { get } from 'lodash';
import { INotification } from './_types';

export const getNotifications = (
  limit: number = 10,
  op: string = 'list'
): Promise<INotification[]> =>
  api()
    .post('/api/ds-notification', { limit, op })
    .then(({ data }) => get(data, 'items', []))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const readNotifications = (): Promise<boolean> =>
  api()
    .post('/api/notification-read')
    .then(() => true)
    .catch((error) => Promise.reject(get(error, 'response.data', '')));
