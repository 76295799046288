import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { pick, get, isNumber } from 'lodash';
import { Dialog, DialogContent, DialogActions, Button, Grid } from '@material-ui/core';

import useValidationSchema from './_form';
import { Formik, FormikHelpers } from 'formik';
import useLoader from 'components/Loader/useLoader';
import useAlerts from 'components/Alerts/useAlerts';
import { addOrganization } from './_api';
import InputField from 'components/form/Input/Input';
import SelectField from 'components/form/Select/Select';
import CheckboxField from 'components/form/Checkbox/Checkbox';
import { IOrganizationFormValues, IOrganizationForm, IUserGroup } from './_types';
import {
  ORGANIZATION_SHARED_TRANSFERS,
  ORGANIZATION_SHARED_CONTACTS,
  ORGANIZATION_EXTERNAL_CONTACTS,
  INVITATION_EXPIRATION_DEFAULT_MAXIMAL,
} from 'constants/constants';
import { getBinaryMapFromOptions } from 'utils/organizationFlags';
import DialogTitleWithClose from 'components/DialogTitleWithClose/DialogTitleWithClose';
import useConfig from 'utils/hooks/useConfig';

const OrganizationForm: React.FC<IOrganizationForm> = ({
  closeDialog,
  getAllOrganizations,
  userGroups,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { toggleLoader } = useLoader();
  const { OrganizationFormSchema } = useValidationSchema();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { getConfigValue } = useConfig();
  const maximal = getConfigValue('appendix.invitation.maximal');

  const allowedFlags = [
    ORGANIZATION_SHARED_TRANSFERS,
    ORGANIZATION_SHARED_CONTACTS,
    ORGANIZATION_EXTERNAL_CONTACTS,
  ];

  let initialValues = { name: '', userGroup: '', flags: {}, invitedUserExpiration: 0 };

  const prepareValues = (values: IOrganizationFormValues) => ({
    ...pick(values, ['name', 'userGroup', 'invitedUserExpiration']),
    flags: getBinaryMapFromOptions(get(values, 'flags')),
  });

  const onOrganizationAdd = async (
    values: IOrganizationFormValues,
    actions: FormikHelpers<IOrganizationFormValues>
  ) => {
    toggleLoader();
    try {
      const response = await addOrganization(prepareValues(values));

      if (response) {
        await getAllOrganizations();
        closeDialog();
        addSuccessAlert(t('manageOrganizations.addingOrganizationSucceeded'));
        history.push(`/organization-detail/${response}`);
      }
    } catch (e) {
      addErrorAlert(t('manageOrganizations.addingOrganizationFailed'));
    }
    toggleLoader(false);
  };

  return (
    <Formik
      onSubmit={onOrganizationAdd}
      initialValues={initialValues}
      validationSchema={OrganizationFormSchema}
    >
      {({ isSubmitting, handleSubmit, values }) => {
        const canEditUserExpiration = get(values, `flags.${ORGANIZATION_EXTERNAL_CONTACTS}`, false);
        return (
          <Dialog
            maxWidth="sm"
            fullWidth={true}
            aria-labelledby="confirmation-dialog-title"
            open={true}
            onClose={closeDialog}
          >
            <DialogTitleWithClose
              title={t('manageOrganizations.addNewOrganization')}
              closeDialogFn={closeDialog}
            />
            <DialogContent>
              <form onSubmit={handleSubmit}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12}>
                    <InputField name="name" label={t('manageOrganizations.organization.name')} />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <SelectField
                      name="userGroup"
                      label={t('manageOrganizations.organization.userGroup')}
                      items={userGroups.map((userGroup: IUserGroup) => ({
                        id: userGroup.userGroupId,
                        label: `${userGroup.userGroupId} - ${t(
                          `manageOrganizations.levelNames.${userGroup.userGroupId}`
                        )}`,
                      }))}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    {allowedFlags.map((checkbox) => (
                      <CheckboxField
                        key={checkbox}
                        name={`flags.${checkbox}`}
                        label={t(`manageOrganizations.checkboxes.${checkbox}`)}
                      />
                    ))}
                  </Grid>
                  <Grid item={true} xs={12}>
                    <InputField
                      name="invitedUserExpiration"
                      type="number"
                      disabled={!canEditUserExpiration}
                      inputProps={{
                        min: 0,
                        max:
                          isNumber(maximal) && maximal > 0
                            ? maximal
                            : INVITATION_EXPIRATION_DEFAULT_MAXIMAL,
                        step: 1,
                      }}
                      label={t('manageOrganizations.organization.invitedUserExpiration')}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      fullWidth={true}
                      disabled={isSubmitting}
                    >
                      {t('manageOrganizations.organizationDialog.submit')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </DialogContent>
            <DialogActions />
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default OrganizationForm;
