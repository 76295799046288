import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { green, red, blue, amber } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    success: {
      backgroundColor: green[600]
    },
    error: {
      backgroundColor: red[500]
    },
    info: {
      backgroundColor: blue[600]
    },
    warning: {
      backgroundColor: amber[700]
    }
  })
);

export default useStyles;
