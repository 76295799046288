import React, { useEffect, useState } from 'react';
import { get, pick, includes, isArray, find } from 'lodash';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import ClearIcon from '@material-ui/icons/Clear';
import PeopleIcon from '@material-ui/icons/People';
import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { Formik, FormikHelpers } from 'formik';

import useStyles from './_styles';
import useLoader from 'components/Loader/useLoader';
import useAlerts from 'components/Alerts/useAlerts';
import BasicGrid from 'components/BasicGrid/BasicGrid';
import { useValidationSchemaRecipient } from './_form';
import { IGroupManageForm, IRecipient, IGroup, IGroupRecipientFormValues } from './_types';
import { addGroupContact, removeGroupContact, listGroupContacts } from './_api';
import DialogTitleWithClose from 'components/DialogTitleWithClose/DialogTitleWithClose';
import InputField from 'components/form/Input/Input';
import Phone from 'components/form/Phone/Phone';
import usePhone from 'utils/hooks/usePhone';
import useUserInfo from 'utils/hooks/useUserInfo';

const GroupManageForm: React.FC<IGroupManageForm> = ({
  group,
  recipients,
  closeDialog,
  reloadGroupsAndSetGroupForEdit,
}) => {
  const { t } = useTranslation();
  const { toggleLoader } = useLoader();
  const classes = useStyles();
  const {
    splitPhoneNumber,
    joinPhoneNumber,
    testIfPhoneHasRightFormat,
    // phoneCanBeChangedForRecipientLevel,
  } = usePhone();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const [recipientsInGroup, setRecipientsInGroup] = useState<IRecipient[]>([]);
  const [selectedRecipient, setSelectedRecipient] = useState<number | null>(null);
  const [recipientSelectVisible, toggleRecipientSelect] = useState<boolean>(false);
  const [isEditingUser, setIsEditingUser] = useState<boolean>(false);
  // const [editedUser, setEditedUser] = useState<IRecipient | null>(null);
  const { userIsOrgAdmin } = useUserInfo();

  const clearEditingUser = () => {
    setIsEditingUser(false);
    // setEditedUser(null);
    setSelectedRecipient(null);
  };

  const groupId = get(group, 'id');

  const userAllowedToEdit = (get(group, 'organization') && userIsOrgAdmin) || get(group, 'user');

  const actualNumberOfContacts = get(group, 'actualNumberOfContacts', '');
  const maxNumberOfContacts = get(group, 'maxNumberOfContacts', '');
  const maximumExceeded = actualNumberOfContacts === maxNumberOfContacts;

  // const mailDisabled = maximumExceeded || isEditingUser || selectedRecipient !== null;
  // const phoneEditAllowed =
  //   (!selectedRecipient && !maximumExceeded) ||
  //   (selectedRecipient && phoneCanBeChangedForRecipientLevel(editedUser as IRecipient));
  // const mailDisabled = !selectedRecipient && maximumExceeded && !isEditingUser;
  // const phoneEditAllowed = !selectedRecipient && !maximumExceeded;

  const RecipientFormSchema = useValidationSchemaRecipient();

  const recipientsInGroupEmails = recipientsInGroup.map((contact: IRecipient) =>
    get(contact, 'email')
  );

  const removeUserFromGroup = async (recipient: IRecipient) => {
    toggleLoader();
    try {
      await removeGroupContact(groupId, get(recipient, 'userId'));
      addSuccessAlert(t('recipientRegistry.contactGroup.dialog.contactRemoved'));
      await reloadGroupsAndSetGroupForEdit(group as IGroup);
      await getGroupRecipients();
    } catch (e) {
      addErrorAlert(t('recipientRegistry.contactGroup.dialog.contactNotRemoved'));
    }

    toggleLoader(false);
  };

  const getGroupRecipients = async () => {
    toggleLoader();
    try {
      const contacts = await listGroupContacts(groupId);
      setRecipientsInGroup(contacts);
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  const onRecipientAdd = async (
    values: IGroupRecipientFormValues,
    { resetForm, setErrors }: FormikHelpers<IGroupRecipientFormValues>
  ) => {
    toggleLoader();

    const phoneNumber = joinPhoneNumber(values.prefix as string, values.phoneNumber as string);
    const phoneIsValid = testIfPhoneHasRightFormat(phoneNumber);
    if (!phoneIsValid) {
      toggleLoader(false);
      setErrors({ phoneNumber: t('form.validations.phoneNumberInvalid') });
      return false;
    }

    try {
      await addGroupContact({
        groupId,
        ...pick(values, ['name', 'email']),
        phone: joinPhoneNumber(values.prefix, values.phoneNumber),
      });
      addSuccessAlert(t('recipientRegistry.contactGroup.dialog.contactAdded'));
      await reloadGroupsAndSetGroupForEdit(group as IGroup);
      await getGroupRecipients();
      resetForm();
      clearEditingUser();
    } catch (e) {
      addErrorAlert(t('recipientRegistry.contactGroup.dialog.contactNotAdded'));
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getGroupRecipients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      maxWidth="lg"
      fullWidth={true}
      aria-labelledby="confirmation-dialog-title"
      open={true}
      onClose={closeDialog}
    >
      <DialogTitleWithClose
        title={t('recipientRegistry.contactGroup.dialog.titleManage')}
        closeDialogFn={closeDialog}
      />
      <DialogContent>
        <Formik
          onSubmit={onRecipientAdd}
          initialValues={{ name: '', email: '', phoneNumber: '', prefix: '' }}
          validationSchema={RecipientFormSchema}
        >
          {({ setFieldValue, handleSubmit, resetForm }) => (
            <>
              <Typography>
                {t('recipientRegistry.contactGroup.name')}:{' '}
                <strong>{get(group, 'name', '')}</strong>
              </Typography>
              <Typography>
                {t('recipientRegistry.contactGroup.count')}:{' '}
                <strong>
                  {actualNumberOfContacts}/{maxNumberOfContacts}
                </strong>
              </Typography>

              {userAllowedToEdit ? (
                <form onSubmit={handleSubmit}>
                  <div className={classes.addRecipientForm}>
                    <Grid container={true} spacing={2} alignItems="center">
                      <Grid item={true} xs={12}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          <Typography>
                            {t('recipientRegistry.contactGroup.dialog.addRecipientFormLabel')}
                          </Typography>
                          <Button
                            variant="contained"
                            size="small"
                            startIcon={<PeopleIcon />}
                            className={classes.addRecipientButton}
                            onClick={() => toggleRecipientSelect(!recipientSelectVisible)}
                          >
                            {t('recipientRegistry.contactGroup.dialog.fillWithExistingRecipient')}
                          </Button>
                          {recipientSelectVisible ? (
                            <Select
                              value={selectedRecipient}
                              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                clearEditingUser();
                                setSelectedRecipient(event.target.value as number);
                                const recipient: IRecipient = find(recipients, {
                                  userId: event.target.value,
                                }) as IRecipient;

                                const [prefix, phoneNumber] = splitPhoneNumber(recipient.phone);
                                setFieldValue('name', recipient.name);
                                setFieldValue('email', recipient.email);
                                setFieldValue('prefix', prefix);
                                setFieldValue('phoneNumber', phoneNumber);
                              }}
                              className={classes.addRecipientFormSelect}
                              autoWidth={false}
                              fullWidth={false}
                              disabled={maximumExceeded}
                            >
                              {isArray(recipients)
                                ? recipients.map((item: IRecipient) => (
                                    <MenuItem
                                      key={item.userId}
                                      value={get(item, 'userId')}
                                      disabled={includes(recipientsInGroupEmails, item.email)}
                                    >
                                      {item.name} - {item.email}
                                    </MenuItem>
                                  ))
                                : null}
                            </Select>
                          ) : null}
                        </div>
                      </Grid>
                      <Grid item={true} xs={3}>
                        <InputField
                          name="name"
                          label={t('recipientRegistry.recipient.name')}
                          disabled={maximumExceeded && !isEditingUser}
                        />
                      </Grid>
                      <Grid item={true} xs={3}>
                        <InputField
                          name="email"
                          label={t('recipientRegistry.recipient.email')}
                          // disabled={mailDisabled}
                        />
                      </Grid>
                      <Grid item={true} xs={3}>
                        <Phone
                        // disabled={!phoneEditAllowed}
                        />
                      </Grid>
                      <div>
                        <Tooltip title={t('recipientRegistry.contactGroup.dialog.resetForm') || ''}>
                          <IconButton
                            onClick={() => {
                              resetForm();
                              clearEditingUser();
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                        <Button
                          variant="contained"
                          type="submit"
                          color="primary"
                          className={classes.addRecipientFormButton}
                          disabled={maximumExceeded && !isEditingUser}
                        >
                          {t(
                            isEditingUser
                              ? 'recipientRegistry.contactGroup.dialog.editRecipientFormButton'
                              : 'recipientRegistry.contactGroup.dialog.addRecipientFormButton'
                          )}
                        </Button>
                      </div>
                    </Grid>
                  </div>
                </form>
              ) : null}
              <BasicGrid
                data={recipientsInGroup}
                columns={[
                  { title: t('recipientRegistry.recipient.name'), field: 'name' },
                  { title: t('recipientRegistry.recipient.email'), field: 'email' },
                  { title: t('recipientRegistry.recipient.phone'), field: 'phone' },
                ]}
                disabledContainer={true}
                actions={
                  userAllowedToEdit
                    ? [
                        (rowData: any) => ({
                          name: 'edit',
                          icon: EditIcon,
                          iconProps: {
                            color: 'primary',
                            fontSize: 'large',
                          },
                          tooltip: t('recipientRegistry.contactGroup.dialog.editUserInGroup'),
                          onClick: (_event: any, recipient: IRecipient) => {
                            setIsEditingUser(true);
                            // setEditedUser(recipient);
                            const [prefix, phoneNumber] = splitPhoneNumber(recipient.phone);
                            setFieldValue('name', recipient.name);
                            setFieldValue('email', recipient.email);
                            setFieldValue('prefix', prefix);
                            setFieldValue('phoneNumber', phoneNumber);
                          },
                        }),
                        (rowData: any) => ({
                          name: 'remove',
                          icon: RemoveIcon,
                          iconProps: {
                            color: 'primary',
                            fontSize: 'large',
                          },
                          tooltip: t('recipientRegistry.contactGroup.dialog.removeUserFromGroup'),
                          onClick: (_event: any, rowData: IRecipient) =>
                            removeUserFromGroup(rowData),
                        }),
                      ]
                    : []
                }
              />
              <Grid container={true} spacing={2} justifyContent="center">
                <Grid item={true} xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    style={{ marginTop: 16 }}
                    onClick={closeDialog}
                  >
                    {t('recipientRegistry.contactGroup.dialog.closeDialog')}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default GroupManageForm;
