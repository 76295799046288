import React, { useState, useEffect } from 'react';
import BasicGrid from 'components/BasicGrid/BasicGrid';
import Title from 'components/Title/Title';
import EditIcon from '@material-ui/icons/Edit';
import LoopIcon from '@material-ui/icons/Loop';
import RowForm from './RowForm';
import useLoader from 'components/Loader/useLoader';
import { useTranslation } from 'react-i18next';
import { isArray, get } from 'lodash';
import { Tooltip } from '@material-ui/core';
import { IDatabaseRow } from './_types';
import { getRows } from './_api';

const Database: React.FC = () => {
  const [rowtFormVisible, toggleRowForm] = useState(false);
  const [editedRow, setEditedRow] = useState<IDatabaseRow | boolean>(false);
  const [rows, fetchRows] = useState<IDatabaseRow[]>([]);
  const { toggleLoader } = useLoader();
  const { t } = useTranslation();

  const closeRowForm = () => {
    toggleRowForm(false);
    setEditedRow(false);
  };

  const prepareAndSetRowForEdit = (row: IDatabaseRow) => {
    setEditedRow(row);
    toggleRowForm(true);
  };

  const getAllDatabaseRows = async () => {
    toggleLoader();
    try {
      const fetchedDatabaseRows = await getRows();
      if (isArray(fetchedDatabaseRows)) {
        fetchRows(fetchedDatabaseRows);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    getAllDatabaseRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title={t('databaseManage.databaseConfigurationTitle') as string} />

      {isArray(rows) && (
        <BasicGrid
          data={rows}
          columns={[
            { title: t('databaseManage.databaseConfiguration.configId'), field: 'configId' },
            { title: t('databaseManage.databaseConfiguration.type'), field: 'type' },
            { title: t('databaseManage.databaseConfiguration.value'), field: 'value' },
            { title: t('databaseManage.databaseConfiguration.unit'), field: 'unit' },
            {
              field: 'reload',
              render: (rowData: IDatabaseRow) => {
                if (get(rowData, 'reload', false)) {
                  return (
                    <Tooltip title={t('databaseManage.reloadServer') || ''}>
                      <LoopIcon />
                    </Tooltip>
                  );
                } else {
                  return <></>;
                }
              },
            },
          ]}
          actions={[
            {
              icon: EditIcon,
              iconProps: {
                color: 'primary',
                fontSize: 'large',
              },
              tooltip: t('databaseManage.editRow'),
              onClick: (_event: any, rowData: IDatabaseRow) => prepareAndSetRowForEdit(rowData),
              position: 'row',
            },
          ]}
        />
      )}
      {rowtFormVisible && (
        <RowForm
          closeDialog={closeRowForm}
          row={editedRow}
          getAllDatabaseRows={getAllDatabaseRows}
        />
      )}
    </>
  );
};

export default Database;
