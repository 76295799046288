import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@material-ui/icons/Home';
import ReplyIcon from '@material-ui/icons/Reply';
import FolderIcon from '@material-ui/icons/Folder';
import { get, keys, isEqual, drop, take } from 'lodash';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import IncomingFilesIcon from '@material-ui/icons/ArrowDownward';
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Breadcrumbs,
  Link,
  Typography,
  Paper,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import useStyles from './_styles';
import useTransfer from 'utils/hooks/useTransfer';
import { IFile } from 'modules/TransferDetail/_types';
import { IFileTree } from './_types';

const FileTree: React.FC<IFileTree> = ({ fileTree = null, downloadFile = null }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const wholeFileTree = fileTree;
  const [currentFolder, setCurrentFolder] = useState({});
  const isRoot = isEqual(wholeFileTree, currentFolder);

  const dirs = get(currentFolder, 'dirs', {});
  const haveDirs = keys(dirs).length > 0;
  const files: IFile[] = get(currentFolder, 'files', []);
  const haveFiles = files.length;

  const { formatFileSize, getIconForFileType } = useTransfer();
  const parentPaths: string[] = drop(get(currentFolder, 'path', ['/']), 1);

  const currentPath = get(currentFolder, 'path', ['/']);

  useEffect(() => {
    if (fileTree !== null) {
      setCurrentFolder(fileTree);
    }
  }, [fileTree]);

  const setCurrentFolderFromPaths = (paths: string[]) => {
    let folder;
    if (!paths.length) {
      folder = wholeFileTree;
    } else {
      let pathForLodash: string[] = [];
      paths.forEach((path) => {
        pathForLodash.push(`dirs.${path}`);
      });
      folder = get(wholeFileTree, pathForLodash.join('.'));
    }
    setCurrentFolder(folder);
  };

  return fileTree !== null ? (
    <>
      {isRoot && !haveDirs ? null : (
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
          style={{ marginBottom: 8 }}
        >
          {currentPath.map((path: string, index: number) => (
            <Link
              color="inherit"
              key={path}
              className={classes.breadcrumbsLink}
              onClick={() => setCurrentFolderFromPaths(drop(take(currentPath, index + 1), 1))}
            >
              {index === 0 && <HomeIcon style={{ display: 'block' }} />}
              {index !== 0 && path}
            </Link>
          ))}

          {/* TODO */}
          {!isRoot && <Typography color="textPrimary">{get(currentFolder, 'name', '')}</Typography>}
        </Breadcrumbs>
      )}

      <Paper>
        <List dense={true}>
          {!isRoot && (
            <ListItem
              classes={{
                root: classes.folder,
              }}
              onClick={() => setCurrentFolderFromPaths(parentPaths)}
            >
              <ListItemAvatar>
                <Avatar>
                  <ReplyIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${t('files.file.back')}`} secondary="&hellip;" />
            </ListItem>
          )}
          {haveDirs
            ? keys(dirs).map((directoryKey: string) => {
                const directory = get(currentFolder, `dirs.${directoryKey}`, {});
                const files = get(directory, `files`, []);
                return (
                  <ListItem
                    key={directoryKey}
                    classes={{
                      root: classes.folder,
                    }}
                    onClick={() => setCurrentFolder(directory)}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <FolderIcon className={classes.folderIcon} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={directoryKey}
                      secondary={`${files.length} ${t('files.file.files', {
                        count: files.length,
                      })}`}
                    />
                  </ListItem>
                );
              })
            : null}
          {haveFiles
            ? files.map((file: IFile) => (
                <ListItem key={file.fileId}>
                  <ListItemAvatar>
                    <Avatar>{getIconForFileType(file.fileType)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={file.name}
                    secondary={`${formatFileSize(file.size)} (${t('files.file.downloads')}: ${get(
                      file,
                      'downloads',
                      0
                    )}x)`}
                  />
                  {downloadFile && (
                    <ListItemSecondaryAction>
                      <Tooltip title={t('files.file.download') || ''}>
                        <IconButton onClick={() => downloadFile(file, false)}>
                          <IncomingFilesIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('files.downloadFiles.downloadHash') || ''}>
                        <IconButton onClick={() => downloadFile(file, false, true)}>
                          <FingerprintIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))
            : null}
        </List>
      </Paper>
    </>
  ) : null;
};

export default FileTree;
