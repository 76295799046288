import { Formik } from 'formik';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useCallback } from 'react';
import {
  set,
  get,
  pick,
  each,
  filter,
  compact,
  isArray,
  includes,
  isObject,
  zipObject,
  isNumber,
  pickBy,
  identity,
  reduce,
  keys,
} from 'lodash';
import { Dialog, DialogContent, Grid, Button } from '@material-ui/core';

import useStyles from './_styles';
import { searchRemote } from './_api';
import { generateCancelToken, cancelAxiosRequest } from 'utils/api';
import useLoader from 'components/Loader/useLoader';
import InputField from 'components/form/Input/Input';
import useFileUpload from 'utils/hooks/useFileUpload';
import DatePicker from 'components/form/DatePicker/DatePicker';
import {
  IRemoteUploadDialog,
  IRemoteSourceSearchParam,
  IRemoteSourceItem,
  IRemoteSourceLevel,
} from './_types';
import AutoSubmitForm from './AutoSubmitForm';
import {
  REMOTE_FIELD_DATE_TYPE_BOOLEAN,
  REMOTE_FIELD_DATE_TYPE_DATE,
  REMOTE_FIELD_DATE_TYPE_STRING,
  TOKEN_ACTION_REMOTE_SOURCE_SEARCH,
} from 'constants/constants';
import useAlerts from 'components/Alerts/useAlerts';
import DialogTitleWithClose from 'components/DialogTitleWithClose/DialogTitleWithClose';
import useRemoteUpload from 'utils/hooks/useRemoteUpload';
import CheckboxField from 'components/form/Checkbox/Checkbox';
import Level from './Level';
import { IAdditionalInfo } from 'modules/RecipientRegistry/_types';
import { useSelector } from 'react-redux';

const RemoteUploadDialog: React.FC<IRemoteUploadDialog> = ({
  closeRemoteUploadDialog,
  remoteSource,
  addRemoteFiles,
  remoteFiles,
}) => {
  const classes = useStyles();
  const { getDateFieldsThatShouldBeFormatted } = useFileUpload();
  const { toggleLoader } = useLoader();
  const { addInfoAlert } = useAlerts();
  const { getDateFieldFrom, getDateFieldTo, getLevel, replaceFormattedFields } = useRemoteUpload();

  const firstLevel = getLevel(remoteSource.levels, 1) as IRemoteSourceLevel;
  const fields: IRemoteSourceSearchParam[] = get(firstLevel, 'searchParams', []);

  const dateFromField = getDateFieldFrom(fields);
  const dateToField = getDateFieldTo(fields);

  const filteredFields = filter(fields, { visible: true });
  const filedsWithValidation = filter(filteredFields, 'minLength');
  const { t } = useTranslation();
  const [results, setResults] = useState<IRemoteSourceItem[] | null>(null);
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);

  const infoFromToken = get(remoteSource, 'infoFromToken');
  const hasInfoFromToken = isObject(infoFromToken);
  const tokenOperation = get(remoteSource, 'infoFromToken.operation');
  const fieldsWithFormattedDates = getDateFieldsThatShouldBeFormatted(
    firstLevel.itemParams,
    firstLevel.itemMergedParams
  );

  const [initialValues, setInitialValues] = useState({});
  const [shouldSearchInitially, setShouldSearchInitially] = useState(false);

  // Fill information to form when there is additional info
  const additionalInfo: IAdditionalInfo = useSelector((state) => get(state, 'app.additionalInfo'));
  useEffect(() => {
    const tokenInitialValues = hasInfoFromToken
      ? {
          ...get(infoFromToken, 'token.searchParams', {}),
          ...get(infoFromToken, 'token.formattedSearchParams', {}),
        }
      : {};

    const initialValues = {
      ...zipObject(
        filteredFields.map((field) => field.name),
        filteredFields.map((field) => (field.type === 'boolean' ? false : ''))
      ),
      ...tokenInitialValues,
    };

    if (additionalInfo) {
      const serviceMappings = get(
        additionalInfo,
        `service.remoteSourceMapping.${get(remoteSource, 'name')}`
      );
      const mappingKeys = keys(serviceMappings);
      if (mappingKeys.length) {
        each(mappingKeys, (key) => {
          set(
            initialValues,
            get(serviceMappings, key),
            get(additionalInfo, `recipient.additionalInfo.${key}`, '')
          );
        });
        setShouldSearchInitially(true);
      }
    }
    setInitialValues(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldSearchInitially) {
      const button = document.getElementById('remote-search-button');
      if (button) {
        button.click();
      }
    }
  }, [shouldSearchInitially]);

  const setResultsAndChangeColumnsThatShouldBeFormatted = (items: IRemoteSourceItem[]) => {
    const itemsWithReplacedFields = replaceFormattedFields(items, fieldsWithFormattedDates);
    setResults(itemsWithReplacedFields);
  };

  const keyboardListener = useCallback((e: any) => {
    if (e.keyCode === 27) {
      cancelAxiosRequest();
    }
  }, []);

  const generateUniqueIdentifier = (item: any, itemUniqueParams: string[]) =>
    reduce(
      pick(item, itemUniqueParams),
      (result, value, key) => (result = `${result}_${value}`),
      ''
    );

  const remoteFilesUniqueIdentifiers = remoteFiles.map((item: any) => item.generatedID);

  const sourceAppendixForItems = pick(remoteSource, [
    'name',
    'label',
    'backgroundColor',
    'textColor',
  ]);

  const setSelectedDates = () => {
    each(filteredFields, (field) => {
      if (includes([dateFromField, dateToField], field.name)) {
        const duration = get(field, 'duration.days');
        if (isNumber(duration)) {
          const date = new Date();
          if (field.name === dateToField) {
            setDateTo(date);
          }
          if (field.name === dateFromField) {
            date.setDate(date.getDate() - get(field, 'duration.days'));
            setDateFrom(date);
          }
        }
      }
    });
  };

  const changeDateFrom = (date: Date | null) => {
    setDateFrom(date);
  };

  const changeDateTo = (date: Date | null) => {
    setDateTo(date);
  };

  const setFilesAlreadySelectedForUpload = (items: any) =>
    items.map((item: any) => ({
      ...item,
      alreadySelectedForUpload: includes(
        remoteFilesUniqueIdentifiers,
        generateUniqueIdentifier(item, firstLevel.itemUniqueParams)
      ),
    }));

  const onSubmit = async (values: any) => {
    toggleLoader(true, true);
    setDataFetched(false);
    setResults(null);
    const searchParams = pickBy(values, identity);

    //FIX DS-975 u UCR se pro vyhledávání nepoužívá dateFrom a dateTo
    const isUCR = get(remoteSource, 'name') === 'ucr';

    if (dateFrom !== null) {
      set(searchParams, isUCR ? 'patientBirthDateFrom' : 'dateFrom', format(dateFrom, 'ddMMyyyy'));
    }
    if (dateTo !== null) {
      set(searchParams, isUCR ? 'patientBirthDateTo' : 'dateTo', format(dateTo, 'ddMMyyyy'));
    }
    const request = {
      searchParams,
      source: get(remoteSource, 'name'),
      timeFormat: 'HH:mm:ss',
      dateFormat: 'dd.MM.yyyy',
      dateTimeFormat: 'dd.MM.yyyy HH:mm:ss',
      level: 1,
      ...(hasInfoFromToken
        ? {
            itemParams: get(infoFromToken, 'token.itemParams'),
            itemFilterMode: get(infoFromToken, 'token.itemFilterMode'),
          }
        : { itemParams: null, itemFilterMode: null }),
    };

    try {
      const remoteResponse = await searchRemote(request, (window as any).axiosSource.token);
      const items = get(remoteResponse, 'items');
      if (isArray(items)) {
        if (hasInfoFromToken && tokenOperation === TOKEN_ACTION_REMOTE_SOURCE_SEARCH) {
          const preselectedItemsIndexes = get(remoteResponse, 'itemFilterIndexes');
          if (isArray(preselectedItemsIndexes)) {
            setResultsAndChangeColumnsThatShouldBeFormatted(
              setFilesAlreadySelectedForUpload(
                items.map((item) => ({
                  ...item,
                  ...(includes(preselectedItemsIndexes, item.$index) ? { checked: true } : {}),
                }))
              )
            );
          } else {
            setResultsAndChangeColumnsThatShouldBeFormatted(
              setFilesAlreadySelectedForUpload(items)
            );
          }
        } else {
          setResultsAndChangeColumnsThatShouldBeFormatted(setFilesAlreadySelectedForUpload(items));
        }
      } else {
        setResultsAndChangeColumnsThatShouldBeFormatted([]);
      }
    } catch (e) {
      console.debug(e);
    }
    setTimeout(() => setDataFetched(true), 100);
    toggleLoader(true, false);
    toggleLoader(false);
  };

  const Spacer = () => (
    <Grid item={true} xs={12} classes={{ root: classes.spacer }}>
      &nbsp;
    </Grid>
  );

  const someValueIsFilled = (values: any) => {
    let someFilled = false;
    each(values, (value) => {
      if (value !== '') {
        someFilled = true;
      }
    });
    if (dateFrom !== null) {
      someFilled = true;
    }
    if (dateTo !== null) {
      someFilled = true;
    }
    return someFilled;
  };

  const addRemoteFilesMapped = (
    files: IRemoteSourceItem[],
    itemDescriptionParams: string[],
    itemUniqueParams: string[]
  ) => {
    const uniqueFiles = compact(
      files.map((file) => {
        const generatedID = generateUniqueIdentifier(file, itemUniqueParams);
        return includes(remoteFilesUniqueIdentifiers, generatedID)
          ? false
          : { ...file, generatedID };
      })
    );

    if (files.length !== uniqueFiles.length) {
      addInfoAlert(t('files.upload.remote.duplicitiesRemoved') as string);
    }

    addRemoteFiles(
      uniqueFiles.map((file) => ({
        ...file,
        source: { ...sourceAppendixForItems, itemDescriptionParams },
      }))
    );
  };

  useEffect(() => {
    document.addEventListener('keydown', keyboardListener);
    return () => {
      document.removeEventListener('keydown', keyboardListener);
    };
  }, [keyboardListener]);

  useEffect(() => {
    generateCancelToken();
    if (!hasInfoFromToken) {
      setSelectedDates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderFieldBasedOnType = (field: IRemoteSourceSearchParam) => {
    const type = field.type;

    if (!type) {
      return <></>;
    }

    if (type === REMOTE_FIELD_DATE_TYPE_STRING) {
      return <InputField name={field.name} label={field.label} />;
    }

    if (type === REMOTE_FIELD_DATE_TYPE_DATE) {
      return (
        <DatePicker
          name={field.name}
          label={field.label}
          selectedDate={field.name === dateToField ? dateTo : dateFrom}
          handleDateChange={field.name === dateToField ? changeDateTo : changeDateFrom}
        />
      );
    }

    if (type === REMOTE_FIELD_DATE_TYPE_BOOLEAN) {
      return <CheckboxField name={field.name} label={field.label} />;
    }
  };

  return (
    <Dialog
      aria-labelledby="confirmation-dialog-title"
      open={true}
      onClose={closeRemoteUploadDialog}
      fullScreen={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitleWithClose
        title={get(remoteSource, 'uploadDescription', '')}
        closeDialogFn={closeRemoteUploadDialog}
      />
      <DialogContent>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={(values) => {
            const errors = {};
            each(filedsWithValidation, (field: IRemoteSourceSearchParam) => {
              const fieldValue = get(values, field.name);
              if (field.minLength && fieldValue.length && fieldValue.length < field.minLength) {
                set(errors, field.name, t('form.validations.fieldLength', { n: field.minLength }));
              }
            });
            return errors;
          }}
        >
          {({ isSubmitting, handleSubmit, values }) => {
            const searchDisabled = isSubmitting || !someValueIsFilled(values);
            return (
              <form onSubmit={handleSubmit}>
                {hasInfoFromToken && <AutoSubmitForm />}
                <Grid container={true} spacing={4}>
                  {filteredFields.map((field) => (
                    <React.Fragment key={field.name}>
                      <Grid key={field.name} item={true} xs={12} sm={4}>
                        {renderFieldBasedOnType(field)}
                      </Grid>
                      {field.lineBreak && <Spacer />}
                    </React.Fragment>
                  ))}
                  <Spacer />
                  <Grid item={true} xs={12} sm={4}>
                    <Button
                      variant="contained"
                      color="default"
                      fullWidth={true}
                      type="submit"
                      id="remote-search-button"
                      onClick={generateCancelToken}
                      style={
                        searchDisabled
                          ? {}
                          : {
                              background: remoteSource.backgroundColor,
                              color: remoteSource.textColor,
                            }
                      }
                      disabled={searchDisabled}
                    >
                      {t('files.upload.remote.search')}
                    </Button>
                  </Grid>
                  <Grid item={true} xs={12} sm={4}>
                    <Button
                      variant="contained"
                      color="default"
                      fullWidth={true}
                      style={{
                        background: remoteSource.backgroundColor,
                        color: remoteSource.textColor,
                      }}
                      onClick={closeRemoteUploadDialog}
                    >
                      {t('files.upload.remote.cancel')}
                    </Button>
                  </Grid>
                </Grid>
                {isArray(results) && !results.length ? (
                  <p>{t('files.upload.remote.noFilesFound')}</p>
                ) : null}

                {!isSubmitting && dataFetched && isArray(results) && results.length ? (
                  <Level
                    rowData={null}
                    levelNumber={1}
                    remoteSource={remoteSource}
                    onSelectItems={(
                      _event: any,
                      files: any,
                      itemDescriptionParams: string[],
                      itemUniqueParams: string[]
                    ) => {
                      addRemoteFilesMapped(
                        isArray(files) ? files : [],
                        itemDescriptionParams,
                        itemUniqueParams
                      );
                      closeRemoteUploadDialog();
                    }}
                    defaultLevelItems={results}
                  />
                ) : null}
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default RemoteUploadDialog;
