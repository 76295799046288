import { get, isObject } from 'lodash';
import { api } from 'utils/api';
import { IUpdatedTemplate } from './_types';

export const getTemplates = () =>
  api()
    .post(`/api/get-lang`, {})
    .then(({ data }) => (isObject(data) ? data : false))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const getOrgTemplates = (organizationId: any) =>
  api()
    .post(`/api/get-lang`, { organizationId: organizationId })
    .then(({ data }) => (isObject(data) ? data : false))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const updateTemplate = (data: IUpdatedTemplate) =>
  api()
    .post(`/api/update-lang`, data)
    .then(({ data }) => (isObject(data) ? data : false))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));
