import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

const useValidators = () => {
  const { t } = useTranslation();

  // Validate for e-mail right format
  const email = (yupInstance: any) => yupInstance.email(t('form.validations.invalidEmailAddress'));

  // Required input field
  const required = (yupInstance: any, errorMessage: string = 'required') =>
    yupInstance.required(t(`form.validations.${errorMessage}`));

  // Required checkbox
  const requiredCheckbox = (yupInstance: any, errorMessage: string = 'required') =>
    yupInstance.oneOf([true], t(`form.validations.${errorMessage}`));

  // Is number
  const number = (yupInstance: any, errorMessage: string = 'mustBeNumber') =>
    yupInstance.typeError(t(`form.validations.${errorMessage}`));

  // One field to match other
  const fieldsMatch = (yupInstance: any, firstFieldName: string, errorMessage: string) =>
    yupInstance.oneOf([yup.ref(firstFieldName), null], t(`form.${errorMessage}`));

  // Field matches regex
  const matchesRegex = (yupInstance: any, regex: any, errorMessage: string) =>
    yupInstance.matches(regex, t(`form.${errorMessage}`));

  // min character count
  const min = (yupInstance: any, minCount: number, errorMessage: string = 'passwordToShort') =>
    yupInstance.min(minCount, t(`form.validations.${errorMessage}`));

  return {
    min,
    email,
    number,
    required,
    fieldsMatch,
    matchesRegex,
    requiredCheckbox,
  };
};

export default useValidators;
