import React, { useState, useEffect } from 'react';
import Title from 'components/Title/Title';
import BasicGrid from 'components/BasicGrid/BasicGrid';
import { isArray, get, filter } from 'lodash';
import { useTranslation } from 'react-i18next';
import useLoader from 'components/Loader/useLoader';
import { getSessions } from './_api';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Grid, Typography } from '@material-ui/core';
import { parseISO, format } from 'date-fns';
import { IActiveSession } from './_types';

const ActiveSessions: React.FC = () => {
  const { t } = useTranslation();
  const [rows, fetchRows] = useState<IActiveSession[]>([]);
  const [numberOfActiveTransfers, setNumberOfActiveTransfers] = useState<number | undefined>(
    undefined
  );
  const [numberOfUnfinishedUploads, setNumberOfUnfinishedUploads] = useState<number | undefined>(
    undefined
  );
  const [canRestart, setCanRestart] = useState<boolean>(false);
  const { toggleLoader } = useLoader();

  const getActiveSessions = async () => {
    toggleLoader();
    try {
      const sessions = await getSessions();
      const activeSessions = filter(get(sessions, 'activeSessions', []), 'isActive');
      fetchRows(activeSessions);
      const transefers = get(sessions, 'numberOfActiveTransfers', undefined);
      const uploads = get(sessions, 'numberOfUnfinishedUploads', undefined);
      setNumberOfActiveTransfers(transefers);
      setNumberOfUnfinishedUploads(uploads);
      setCanRestart(activeSessions.length === 1 && uploads === 0);
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    getActiveSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Title title={t('activeSessions.title') as string} />

      {numberOfActiveTransfers !== undefined && numberOfUnfinishedUploads !== undefined && (
        <>
          <Grid container={true} spacing={2} justifyContent="center">
            <Typography variant="h6" style={{ color: canRestart ? 'green' : 'red' }}>
              {canRestart ? t('activeSessions.canRestart') : t('activeSessions.canNotRestart')}
            </Typography>
          </Grid>
          <Grid container={true} spacing={2} justifyContent="center">
            <Typography variant="h6">
              {t('activeSessions.numberOfActiveTransfers')}: {numberOfActiveTransfers}
            </Typography>
          </Grid>
          <Grid container={true} spacing={2} justifyContent="center">
            <Typography variant="h6">
              {t('activeSessions.numberOfUnfinishedUploads')}: {numberOfUnfinishedUploads}
            </Typography>
          </Grid>
        </>
      )}
      {isArray(rows) && (
        <BasicGrid
          data={rows}
          columns={[
            { title: t('activeSessions.table.name'), field: 'name' },
            { title: t('activeSessions.table.username'), field: 'loggedUser' },
            {
              title: t('activeSessions.table.lastActivity'),
              field: 'lastActivity',
              render: (rowData: IActiveSession) => {
                return <>{format(parseISO(get(rowData, 'lastActivity')), 'd. M. y HH:mm:ss')}</>;
              },
            },
            {
              title: t('activeSessions.table.lastRealActivity'),
              field: 'lastRealActivity',
              render: (rowData: IActiveSession) => {
                return (
                  <>{format(parseISO(get(rowData, 'lastRealActivity')), 'd. M. y HH:mm:ss')}</>
                );
              },
            },
            {
              title: t('activeSessions.table.isActive'),
              field: 'isActive',
              render: (rowData: IActiveSession) => {
                return (
                  <>
                    <FiberManualRecordIcon
                      style={{ color: get(rowData, 'isActive', false) ? 'green' : 'red' }}
                    />
                  </>
                );
              },
            },
          ]}
        />
      )}
    </>
  );
};
export default ActiveSessions;
