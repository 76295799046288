import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useLanguages from 'utils/hooks/useLanguages';

const CookiesBar: React.FC = () => {
  const { t } = useTranslation();
  const { currentLanguage } = useLanguages();

  useEffect(() => {
    const consentLibraryAvailable = 'initCookieConsent' in window;
    if (consentLibraryAvailable) {
      // Full documentation here: https://github.com/orestbida/cookieconsent
      var cc = (window as any).initCookieConsent();
      if (typeof cc === 'object') {
        cc.run({
          current_lang: currentLanguage,
          autoclear_cookies: true, // default: false
          page_scripts: false, // default: false
          gui_options: {
            consent_modal: {
              layout: 'box', // box/cloud/bar
              position: 'bottom left', // bottom/middle/top + left/right/center
              transition: 'slide', // zoom/slide
              swap_buttons: false, // enable to invert buttons
            },
          },

          onFirstAction: (user_preferences: any, cookie: any) => {
            console.debug('ACC');
            window.location.reload();
            // callback triggered only once on the first accept/reject action
          },

          onAccept: (cookie: any) => {
            // callback triggered on the first accept/reject action, and after each page load
          },

          onChange: (cookie: any, changed_categories: any) => {
            // callback triggered when user changes preferences after consent has already been given
          },

          languages: {
            [currentLanguage]: {
              consent_modal: {
                title: t('cookiesBar.consentModal.title'),
                description: `${t(
                  'cookiesBar.consentModal.description'
                )} <button type="button" data-cc="c-settings" class="cc-link">${t(
                  'cookiesBar.consentModal.letMeChoose'
                )}</button>`,
                primary_btn: {
                  text: t('cookiesBar.consentModal.acceptAll'),
                  role: 'accept_all', // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                  text: t('cookiesBar.consentModal.acceptNecessary'),
                  role: 'accept_necessary', // 'settings' or 'accept_necessary'
                },
              },
              settings_modal: {
                title: t('cookiesBar.consentModal.cookiePreferences'),
                save_settings_btn: t('cookiesBar.consentModal.saveSettings'),
                accept_all_btn: t('cookiesBar.consentModal.acceptAll'),
                reject_all_btn: t('cookiesBar.consentModal.rejectAll'),
                close_btn_label: t('cookiesBar.consentModal.close'),
                cookie_table_headers: [
                  { col1: t('cookiesBar.consentModal.tableHeaders.col1') },
                  { col2: t('cookiesBar.consentModal.tableHeaders.col2') },
                  // { col3: t('cookiesBar.consentModal.tableHeaders.col3') },
                  // { col4: t('cookiesBar.consentModal.tableHeaders.col4') },
                ],
                blocks: [
                  {
                    title: t('cookiesBar.consentModal.blocks.blockUsage.title'),
                    description: `${t(
                      'cookiesBar.consentModal.blocks.blockUsage.description'
                    )} <a href="/terms-of-use">${t(
                      'cookiesBar.consentModal.blocks.blockUsage.termsOfUse'
                    )}</a>.`,
                  },
                  {
                    title: t('cookiesBar.consentModal.blocks.blockNecessaryCookies.title'),
                    description: t(
                      'cookiesBar.consentModal.blocks.blockNecessaryCookies.description'
                    ),
                    toggle: {
                      value: 'necessary',
                      enabled: true,
                      readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                    },
                  },
                  {
                    title: t('cookiesBar.consentModal.blocks.blockAnalyticCookies.title'),
                    description: t(
                      'cookiesBar.consentModal.blocks.blockAnalyticCookies.description'
                    ),
                    toggle: {
                      value: 'analytics', // your cookie category
                      enabled: false,
                      readonly: false,
                    },
                    cookie_table: [
                      // list of all expected cookies
                      {
                        col1: '^_ga', // match all cookies starting with "_ga"
                        col2: 'google.com',
                        col3: '2 years',
                        col4: 'description ...',
                        is_regex: true,
                      },
                      {
                        col1: '_gid',
                        col2: 'google.com',
                        col3: '1 day',
                        col4: 'description ...',
                      },
                    ],
                  },
                  {
                    title: t('cookiesBar.consentModal.blocks.blockAdvertisementCookies.title'),
                    description: t(
                      'cookiesBar.consentModal.blocks.blockAdvertisementCookies.description'
                    ),
                    toggle: {
                      value: 'targeting',
                      enabled: false,
                      readonly: false,
                    },
                  },
                  {
                    title: t('cookiesBar.consentModal.blocks.blockMore.title'),
                    description: `${t(
                      'cookiesBar.consentModal.blocks.blockMore.description'
                    )} <a class="cc-link" href="/contact-form">${t(
                      'cookiesBar.consentModal.blocks.blockMore.contactUs'
                    )}</a>.`,
                  },
                ],
              },
            },
          },
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default CookiesBar;
