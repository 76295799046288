import { Link } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { addDays, format, getDay, parseISO } from 'date-fns';
import {
  isArray,
  get,
  each,
  times,
  constant,
  isEmpty,
  head,
  isNumber,
  includes,
  flatten,
  uniq,
} from 'lodash';
import { Button, Switch, FormControlLabel } from '@material-ui/core';

import useStyles from './_styles';
import Title from 'components/Title/Title';
import { IUploadFormSubmit } from './_types';
import useUserInfo from 'utils/hooks/useUserInfo';
import useFileUpload from 'utils/hooks/useFileUpload';
import SelectField from 'components/form/Select/Select';
import { ISelectItem } from 'components/form/Select/_types';
import CheckboxField from 'components/form/Checkbox/Checkbox';
import { IRecipient } from 'modules/RecipientRegistry/_types';
import { getServerTime } from './_api';
import { shallowEqual, useSelector } from 'react-redux';
import { INTERNAL_CONTACTS_SAVE_MODE_OPTIONAL } from 'constants/constants';

const UploadFormSubmit: React.FC<IUploadFormSubmit> = ({
  buttonDisabled,
  title,
  buttonTitle,
  Icon,
  setFieldValue,
  showTermsOfUseCheckbox,
  transferDelayVisible,
  toggleTransferDelatVisible,
  anonymizations,
  anonymizeSnederMailVisible,
  toggleAnonymizeSnederMailVisible,
  sharedTransfer,
  toggleSharedTransfer,
  internalContactsSaveMode,
  setShowInternalContactsSaveDialog,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { otherFlags } = useSelector((state) => get(state, 'user'), shallowEqual);
  const contacts = useSelector((state) => get(state, 'user.contacts'));
  const { values, getFieldHelpers } = useFormikContext();
  const { recipientIsEmpty } = useFileUpload();
  const { userIsAuthorized, maxTransferDelay, actualOrganization } = useUserInfo();
  const orgFlags = get(actualOrganization, 'flags');
  const canDelayTransfer = maxTransferDelay && maxTransferDelay > 0 ? true : false;
  const [transferDelayItems, setTransferDelayItems] = useState<ISelectItem[]>([]);
  const canAnonymizeSender = !isEmpty(anonymizations) && isArray(anonymizations);
  const [anonymizeSenderMails, setAnonymizeSenderMails] = useState<ISelectItem[]>([]);
  const hasOnlyOneAnonymizationProfile = isArray(anonymizations) && anonymizations.length === 1;
  const [sharedTransferDisabled, setSharedTransferDisabled] = useState<boolean>(false);

  const showSharedTransfer = isNumber(orgFlags) && orgFlags >= 0;
  const sharedTransfersEnabled = (orgFlags & 1) > 0;
  const privateTransfersDisabled = (orgFlags & 16) > 0;

  const removeEmptyRecipientsBeforeUpload = () => {
    const recipients = get(values, 'recipients');
    if (recipients.length > 1) {
      const { setValue } = getFieldHelpers('recipients');
      if (isArray(recipients)) {
        const newRecipientsArray: IRecipient[] = [];
        each(recipients, (recipient, index) => {
          if (index === 0 || !recipientIsEmpty(recipient)) {
            newRecipientsArray.push(recipient);
          }
        });
        if (recipients.length !== newRecipientsArray.length) {
          setValue(newRecipientsArray);
        }
      }
    }
  };

  const storeOptions = (serverTime: string | false) => {
    if (serverTime !== '' && serverTime !== false) {
      setTransferDelayItems(
        times(Number(maxTransferDelay), constant(null)).map((item, index) => {
          const day = addDays(new Date(parseISO(serverTime)), index + 1);
          let dayWord;
          if (index === 0) {
            dayWord = t('files.upload.tomorrow');
          } else {
            dayWord = t(`files.upload.dayNames.day${getDay(day)}`);
          }

          return {
            id: index + 1,
            label: `${format(day, 'dd. MM. yyyy HH:mm')} [${dayWord}]`,
          };
        })
      );
    }
  };

  const storeAnonymizationOptions = () => {
    if (canAnonymizeSender) {
      setAnonymizeSenderMails(
        anonymizations.map((anonymizationMail: string, index: number) => {
          return {
            id: anonymizationMail,
            label: anonymizationMail,
          };
        })
      );
    }
  };

  useEffect(() => {
    const loadServerTime = async () => {
      return await getServerTime().then((response) => response);
    };

    loadServerTime().then((serverTime) => {
      storeOptions(serverTime);
    });

    storeAnonymizationOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (canAnonymizeSender) {
      // Set anonymization to checked if user has this options set by default in profile
      if ((otherFlags & 1) === 1) {
        toggleAnonymizeSnederMailVisible(true);
      }

      // Set anonymization mail when there's only one
      if (hasOnlyOneAnonymizationProfile) {
        setFieldValue('anonymizeSender', head(anonymizations));
      }
    }

    // DS-962
    if (sharedTransfersEnabled === false && privateTransfersDisabled === false) {
      toggleSharedTransfer(false);
      setSharedTransferDisabled(true);
    } else if (sharedTransfersEnabled === true && privateTransfersDisabled === false) {
      toggleSharedTransfer(true);
    } else if (sharedTransfersEnabled === false && privateTransfersDisabled === true) {
      console.log('tato situace by neměla nastat');
    } else if (sharedTransfersEnabled === true && privateTransfersDisabled === true) {
      toggleSharedTransfer(true);
      setSharedTransferDisabled(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualOrganization]);

  //DS-957
  const recipients = get(values, 'recipients', []).map((item: any) => item.email);
  const internalContacts = get(contacts, 'internal', []).map((item: any) => item.email);
  const groupContacts = flatten(
    get(contacts, 'groups', []).map((item: any) => item.items.map((item2: any) => item2.email))
  );
  const isNotInContacts = recipients.some(
    (item: any) => !includes(uniq([...internalContacts, ...groupContacts]), item)
  );

  return (
    <>
      <Title title={title} />
      <div className={classes.submit}>
        {canDelayTransfer ? (
          <div className={classes.transferDelay}>
            <FormControlLabel
              control={
                <Switch
                  checked={transferDelayVisible}
                  onChange={(event) => toggleTransferDelatVisible(event.target.checked)}
                  name="checkedB"
                  color="primary"
                />
              }
              label={t('files.upload.delayTransfer')}
            />
            {transferDelayVisible ? (
              <div>
                <SelectField
                  name="transferDelay"
                  label={t('files.upload.transferDelayDate')}
                  items={transferDelayItems}
                />
              </div>
            ) : null}
          </div>
        ) : null}
        {canAnonymizeSender ? (
          <div className={classes.transferDelay}>
            <FormControlLabel
              control={
                <Switch
                  checked={anonymizeSnederMailVisible}
                  onChange={(event) => toggleAnonymizeSnederMailVisible(event.target.checked)}
                  name="checkedC"
                  color="primary"
                />
              }
              label={t('files.upload.anonymizeSender')}
            />
            {anonymizeSnederMailVisible ? (
              <div style={{ display: hasOnlyOneAnonymizationProfile ? 'none' : 'block' }}>
                <SelectField
                  name="anonymizeSender"
                  label={t('files.upload.anonymizeSenderMail')}
                  items={anonymizeSenderMails}
                />
              </div>
            ) : null}
          </div>
        ) : null}
        {showSharedTransfer ? (
          <div className={classes.transferDelay}>
            <FormControlLabel
              control={
                <Switch
                  checked={sharedTransfer}
                  onChange={(event) => toggleSharedTransfer(event.target.checked)}
                  name="checkedD"
                  color="primary"
                  disabled={sharedTransferDisabled}
                />
              }
              label={t('files.upload.sharedTransfer')}
            />
          </div>
        ) : null}
        <Button
          type={
            internalContactsSaveMode === INTERNAL_CONTACTS_SAVE_MODE_OPTIONAL && isNotInContacts
              ? 'button'
              : 'submit'
          }
          disabled={buttonDisabled}
          classes={{ label: classes.submitButton }}
          color="primary"
          id="upload-submit-button"
          onClick={() => {
            if (userIsAuthorized) {
              removeEmptyRecipientsBeforeUpload();
            }

            // DS-957
            if (
              internalContactsSaveMode === INTERNAL_CONTACTS_SAVE_MODE_OPTIONAL &&
              isNotInContacts
            ) {
              setShowInternalContactsSaveDialog(true);
            }
          }}
        >
          <Icon classes={{ root: classes.submitButtonIcon }} />
          {buttonTitle}
        </Button>

        {showTermsOfUseCheckbox && (
          <CheckboxField
            name="termsOfUse"
            label={
              <div>
                {t('login.termsOfUse1')} <Link to="/terms-of-use">{t('login.termsOfUse2')}</Link>{' '}
                {t('login.termsOfUse3')}
              </div>
            }
          />
        )}
      </div>
    </>
  );
};

export default UploadFormSubmit;
