import dicomParser from 'dicom-parser';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@material-ui/icons/Done';
import { head, isArray, isObject, compact, get, pick, filter, includes } from 'lodash';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import usePhone from './usePhone';
import {
  IUploadFormRecipient,
  ITransferRecipient,
  ITransferConfirmResponse,
  ITransferUploadStartResponse,
  IUploadStartResponse,
} from 'modules/Upload/_types';
import { IRecipient } from 'modules/RecipientRegistry/_types';
import {
  IRemoteSourceItemMergedParam,
  IRemoteSourceItemParam,
} from 'components/RemoteUploadDialog/_types';
import { Tooltip } from '@material-ui/core';
import { REMOTE_FIELD_DATE_TYPE_BOOLEAN, REMOTE_FIELD_DATE_TYPE_ENUM } from 'constants/constants';

const calculateSize = (bytes: number, decimals: number = 0) => {
  if (bytes === 0) return '0 B';
  var k = 1024,
    dm = decimals <= 0 ? 0 : decimals || 2,
    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const useFileUpload = () => {
  const { joinPhoneNumber } = usePhone();
  const { t } = useTranslation();
  const { splitPhoneNumber } = usePhone();

  const getImagePreview = (resumableUniqueIdentifier: string, file: File) => {
    try {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (event: any) => {
        const elem = document.getElementById(`img-${resumableUniqueIdentifier}`);
        if (elem !== null) {
          elem.setAttribute('src', event.target.result);
        }
      };
    } catch (e) {
      // Preview image not available
    }
  };

  const getDicomPreview = (id: string, file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (_file2) => {
        const arrayBuffer = reader.result;
        const byteArray = new Uint8Array(arrayBuffer as ArrayBuffer);
        const dataSet = dicomParser.parseDicom(byteArray, {
          omitPrivateAttibutes: true,
          untilTag: 'x0020000e',
          maxElementLength: 128,
        });
        if (dataSet) {
          const instance = {
            patientName: dataSet.string('x00100010', 0),
            patientId: dataSet.string('x00100020', 0),
            studyDate: dataSet.string('x00080020', 0),
            studyId: dataSet.string('x00200010', 0),
            studyDescription: dataSet.string('x00081030', 0),
          };
          resolve(instance);
        } else {
          reject('noData');
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  const formatRecipientForApi = (recipient: IUploadFormRecipient): ITransferRecipient | null =>
    isObject(recipient)
      ? {
          recipientEmail: recipient.email,
          recipientPhone: joinPhoneNumber(recipient.prefix, recipient.phoneNumber),
        }
      : null;

  const recipientIsEmpty = (recipient: IUploadFormRecipient) =>
    get(recipient, 'email') === '' && get(recipient, 'phoneNumber') === '';

  const getPrimaryRecipient = (recipients: IUploadFormRecipient[]) => {
    const recipient = head(recipients);
    return isObject(recipient) ? formatRecipientForApi(recipient) : null;
  };

  const getOtherRecipients = (recipients: IUploadFormRecipient[]): Array<ITransferRecipient> | [] =>
    isArray(recipients)
      ? compact(recipients.slice(1).map((recipient) => formatRecipientForApi(recipient)))
      : [];

  const validateUploadDone = (transferConfirmResponse: ITransferConfirmResponse) => {
    if (get(transferConfirmResponse, 'success')) {
      return true;
    }
    if (get(transferConfirmResponse, 'noUploads')) {
      return t('files.upload.noUploadsError');
    }
    if (get(transferConfirmResponse, 'totalSizeLimitExceeded')) {
      return t('files.upload.userLimitWithoutSize');
    }
    if (get(transferConfirmResponse, 'transferSizeLimitExceeded')) {
      return t('files.upload.transferLimitWithoutSize');
    }
    if (get(transferConfirmResponse, 'transfersPerDayLimitExceeded')) {
      return t('files.upload.transfersPerDayLimit');
    }
    if (get(transferConfirmResponse, 'numberOfRecipientsLimitExceeded')) {
      return t('files.upload.numberOfTransferRecipientsLimit');
    }
    if (
      get(transferConfirmResponse, 'linkEmailSuccess') != null &&
      !get(transferConfirmResponse, 'linkEmailSuccess')
    ) {
      return t('files.upload.linkEmailError');
    }
  };

  const convertRecipientForForm = (recipient: IRecipient) => {
    const [prefix, phoneNumber] = splitPhoneNumber(get(recipient, 'phone'));
    return { ...pick(recipient, ['email']), prefix, phoneNumber };
  };

  const validateUploadStart = (
    transferUploadStartResponse: ITransferUploadStartResponse,
    localSizeInBytes: number,
    numberOfRecipients: number
  ) => {
    const cipherLimit = get(transferUploadStartResponse, 'cipherLimit');
    if (cipherLimit !== 0 && localSizeInBytes > cipherLimit) {
      const limit = calculateSize(localSizeInBytes - cipherLimit, 2);
      return t('files.upload.transferLimit', { limit });
    }

    if (get(transferUploadStartResponse, 'transferOverLimit') === true) {
      return true;
    }

    if (get(transferUploadStartResponse, 'userLimit') !== 0) {
      const remainLimit =
        get(transferUploadStartResponse, 'userLimit') -
        get(transferUploadStartResponse, 'userUsed');
      if (remainLimit < localSizeInBytes) {
        const limit = calculateSize(localSizeInBytes - remainLimit, 2);
        return t('files.upload.userLimit', { limit });
      }
    }

    const transferLimit = get(transferUploadStartResponse, 'transferLimit');
    if (transferLimit !== 0) {
      if (transferLimit < localSizeInBytes) {
        const limit = calculateSize(localSizeInBytes - transferLimit, 2);
        return t('files.upload.transferLimit', { limit });
      }
    }
    if (get(transferUploadStartResponse, 'transfersPerDayLimit') !== 0) {
      if (
        get(transferUploadStartResponse, 'transfersPerDayUsed') + numberOfRecipients >
        get(transferUploadStartResponse, 'transfersPerDayLimit')
      ) {
        return t('files.upload.transfersPerDayLimit');
      }
    }
    if (
      get(transferUploadStartResponse, 'numberOfRecipientsLimit') !== null &&
      get(transferUploadStartResponse, 'numberOfRecipientsLimit') !== 0 &&
      numberOfRecipients != null
    ) {
      if (numberOfRecipients > get(transferUploadStartResponse, 'numberOfRecipientsLimit')) {
        return t('files.upload.numberOfTransferRecipientsLimit');
      }
    }

    return true;
  };

  const validateUploadStartUnAuth = (
    unauthorizedUploadStartResponse: IUploadStartResponse,
    localSizeInBytes: number,
    numberOfRecipients: number
  ) => {
    const cipherLimit = get(unauthorizedUploadStartResponse, 'cipherLimit');
    if (cipherLimit !== 0 && localSizeInBytes > cipherLimit) {
      const limit = calculateSize(localSizeInBytes - cipherLimit, 2);
      return t('files.upload.transferLimit', { limit });
    }

    if (get(unauthorizedUploadStartResponse, 'transferOverLimit') === true) {
      return true;
    }

    const userLimit = get(unauthorizedUploadStartResponse, 'userLimit');
    const userUsed = get(unauthorizedUploadStartResponse, 'userUsed');
    if (userLimit !== 0) {
      const remainLimit = userLimit - userUsed;
      if (remainLimit < localSizeInBytes) {
        const limit = calculateSize(localSizeInBytes - remainLimit, 2);
        return t('files.upload.userLimit', { limit });
      }
    }

    const transferLimit = get(unauthorizedUploadStartResponse, 'transferLimit');
    if (transferLimit !== 0) {
      if (transferLimit < localSizeInBytes) {
        const limit = calculateSize(localSizeInBytes - transferLimit, 2);
        return t('files.upload.transferLimit', { limit });
      }
    }

    const transfersPerDayLimit = get(unauthorizedUploadStartResponse, 'transfersPerDayLimit');
    const transfersPerDayUsed = get(unauthorizedUploadStartResponse, 'transfersPerDayUsed');
    if (transfersPerDayLimit !== 0) {
      if (transfersPerDayUsed + numberOfRecipients > transfersPerDayLimit) {
        return t('files.upload.transfersPerDayLimit');
      }
    }

    const nOfRecipientsLimit = get(unauthorizedUploadStartResponse, 'numberOfRecipientsLimit');
    if (nOfRecipientsLimit !== null && nOfRecipientsLimit !== 0 && nOfRecipientsLimit != null) {
      if (numberOfRecipients > nOfRecipientsLimit) {
        return t('files.upload.numberOfTransferRecipientsLimit');
      }
    }

    return true;
  };

  const getGridColumns = (
    columns: IRemoteSourceItemMergedParam[] | IRemoteSourceItemParam[],
    showCheckboxes: boolean
  ) => {
    const columnsArray = filter(columns, { visible: true }).map((column) => ({
      field: column.name,
      title: column.label,
      ...(column.type === REMOTE_FIELD_DATE_TYPE_ENUM
        ? {
            render: (rowData: any) => get(column.map, get(rowData, column.name)),
          }
        : column.type === REMOTE_FIELD_DATE_TYPE_BOOLEAN
        ? {
            render: (rowData: any) =>
              get(rowData, column.name) ? (
                <CheckIcon color="primary" />
              ) : (
                <span style={{ color: '#db2307' }}>
                  <ClearIcon color="inherit" />
                </span>
              ),
          }
        : {}),
    }));

    // Only push checkbox field when neccessary
    if (showCheckboxes) {
      columnsArray.unshift({
        field: 'alreadySelectedForUpload',
        title: t('files.upload.itemAlreadySelected'),
        render: (rowData: any) =>
          rowData.alreadySelectedForUpload ? (
            <Tooltip title={t('files.upload.itemIsAlreadySelected') || ''}>
              <DoneIcon />
            </Tooltip>
          ) : null,
      });
    }
    return columnsArray;
  };

  const getFileExtension = (fileName: string) => {
    const fileExtensionRegularExpression = /(?:\.([^.]+))?$/;
    return (fileExtensionRegularExpression.exec(fileName) || [])[1];
  };

  const getDateFieldsThatShouldBeFormatted = (
    itemParams: IRemoteSourceItemParam[] = [],
    itemMergedParams: IRemoteSourceItemMergedParam[] = []
  ) => {
    try {
      let paramNames: string[] = [];

      const filterAndReturnParamNames = (items: any) =>
        filter(items, (item: IRemoteSourceItemParam) =>
          includes(['date', 'time', 'datetime'], item.type)
        ).map((itemParam) => itemParam.name);

      if (isArray(itemParams)) {
        paramNames = filterAndReturnParamNames(itemParams);
      }
      if (isArray(itemMergedParams)) {
        paramNames = [...paramNames, ...filterAndReturnParamNames(itemMergedParams)];
      }

      return paramNames;
    } catch (e) {
      return [];
    }
  };

  return {
    getGridColumns,
    getImagePreview,
    getDicomPreview,
    getFileExtension,
    recipientIsEmpty,
    validateUploadDone,
    getOtherRecipients,
    getPrimaryRecipient,
    validateUploadStart,
    convertRecipientForForm,
    validateUploadStartUnAuth,
    getDateFieldsThatShouldBeFormatted,
  };
};

export default useFileUpload;
