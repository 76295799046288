import React, { useEffect } from 'react';
import { filter, get } from 'lodash';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import LockIcon from '@material-ui/icons/Lock';
import MailIcon from '@material-ui/icons/Mail';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import LinkIcon from '@material-ui/icons/OpenInBrowser';
import UploadIcon from '@material-ui/icons/CloudUpload';
import AssessmentIcon from '@material-ui/icons/Assessment';
import RegistrationIcon from '@material-ui/icons/PersonAdd';
import PhoneLockedIcon from '@material-ui/icons/PhoneLocked';
import OutgoingFilesIcon from '@material-ui/icons/ArrowUpward';
import IncomingFilesIcon from '@material-ui/icons/ArrowDownward';
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';
import ScreenLockRotationIcon from '@material-ui/icons/ScreenLockRotation';

import useStyles from './_styles';
import Tile from 'components/Tile/Tile';
import useConfig from 'utils/hooks/useConfig';
import useUserInfo from 'utils/hooks/useUserInfo';
import {
  PERMISSION_ADV_SHARE,
  PERMISSION_LINK_SHARE,
  PERMISSION_EDIT_PROFILE,
  PERMISSION_SMS_VERIFICATION,
  PERMISSION_ADMINISTRATING,
  PERMISSION_SUPER_ADMINISTRATING,
  PERMISSION_STATISTICS,
  ORGANIZATION_PERMISSION_STATISTICS,
} from 'constants/constants';
import useUrl from 'utils/hooks/useUrl';
import useStorage from 'utils/hooks/useStorage';
import useAppInfo from 'utils/hooks/useAppInfo';
import useContacts from 'utils/hooks/useContacts';

const UserHome: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { getParamValue } = useUrl();
  const { getConfigValue } = useConfig();
  const history = useHistory();
  const {
    user,
    userIsAuthorized,
    userHasPermissions,
    userHasOrganizationPermission,
    userCanRegister,
    actualOrganization,
    actualUserWasInvited,
  } = useUserInfo();

  useEffect(() => {
    const smsVerificationPermission = userHasPermissions(PERMISSION_SMS_VERIFICATION);
    const sharePermission = userHasPermissions(PERMISSION_ADV_SHARE);
    if (smsVerificationPermission && !sharePermission) {
      history.push('/add-phone');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showAfterRegistrationMessage = getParamValue('registration');
  const { get: storageGet } = useStorage();
  const { mfa } = useAppInfo();
  const { contactsDisabled } = useContacts();

  const showTotpBanner =
    storageGet('userHadSmsVerificationPermission') === 'true' &&
    get(mfa, 'enabled', false) &&
    get(mfa, 'type', '') === 'totp';

  const canUploadFiles = userHasPermissions(PERMISSION_ADV_SHARE);
  const displayVerificationMsg =
    userHasPermissions(PERMISSION_SMS_VERIFICATION) && !userHasPermissions(PERMISSION_ADV_SHARE);
  const canCreateLink = userHasPermissions(PERMISSION_LINK_SHARE);
  const showIncomingFiles = userIsAuthorized && getConfigValue('showIncomingFiles');
  const canChangePhone =
    userHasPermissions(PERMISSION_EDIT_PROFILE) && !userHasPermissions(PERMISSION_SMS_VERIFICATION);
  const showContactForm = userIsAuthorized && getConfigValue('showContactForm');
  const canViewStatistics =
    user.anyOrganizationAdmin ||
    userHasPermissions(PERMISSION_ADMINISTRATING) ||
    userHasPermissions(PERMISSION_SUPER_ADMINISTRATING) ||
    userHasPermissions(PERMISSION_STATISTICS) ||
    userHasOrganizationPermission(ORGANIZATION_PERMISSION_STATISTICS);
  const canLogin = !userIsAuthorized && !getConfigValue('appendix.isOnlyAutologinAuth');
  const canChangePassword =
    userIsAuthorized &&
    getConfigValue('appendix.authentication.changePasswordEnabled') &&
    get(user, 'canChangePassword');
  const canAddPhone = userHasPermissions(PERMISSION_SMS_VERIFICATION);
  const canRecoverPassword =
    !userIsAuthorized && getConfigValue('appendix.authentication.changePasswordEnabled');
  const canAdministrate =
    userHasPermissions(PERMISSION_ADMINISTRATING) ||
    userHasPermissions(PERMISSION_SUPER_ADMINISTRATING) ||
    user.anyOrganizationAdmin;
  const canInviteUser =
    userIsAuthorized && (get(actualOrganization, 'flags', 0) & 8) === 8 && !actualUserWasInvited;
  const userCanUseTOTP =
    !userHasPermissions(PERMISSION_SMS_VERIFICATION) && get(mfa, 'enabled', false);
  const showContactsTile = !contactsDisabled;

  // prettier-ignore
  const tiles = [
    { label: t('common.tiles.upload'), Icon: UploadIcon, link: '/#upload', visible: canUploadFiles },
    { label: t('common.tiles.uploadLink'), Icon: LinkIcon, link: '/#upload&mode=link', visible: canCreateLink },
    { label: t('common.tiles.incomingFiles'), Icon: IncomingFilesIcon, link: '/incoming-files', visible: showIncomingFiles },
    { label: t('common.tiles.outgoingFiles'), Icon: OutgoingFilesIcon, link: '/outgoing-files', visible: canUploadFiles },
    { label: t('common.tiles.profile'), Icon: PersonIcon, link: '/profile', visible: userIsAuthorized },
    { label: t('common.tiles.inviteUser'), Icon: RegistrationIcon, link: '/invite', visible: canInviteUser },
    { label: t('common.tiles.totp'), Icon: ScreenLockRotationIcon, link: '/totp', visible: userCanUseTOTP },
    { label: t('common.tiles.registration'), Icon: RegistrationIcon, link: '/registration', visible: userCanRegister },
    { label: t('common.tiles.login'), Icon: PersonIcon, link: '/login', visible: canLogin },
    { label: t('common.tiles.changePassword'), Icon: LockIcon, link: '/change-password', visible: canChangePassword },
    { label: t('common.tiles.addPhone'), Icon: SettingsPhoneIcon, link: '/add-phone', visible: canAddPhone },
    { label: t('common.tiles.phoneVerification'), Icon: SettingsPhoneIcon, link: '/phone-verification', visible: canAddPhone },
    { label: t('common.tiles.changePhone'), Icon: PhoneLockedIcon, link: '/change-phone', visible: canChangePhone },
    { label: t('common.tiles.fogottenPassword'), Icon: LockIcon, link: '/forgotten-password', visible: canRecoverPassword },
    { label: t('common.tiles.contactUs'), Icon: MailIcon, link: '/contact-form', visible: showContactForm },
    { label: t('common.tiles.recipientRegistry'), Icon: PeopleIcon, link: '/recipient-registry', visible: canUploadFiles && showContactsTile },
    { label: t('common.tiles.statistics'), Icon: AssessmentIcon, link: '/statistics', visible: canViewStatistics },
    { label: t('common.tiles.administration'), Icon: SettingsIcon, link: '/administration', visible: canAdministrate },
  ];

  const visibleTiles = filter(tiles, 'visible');

  return (
    <>
      {showAfterRegistrationMessage && (
        <div className={classes.info}>{t('common.showAfterRegistrationMessage')}</div>
      )}
      {showTotpBanner && (
        <div className={classes.info}>
          {t('common.verification.totp')}{' '}
          <a style={{ color: 'white' }} href="/totp">
            {t('common.verification.totpLink')}
          </a>
        </div>
      )}
      {displayVerificationMsg && (
        <div className={classes.danger}>{t('common.verification.alert')}</div>
      )}
      <div className={classes.tiles}>
        {visibleTiles.map((tile) => (
          <Tile {...tile} key={tile.link} />
        ))}
      </div>
    </>
  );
};

export default UserHome;
