import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { themeVariables } from 'theme';

const useStyles = makeStyles((theme: Theme) => {
  // console.debug(theme);
  return createStyles({
    appBarRoot: {
      backgroundColor: themeVariables.colors.grey,
    },
    toolbarRoot: {
      justifyContent: 'space-between',
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
    },
    logo: {
      height: 46,
      display: 'block',
    },
    buttonRoot: {
      color: '#9E9E9E',
    },
    buttonLabel: {
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 'normal',
      color: 'inherit',
    },
    activeLink: {
      color: theme.palette.primary.main,
    },
    badge: {
      top: '20%',
      right: '20%',
    },
    barRight: {
      display: 'flex',
      alignItems: 'center',
    },
    danger: {
      fontSize: 24,
      color: '#fff',
      fontWeight: 'bold',
      background: '#F44336',
      padding: theme.spacing(2),
    },
    informationalBar: {
      fontSize: 16,
      background: '#f9928b',
      padding: theme.spacing(2),
    },
    dangerLink: {
      color: '#fff',
    },
  });
});

export default useStyles;
