import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(({ spacing, palette }: Theme) => {
  return createStyles({
    folder: {
      '&:hover': {
        backgroundColor: '#eee',
        cursor: 'pointer',
      },
    },
    folderIcon: {
      color: '#ffe18d',
    },
    breadcrumbsLink: {
      cursor: 'pointer',
    },
  });
});

export default useStyles;
