import {
  IRemoteSourceSearchParam,
  IRemoteSourceItemParam,
  IRemoteSourceLevel,
  IRemoteSourceItem,
} from 'components/RemoteUploadDialog/_types';
import { compact, each, filter, find, get, isArray, maxBy, orderBy, set } from 'lodash';

const useRemoteUpload = () => {
  const getDateFieldFrom = (searchParams: IRemoteSourceSearchParam[]) => {
    const field = find(searchParams, 'isFieldDateFrom');
    return field ? field.name : undefined;
  };

  const getDateFieldTo = (searchParams: IRemoteSourceSearchParam[]) => {
    const field = find(searchParams, 'isFieldDateTo');
    return field ? field.name : undefined;
  };

  const getFieldsByType = (itemParams: IRemoteSourceItemParam[], type: string) => {
    if (!isArray(itemParams)) return [];
    return filter(itemParams, { type });
  };

  const getFieldNamesByType = (itemParams: IRemoteSourceItemParam[], type: string) => {
    return getFieldsByType(itemParams, type).map((item) => item.name);
  };

  const getLevel = (levels: IRemoteSourceLevel[], level: number) => find(levels, { level });
  const getLastLevel = (levels: IRemoteSourceLevel[]) => maxBy(levels, 'level');
  const getLastLevelNumber = (levels: IRemoteSourceLevel[]) => get(maxBy(levels, 'level'), 'level');
  const isLastLevel = (levels: IRemoteSourceLevel[], actualLavel: IRemoteSourceLevel) =>
    getLastLevelNumber(levels) === actualLavel.level;

  const hasMoreThanOneLevel = (levels: IRemoteSourceLevel[]) =>
    isArray(levels) && levels.length > 1;

  const replaceFormattedFields = (
    items: IRemoteSourceItem[],
    fieldsWithFormattedDates: string[]
  ) => {
    if (!isArray(items)) {
      return [];
    }

    try {
      return items.map((item) => {
        let newItem = { ...item };
        each(fieldsWithFormattedDates, (field) => {
          const formattedValue = get(item, `${field}Formatted`);

          // Only set when formatted value exists
          if (formattedValue) {
            set(newItem, field, formattedValue);
          }
        });
        return newItem;
      });
    } catch (e) {
      return [];
    }
  };

  const getRemoteFileDescription = (previousLevelData: any, itemDescriptionParams: string[]) => {
    if (
      !isArray(previousLevelData) ||
      !isArray(itemDescriptionParams) ||
      !previousLevelData.length ||
      !itemDescriptionParams.length
    ) {
      return 'Missing file description';
    }
    const paramValuesArray: string[] = [];
    each(orderBy(itemDescriptionParams, 'order'), (param: any) => {
      paramValuesArray.push(get(find(previousLevelData, { level: param.level }), param.paramName));
    });
    return compact(paramValuesArray).join(' - ');
  };

  return {
    isLastLevel,
    getDateFieldFrom,
    getDateFieldTo,
    getFieldsByType,
    getFieldNamesByType,
    getLevel,
    getLastLevel,
    getLastLevelNumber,
    hasMoreThanOneLevel,
    replaceFormattedFields,
    getRemoteFileDescription,
  };
};

export default useRemoteUpload;
