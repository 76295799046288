import React from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { IDatePicker } from './_types';
import { cs, sk, enGB } from 'date-fns/esm/locale';
import { useTranslation } from 'react-i18next';
import useLanguages from 'utils/hooks/useLanguages';

const DatePicker: React.FC<IDatePicker> = ({ label, selectedDate, handleDateChange }) => {
  const { t } = useTranslation();
  const { currentLanguage } = useLanguages();
  const selectedLocale = currentLanguage === 'cs' ? cs : currentLanguage === 'sk' ? sk : enGB;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={selectedLocale}>
      <KeyboardDatePicker
        id="date-picker-dialog"
        label={label}
        format="dd.MM.yyyy"
        value={selectedDate}
        onChange={handleDateChange}
        fullWidth={true}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        cancelLabel={t('form.date.cancel')}
        clearLabel={t('form.date.clear')}
        autoOk={true}
        clearable={true}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
