import React from 'react';
import { throttle } from 'lodash';
import { connect } from 'react-redux';
import Resumablejs, { ResumableFile } from 'resumablejs';

import { IResumable } from './_types';
import { UPLOAD_SLUG } from 'constants/constants';
import { ResumableUploadTick } from '../Upload/_redux';

class Resumable extends React.Component<IResumable> {
  componentDidMount() {
    (window as any).StoredResumableField = new Resumablejs.Resumable({
      target: `/api/${UPLOAD_SLUG}`,
      method: 'octet',
      testMethod: 'options',
      chunkSize: 1 * 1024 * 1024,
      forceChunkSize: false,
      simultaneousUploads: 1,
      fileParameterName: 'file',
      query: {},
      headers: {},
      prioritizeFirstAndLastChunk: false,
      parameterNamespace: '',
      testChunks: false,
      maxChunkRetries: undefined,
      chunkRetryInterval: undefined,
      maxFiles: undefined,
      withCredentials: true,
    });

    const ResumableField = (window as any).StoredResumableField;
    const { checkFileOnUpload } = this.props;

    const dropElem = document.getElementById('root') as HTMLElement;
    const browseElem = document.getElementById('browse-zone') as HTMLElement;
    if (dropElem) {
      ResumableField.assignDrop(dropElem);
    }
    if (browseElem) {
      ResumableField.assignBrowse(browseElem, false);
    }

    // TODO - handle not supported browser
    // console.debug(ResumableField.support);

    ResumableField.on('fileAdded', (file: ResumableFile) => {
      const fileAllowed = checkFileOnUpload(file);
      if (fileAllowed) {
        this.props.setUploadedFiles([...this.props.uploadedFiles, file]);
      } else {
        ResumableField.removeFile(ResumableField.getFromUniqueIdentifier(file.uniqueIdentifier));
      }
    });

    ResumableField.on('fileSuccess', (file: any, message: any) =>
      console.debug('fileSuccess', { file, message })
    );

    // Only update redux store every XXX milliseconds
    const throttledDispatch = throttle(() => {
      this.props.ResumableUploadTick();
    }, 300);

    ResumableField.on('progress', () => {
      // console.debug(ResumableField.progress());
      throttledDispatch();
    });

    ResumableField.on('complete', () => {
      console.debug('completed');
      this.props.uploadDone();
    });
  }

  componentWillUnmount() {
    (window as any).StoredResumableField = undefined;
  }

  render() {
    return null;
  }
}

export default connect(null, {
  ResumableUploadTick,
})(Resumable);
