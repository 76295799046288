import React from 'react';
import { Typography } from '@material-ui/core';

import useStyles from './_styles';
import { ITitle } from './_types';

const Title: React.FC<ITitle> = ({ title, subtitle = false, gutterBottom = true }) => {
  const classes = useStyles();

  return (
    <Typography
      variant={subtitle ? 'subtitle1' : 'h5'}
      align="center"
      gutterBottom={gutterBottom}
      classes={{
        h5: classes.title,
        subtitle1: classes.subtitle,
        gutterBottom: classes.gutterBottom
      }}
    >
      {title}
    </Typography>
  );
};

export default Title;
