import classnames from 'classnames';
import React, { useEffect } from 'react';
import { Button, DialogContent, Dialog, DialogActions } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import RegistrationIcon from '@material-ui/icons/LockOpen';

import useStyles from './_styles';
import ButtonNavLink from 'components/ButtonNavLink/ButtonNavLink';
import { useHistory } from 'react-router';
import useLogin from 'utils/hooks/useLogin';
import useAlphanum from 'utils/hooks/useAlphanum';
import useUrl from 'utils/hooks/useUrl';
import useStorage from 'utils/hooks/useStorage';
import {
  AUTH_METHOD_OPENID,
  AUTH_METHOD_OPENID_FULL_URL_COOKIE_NAME,
  AUTH_METHOD_OPENID_REDIRECT_URI_COOKIE_NAME,
  AUTH_METHOD_OPENID_STATE_COOKIE_NAME,
} from 'constants/constants';
import DialogTitleWithClose from 'components/DialogTitleWithClose/DialogTitleWithClose';
import { find, isArray, isFunction } from 'lodash';
import { ILoginSelect } from './_types';
import useUserInfo from 'utils/hooks/useUserInfo';
import useHomePage from 'utils/hooks/useHomePage';

const LoginSelect: React.FC<ILoginSelect> = ({
  isDialog = false,
  closeLoginSelectDialog,
  loginRequired,
  showLoginSelectDialog,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { put } = useStorage();
  const { mapObjectToUrlParams } = useUrl();
  const { userIsAuthorized } = useUserInfo();
  const { generateString } = useAlphanum();
  const homeUrlPage = useHomePage();
  const {
    oauthConfig,
    localAuthEnabled,
    otherAuthMethods,
    hasOtherAuthMethod,
    hasOnlyOneLoginMethod,
    isAuthMethod,
    theOnlyOneLoginMethodIsLocalForm,
  } = useLogin();

  const hasBeenJustLogoutFromSSO = window.location.href.indexOf('idmLoggedOut=true') > -1;

  // Automatically redirect to login form when there is no other option (but not in dialog)
  useEffect(() => {
    if (!isDialog) {
      if (userIsAuthorized) {
        history.push(homeUrlPage);
      } else if (hasOnlyOneLoginMethod) {
        // Local login form -> redirect to URL
        if (theOnlyOneLoginMethodIsLocalForm) {
          history.push('/login-form');
        }

        // It's not local form -> decide based on method ID
        if (!theOnlyOneLoginMethodIsLocalForm) {
          const method = otherAuthMethods[0];
          const isOpenID = isAuthMethod(method, AUTH_METHOD_OPENID);
          if (isOpenID) {
            const methodButton = document.getElementById(`btn-method-${method.id}`);
            if (methodButton && !hasBeenJustLogoutFromSSO) {
              methodButton.click();
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If we don't provide closeLoginSelectDialog, then it can't be closed
  const closeDialogFn = () => {
    if (!loginRequired && isFunction(closeLoginSelectDialog)) {
      closeLoginSelectDialog();
    }
  };

  const findBestCallbackUriForCurrentUrl = () => {
    const uris = oauthConfig?.callbackUris;
    if (isArray(uris)) {
      if (uris.length === 1) {
        return uris[0];
      } else {
        const currentUrl = window.location.hostname;
        const foundUri = find(uris, (uri) => uri.indexOf(currentUrl) > -1);
        return foundUri ? foundUri : uris[0];
      }
    } else {
      return '';
    }
  };

  const Buttons = () => {
    return (
      <>
        <div className={classes.tilesSelect}>
          {localAuthEnabled && (
            <Button
              variant="text"
              classes={{
                root: classnames(classes.tile, classes.loginButton),
                label: classnames(classes.label, classes.loginButtonLabel),
              }}
              {...(!isDialog ? { to: '/login-form', component: ButtonNavLink } : {})}
              {...(isDialog
                ? {
                    onClick: () => {
                      if (isFunction(showLoginSelectDialog)) {
                        showLoginSelectDialog();
                      }
                    },
                  }
                : {})}
            >
              <div className={classes.iconWrapper}>
                <RegistrationIcon className={classes.icon} />
              </div>
              <div className={classes.labelWrapper}>{t('login.methods.classicform')}</div>
            </Button>
          )}

          {hasOtherAuthMethod &&
            otherAuthMethods.map((method) => {
              let clickCallback: any = () => false;
              const bestUri = findBestCallbackUriForCurrentUrl();

              if (isAuthMethod(method, AUTH_METHOD_OPENID)) {
                clickCallback = (e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  const state = generateString(125);
                  const redirectUrl = bestUri;
                  put(AUTH_METHOD_OPENID_STATE_COOKIE_NAME, state);
                  put(AUTH_METHOD_OPENID_REDIRECT_URI_COOKIE_NAME, redirectUrl);
                  put(AUTH_METHOD_OPENID_FULL_URL_COOKIE_NAME, window.location.href);
                  let urlParams = {
                    response_type: 'code',
                    client_id: oauthConfig?.clientId,
                    grant_type: 'password',
                    state,
                    redirect_uri: redirectUrl,
                    prompt: 'consent',
                  };
                  const urlToRedirectTo = `${oauthConfig?.gatewayUri}?${mapObjectToUrlParams(
                    urlParams
                  )}`;

                  window.location.href = urlToRedirectTo;
                };
              }

              return (
                <Button
                  key={method.id}
                  variant="text"
                  classes={{
                    root: classnames(classes.tile, classes.loginButton),
                    label: classnames(classes.label, classes.loginButtonLabel),
                  }}
                  onClick={clickCallback}
                  id={`btn-method-${method.id}`}
                >
                  <div className={classes.iconWrapper}>
                    <img
                      src={`${process.env.PUBLIC_URL}/img/login/${method.id}.png`}
                      alt="Login method icon"
                      width="50"
                      height="50"
                    />
                  </div>
                  <div className={classes.labelWrapper}>{t(`login.methods.${method.id}`)}</div>
                </Button>
              );
            })}
        </div>
      </>
    );
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isDialog ? (
    <Dialog
      maxWidth="sm"
      aria-labelledby="confirmation-dialog-title"
      open={true}
      onClose={closeDialogFn}
    >
      <DialogTitleWithClose title={t('login.login')} closeDialogFn={closeDialogFn} />
      <DialogContent>
        <Buttons />
      </DialogContent>
      <DialogActions />
    </Dialog>
  ) : (
    <>
      {hasBeenJustLogoutFromSSO ? (
        <div className={classes.userLogoutFromSSO}>{t('login.userLogoutFromSSO')}</div>
      ) : null}
      <Buttons />
    </>
  );
};

export default LoginSelect;
