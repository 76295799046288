import React from 'react';
import TextPage from 'components/TextPage/TextPage';
import useStyleByAppVersion from 'utils/hooks/useStyleByAppVersion';

const Terms: React.FC = () => {
  const { getTermOfUsePage } = useStyleByAppVersion();
  return <TextPage template={getTermOfUsePage()} />;
};

export default Terms;
