import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { includes, pull } from 'lodash';
import { LINK_LOCATION, PAGE_SLUG_LINK } from 'constants/constants';

const Link: React.FC = () => {
  const history = useHistory();
  const { hash, pathname } = useLocation();
  const isLinkWithHash = includes(hash, 'link');
  const isLinkWithoutHash = includes(pathname, '/link');
  const isLink = isLinkWithHash || isLinkWithoutHash;

  if (isLink) {
    try {
      let params: string[] = [];
      if (isLinkWithHash) {
        params = pull(hash.split('&'), PAGE_SLUG_LINK);
      }
      if (isLinkWithoutHash) {
        params = pull(pathname.split('&'), '/link');
      }
      const url = params.join('&');
      history.push(`${LINK_LOCATION}?${url}`);
    } catch (e) {
      console.debug(e);
    }
  }

  return null;
};

export default Link;
