import { startsWith } from 'lodash';
import { useHistory, useLocation } from 'react-router';

export const UploadWithoutHash: React.FC = () => {
  const { hash, pathname, search } = useLocation();
  const history = useHistory();

  if (!hash.length && pathname.length && startsWith(pathname, '/upload')) {
    history.push(`/#upload${search.replace('?', '&')}`);
  }

  return null;
};
