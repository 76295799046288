import { get } from 'lodash';
import { api } from 'utils/api';

export const stopSharing = (transferId: number) =>
  api()
    .post('/api/stop-sharing', { transferId })
    .then(({ data }) => get(data, 'success'))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const createTransferLink = (transferId: number) =>
  api()
    .post('/api/transfer-link', { transferId })
    .then(({ data }) => get(data, 'link'))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));
