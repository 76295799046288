import { get } from 'lodash';
import { api } from 'utils/api';
import {
  ITransferStartResponse,
  ITransferUploadStartResponse,
  IUploadStartData,
  ITransferConfirmData,
  ITransferConfirmResponse,
  ITransferUploadDoneData,
  IUploadStartResponse,
  IUnauthorizedUploadDoneData,
  IRemoteSourceUploadData,
  ITransferUploadInfoResponse,
} from './_types';

export const transferStart = async (
  senderEmail: string,
  senderOrganization?: number
): Promise<ITransferStartResponse | false> => {
  try {
    return await api()
      .post(`/api/transfer-start`, {
        senderEmail,
        ...(senderOrganization ? { senderOrganization } : {}),
      })
      .then(({ data }) => (get(data, 'success') ? data : Promise.reject()))
      .catch((_error) => Promise.reject());
  } catch (e) {
    return false;
  }
};

export const getTransferUploadInfo = async (
  transferId: number,
  code: string,
  prefixes: string[]
): Promise<ITransferUploadInfoResponse | false> => {
  try {
    return await api()
      .post(`/api/transfer-upload-info`, {
        transferId,
        code,
        prefixes,
      })
      .then(({ data }) => (data ? data : Promise.reject()))
      .catch((_error) => Promise.reject());
  } catch (e) {
    return false;
  }
};

export const transferUploadStart = async (
  uploadStartData: IUploadStartData
): Promise<ITransferUploadStartResponse | false> => {
  try {
    return await api()
      .post(`/api/transfer-upload-start`, uploadStartData)
      .then(({ data }) => (get(data, 'success') ? data : Promise.reject()))
      .catch((_error) => Promise.reject());
  } catch (e) {
    return false;
  }
};

export const transferConfirm = async (
  transferConfirmData: ITransferConfirmData
): Promise<ITransferConfirmResponse | false> => {
  try {
    return await api()
      .post(`/api/transfer-confirm`, transferConfirmData)
      .then(({ data }) => (get(data, 'success') ? data : Promise.reject()))
      .catch((_error) => {
        console.log(_error);
        return Promise.reject();
      });
  } catch (e) {
    return false;
  }
};

export const transferUploadDone = async (
  transferUploadDoneData: ITransferUploadDoneData
): Promise<boolean> => {
  try {
    return await api()
      .post(`/api/transfer-upload-done`, transferUploadDoneData)
      .then(({ data }) => (get(data, 'success') ? true : Promise.reject()))
      .catch((_error) => Promise.reject());
  } catch (e) {
    return false;
  }
};

export const unauthorizedUploadStart = async (
  recipientEmail: string,
  recipientPhone: string,
  note: string,
  senderEmail: string,
  senderName: string,
  message: string,
  sendAt: string | null = null
): Promise<IUploadStartResponse | false> => {
  try {
    return await api()
      .post(`/api/upload-start`, {
        otherRecipient: null,
        recipientEmail,
        recipientName: null,
        recipientPhone,
        note,
        senderEmail,
        senderName,
        sendAt,
        ...(message ? { messages: { '1': message } } : {}),
      })
      .then(({ data }) => (get(data, 'numberOfRecipientsUsed') ? data : false))
      .catch((_error) => Promise.reject());
  } catch (e) {
    return false;
  }
};

export const unauthorizedUploadDone = async (
  unauthorizedUploadDoneData: IUnauthorizedUploadDoneData
): Promise<boolean> => {
  try {
    return await api()
      .post(`/api/upload-done`, unauthorizedUploadDoneData)
      .then(({ data }) => (get(data, 'success') ? true : Promise.reject()))
      .catch((_error) => Promise.reject());
  } catch (e) {
    return false;
  }
};

export const remoteSourceUpload = async (
  remoteSourceUploadData: IRemoteSourceUploadData
): Promise<any> => {
  try {
    return await api()
      .post('/api/remote-source-upload', remoteSourceUploadData)
      .then(({ data }) => (get(data, 'success') ? true : false))
      .catch((_error) => false);
  } catch (e) {}
};

export const tokenDecrypt = async (
  operation: string,
  token: string,
  dateFormat: string = 'yyyy-MM-dd',
  timeFormat: string = 'HH:mm:ss',
  dateTimeFormat: string = 'yyyy-MM-dd HH:mm:ss.SSS'
): Promise<any> => {
  try {
    return await api()
      .post('/api/decrypt-token', { operation, token, dateFormat, dateTimeFormat, timeFormat })
      .then(({ data }) => data)
      .catch((_error) => false);
  } catch (e) {}
};

export const limitsExceededNotification = async (
  senderId: number | null,
  senderName: string,
  senderEmail: string,
  bytes: number,
  organizationId: number | null
): Promise<any> => {
  try {
    return await api()
      .post(`/api/limits-exceeded-notification`, {
        senderId,
        senderName,
        senderEmail,
        bytes,
        organizationId,
      })
      .then(() => true)
      .catch((_error) => false);
  } catch (e) {
    return false;
  }
};

export const getServerTime = async (): Promise<any> => {
  try {
    return await api()
      .post(`/api/time`)
      .then(({ data }) => get(data, 'time', false))
      .catch((_error) => false);
  } catch (e) {
    return false;
  }
};
