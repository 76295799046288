import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notifications: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: '40vw'
      }
    },
    unread: {
      background: theme.palette.primary.light,
      color: theme.palette.primary.contrastText
    },
    noNotifications: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    }
  })
);

export default useStyles;
