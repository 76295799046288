import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, palette: { primary: { main } } }: Theme) =>
  createStyles({
    textPage: {
      lineHeight: 1.75,
      '& h1, h2': {
        color: main,
        '&:first-child': {
          marginTop: 0,
          lineHeight: 1
        }
      },
      '& table': {
        borderCollapse: 'collapse',
        margin: `${spacing(2)}px auto`,
        maxWidth: '80%',
        '& tbody': {
          '& tr': {
            '&:nth-child(odd)': {
              '& td': {
                background: '#ddd'
              }
            }
          }
        },
        '& th, td': {
          padding: spacing(1),
          border: '1px solid #ddd'
        }
      },
      '& strong': {
        color: main
      },
      '& p:last-child': {
        marginBottom: 0
      }
    }
  })
);

export default useStyles;
