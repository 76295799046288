import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import { Grid, Button } from '@material-ui/core';

import { register } from './_api';
import useValidationSchema from './_form';
import { RegistrationFormValues } from './_types';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import useAlerts from 'components/Alerts/useAlerts';
import useLoader from 'components/Loader/useLoader';
import InputField from 'components/form/Input/Input';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';
import CheckboxField from 'components/form/Checkbox/Checkbox';
import { REGISTRATION_ERROR_EMAIL_EXISTS } from 'constants/constants';
import { get } from 'lodash';

const Registration: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { toggleLoader } = useLoader();
  const RegistrationFormSchema = useValidationSchema();
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const { captchaEnabled, generateCaptchaToken } = useRecaptcha();

  const onSubmit = async (
    values: RegistrationFormValues,
    actions: FormikHelpers<RegistrationFormValues>
  ) => {
    toggleLoader();
    let recaptcha;
    if (captchaEnabled) {
      recaptcha = await generateCaptchaToken('registration');
    }

    try {
      const response = await register({ ...values, ...(captchaEnabled ? { recaptcha } : {}) });
      if (response) {
        addSuccessAlert(t('login.registrationSucceeded'));
        history.push('/user-home?registration=true');
      }
    } catch (error) {
      addErrorAlert(
        t(
          get(error, 'errorMessage') === REGISTRATION_ERROR_EMAIL_EXISTS
            ? 'login.registrationFailedEmailExists'
            : 'login.registrationFailed'
        )
      );
    }
    toggleLoader(false);
  };

  return (
    <Formik
      initialValues={{
        email: '',
        name: '',
        personalData: false,
        termsOfUse: false,
      }}
      onSubmit={onSubmit}
      validationSchema={RegistrationFormSchema}
    >
      {({ isSubmitting, handleSubmit }) => (
        <WhiteBox title={t('login.registration')}>
          <form onSubmit={handleSubmit}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <InputField name="email" label={t('login.email')} autoComplete="email" />
              </Grid>
              <Grid item={true} xs={12}>
                <InputField name="name" label={t('login.nameAndSurname')} autoComplete="nam" />
              </Grid>
              <Grid item={true} xs={12}>
                <CheckboxField inline={true} name="personalData" label={t(`login.personalData`)} />
                <CheckboxField
                  name="termsOfUse"
                  label={
                    <div>
                      {t('login.termsOfUse1')}{' '}
                      <Link to="/terms-of-use">{t('login.termsOfUse2')}</Link>{' '}
                      {t('login.termsOfUse3')}
                    </div>
                  }
                />{' '}
              </Grid>
              <Grid item={true} xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth={true}
                >
                  {t('login.registerAction')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </WhiteBox>
      )}
    </Formik>
  );
};

export default Registration;
