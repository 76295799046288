import React from 'react';
import { includes, get, startsWith } from 'lodash';
import { useTranslation } from 'react-i18next';
import useConfig from 'utils/hooks/useConfig';
import useUserInfo from 'utils/hooks/useUserInfo';

import useStyles from './_styles';

export const BottomBanner: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { getConfigValue } = useConfig();
  const { userGroup } = useUserInfo();
  const bottomBannerConfig = getConfigValue('advertisements.bottom');
  const showBottomBanner =
    get(bottomBannerConfig, 'show', false) && includes(['L0', 'L2'], userGroup);
  const link = get(bottomBannerConfig, 'link', '');

  return showBottomBanner ? (
    <div className={classes.bottomWrapper}>
      {!get(bottomBannerConfig, 'hideAdvertLabel', false) ? (
        <div className={classes.bannerLabel}>{t('common.advert')}</div>
      ) : null}
      <a
        href={link}
        target={startsWith(link, 'http') ? '_blank' : undefined}
        rel="noopener noreferrer"
        className={classes.bottom}
        style={{
          backgroundImage: `url('${process.env.PUBLIC_URL}${get(
            bottomBannerConfig,
            'imgUrl',
            ''
          )}')`,
        }}
      >
        <span className={classes.bottomText}>{get(bottomBannerConfig, 'hiddenText', '')}</span>
      </a>
      {get(bottomBannerConfig, 'showVideoUnderBanner') ? (
        <div className={classes.embed}>
          <div className={classes.embedContainer} id="video">
            <iframe
              title="Video"
              src={`https://www.youtube.com/embed/${get(bottomBannerConfig, 'youtubeID')}`}
              frameBorder={0}
              allowFullScreen={true}
            ></iframe>
          </div>
        </div>
      ) : null}
    </div>
  ) : null;
};
