import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing }) => {
  return createStyles({
    dialogTitleRoot: {
      paddingTop: spacing(1),
      paddingBottom: spacing(1),
    },
    dialogTitle: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  });
});

export default useStyles;
