import React, { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Grid, Button, Container, Tooltip, IconButton } from '@material-ui/core';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';
import CheckboxField from 'components/form/Checkbox/Checkbox';
import { useTranslation } from 'react-i18next';
import useAppInfo from 'utils/hooks/useAppInfo';
import { get, isEmpty } from 'lodash';
import { TOTP_FLAG_2FA, TOTP_FLAG_SECOND_TRANSFER_CHANNEL } from 'constants/constants';
import { shallowEqual, useSelector } from 'react-redux';
import LockIcon from '@material-ui/icons/Lock';
import useStyles from './_styles';
import { changeTotp, changeTotpRequest, updateTotp } from './_api';
import useLoader from 'components/Loader/useLoader';
import Title from 'components/Title/Title';
import SMSConfirmDialog from './SMSConfirmdialog';
import QRCodeDialog from './QRCodeDialog';
import { getBinaryMapFromOptions, getCheckedOptionsFromBinaryMap } from 'utils/totpFunctions';
import HelpIcon from '@material-ui/icons/Help';
import TotpHelpDialog from './TotpHelpDialog';
import useFetchAndStoreUser from 'utils/hooks/useFetchAndStoreUser';
import useAlerts from 'components/Alerts/useAlerts';
import { ITotpValues } from './_types';

const Totp: React.FC = () => {
  const { t } = useTranslation();
  const { mfa } = useAppInfo();
  const classes = useStyles();
  const { toggleLoader } = useLoader();
  const { fetchAndStoreUser } = useFetchAndStoreUser();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const [openSmsConfirmDialog, setOpenSmsConfirmDialog] = useState(false);
  const [openQRDialog, setOpenQRDialog] = useState(false);
  const [qrSecretKey, setQrSecretKey] = useState({});
  const [generateSecretKey, setGenerateSecretKey] = useState(false);
  const [updateTotpObject, setUpdateTotpObject] = useState({ smsCode: null, flags: 0 });
  const [showHelp, setShowHelp] = useState(false);
  const { totpFlags, totpInited, date, totpDate } = useSelector(
    (state) => get(state, 'user'),
    shallowEqual
  );

  const calculateDifference = () => {
    try {
      if (totpDate === null || totpDate === undefined) {
        return 0;
      } else {
        return Math.abs(new Date(date).getTime() - new Date(totpDate).getTime());
      }
    } catch (e) {
      console.error(e);
      return 0;
    }
  };

  const determinate = () => {
    const seconds = calculateDifference();
    if (seconds > 0 && seconds <= 580000) {
      return false;
    } else {
      return true;
    }
  };

  const useSmsForFormUpdate = determinate();

  const allowedTotpFce =
    get(mfa, 'enabledOps', 0) === 3
      ? [TOTP_FLAG_2FA, TOTP_FLAG_SECOND_TRANSFER_CHANNEL]
      : get(mfa, 'enabledOps', 0) === 2
      ? [TOTP_FLAG_SECOND_TRANSFER_CHANNEL]
      : get(mfa, 'enabledOps', 0) === 1
      ? [TOTP_FLAG_2FA]
      : [];

  const generateNewSecretKey = async () => {
    toggleLoader();
    await fetchAndStoreUser();

    if (useSmsForFormUpdate) {
      const requested = await changeTotpRequest();
      if (requested) {
        setGenerateSecretKey(true);
        setOpenSmsConfirmDialog(true);
      }
    } else {
      const response = await changeTotp({});
      if (response) {
        await fetchAndStoreUser();
        setQrSecretKey(response);
        setOpenQRDialog(true);
        addSuccessAlert(t('profile.totp.dialog.changeTotpSucced'));
      } else {
        addErrorAlert(t('profile.totp.dialog.changeTotpFiled'));
      }
    }

    toggleLoader(false);
  };

  const onSubmit = async (values: ITotpValues, actions: FormikHelpers<ITotpValues>) => {
    toggleLoader();

    await fetchAndStoreUser();

    if (useSmsForFormUpdate) {
      setUpdateTotpObject({
        smsCode: null,
        flags: getBinaryMapFromOptions(get(values, 'totpFunctions')),
      });
      const requested = await changeTotpRequest();
      if (requested) {
        setGenerateSecretKey(false);
        setOpenSmsConfirmDialog(true);
      }
      setOpenSmsConfirmDialog(true);
    } else {
      const response = await updateTotp({
        smsCode: null,
        flags: getBinaryMapFromOptions(get(values, 'totpFunctions')),
      });
      if (response) {
        await fetchAndStoreUser();
        addSuccessAlert(t('profile.totp.dialog.updateTotpSettingsSucced'));
      } else {
        addErrorAlert(t('profile.totp.dialog.updateTotpSettingsFailed'));
      }
    }
    actions.setSubmitting(false);
    toggleLoader(false);
  };

  const closeSmsDialog = async (secretKey: any) => {
    await fetchAndStoreUser();
    setOpenSmsConfirmDialog(false);
    if (generateSecretKey) {
      setQrSecretKey(secretKey);
      setOpenQRDialog(true);
    }
  };

  const closeQrDialog = async () => {
    await fetchAndStoreUser();
    setOpenQRDialog(false);
  };

  const closeHelpDialog = () => {
    setShowHelp(false);
  };

  const initialValues = { totpFunctions: { ...getCheckedOptionsFromBinaryMap(totpFlags) } };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ isSubmitting, handleSubmit }) => (
          <>
            <Title title={t('profile.totp.title')} />
            <Container maxWidth="sm">
              <Grid container={true} spacing={2} justifyContent="center">
                <Grid item={true}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={generateNewSecretKey}
                    className={classes.requestButton}
                  >
                    {totpInited !== true
                      ? t('profile.totp.initializeSecreet')
                      : t('profile.totp.generateNewSecret')}
                  </Button>
                </Grid>
                <Grid item={true}>
                  <Tooltip title={`${t('profile.totp.helpTitle')}`}>
                    <IconButton
                      key="help"
                      aria-label="Help"
                      color="inherit"
                      onClick={() => setShowHelp(true)}
                      style={{ paddingTop: 4 }}
                    >
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Container>
            <WhiteBox>
              <form
                onSubmit={handleSubmit}
                {...(totpInited !== true ? { className: classes.lockedForm } : {})}
              >
                {totpInited !== true && <LockIcon className={classes.lockIcon} />}
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12}>
                    {allowedTotpFce.map((checkbox) => (
                      <CheckboxField
                        key={checkbox}
                        name={`totpFunctions.${checkbox}`}
                        label={t(`profile.totp.checkboxes.${checkbox}`)}
                        disabled={!totpInited}
                      />
                    ))}
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!totpInited || isSubmitting}
                      fullWidth={true}
                    >
                      {t('common.update')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </WhiteBox>
          </>
        )}
      </Formik>
      {openSmsConfirmDialog && (
        <SMSConfirmDialog
          generateSecretKey={generateSecretKey}
          closeSmsDialog={closeSmsDialog}
          updateTotpObject={updateTotpObject}
        />
      )}
      {!isEmpty(qrSecretKey) && openQRDialog && (
        <QRCodeDialog
          qrSecretKey={qrSecretKey}
          closedialog={closeQrDialog}
          openQRDialog={openQRDialog}
        />
      )}
      <TotpHelpDialog closedialog={closeHelpDialog} openDialog={showHelp} />
    </>
  );
};

export default Totp;
