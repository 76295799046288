import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@material-ui/core';
import { Formik, FormikHelpers } from 'formik';
import { each, get, head, isArray } from 'lodash';

import { sendInvitation } from './_api';
import useValidationSchema from './_form';
import { InviteFormValues } from './_types';
import useUserInfo from 'utils/hooks/useUserInfo';
import useAlerts from 'components/Alerts/useAlerts';
import useLoader from 'components/Loader/useLoader';
import InputField from 'components/form/Input/Input';
import SelectField from 'components/form/Select/Select';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';

const Invite: React.FC = () => {
  const { t } = useTranslation();
  const { toggleLoader } = useLoader();
  const { InviteFormSchema } = useValidationSchema();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { actualUserWasInvited } = useUserInfo();
  const history = useHistory();

  useEffect(() => {
    if (actualUserWasInvited) {
      history.push('/');
    }
  });

  const onSubmit = async (
    values: InviteFormValues,
    { resetForm }: FormikHelpers<InviteFormValues>
  ) => {
    toggleLoader();
    try {
      let response = await sendInvitation({ ...values, name: values.name || null });
      if (response.success) {
        addSuccessAlert(t('invite.invitationSent'));
        resetForm();
      } else {
        if (response.message) {
          addErrorAlert(t(`invite.responseErrors.${response.message}`));
        } else {
          throw new Error(response.message);
        }
      }
    } catch (e) {
      addErrorAlert(t('invite.invitationFailed'));
    }
    toggleLoader(false);
  };

  let mappedOrganisationsForInvitations: any = [];
  const { organizations } = useUserInfo();

  if (isArray(organizations)) {
    each(organizations, (organization) => {
      if ((get(organization, 'flags', 0) & 8) === 8) {
        mappedOrganisationsForInvitations.push({ id: organization.id, label: organization.name });
      }
    });
  }

  const initialValues = {
    name: '',
    email: '',
    organization:
      isArray(mappedOrganisationsForInvitations) && mappedOrganisationsForInvitations.length === 1
        ? get(head(mappedOrganisationsForInvitations), 'id')
        : '',
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={InviteFormSchema}>
      {({ isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <WhiteBox title={t('invite.inviteUser')}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <InputField name="email" label={t('invite.email')} />
              </Grid>

              <Grid item={true} xs={12}>
                <InputField name="name" label={t('invite.name')} />
              </Grid>

              <Grid item={true} xs={12}>
                <SelectField
                  name="organization"
                  label={t('invite.organization')}
                  items={mappedOrganisationsForInvitations}
                />
              </Grid>

              <Grid item={true} xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth={true}
                >
                  {t('invite.sendInvitation')}
                </Button>
              </Grid>
            </Grid>
          </WhiteBox>
        </form>
      )}
    </Formik>
  );
};

export default Invite;
