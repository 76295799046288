import { Formik } from 'formik';
import { isArray, get } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Grid, Button, Container, Dialog, DialogContent, DialogActions } from '@material-ui/core';

import useValidationSchema from './_form';
import Title from 'components/Title/Title';
import { getBlokedRecipients } from './_api';
import useConfig from 'utils/hooks/useConfig';
import useLoader from 'components/Loader/useLoader';
import useAlerts from 'components/Alerts/useAlerts';
import {
  ISearchBlockedRecipientsFormValues,
  IUnblockTransferFormValues,
  IUnblockTransferNoSmsRequiredFormValues,
} from './_types';
import InputField from 'components/form/Input/Input';
import BasicGrid from 'components/BasicGrid/BasicGrid';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';
import { unblockTransfer } from './_api';

const UnblockTransfer: React.FC = () => {
  const { t } = useTranslation();
  const { toggleLoader } = useLoader();
  const [blockedRecipients, setBlockedRecipients] = useState<any>();
  const [selectedBlockedRecipient, setSelectedBlockedRecipient] = useState<any>();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { getConfigValue } = useConfig();
  const {
    SearchBlockedRecipientsValidationSchema,
    UnblockTransferValidationSchema,
    UnblockTransferNoSmsRequiredValidationSchema,
  } = useValidationSchema();

  const SMSrequired = getConfigValue('appendix.download.unblock.smsRequired');

  const onSubmit = async ({ transferId }: ISearchBlockedRecipientsFormValues) => {
    toggleLoader();
    try {
      const blockedRecipientsResponse = await getBlokedRecipients(
        parseInt(transferId as string, 10)
      );
      setBlockedRecipients(
        isArray(blockedRecipientsResponse) && blockedRecipientsResponse.length
          ? blockedRecipientsResponse.map((email) => ({ email, transferId }))
          : []
      );
    } catch (e) {
      addErrorAlert(t('unblockTransfer.fail'));
    }
    toggleLoader(false);
  };

  const onSubmitUnblockTransfer = async (
    values: IUnblockTransferFormValues | IUnblockTransferNoSmsRequiredFormValues
  ) => {
    toggleLoader();
    let response: any = true;
    try {
      response = await unblockTransfer({
        transferId: parseInt(values.transferId, 10),
        recipientCode: values.emailCode,
        recipientEmail: values.recipientEmail,
        ...(SMSrequired ? { smsCode: get(values, 'smsCode') } : {}),
      });
    } catch (error) {
      response = error;
    }
    if (response) {
      addSuccessAlert(t('unblockTransfer.success'));
      setSelectedBlockedRecipient(undefined);
    } else {
      addErrorAlert(t('unblockTransfer.fail'));
    }

    toggleLoader(false);
  };

  return (
    <>
      <Formik
        initialValues={{ transferId: '' }}
        onSubmit={onSubmit}
        validationSchema={SearchBlockedRecipientsValidationSchema}
      >
        {({ isSubmitting, handleSubmit }) => (
          <>
            <Title title={t('unblockTransfer.title')} />

            <WhiteBox>
              <form onSubmit={handleSubmit}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12}>
                    <InputField name="transferId" label={t('unblockTransfer.transferId')} />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth={true}
                    >
                      {t('unblockTransfer.receiveBlockedRecipients')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </WhiteBox>

            {isArray(blockedRecipients) ? (
              <Container maxWidth="sm" style={{ padding: 0, marginTop: 32 }}>
                <Title title={t('unblockTransfer.blockedRecipitens')} />
                {blockedRecipients.length ? (
                  <BasicGrid
                    data={blockedRecipients}
                    disabledContainer={true}
                    columns={[
                      {
                        title: t('unblockTransfer.email'),
                        field: 'email',
                      },
                    ]}
                    actions={[
                      {
                        icon: LockOpenIcon,
                        iconProps: {
                          color: 'primary',
                          fontSize: 'large',
                        },
                        tooltip: t('unblockTransfer.unblock'),
                        onClick: (_event: any, rowData: any) =>
                          setSelectedBlockedRecipient(rowData),
                        position: 'row',
                      },
                    ]}
                  />
                ) : (
                  <WhiteBox>
                    <div>{t('unblockTransfer.noRecipientsBlocked')}</div>
                  </WhiteBox>
                )}
              </Container>
            ) : null}
          </>
        )}
      </Formik>

      {selectedBlockedRecipient !== undefined ? (
        <Formik
          initialValues={{
            transferId: selectedBlockedRecipient.transferId,
            recipientEmail: selectedBlockedRecipient.email,
            emailCode: '',
            ...(SMSrequired ? { smsCode: '' } : {}),
          }}
          onSubmit={onSubmitUnblockTransfer}
          validationSchema={
            SMSrequired
              ? UnblockTransferValidationSchema
              : UnblockTransferNoSmsRequiredValidationSchema
          }
        >
          {({ isSubmitting, handleSubmit }) => (
            <Dialog
              maxWidth="sm"
              fullWidth={true}
              aria-labelledby="confirmation-dialog-title"
              open={true}
              onClose={() => setSelectedBlockedRecipient(undefined)}
            >
              <DialogContent>
                <form onSubmit={handleSubmit}>
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12}>
                      <InputField
                        name="transferId"
                        label={t('unblockTransfer.transferId')}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <InputField
                        name="recipientEmail"
                        label={t('unblockTransfer.email')}
                        disabled={true}
                      />
                    </Grid>
                    {SMSrequired ? (
                      <Grid item={true} xs={12}>
                        <InputField name="smsCode" label={t('unblockTransfer.smsCode')} />
                      </Grid>
                    ) : null}
                    <Grid item={true} xs={12}>
                      <InputField name="emailCode" label={t('unblockTransfer.emailCode')} />
                    </Grid>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth={true}
                    >
                      {t('unblockTransfer.unblock')}
                    </Button>
                  </Grid>
                </form>
              </DialogContent>
              <DialogActions />
            </Dialog>
          )}
        </Formik>
      ) : null}
    </>
  );
};
export default UnblockTransfer;
