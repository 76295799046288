import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    tiles: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center'
    },
    info: {
      fontSize: 24,
      color: '#fff',
      fontWeight: 'bold',
      textAlign: 'center',
      background: '#43A047',
      padding: theme.spacing(2)
    },
    danger: {
      fontSize: 24,
      color: '#fff',
      fontWeight: 'bold',
      textAlign: 'center',
      background: '#F44336',
      padding: theme.spacing(2)
    }
  });
});

export default useStyles;
