import React from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';

import { ILinkDialog } from './_types';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import DialogTitleWithClose from 'components/DialogTitleWithClose/DialogTitleWithClose';

const LinkDialog: React.FC<ILinkDialog> = ({ title, content, onClose }) => {
  const { t } = useTranslation();

  const copyAndClose = () => {
    try {
      copy(content);
    } catch (e) {
      console.debug(e);
    }
    onClose();
  };

  return (
    <Dialog maxWidth="lg" aria-labelledby="link-dialog-title" open={true} onClose={copyAndClose}>
      <DialogTitleWithClose title={title} closeDialogFn={copyAndClose} />
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={copyAndClose}>
          {t('common.copyAndClose')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LinkDialog;
