import React from 'react';
import { isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import { LinearProgress, Tooltip } from '@material-ui/core';

import useStyles from './_styles';
import { ILimitProgressBar } from './_types';
import useTransfer from 'utils/hooks/useTransfer';

const LimitProgressBar: React.SFC<ILimitProgressBar> = ({ used, max }) => {
  const { formatFileSize } = useTransfer();
  const { t } = useTranslation();

  let progress;
  let spaceLeft;

  try {
    progress = Math.floor((used * 100) / max);
    spaceLeft = formatFileSize(Math.abs(max - used), true, { GB: 2, MB: 0, KB: 0 });
  } catch (e) {
    progress = null;
    spaceLeft = '';
  }

  const classes = useStyles();
  return isNumber(progress) ? (
    <Tooltip title={`${t(used > max ? 'files.limitExceded' : 'files.spaceLeft')}: ${spaceLeft}`}>
      <div className={classes.progressBarContainer}>
        <LinearProgress
          variant="determinate"
          value={progress > 100 ? 100 : progress}
          color="primary"
          classes={{ root: classes.progressBarRoot, bar: classes.progressBar }}
        />
        <div>
          {t('files.used')}: {formatFileSize(used, true, { GB: 2, MB: 0, KB: 0 })}/
          {formatFileSize(max, true, { GB: 2, MB: 0, KB: 0 })}
        </div>
      </div>
    </Tooltip>
  ) : null;
};

export default LimitProgressBar;
