import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './_styles';
import { Typography, Button } from '@material-ui/core';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';
import useHomePage from 'utils/hooks/useHomePage';

const Page404: React.FC = () => {
  const homeUrlPage = useHomePage();
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <WhiteBox maxWidth="lg">
      <Typography variant="h3" align="center">
        {t('404.title')}
      </Typography>
      <Typography>
        <Typography variant="h5" className={classes.content}>
          {t('404.content')}
        </Typography>
        <Button variant="contained" href={homeUrlPage}>
          {t('404.link')}
        </Button>
      </Typography>
    </WhiteBox>
  );
};

export default Page404;
