import { get, isObject } from 'lodash';
import { api } from 'utils/api';

export const addOrganization = (item: any, op: string = 'create') =>
  api()
    .post(`/api/ds-organization`, { item, op })
    .then(({ data }) => get(data, 'id'))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const updateOrganization = (item: any): Promise<any | boolean> =>
  api()
    .post(`/api/update-organization`, item)
    .then(({ data }) => (isObject(data) ? data : false))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const getOrganizations = () =>
  api()
    .post(`/api/list-all-organizations`)
    .then(({ data }) => (get(data, 'total') ? get(data, 'items') : []))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const getOrganization = (item: any, op: string = 'read', convert: boolean = true) =>
  api()
    .post(`/api/ds-organization`, { convert, item, op })
    .then(({ data }) => data)
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const getUserGroups = (op: string = 'list') =>
  api()
    .post(`/api/ds-user-groups`, { op })
    .then(({ data }) => (get(data, 'total') ? get(data, 'items') : []))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const getAllUsers = (op: string = 'list', convert: Boolean = false, filter: string = '') =>
  api()
    .post(`/api/ds-user`, { op, convert, filter })
    .then(({ data }) => (get(data, 'total') ? get(data, 'items') : []))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const deleteSingleUser = (data: any) =>
  api()
    .post(`/api/ds-user`, data)
    .then(({ data }) => get(data, 'userId'))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const getAllDomains = (op: string = 'list', filter: string = '') =>
  api()
    .post(`/api/ds-domain`, { op, filter })
    .then(({ data }) => (get(data, 'total') ? get(data, 'items') : []))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const deleteOrganization = (item: any) =>
  api()
    .post(`/api/ds-organization`, { item, op: 'delete' })
    .then(({ data }) => (get(data, 'total') ? get(data, 'items') : []))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const revertOrganization = (item: any) =>
  api()
    .post(`/api/ds-organization`, { item, op: 'revert' })
    .then(({ data }) => (get(data, 'total') ? get(data, 'items') : []))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const getWorkplaces = () =>
  api()
    .post(`/api/ds-workplace`, { op: 'list' })
    .then(({ data }) => (get(data, 'total') ? get(data, 'items') : []))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const getWorkplaceOrgConfig = (item: any, op: string = 'list') =>
  api()
    .post(`/api/ds-workplace-organization-config`, { ...item, op })
    .then(({ data }) => (get(data, 'total') ? get(data, 'items') : []))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));
