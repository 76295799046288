import React from 'react';
import { isArray, get } from 'lodash';
import { SelectProps } from '@material-ui/core/Select';
import { FieldProps, Field, FieldConfig } from 'formik';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ListItemIcon,
  FormHelperText,
} from '@material-ui/core';

import useStyles from './_styles';
import { ISelectField, ISelectItem } from './_types';

const CustomSelect: React.FC<FieldProps<any> & ISelectField> = ({
  field,
  form: { touched, errors },
  items,
  isCountriesPrefixesList = false,
  ...props
}) => {
  const classes = useStyles();
  const filedError = errors[field.name];
  return (
    <FormControl classes={{ root: classes.formControl }}>
      <InputLabel htmlFor={field.name}>{props.label}</InputLabel>
      <Select
        {...field}
        {...props}
        id={field.name}
        error={touched[field.name] && Boolean(filedError)}
        inputProps={{
          name: field.name,
          id: field.name,
        }}
      >
        {isArray(items)
          ? items.map((item: ISelectItem) => (
              <MenuItem key={item.id} value={get(item, 'id')}>
                {isCountriesPrefixesList && (
                  <ListItemIcon classes={{ root: classes.listItemIcon }}>
                    <div>
                      <img
                        src={`${process.env.PUBLIC_URL}/img/flags/${get(item, 'id').replace(
                          '+',
                          ''
                        )}.png`}
                        alt={item.label}
                      />
                    </div>
                  </ListItemIcon>
                )}
                {item.label}
              </MenuItem>
            ))
          : null}
      </Select>
      {touched[field.name] && Boolean(filedError) ? (
        <FormHelperText>{filedError}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

const SelectField: React.FC<ISelectField & SelectProps & FieldConfig> = (props) => (
  <Field {...props} component={CustomSelect} />
);

export default SelectField;
