import React from 'react';
import { isObject, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogActions, Button, Grid } from '@material-ui/core';

import useValidationSchema from './_form';
import { Formik, FormikHelpers } from 'formik';
import { updateRow } from './_api';
import InputField from 'components/form/Input/Input';
import { IRowFormValues, IRowForm } from './_types';
import useAlerts from 'components/Alerts/useAlerts';
import useLoader from 'components/Loader/useLoader';
import DialogTitleWithClose from 'components/DialogTitleWithClose/DialogTitleWithClose';

const RowForm: React.FC<IRowForm> = ({ closeDialog, row, getAllDatabaseRows }) => {
  const { t } = useTranslation();
  const DatabaseRowFormSchema = useValidationSchema();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { toggleLoader } = useLoader();

  let initialValues = isObject(row)
    ? { ...row }
    : {
        configId: '',
        type: '',
        value: '',
        reload: false,
      };

  const onRowEdit = async (values: IRowFormValues, actions: FormikHelpers<IRowFormValues>) => {
    toggleLoader();
    try {
      await updateRow({
        configId: get(values, 'configId', null),
        value: get(values, 'value', null),
        unit: get(values, 'unit', null),
        type: get(values, 'type', null),
        reload: get(values, 'reload', null),
      });
      await getAllDatabaseRows();
      closeDialog();
      addSuccessAlert(t('databaseManage.editRowSucceeded') as string);
    } catch (e) {
      addErrorAlert(t('databaseManage.editRowFailed') as string);
    }
    toggleLoader(false);
  };

  return (
    <Formik
      onSubmit={onRowEdit}
      initialValues={initialValues}
      validationSchema={DatabaseRowFormSchema}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          aria-labelledby="confirmation-dialog-title"
          open={true}
          onClose={closeDialog}
        >
          <DialogTitleWithClose
            title={t('databaseManage.editRow') as string}
            closeDialogFn={closeDialog}
          />
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <InputField
                    name="configId"
                    label={t('databaseManage.databaseConfiguration.configId') as string}
                    disabled={true}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <InputField
                    name="type"
                    label={t('databaseManage.databaseConfiguration.type') as string}
                    disabled={true}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <InputField
                    name="value"
                    label={t('databaseManage.databaseConfiguration.value') as string}
                    {...(get(row, 'unit') ? { endAdornment: get(row, 'unit') } : {})}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth={true}
                    disabled={isSubmitting}
                  >
                    {t('databaseManage.editRow')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions />
        </Dialog>
      )}
    </Formik>
  );
};

export default RowForm;
