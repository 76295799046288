import React from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';

import { IConfirm } from './_types';
import { useTranslation } from 'react-i18next';
import DialogTitleWithClose from 'components/DialogTitleWithClose/DialogTitleWithClose';

const Confirm: React.FC<IConfirm> = ({ title, content, onClose, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Dialog maxWidth="sm" aria-labelledby="confirmation-dialog-title" open={true} onClose={onClose}>
      <DialogTitleWithClose title={title} closeDialogFn={onClose} />
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          {t('common.yes')}
        </Button>
        <Button variant="contained" onClick={onClose}>
          {t('common.no')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirm;
