import React from 'react';
import { get } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';

import useStyles from './_styles';
import { IAlert } from './_types';
import { RemoveAlert } from './_redux';

const Alerts: React.FC = () => {
  const dispatch = useDispatch();
  const alerts: IAlert[] = useSelector(state => get(state, 'app.alerts'), shallowEqual);

  const closeAlert = (id: string) => {
    dispatch(RemoveAlert(id));
  };

  const classes = useStyles();

  return (
    <div>
      {alerts.map((alert: IAlert) => (
        <Snackbar
          key={alert.id}
          autoHideDuration={alert.hideDuration}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={true}
          disableWindowBlurListener={true}
          onClose={() => closeAlert(alert.id)}
          ClickAwayListenerProps={{ onClickAway: () => false }}
        >
          <SnackbarContent
            className={classes[alert.type]}
            message={<div>{get(alert, 'text', '')}</div>}
            action={
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => closeAlert(alert.id)}
              >
                <CloseIcon />
              </IconButton>
            }
          />
        </Snackbar>
      ))}
    </div>
  );
};

export default React.memo(Alerts);
