import { getBackendConfig } from 'components/Bootstrap/_api';
import { isObject } from 'lodash';
import { useDispatch } from 'react-redux';
import { StoreBackendConfig } from 'modules/Login/_redux';

const useFetchAndStoreBackendConfig = () => {
  const dispatch = useDispatch();

  const fetchAndStoreBackendConfig = async () => {
    const backendConfig = await getBackendConfig();
    if (isObject(backendConfig)) {
      dispatch(StoreBackendConfig(backendConfig));
      return true;
    } else {
      return Promise.reject('Cannot fetch backend configuration');
    }
  };

  return { fetchAndStoreBackendConfig };
};

export default useFetchAndStoreBackendConfig;
