import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { get, pick, isObject, find, set } from 'lodash';
import { IFrontendConfig, IBackendConfig } from 'modules/Login/_types';
import {
  BE_CONFIG_EXPIRATION,
  BE_CONFIG_MAXTRANSFERSIZE,
  BE_CONFIG_MAXTOTALSIZE,
  BE_CONFIG_TRANSFERSPERDAY,
  BE_VIRTUAL_CONFIG_TRANSFERSPERMONTH,
  BE_CONFIG_DICOMVIEWER,
  BE_CONFIG_MAXRECIPIENTS,
  BE_CONFIG_MESSAGES,
  BE_CONFIG_MAXTRANSFERDELAY,
} from 'constants/constants';

const useAppInfo = (): Partial<
  IFrontendConfig & IBackendConfig & { configLimits: any } & { mfa: any }
> => {
  const { t } = useTranslation();
  const version = useSelector((state) => get(state, 'app.version'));
  const backendConfig = useSelector((state) => get(state, 'app.configBackend'));
  const frontendConfig = useSelector((state) => get(state, 'app.configFrontend'));
  const items = get(backendConfig, 'items', []);
  let limits: any = {};
  const dicomViewerExists = get(backendConfig, 'appendix.download.dicomViewer.type', '').length;

  const mfa = get(backendConfig, 'appendix.authentication.mfa', {});

  [
    BE_CONFIG_EXPIRATION,
    BE_CONFIG_MAXTRANSFERSIZE,
    BE_CONFIG_MAXTOTALSIZE,
    BE_CONFIG_TRANSFERSPERDAY,
    BE_CONFIG_DICOMVIEWER,
    BE_CONFIG_MAXRECIPIENTS,
    BE_CONFIG_MESSAGES,
    BE_CONFIG_MAXTRANSFERDELAY,
  ].forEach((param: string) => {
    const levels = Array.from(Array(7).keys());
    levels.forEach((level: number) => {
      const key = `Limits.L${level}.${param}`;
      const value = get(find(items, { configId: key }), 'value');
      let alteredValue = value;

      // Days translated
      if (param === BE_CONFIG_EXPIRATION) {
        alteredValue = t('backendConfig.days', { count: parseInt(value) });
      }

      // Transfer size with MB
      if (param === BE_CONFIG_MAXTRANSFERSIZE || param === BE_CONFIG_MAXTOTALSIZE) {
        if (!value) {
          alteredValue = '-';
        } else if (value === '0') {
          alteredValue = t('backendConfig.unlimited');
        } else {
          alteredValue = t('backendConfig.maxTransferSize', { size: value });
        }
      }

      // Transfers per day
      if (param === BE_CONFIG_TRANSFERSPERDAY && value === '0') {
        alteredValue = t('backendConfig.unlimited');
      }
      if (param === BE_CONFIG_TRANSFERSPERDAY) {
        let monthValue = alteredValue;
        if (monthValue !== t('backendConfig.unlimited')) {
          let monthValueInt = Number(alteredValue) * 30;
          monthValue = monthValueInt.toString();
        }
        let monthParamName = BE_VIRTUAL_CONFIG_TRANSFERSPERMONTH;
        set(limits, `Limits.L${level}.${monthParamName}`, monthValue);
      }

      if (param === BE_CONFIG_DICOMVIEWER) {
        alteredValue =
          value === 'true' && dicomViewerExists
            ? t('backendConfig.true')
            : t('backendConfig.false');
      }

      if (param === BE_CONFIG_MAXRECIPIENTS) {
        alteredValue =
          value === '0' || parseInt(value) > 1 ? t('backendConfig.true') : t('backendConfig.false');
      }

      if (param === BE_CONFIG_MESSAGES) {
        alteredValue = value === 'true' ? t('backendConfig.true') : t('backendConfig.false');
      }

      if (param === BE_CONFIG_MAXTRANSFERDELAY) {
        if (!value || value === '0') {
          alteredValue = '-';
        } else {
          alteredValue = t('backendConfig.days', { count: parseInt(value) });
        }
      }

      set(limits, key, alteredValue);
    });
  });

  return {
    version,
    mfa,
    ...(isObject(frontendConfig) ? frontendConfig : {}),
    ...(isObject(backendConfig)
      ? pick(get(backendConfig, 'appendix'), ['cryptoAlgorithm', 'cryptoMode', 'cryptoBits'])
      : {}),
    configLimits: get(limits, 'Limits'),
  };
};

export default useAppInfo;
