import * as yup from 'yup';

import { PASSWORD_REGEX } from 'constants/constants';
import useValidators from 'components/form/validations/useValidators';

const useValidationSchema = () => {
  const { required, fieldsMatch, matchesRegex, min } = useValidators();

  const PasswordChangeFormSchema = yup.object().shape({
    oldPass: required(yup.string()),
    newPass: matchesRegex(
      min(required(yup.string()), 8),
      PASSWORD_REGEX,
      'validations.passwordSecure'
    ),
    newPassCopy: matchesRegex(
      fieldsMatch(required(yup.string()), 'newPass', 'validations.fieldsDontMatch'),
      PASSWORD_REGEX,
      'validations.passwordSecure'
    ),
    verificationCode: required(yup.string())
  });

  return PasswordChangeFormSchema;
};

export default useValidationSchema;
