import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Card, CardContent, CardActions, Button, Typography } from '@material-ui/core';

import useStyles from './_styles';
import useStorage from 'utils/hooks/useStorage';

const AppError: React.FC = () => {
  const classes = useStyles();
  const { clear } = useStorage();
  const { t } = useTranslation();

  return (
    <div className={classes.appError}>
      <Grid container={true} justifyContent="center">
        <Grid item={true} sm={12} md={8} lg={6} xl={4}>
          <Card>
            <CardContent>
              <Typography variant="h5" className={classes.title}>
                {t('login.errorPage.title')}
              </Typography>
              <Typography>{t('login.errorPage.text')}</Typography>
            </CardContent>
            <CardActions className={classes.actions}>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => {
                  clear();
                  window.location.reload();
                }}
              >
                {t('login.errorPage.reloadPage')}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default AppError;
