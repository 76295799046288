import * as yup from 'yup';
import useValidators from 'components/form/validations/useValidators';

const useValidationSchema = () => {
  const { required, email } = useValidators();

  const InviteFormSchema = yup.object().shape({
    name: yup.string(),
    email: email(required(yup.string())),
    organization: required(yup.string()),
  });

  return { InviteFormSchema };
};

export default useValidationSchema;
