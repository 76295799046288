import { useHistory } from 'react-router';
import { parseISO, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { get, isArray, isNull, isNumber, isEmpty, sumBy, find, head } from 'lodash';
import xss from 'xss';

import { getTransferDetail } from 'modules/TransferDetail/_api';
import { IFile, ITransferDetail } from 'modules/TransferDetail/_types';
import {
  getTransferFiles,
  checkIfLoginRequired,
  getSecretTransferNote,
  logoutActualUser,
  getTransferMessage,
  delivered,
  sendSecondPartOfTransferKey,
} from './_api';
import KeyConfirmationDialog from './KeyConfigmationDialog';
import useUrl from 'utils/hooks/useUrl';
import useFetchAndStoreUser from 'utils/hooks/useFetchAndStoreUser';
import Login from 'modules/Login/Login';
import BasicGrid from 'components/BasicGrid/BasicGrid';
import useTransfer from 'utils/hooks/useTransfer';
import {
  Grid,
  Button,
  Container,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
} from '@material-ui/core';
import useStyles from './_styles';

import Title from 'components/Title/Title';
import useStorage from 'utils/hooks/useStorage';
import { nopAPi } from 'components/Bootstrap/_api';
import useAlerts from 'components/Alerts/useAlerts';
import FileTree from 'components/FileTree/FileTree';
import LoginSelect from 'modules/Login/LoginSelect';
import useLogin from 'utils/hooks/useLogin';

const createMarkup = (html: any) => ({ __html: html });

const DownloadFiles: React.FC = () => {
  const { t } = useTranslation();
  const { mapAllUrlParamsToObject } = useUrl();
  const history = useHistory();
  const params = mapAllUrlParamsToObject();
  const transferId = parseInt(get(params, 'id', 0), 0); // pokud je 0, zobrazit chybovou hlášku
  const code = get(params, 'code', null);
  const key = get(params, 'key', null);
  const sms = get(params, 'sms', null);
  const reason = get(params, 'reason', null); // pokud neni null, zobrazí se chybová hláška a pokračuje zpracování
  const { addErrorAlert } = useAlerts();
  const [loginRequired, setLoginRequired] = useState<boolean | null>(null);
  const [isTotpMethod, setIsTotpMethod] = useState<boolean | false>(false);
  const [loginPossible, setLoginPossible] = useState<boolean | null>(null);
  const [secondKeyTranslationString, setSecondKeyTranslationString] = useState<string>(
    t('files.downloadFiles.secondKey')
  );
  const [loadedTextMessages, setLoadedTextMessages] = useState<any>({});
  const [username, setUsername] = useState<string>('');
  const [showConfirmdialog, setShowConfirmdialog] = useState<boolean>(false);
  const [keyConfirmed, setKeyConfirmed] = useState<boolean>(false);
  const [hasFiles, setHasFiles] = useState<boolean>(false);
  const [fileTree, fetchFiletree] = useState<any>(null);
  const [transferDetail, fetchTransferDetail] = useState<ITransferDetail[]>();
  const [fileInfoTitle, setFileInfoTitle] = useState<string>('');
  const [showViewerButton, setShowViewerButton] = useState<boolean>(false);
  const [firstKey, setFirstKey] = useState<string | null>(null);
  const [secondKey, setSecondKey] = useState<string | null>(null);
  const { transferHasFiles, getSecondPartOfTheKeyForForm, transferIsTotpMethod } = useTransfer();
  const { getAccessToken } = useStorage();
  const { fetchAndStoreUser } = useFetchAndStoreUser();
  const classes = useStyles();
  const { hasOnlyOneLoginMethod, theOnlyOneLoginMethodIsLocalForm } = useLogin();
  const hasOnlyLocalForm = hasOnlyOneLoginMethod && theOnlyOneLoginMethodIsLocalForm;
  const [localFormSelectedForLogin, setLocalFormSelectedForLogin] = useState<boolean>(false);

  const downloadFile = async (rowData: IFile | null, all: boolean, hash: boolean = false) => {
    let url = '/api/';

    const token = getAccessToken();
    let urlParams = `?${
      token === undefined ? '' : `accessToken=${token}&`
    }id=${transferId}&key=${firstKey}&back=/%23download&sms=${secondKey}${
      code !== null ? `&code=${code}` : ''
    }`;

    if (hash) {
      url += 'file-hash';
      urlParams += `&file=${get(rowData, 'fileId')}`;
    } else if (all) {
      url += 'download';
    } else {
      url += 'download';
      urlParams += `&file=${get(rowData, 'fileId')}`;
    }

    window.location.href = `${url}${urlParams}`;
    await nopAPi();
  };

  const openViewer = () => {
    let urlParams = `id=${transferId}&key=${firstKey}&sms=${secondKey}${
      code !== null ? `&code=${code}` : ''
    }`;
    history.push(`/dicom?${urlParams}`);
  };

  const calculateSize = (bytes: number, decimals: number = 0) => {
    if (bytes === 0) return '0 Bytes';
    var k = 1024,
      dm = decimals <= 0 ? 0 : decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const checkIfcanShowViewerButton = (canAccessDicomViewer: boolean, items: IFile[]) => {
    if (canAccessDicomViewer) {
      return find(items, ['fileType', 'dicom']) !== undefined;
    } else {
      return false;
    }
  };

  const getTransferInfo = async (firstPart: string, secondPart: string, isTotp: boolean | null) => {
    if (isTotpMethod || (isTotp != null && isTotp === true)) {
      try {
        const replaceCodeRsp = await sendSecondPartOfTransferKey(transferId, code, secondPart);
        const replaceCode = get(replaceCodeRsp, 'code', null);
        if (replaceCode != null) {
          secondPart = replaceCode;
        }
      } catch (e) {}
    }
    setFirstKey(firstPart);
    setSecondKey(secondPart);
    if (window.location.href.indexOf('sms') === -1) {
      history.replace(
        `${window.location.pathname}${window.location.search}&key=${firstPart}&sms=${secondPart}`
      );
    }
    const transferFilesReponse = await getTransferFiles(transferId, code, secondPart);
    const errorMessage = get(
      transferFilesReponse,
      'appendix.reason',
      get(transferFilesReponse, 'errorMessage', '')
    );
    if (!errorMessage.isEmpty && get(transferFilesReponse, 'appendix.success', false)) {
      const secretTransferNoteResponse = await getSecretTransferNote(
        transferId,
        code,
        firstPart,
        secondPart
      );
      if (get(secretTransferNoteResponse, 'success', false)) {
        const fetchedTransfer = await getTransferDetail(
          transferId,
          code,
          undefined,
          undefined,
          'in'
        );
        let canAccessDicomViewer = false;
        if (!isEmpty(fetchedTransfer)) {
          canAccessDicomViewer = get(fetchedTransfer, 'canAccessDicomViewer', false);
          fetchTransferDetail([fetchedTransfer]);
        }
        const items = get(transferFilesReponse, 'items', []);
        const fileTree = get(transferFilesReponse, 'appendix.fileTree');

        setFileInfoTitle(
          ` ${t('files.downloadFiles.fileCount')}: ${items.length} (${calculateSize(
            sumBy(items, (item: IFile) => {
              return get(item, 'size', 0);
            }),
            2
          )}, ${t('files.downloadFiles.expiration')}: ${format(
            parseISO(get(fetchedTransfer, 'expiration')),
            'd. M. y HH:mm:ss'
          )})`
        );
        setShowViewerButton(checkIfcanShowViewerButton(canAccessDicomViewer, items));
        fetchFiletree(fileTree);
        setHasFiles(transferHasFiles(items));
      } else {
        throw new Error();
      }
    } else {
      throw new Error(errorMessage);
    }
  };

  const initialValues = async () => {
    if (transferId !== 0) {
      const loginRequiredResponse = await checkIfLoginRequired(transferId, code);
      const logoutIsRequired = get(loginRequiredResponse, 'logoutRequired', false);
      if (logoutIsRequired) {
        await logoutActualUser();
        await fetchAndStoreUser();
      }
      const userLoginIsRequired = get(loginRequiredResponse, 'loginRequired', true);
      setLoginRequired(userLoginIsRequired);
      setLoginPossible(
        userLoginIsRequired === false && get(loginRequiredResponse, 'loginPossible', false)
      );
      setUsername(get(loginRequiredResponse, 'login', ''));
      const deliveredResponse = userLoginIsRequired
        ? null
        : await delivered(transferId, code, true);
      if (deliveredResponse) {
        setSecondKeyTranslationString(getSecondPartOfTheKeyForForm(deliveredResponse));
        if (transferIsTotpMethod(deliveredResponse)) {
          setIsTotpMethod(true);
        }
      }

      const invalidDeliveredResponse =
        (deliveredResponse == null && !userLoginIsRequired) ||
        (deliveredResponse != null &&
          get(deliveredResponse, 'success', false) !== true &&
          get(deliveredResponse, 'limit', 0) === 0 &&
          get(deliveredResponse, 'sent', 0) === 0);
      if (invalidDeliveredResponse) {
        setShowConfirmdialog(false);
        addErrorAlert(t('files.downloadFiles.transferExpiredOrDeleted'));
      } else {
        if (
          (isNull(reason) || reason === '') &&
          !isNull(key) &&
          key !== '' &&
          !isNull(sms) &&
          sms !== ''
        ) {
          setShowConfirmdialog(false);
          await getTransferInfo(key, sms, null);
          setKeyConfirmed(true);
        } else if (
          (!isNull(reason) || reason !== '') &&
          !isNull(key) &&
          key !== '' &&
          !isNull(sms) &&
          sms !== ''
        ) {
          addErrorAlert(t(`files.downloadFiles.${reason}`));
          setShowConfirmdialog(false);
        } else {
          setShowConfirmdialog(true);
        }
      }
    } else {
      addErrorAlert(t('files.downloadFiles.transferIdMissing')); // vyresi se az budou preklady v 1 souboru
    }
  };

  const setKeysConfirmation = (keyConfirmed: boolean) => {
    setKeyConfirmed(keyConfirmed);
    closeDialog();
  };

  const closeDialog = () => {
    setShowConfirmdialog(false);
  };

  const closeLoginDialog = () => {
    setLoginRequired(false);
  };

  const showLoginSelectDialog = () => {
    setLocalFormSelectedForLogin(true);
  };

  useEffect(() => {
    initialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const numberOfMessages = get(head(transferDetail), 'numberOfMessages');
  const hasTextMessages = isNumber(numberOfMessages) && numberOfMessages > 0;
  const textMessages = get(head(transferDetail), 'messages', []);

  const loadTextMessage = async (id: number) => {
    const message = await getTransferMessage(
      id,
      transferId,
      firstKey as string,
      secondKey as string,
      code
    );
    let newTextMessages = { ...loadedTextMessages, ...get(message, 'messages') };
    setLoadedTextMessages(newTextMessages);
  };

  const customCloseLoginDialogFn = () => {
    setLocalFormSelectedForLogin(false);
  };

  return (
    <>
      <Title title={t('files.downloadFiles.title')} />
      <Title title={`${t('files.downloadFiles.transferId')}: ${transferId}`} subtitle={true} />
      {transferId === 0 ? (
        <></>
      ) : (
        <>
          {loginRequired != null ? (
            loginRequired ? (
              <>
                {hasOnlyLocalForm || localFormSelectedForLogin ? (
                  <Login
                    email={username}
                    password={''}
                    isDialog={true}
                    closeLoginDialog={closeLoginDialog}
                    loginRequired={loginRequired}
                    customCloseLoginDialogFn={customCloseLoginDialogFn}
                  />
                ) : (
                  <LoginSelect
                    isDialog={true}
                    loginRequired={loginRequired}
                    showLoginSelectDialog={showLoginSelectDialog}
                  />
                )}
              </>
            ) : keyConfirmed ? (
              <Grid container={true} spacing={2}>
                {isArray(transferDetail) && !isEmpty(transferDetail) ? (
                  <BasicGrid
                    data={transferDetail}
                    columns={[
                      {
                        title: t('files.downloadFiles.senderName'),
                        field: 'senderName',
                      },
                      {
                        title: t('files.downloadFiles.senderEmail'),
                        field: 'senderEmail',
                      },
                      {
                        title: t('files.downloadFiles.note'),
                        field: 'note',
                      },
                    ]}
                    options={{ search: false, toolbar: false }}
                    topMargin={false}
                  />
                ) : (
                  <></>
                )}

                {hasTextMessages && isArray(textMessages) && textMessages.length ? (
                  <Container maxWidth="lg" style={{ marginTop: '15px' }}>
                    <Grid container={true} justify="flex-start">
                      <Grid item={true} xs={12}>
                        <Title
                          title={`${t('files.downloadFiles.textMessages')} (${numberOfMessages})`}
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        {textMessages.map((textMessageId) => (
                          <ExpansionPanel
                            key={textMessageId}
                            onChange={async (_e, isOpened) => {
                              if (isOpened) {
                                await loadTextMessage(textMessageId);
                              }
                            }}
                          >
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>
                                {t('files.downloadFiles.showEncryptedMessage')}
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Typography className={classes.textMessage}>
                                <div
                                  dangerouslySetInnerHTML={createMarkup(
                                    xss(get(loadedTextMessages, textMessageId, ''), {
                                      whiteList: {
                                        strong: ['style'],
                                        em: ['style'],
                                        h1: ['style'],
                                        h2: ['style'],
                                        h3: ['style'],
                                        h4: ['style'],
                                        h5: ['style'],
                                        h6: ['style'],
                                        p: ['style'],
                                        ol: ['style'],
                                        ul: ['style'],
                                        span: ['style'],
                                        li: ['style'],
                                        table: ['style', 'border'],
                                        thead: ['style'],
                                        tbody: ['style'],
                                        tfoot: ['style'],
                                        tr: ['style'],
                                        td: ['style', 'colspan', 'rowspan'],
                                        th: ['style', 'colspan', 'rowspan'],
                                        caption: ['style'],
                                        col: ['style'],
                                        colgroup: ['style'],
                                      },
                                    })
                                  )}
                                ></div>
                              </Typography>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        ))}
                      </Grid>
                    </Grid>
                  </Container>
                ) : null}

                <div style={{ width: '100%' }}></div>
                {hasFiles ? (
                  <>
                    <Container maxWidth="md" style={{ marginTop: '16px' }}>
                      <Grid container={true} justifyContent="center">
                        <Grid item={true}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth={true}
                            onClick={() => {
                              downloadFile(null, true);
                            }}
                          >
                            {t('files.downloadFiles.downloadAll')}
                          </Button>
                        </Grid>
                      </Grid>
                      {showViewerButton && (
                        <Grid
                          container={true}
                          style={{ marginTop: '15px' }}
                          justifyContent="center"
                        >
                          <Grid item={true}>
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth={true}
                              onClick={() => {
                                openViewer();
                              }}
                            >
                              {t('files.downloadFiles.showViewer')}
                            </Button>
                          </Grid>
                        </Grid>
                      )}

                      <br />
                      <Title title={fileInfoTitle} subtitle={true} />
                    </Container>

                    <div style={{ width: '100%' }}></div>
                    <Container maxWidth="lg" style={{ marginTop: '16px' }}>
                      <FileTree fileTree={fileTree} downloadFile={downloadFile} />
                    </Container>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            ) : (
              showConfirmdialog && (
                <KeyConfirmationDialog
                  setKeysConfirmation={setKeysConfirmation}
                  closeDialog={closeDialog}
                  getTransferInfo={getTransferInfo}
                  firstKey={key}
                  transferIdAndCodeObject={{ code, transfer: transferId }}
                  showOptionalLogin={loginPossible}
                  optionalLogin={username}
                  secondKeyTranslationString={secondKeyTranslationString}
                  setSecondKeyTranslationString={setSecondKeyTranslationString}
                />
              )
            )
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default DownloadFiles;
