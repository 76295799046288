import { useHistory } from 'react-router';
import { get, head, isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { AppBar, Container, Tab, Tabs } from '@material-ui/core';

import useStyles from './_styles';
import ExternalService from './ExternalService';
import InternalContacts from './InternalContacts';
import useContacts from 'utils/hooks/useContacts';
import { IExternalService, IRecipientRegistry } from './_types';

const RecipientRegistry: React.FC<IRecipientRegistry> = ({
  selectionOnly = false,
  closeRecipientRegistry,
  selectRecipients,
  remianingRecipients,
  selectionDisabled = false,
}) => {
  const history = useHistory();
  const {
    internalContactsEnabled,
    externalContactsEnabled,
    contactsDisabled,
    enabledExternalServices,
  } = useContacts();

  const initialTab = internalContactsEnabled
    ? 'internal'
    : !internalContactsEnabled && externalContactsEnabled
    ? get(head(enabledExternalServices), 'name')
    : '';
  const [activeTab, setActiveTab] = useState<string>(initialTab);
  const classes = useStyles();
  const { t } = useTranslation();

  let numberOfServices = 0;
  if (internalContactsEnabled) {
    numberOfServices++;
  }
  if (externalContactsEnabled && isArray(enabledExternalServices)) {
    numberOfServices += enabledExternalServices.length;
  }

  useEffect(() => {
    // Disabled contacts => redirect
    if (contactsDisabled) {
      history.push('/');
    }

    // Set default tab
    // if (internalContactsEnabled) {
    //   setActiveTab('internal');
    // }

    // if (!internalContactsEnabled && externalContactsEnabled) {
    //   setActiveTab(get(head(enabledExternalServices), 'name'));
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (_event: any, newValue: string) => setActiveTab(newValue);

  return (
    <>
      {numberOfServices > 1 ? (
        <Container maxWidth="lg">
          <AppBar position="static">
            <Tabs
              value={activeTab}
              onChange={handleChange}
              aria-label="Contacts"
              classes={{ root: classes.tabsHeader }}
              textColor="primary"
              indicatorColor="primary"
            >
              {internalContactsEnabled ? (
                <Tab label={t('recipientRegistry.contacts')} id="tab-internal" value="internal" />
              ) : null}
              {externalContactsEnabled
                ? enabledExternalServices.map((externalService) => (
                    <Tab
                      label={externalService.label}
                      id={`tab-${externalService.name}`}
                      value={externalService.name}
                      key={`tab-${externalService.name}`}
                    />
                  ))
                : null}
            </Tabs>
          </AppBar>
        </Container>
      ) : null}

      {internalContactsEnabled ? (
        <div className={activeTab === 'internal' ? classes.tabRevealed : classes.tab}>
          <InternalContacts
            selectionOnly={selectionOnly}
            closeRecipientRegistry={closeRecipientRegistry}
            selectRecipients={selectRecipients}
            remianingRecipients={remianingRecipients}
            selectionDisabled={selectionDisabled}
          />
        </div>
      ) : null}
      {externalContactsEnabled && isArray(enabledExternalServices)
        ? enabledExternalServices.map((service: IExternalService) => {
            return (
              <div
                className={activeTab === service.name ? classes.tabRevealed : classes.tab}
                key={service.name}
              >
                <ExternalService
                  service={service}
                  selectionOnly={selectionOnly}
                  closeRecipientRegistry={closeRecipientRegistry}
                  selectRecipients={selectRecipients}
                  remianingRecipients={remianingRecipients}
                  selectionDisabled={selectionDisabled}
                />
              </div>
            );
          })
        : null}
    </>
  );
};

export default RecipientRegistry;
