import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Fade, CircularProgress, Button } from '@material-ui/core';

import useStyles from './_styles';
import { cancelAxiosRequest } from 'utils/api';

const Loader: React.FC = () => {
  const isLoading = useSelector(state => get(state, 'app.isLoading'));
  const showCancelButton = useSelector(state => get(state, 'app.showCancelButton'));
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Fade in={isLoading}>
      <div className={classes.loader}>
        <CircularProgress color="primary" size={100} />
        {showCancelButton && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={cancelAxiosRequest}
            className={classes.button}
          >
            {t('common.cancelSearch')}
          </Button>
        )}
      </div>
    </Fade>
  );
};

export default React.memo(Loader);
