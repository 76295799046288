import { TOGGLE_NOTIFICATIONS_POPUP, STORE_NOTIFICATIONS } from 'constants/constants';
import { INotification } from './_types';

export const ToggleNotificationsPopup = (state: boolean) => ({
  type: TOGGLE_NOTIFICATIONS_POPUP,
  payload: state
});

export const StoreNotifications = (notifications: INotification[]) => ({
  type: STORE_NOTIFICATIONS,
  payload: notifications
});
