import React, { useEffect, useState } from 'react';
import { get, isString } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';

import useUrl from 'utils/hooks/useUrl';
import { confirmLoginUser, loginUser } from 'modules/Login/_api';
import useStorage from 'utils/hooks/useStorage';
import useHomePage from 'utils/hooks/useHomePage';
import useAlerts from 'components/Alerts/useAlerts';
import {
  AUTH_METHOD_OPENID,
  AUTH_METHOD_OPENID_FULL_URL_COOKIE_NAME,
  AUTH_METHOD_OPENID_REDIRECT_URI_COOKIE_NAME,
  AUTH_METHOD_OPENID_STATE_COOKIE_NAME,
} from 'constants/constants';
import useLoader from 'components/Loader/useLoader';
import { IUser } from 'modules/Login/_types';
import { userHasMoreOrganizations } from 'utils/hooks/useUserInfo';
import useFetchAndStoreUser from 'utils/hooks/useFetchAndStoreUser';
import { useDispatch } from 'react-redux';
import { ToggleOrganizationDialog } from 'components/OrganizationDialog/_redux';
import useLanguages from 'utils/hooks/useLanguages';
import useFetchAndStoreBackendConfig from 'utils/hooks/useFetchAndStoreBackendConfig';
import Login2FaForm from 'modules/Login/Login2Faform';
import { Formik } from 'formik';
import { Dialog, DialogContent } from '@material-ui/core';

const Redirect: React.FC = () => {
  const match = useRouteMatch('/redirect/:authType');
  const authType = get(match, 'params.authType') || AUTH_METHOD_OPENID; // Set default to OPENID
  const { get: storageGet, remove } = useStorage();
  const history = useHistory();
  const { forceLoader, removeForcedLoader } = useLoader();
  const { mapAllUrlParamsToObject } = useUrl();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { t } = useTranslation();
  const homeUrlPage = useHomePage();
  const dispatch = useDispatch();
  const { fetchAndStoreUser } = useFetchAndStoreUser();
  const { fetchAndStoreLanguage } = useLanguages();
  const { fetchAndStoreBackendConfig } = useFetchAndStoreBackendConfig();
  const [showConfirmForm, setshowConfirmForm] = useState(false);
  const [loginFor2FA, setLoginFor2FA] = useState();

  const params = mapAllUrlParamsToObject();

  // Params stored in localstorage before redirection to external auth gate
  const localStorageState = storageGet(AUTH_METHOD_OPENID_STATE_COOKIE_NAME);
  const localStorageRedirectUri = storageGet(AUTH_METHOD_OPENID_REDIRECT_URI_COOKIE_NAME);
  const localStorageFullUrl = storageGet(AUTH_METHOD_OPENID_FULL_URL_COOKIE_NAME);

  const redirectToLoginWithError = (errorMessage: string) => {
    addErrorAlert(t(errorMessage));
    history.push('/login');
  };

  // Error: Auth type missing in URL, redirect back to login
  if (!isString(authType)) {
    redirectToLoginWithError('login.errorAuthMethodMissing');
  }

  const processApisAfterSuccessfullLogin = async () => {
    addSuccessAlert(t('login.oauthSucceeded'));
    const user: IUser | boolean = await fetchAndStoreUser();
    if (userHasMoreOrganizations(user) && !localStorageFullUrl) {
      dispatch(ToggleOrganizationDialog(true));
    }
    await fetchAndStoreLanguage();
    await fetchAndStoreBackendConfig();
    addSuccessAlert(t('login.loginSucceeded'));

    if (localStorageFullUrl) {
      remove(AUTH_METHOD_OPENID_FULL_URL_COOKIE_NAME);
      window.location.href = localStorageFullUrl;
    } else {
      history.push(homeUrlPage);
    }
  };

  // Only do this once
  useEffect(() => {
    // openid auth type
    if (authType === AUTH_METHOD_OPENID) {
      forceLoader();

      // Params retrieved from external auth gate from URL
      const urlErrorParam = get(params, 'error');
      const urlStateParam = get(params, 'state');
      const urlCodeParam = get(params, 'code');

      if (isString(urlErrorParam)) {
        removeForcedLoader();
        return redirectToLoginWithError('login.idmLoginCanceled');
      }

      // Check if needed params are in URL
      const urlContainsParams = isString(urlStateParam) && isString(urlCodeParam);

      // Redirect to login if params are missing in URL
      if (!urlContainsParams) {
        removeForcedLoader();
        return redirectToLoginWithError('login.errorOauthMissingParams');
      }

      // Proceed if URL contains params
      if (urlContainsParams) {
        // Error: localStorage param is not the same as the one in URL
        if (urlStateParam !== localStorageState) {
          removeForcedLoader();
          redirectToLoginWithError('login.errorOauthParamsDontMatch');
        }

        // Proceed: localStorage param is the same as the one in URL
        if (urlStateParam === localStorageState) {
          const loginUserWithCodeAndState = async () => {
            try {
              const loginRs = await loginUser({
                token: urlCodeParam,
                state: urlStateParam,
                callbackUri: localStorageRedirectUri,
              });
              removeForcedLoader();
              if (loginRs) {
                const confirmRequired = get(loginRs, 'confirmRequired', false);
                if (confirmRequired) {
                  setshowConfirmForm(true);
                  setLoginFor2FA(get(loginRs, 'login'));
                } else {
                  processApisAfterSuccessfullLogin();
                }
              } else {
                removeForcedLoader();
                redirectToLoginWithError('login.errorOauthCantLogin');
              }
            } catch (e) {
              removeForcedLoader();
              redirectToLoginWithError('login.errorOauthCantLogin');
            }
          };

          loginUserWithCodeAndState();
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: any) => {
    try {
      const login = await confirmLoginUser({
        code: get(values, 'code'),
        login: loginFor2FA,
      });
      if (login) {
        setshowConfirmForm(false);
        processApisAfterSuccessfullLogin();
      }
    } catch (e) {
      addErrorAlert(t('login.loginFailed'));
    }
  };

  return (
    <>
      {showConfirmForm === true ? (
        <Dialog
          maxWidth="sm"
          aria-labelledby="confirmation-dialog-title"
          open={true}
          onClose={() => false}
        >
          <DialogContent>
            <Formik onSubmit={onSubmit} initialValues={{}} enableReinitialize={true}>
              {({ isSubmitting, handleSubmit }) => (
                <Login2FaForm handleSubmit={handleSubmit} isSubmitting={isSubmitting} />
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
};

export default Redirect;
