import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  get,
  isObject,
  isArray,
  isEmpty,
  find,
  sortBy,
  pick,
  isNumber,
  compact,
  includes,
  isEqual,
} from 'lodash';
import {
  Button,
  Grid,
  Container,
  Tooltip,
  FormControlLabel,
  Switch,
  Typography,
  AppBar,
  Tabs,
  Tab,
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useStyles from './_styles';
import useValidationSchema from './_form';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';
import Confirm from 'components/Confirm/Confirm';
import useLoader from 'components/Loader/useLoader';
import useAlerts from 'components/Alerts/useAlerts';
import BasicGrid from 'components/BasicGrid/BasicGrid';
import CheckboxField from 'components/form/Checkbox/Checkbox';
import {
  getOrganization,
  updateOrganization,
  getUserGroups,
  getAllUsers,
  getAllDomains,
  deleteSingleUser,
  getWorkplaceOrgConfig,
  getWorkplaces,
} from './_api';
import { getCheckedOptionsFromBinaryMap, getBinaryMapFromOptions } from 'utils/emailNotifications';
import InputField from 'components/form/Input/Input';
import SelectField from 'components/form/Select/Select';
import Title from 'components/Title/Title';
import {
  IOrganizationFormValues,
  IOrganizationForm,
  IOrganization,
  IUserGroup,
  IUser,
  IDomain,
  IDomainFormValues,
  IAddByEmailFormValues,
} from './_types';
import SelectedPersonIcon from '@material-ui/icons/Person';
import PersonFromDomainIcon from '@material-ui/icons/FolderShared';
import MailIcon from '@material-ui/icons/Mail';
import {
  USER_IN_GROUP,
  USER_NOT_IN_GROUP,
  ORGANIZATION_SHARED_TRANSFERS,
  ORGANIZATION_PRIVATE_TRANSFERS,
  ORGANIZATION_SHARED_CONTACTS,
  LEVEL_L0,
  LEVEL_L1,
  ORGANIZATION_PRIVATE_CONTACTS,
  ORGANIZATION_EXTERNAL_CONTACTS,
  INVITATION_EXPIRATION_DEFAULT_MAXIMAL,
  PERMISSION_ADMINISTRATING,
  NOTIFICATION_DELIVERED,
  NOTIFICATION_VIEWED,
  NOTIFICATION_EXPIRED,
  NOTIFICATION_PHONE_CHANGE,
  NOTIFICATION_BLOCKED_RECIPIENT,
  NOTIFICATION_EXTERNAL_USERS,
  NOTIFICATION_SEND_STATISTICS,
} from 'constants/constants';
import useUserInfo from 'utils/hooks/useUserInfo';
import useConfig from 'utils/hooks/useConfig';
import Templates from 'modules/Administration/Templates/Templates';
import useFetchAndStoreUser from 'utils/hooks/useFetchAndStoreUser';

const OrganizationDetail: React.FC<IOrganizationForm> = () => {
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();
  const { toggleLoader } = useLoader();
  const { fetchAndStoreUser } = useFetchAndStoreUser();
  const classes = useStyles();
  const { OrganizationFormSchema, DomainFormSchema, AddByEmailFormSchema } = useValidationSchema();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const [organization, fetchOrganizationDetail] = useState<IOrganization>();
  const [activeTab, setActiveTab] = useState<string>('editOrganization');
  const [userGroups, fetchUserGroups] = useState<IUserGroup[]>([]);
  const [users, fetchUsers] = useState<IUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUser | boolean>(false);
  const [selectedAdmin, setSelectedAdmin] = useState<IUser | boolean>(false);
  const [selectedUserToBeDeleted, setSelectedUserToBeDeleted] = useState<IUser | boolean>(false);
  const [domains, fetchDomains] = useState<IDomain[]>([]);
  const [workplaces, setWorkplaces] = useState<any>([]);
  const [workplaceExpanded, setWorkplaceExpanded] = useState<string>('');
  const [allDomains, fetchAllDomains] = useState<IDomain[]>([]);
  const [selectedActive, setSelectedActive] = useState<IDomain | boolean>(false);
  const [addNewDomain, setAddNewDomain] = useState<boolean>(false);
  const [addNewUser, setAddNewUser] = useState<boolean>(false);
  const organizationId = parseInt(get(params, 'id'), 10);
  const tableRef: any = React.createRef();
  const { userIsAdminOrSuperAdmin, userIsOrgAdmin, userHasPermissions } = useUserInfo();
  const { getConfigValue } = useConfig();
  const maximal = getConfigValue('appendix.invitation.maximal');

  const closeSelectedUserDialog = () => setSelectedUser(false);
  const closeSelectedAdminDialog = () => setSelectedAdmin(false);
  const closeSelectedUserToBeDeletedDialog = () => setSelectedUserToBeDeleted(false);
  const closeSelectedDomainDialog = () => setSelectedActive(false);

  const handleChange = (_event: any, newValue: string) => setActiveTab(newValue);

  const prepareValues = (values: IOrganizationFormValues) => ({
    ...pick(values, [
      'id',
      'name',
      'invitedUserExpiration',
      ...(userIsAdminOrSuperAdmin ? ['userGroup'] : []),
    ]),
    flags: getBinaryMapFromOptions(get(values, 'flags')),
  });

  const goBack = () => {
    history.push('/manage-organizations');
  };

  const onOrganizationEdit = async (values: IOrganizationFormValues) => {
    toggleLoader();
    try {
      const response = await updateOrganization(prepareValues(values));
      if (response) {
        addSuccessAlert(t('manageOrganizations.editingOrganizationSucceeded'));
      }
    } catch (e) {
      addErrorAlert(t('manageOrganizations.editingOrganizationFailed'));
    }
    toggleLoader(false);
  };

  const setDomains = (organization: IOrganization, allDomains: any) => {
    fetchAllDomains(allDomains);
    let myAllDomains = allDomains;
    if (!isEmpty(myAllDomains)) {
      const domains = get(organization, 'domains', []);
      if (!isEmpty(domains)) {
        myAllDomains = myAllDomains.map((domain: IDomain) => {
          const isActive = find(domains, { id: get(domain, 'id', 0) });
          return { ...domain, isActive: isActive ? 1 : 9 };
        });
      }
    }
    fetchDomains(sortBy(myAllDomains, ['name']));
  };

  const mapUsersForGrid = (
    users: IUser[],
    selectedOrganization: IOrganization | undefined = organization
  ) => {
    const usersByDomains: IUser[] = get(selectedOrganization, 'allUsers', []);
    const admins: IUser[] = get(selectedOrganization, 'admins', []);
    const normalUsers: IUser[] = get(selectedOrganization, 'users', []);
    return isArray(users)
      ? users.map((user: IUser) => {
          const isUserByDomains = find(usersByDomains, { userId: get(user, 'userId', 0) });
          const isUser = find(normalUsers, { userId: get(user, 'userId', 0) });
          const isAdmin = find(admins, { userId: get(user, 'userId', 0) });
          return {
            ...user,
            isUserByDomains: isUserByDomains ? USER_IN_GROUP : USER_NOT_IN_GROUP,
            isUser: isUser ? USER_IN_GROUP : USER_NOT_IN_GROUP,
            isAdmin: isAdmin ? USER_IN_GROUP : USER_NOT_IN_GROUP,
          };
        })
      : [];
  };

  const setOrganizationUsers = (organization: IOrganization, allUsers: any, allDomains: any) => {
    setDomains(organization, allDomains);
    fetchOrganizationDetail(organization);
    fetchUsers(sortBy(mapUsersForGrid(allUsers, organization), ['isUserByDomains', 'name']));
  };

  const mergeWorkplacesConf = (workplaces: any, conf: any) =>
    workplaces.map((item: any) => ({
      name: item.id,
      userFlags: get(find(conf, { workplace: item.id }), 'userFlags', 0),
    }));

  const getDetail = async () => {
    toggleLoader();
    try {
      const [
        fetchedOrganization,
        fetchedUserGroups,
        fetchedWorkplaces,
        fetchedWorkplacesConfig,
        // fetchedAllUsers,
        fetchedAllDomains,
      ] = await Promise.all([
        getOrganization({ id: organizationId }),
        getUserGroups(),
        getWorkplaces(),
        getWorkplaceOrgConfig({ organization: organizationId }),
        // getAllUsers(),
        ...(userIsAdminOrSuperAdmin ? [getAllDomains()] : []),
      ]);

      // fetchAllUsers(fetchedAllUsers);
      setOrganizationUsers(
        fetchedOrganization,
        get(fetchedOrganization, 'allUsers', []),
        fetchedAllDomains
      );
      setWorkplaces(mergeWorkplacesConf(fetchedWorkplaces, fetchedWorkplacesConfig));
      if (isArray(fetchedUserGroups)) {
        const filteredUserGroups = fetchedUserGroups.filter(
          (userGroup) =>
            userGroup.userGroupId !== LEVEL_L0 &&
            userGroup.userGroupId !== LEVEL_L1 &&
            userGroup.userGroupId !== 'admin'
        );
        fetchUserGroups(filteredUserGroups);
      }
    } catch {}
    toggleLoader(false);
  };

  useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadTable = () => {
    const brn = document.getElementById('btn-refresh');
    if (brn) {
      brn.click();
    }
  };

  const selectUser = async (user: boolean | IUser, forAdmin: boolean) => {
    toggleLoader();
    try {
      const isUser = find(get(organization, forAdmin ? 'admins' : 'users', []), {
        userId: get(user, 'userId', 9),
      });

      const userId = get(user, 'userId');

      const usersToRemove = !forAdmin && isUser;
      const usersToAdd = !forAdmin && !isUser;
      const adminsToRemove = forAdmin && isUser;
      const adminsToAdd = forAdmin && !isUser;

      const items = {
        id: organizationId,
        usersToRemove: usersToRemove ? [{ userId }] : null,
        usersToAdd: usersToAdd ? [{ userId }] : null,
        adminsToRemove: adminsToRemove ? [{ userId }] : null,
        adminsToAdd: adminsToAdd ? [{ userId }] : null,
      };

      const response = await updateOrganization(items);
      if (response) {
        setOrganizationUsers(response, get(response, 'allUsers', []), allDomains);
        reloadTable();
      }
      addSuccessAlert(
        t(
          usersToRemove
            ? 'manageOrganizations.users.alerts.remove.ok'
            : usersToAdd
            ? 'manageOrganizations.users.alerts.add.ok'
            : adminsToRemove
            ? 'manageOrganizations.users.alerts.admin.remove.ok'
            : adminsToAdd
            ? 'manageOrganizations.users.alerts.admin.add.ok'
            : ''
        )
      );
    } catch (e) {
      addErrorAlert(
        t(
          forAdmin
            ? 'manageOrganizations.users.alerts.admin.fail'
            : 'manageOrganizations.users.alerts.fail'
        )
      );
    }
    forAdmin ? closeSelectedAdminDialog() : closeSelectedUserDialog();
    toggleLoader(false);
  };

  const deleteUser = async (user: IUser) => {
    try {
      const deletedUserId = await deleteSingleUser({
        op: 'delete',
        item: { userId: user.userId },
      });
      if (isNumber(deletedUserId)) {
        await getDetail();
        addSuccessAlert(t('manageOrganizations.users.alerts.delete.ok'));
        reloadTable();
        closeSelectedUserToBeDeletedDialog();
      } else {
        addErrorAlert(t('manageOrganizations.users.alerts.delete.fail'));
      }
    } catch (e) {
      addErrorAlert(t('manageOrganizations.users.alerts.delete.fail'));
    }
  };

  const selectDomainActive = async (domain: boolean | IDomain) => {
    toggleLoader();
    try {
      const isActive = find(get(organization, 'domains', []), {
        id: get(domain, 'id', 9),
      });
      const items = {
        id: organizationId,
        domainsToRemove: isActive ? [{ id: get(domain, 'id') }] : null,
        domainsToAdd: !isActive ? [{ id: get(domain, 'id') }] : null,
      };
      const response = await updateOrganization(items);
      if (response) {
        const fetchedAllDomains = await getAllDomains();
        setOrganizationUsers(response, get(response, 'allUsers', []), fetchedAllDomains);
        reloadTable();
      }
      addSuccessAlert(
        t(
          isActive
            ? 'manageOrganizations.domain.alerts.remove.ok'
            : 'manageOrganizations.domain.alerts.add.ok'
        )
      );
    } catch (e) {
      addErrorAlert(t('manageOrganizations.domain.alerts.fail'));
    }
    closeSelectedDomainDialog();
    toggleLoader(false);
  };

  const onDomainCreate = async (
    values: IDomainFormValues,
    actions: FormikHelpers<IDomainFormValues>
  ) => {
    toggleLoader();
    try {
      const items = { id: organizationId, domainsToAdd: [{ name: get(values, 'name') }] };
      const response = await updateOrganization(items);
      if (response) {
        const fetchedAllDomains = await getAllDomains();
        setOrganizationUsers(response, get(response, 'allUsers', []), fetchedAllDomains);
        reloadTable();
        addSuccessAlert(t('manageOrganizations.domain.addingDomainSucceeded'));
        setAddNewDomain(false);
      } else {
        addErrorAlert(t('manageOrganizations.domain.addingDomainFailed'));
      }
    } catch (e) {
      addErrorAlert(t('manageOrganizations.domain.addingDomainFailed'));
    }

    toggleLoader(false);
  };

  const onUserAddByEmail = async (values: IAddByEmailFormValues) => {
    toggleLoader();
    try {
      const response = await updateOrganization({
        id: organizationId,
        usersToAdd: [{ email: values.email, name: values.name }],
        usersToRemove: null,
        adminsToRemove: null,
        adminsToAdd: null,
      });
      if (response) {
        let fetchedAllDomains = [];
        if (userIsAdminOrSuperAdmin) {
          fetchedAllDomains = await getAllDomains();
        }

        setOrganizationUsers(response, get(response, 'allUsers', []), fetchedAllDomains);
        reloadTable();
        addSuccessAlert(t('manageOrganizations.users.alerts.add.ok'));
      } else {
        addErrorAlert(t('manageOrganizations.users.alerts.fail'));
      }
    } catch (e) {
      addErrorAlert(t('manageOrganizations.users.alerts.fail'));
    }
    toggleLoader(false);
  };

  const allowedFlags = [
    ORGANIZATION_SHARED_TRANSFERS,
    ORGANIZATION_PRIVATE_TRANSFERS,
    ORGANIZATION_SHARED_CONTACTS,
    ORGANIZATION_PRIVATE_CONTACTS,
    ORGANIZATION_EXTERNAL_CONTACTS,
  ];

  let initialValues = isObject(organization)
    ? {
        id: get(organization, 'id', ''),
        name: get(organization, 'name', ''),
        userGroup: get(organization, 'userGroup', ''),
        invitedUserExpiration: get(organization, 'invitedUserExpiration', 0),
        flags: { ...getCheckedOptionsFromBinaryMap(get(organization, 'flags', 0)) },
      }
    : {
        name: '',
        userGroup: '',
        invitedUserExpiration: 0,
        flags: {},
      };

  const initialWorkplaceValues = {
    emailNotifications: Object.assign(
      {},
      ...workplaces.map((item: any) => ({
        [item.name]: getCheckedOptionsFromBinaryMap(item.userFlags),
      }))
    ),
  };

  const allowedNotificationTypes = [
    NOTIFICATION_DELIVERED,
    NOTIFICATION_VIEWED,
    NOTIFICATION_EXPIRED,
    NOTIFICATION_PHONE_CHANGE,
    NOTIFICATION_BLOCKED_RECIPIENT,
    NOTIFICATION_EXTERNAL_USERS,
    NOTIFICATION_SEND_STATISTICS,
  ];

  const onWorkplaceEdit = async (values: any, workplace: any) => {
    toggleLoader();
    // update neexistuje, je potreba smazat a vytvorit novou vazbu  - DS-981
    await getWorkplaceOrgConfig(
      {
        item: {
          workplace,
          organization: organizationId,
        },
      },
      'delete'
    );
    await getWorkplaceOrgConfig(
      {
        item: {
          workplace,
          organization: organizationId,
          userFlags: getBinaryMapFromOptions(values),
        },
      },
      'create'
    );
    //nacteni aktualnich dat
    const fetchedWorkplaces = await getWorkplaces();
    const fetchedWorkplacesConfig = await getWorkplaceOrgConfig({ organization: organizationId });
    setWorkplaces(mergeWorkplacesConf(fetchedWorkplaces, fetchedWorkplacesConfig));
    await fetchAndStoreUser();
    toggleLoader(false);
  };

  const handleWorkplaceChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setWorkplaceExpanded(isExpanded ? panel : '');
    };

  return isObject(organization) ? (
    <Container maxWidth="md">
      <AppBar position="static">
        <Tabs
          value={activeTab}
          onChange={handleChange}
          aria-label="Contacts"
          classes={{ root: classes.tabsHeader }}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab
            label={t('manageOrganizations.organizationDialog.editOrganization')}
            id="tab-edit-organization"
            value="editOrganization"
          />
          <Tab
            label={t('manageOrganizations.manageTemplates')}
            id="tab-template"
            value="template"
          />
          {userIsAdminOrSuperAdmin && (
            <Tab label={t('manageOrganizations.domain.domains')} id="tab-domains" value="domains" />
          )}
          <Tab label={t('manageOrganizations.users.users')} id="tab-users" value="users" />
          {(userHasPermissions(PERMISSION_ADMINISTRATING) || userIsOrgAdmin) && (
            <Tab
              label={t('manageOrganizations.notifications')}
              id="tab-notifications"
              value="notifications"
            />
          )}
        </Tabs>
      </AppBar>

      {activeTab === 'editOrganization' && (
        <div style={{ marginTop: '24px' }}>
          <Formik
            enableReinitialize={true}
            onSubmit={onOrganizationEdit}
            initialValues={initialValues}
            validationSchema={OrganizationFormSchema}
          >
            {({ isSubmitting, handleSubmit, values, setFieldValue }) => {
              const canEditUserExpiration = get(
                values,
                `flags.${ORGANIZATION_EXTERNAL_CONTACTS}`,
                false
              );
              const isOrganizationSharedTransfers = get(
                values,
                `flags.${ORGANIZATION_SHARED_TRANSFERS}`,
                false
              );
              const isOrganizationPrivateTransfers = get(
                values,
                `flags.${ORGANIZATION_PRIVATE_TRANSFERS}`,
                false
              );
              let disabledFlags: number[] = [];
              if (isOrganizationSharedTransfers === false) {
                disabledFlags = [ORGANIZATION_PRIVATE_TRANSFERS];
                if (isOrganizationPrivateTransfers) {
                  setFieldValue(`flags.${ORGANIZATION_PRIVATE_TRANSFERS}`, false);
                }
              }

              return (
                <WhiteBox>
                  <form onSubmit={handleSubmit}>
                    <Grid container={true} spacing={2}>
                      <Grid item={true} xs={12}>
                        <InputField
                          name="name"
                          label={t('manageOrganizations.organization.name')}
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <SelectField
                          name="userGroup"
                          disabled={!userIsAdminOrSuperAdmin}
                          label={t('manageOrganizations.organization.userGroup')}
                          items={userGroups.map((userGroup: IUserGroup) => ({
                            id: userGroup.userGroupId,
                            label: `${userGroup.userGroupId} - ${t(
                              `manageOrganizations.levelNames.${userGroup.userGroupId}`
                            )}`,
                          }))}
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        {allowedFlags.map((checkbox) => (
                          <CheckboxField
                            key={checkbox}
                            name={`flags.${checkbox}`}
                            label={t(`manageOrganizations.checkboxes.${checkbox}`)}
                            disabled={includes(disabledFlags, checkbox)}
                          />
                        ))}
                      </Grid>

                      <Grid item={true} xs={12}>
                        <InputField
                          name="invitedUserExpiration"
                          type="number"
                          disabled={!canEditUserExpiration}
                          inputProps={{
                            min: 0,
                            max:
                              isNumber(maximal) && maximal > 0
                                ? maximal
                                : INVITATION_EXPIRATION_DEFAULT_MAXIMAL,
                            step: 1,
                          }}
                          label={t('manageOrganizations.organization.invitedUserExpiration')}
                        />
                      </Grid>
                      <Grid item={true} xs={6}>
                        <Button
                          variant="contained"
                          type="submit"
                          color="primary"
                          fullWidth={true}
                          disabled={isSubmitting}
                        >
                          {t('manageOrganizations.organizationDialog.editOrganization')}
                        </Button>
                      </Grid>
                      <Grid item={true} xs={6}>
                        <Button variant="contained" fullWidth={true} onClick={() => goBack()}>
                          {t('manageOrganizations.goBack')}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </WhiteBox>
              );
            }}
          </Formik>
        </div>
      )}
      {activeTab === 'template' && (
        <div style={{ marginTop: '24px' }}>
          <Templates />
        </div>
      )}

      {activeTab === 'domains' && userIsAdminOrSuperAdmin && isArray(domains) ? (
        <div style={{ marginTop: '24px' }}>
          {!addNewDomain && (
            <Grid container={true} direction="row" justifyContent="center" alignItems="center">
              <Grid item={true} xs={5} style={{ marginTop: '8px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth={true}
                  onClick={() => setAddNewDomain(true)}
                >
                  {t('manageOrganizations.domain.setDomainAsActive')}
                </Button>
              </Grid>
            </Grid>
          )}
          {addNewDomain && (
            <WhiteBox>
              <Formik
                onSubmit={onDomainCreate}
                initialValues={{ name: '' }}
                validationSchema={DomainFormSchema}
              >
                {({ isSubmitting, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid
                      container={true}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item={true} xs={12}>
                        <InputField
                          name="name"
                          label={t('manageOrganizations.domain.addNewDomainName')}
                        />
                      </Grid>

                      <Grid item={true} xs={5} style={{ marginTop: '8px' }}>
                        <Button
                          variant="contained"
                          type="submit"
                          color="primary"
                          fullWidth={true}
                          disabled={isSubmitting}
                        >
                          {t('manageOrganizations.domain.submit')}
                        </Button>
                      </Grid>
                      <Grid item={true} xs={5} style={{ marginTop: '8px' }}>
                        <Button
                          variant="contained"
                          fullWidth={true}
                          onClick={() => setAddNewDomain(false)}
                        >
                          {t('manageOrganizations.goBack')}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </WhiteBox>
          )}
          <div style={{ marginTop: '24px' }}>
            <Title title={t('manageOrganizations.domain.domains')} />
            <BasicGrid
              topMargin={false}
              data={domains}
              columns={[
                { title: t('manageOrganizations.domain.name'), field: 'name' },
                {
                  title: '',
                  field: 'isActive',
                  cellStyle: { padding: '0 16px' },
                  render: (domain: IDomain) => {
                    const isActive = get(domain, 'isActive', 9);
                    return (
                      <Tooltip
                        title={
                          isActive === 1
                            ? t('manageOrganizations.domain.setDomainAsInactive') || ''
                            : t('manageOrganizations.domain.setDomainAsActive') || ''
                        }
                      >
                        <FormControlLabel
                          control={<Switch checked={isActive === 1 ? true : false} />}
                          onChange={(e, state) => {
                            setSelectedActive(domain);
                          }}
                          label={
                            isActive === 1
                              ? t('manageOrganizations.active')
                              : t('manageOrganizations.inactive')
                          }
                        />
                      </Tooltip>
                    );
                  },
                },
              ]}
            />
          </div>
        </div>
      ) : null}

      {activeTab === 'users' && (
        <>
          {!addNewUser && (
            <div style={{ marginTop: '24px' }}>
              <Grid container={true} direction="row" justifyContent="center" alignItems="center">
                <Grid item={true} xs={5} style={{ marginTop: '8px' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    onClick={() => setAddNewUser(true)}
                  >
                    {t('manageOrganizations.users.addUser')}
                  </Button>
                </Grid>
              </Grid>
            </div>
          )}
          {addNewUser && (
            <div className={classes.basicGridContainer}>
              <button
                id="btn-refresh"
                type="button"
                className={classes.refreshButton}
                onClick={() => tableRef.current && tableRef.current.onQueryChange()}
              >
                Refresh
              </button>

              {userIsAdminOrSuperAdmin ? (
                <>
                  <Title title={t('manageOrganizations.users.addExistingUser')} />
                  <Typography className={classes.addUserText}>
                    {t('manageOrganizations.users.addUserText')}
                  </Typography>
                  <BasicGrid
                    topMargin={false}
                    data={(query: any) =>
                      new Promise(async (resolve, reject) => {
                        if (query.search.length > 2) {
                          getAllUsers('list', true, query.search).then((response) => {
                            resolve({
                              data: mapUsersForGrid(response),
                            });
                          });
                        } else {
                          resolve({ data: [] });
                        }
                      })
                    }
                    actions={
                      userIsAdminOrSuperAdmin
                        ? [
                            (rowData: any) => ({
                              icon: DeleteIcon,
                              iconProps: {
                                color: 'primary',
                                fontSize: 'large',
                              },
                              tooltip: t('manageOrganizations.users.deleteUser'),
                              onClick: (_event: any, user: any) => {
                                setSelectedUserToBeDeleted(user);
                              },
                              position: 'row',
                              disabled: rowData.deletable === false,
                            }),
                          ]
                        : null
                    }
                    columns={[
                      {
                        field: 'isUserByDomains',
                        cellStyle: { padding: '0 16px' },
                        render: (user: IUser) => {
                          const isUserByDomains = get(user, 'isUserByDomains', 0);
                          const isUser = get(user, 'isUser', 0);
                          return (
                            <>
                              {isUser === 1 ? (
                                <Tooltip title={t('manageOrganizations.users.selectedUser') || ''}>
                                  <SelectedPersonIcon color="secondary" />
                                </Tooltip>
                              ) : isUserByDomains === 1 ? (
                                <Tooltip title={t('manageOrganizations.users.userByDomain') || ''}>
                                  <PersonFromDomainIcon color="primary" />
                                </Tooltip>
                              ) : (
                                ''
                              )}
                            </>
                          );
                        },
                      },
                      { title: t('manageOrganizations.users.name'), field: 'name' },
                      { title: t('manageOrganizations.users.email'), field: 'email' },
                      {
                        title: t('manageOrganizations.users.isUser'),
                        field: 'isUser',
                        cellStyle: { padding: '0 16px' },
                        render: (user: IUser) => {
                          const isUser = get(user, 'isUser', 0);
                          return (
                            <Tooltip
                              title={
                                isUser === 1
                                  ? t('manageOrganizations.users.setUserAsInactive') || ''
                                  : t('manageOrganizations.users.setUserAsActive') || ''
                              }
                            >
                              <FormControlLabel
                                control={<Switch checked={isUser === 1 ? true : false} />}
                                onChange={(e, state) => {
                                  setSelectedUser(user);
                                }}
                                label={''}
                              />
                            </Tooltip>
                          );
                        },
                      },
                      {
                        title: t('manageOrganizations.users.isAdmin'),
                        field: 'isAdmin',
                        cellStyle: { padding: '0 16px' },
                        render: (user: IUser) => {
                          const isAdmin = get(user, 'isAdmin', 0);
                          return (
                            <Tooltip
                              title={
                                isAdmin === 1
                                  ? t('manageOrganizations.users.setAdminAsInactive') || ''
                                  : t('manageOrganizations.users.setAdminAsActive') || ''
                              }
                            >
                              <FormControlLabel
                                control={<Switch checked={isAdmin === 1 ? true : false} />}
                                onChange={(e, state) => {
                                  setSelectedAdmin(user);
                                }}
                                label={''}
                              />
                            </Tooltip>
                          );
                        },
                      },
                    ]}
                    tableRef={tableRef}
                  />
                </>
              ) : null}

              <div className={classes.basicGridContainer}>
                <Formik
                  onSubmit={onUserAddByEmail}
                  initialValues={{ email: '', name: '' }}
                  validationSchema={AddByEmailFormSchema}
                >
                  {({ handleSubmit, isSubmitting }) => (
                    <>
                      <Title title={t('manageOrganizations.users.addUserByEmail')} />
                      <WhiteBox>
                        <form onSubmit={handleSubmit}>
                          <Grid container={true} spacing={2} justifyContent="center">
                            <Grid item={true} xs={12}>
                              <InputField
                                name="email"
                                label={t('manageOrganizations.users.email')}
                              />
                            </Grid>
                            <Grid item={true} xs={12}>
                              <InputField name="name" label={t('manageOrganizations.users.name')} />
                            </Grid>

                            <Grid item={true} xs={6}>
                              <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                fullWidth={true}
                                disabled={isSubmitting}
                              >
                                {t('manageOrganizations.users.addUser')}
                              </Button>
                            </Grid>
                            <Grid item={true} xs={6}>
                              <Button
                                variant="contained"
                                fullWidth={true}
                                onClick={() => setAddNewUser(false)}
                              >
                                {t('manageOrganizations.goBack')}
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      </WhiteBox>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          )}
          <div className={classes.basicGridContainer}>
            <Title title={t('manageOrganizations.users.allUsers')} />
            <BasicGrid
              topMargin={false}
              data={users}
              actions={
                userIsAdminOrSuperAdmin
                  ? [
                      (rowData: any) => ({
                        icon: DeleteIcon,
                        iconProps: {
                          color: 'primary',
                          fontSize: 'large',
                        },
                        tooltip: t('manageOrganizations.users.deleteUser'),
                        onClick: (_event: any, user: any) => {
                          setSelectedUserToBeDeleted(user);
                        },
                        position: 'row',
                        disabled: rowData.deletable === false,
                      }),
                    ]
                  : null
              }
              columns={compact([
                {
                  field: 'isUserByDomains',
                  cellStyle: { padding: '0 16px' },
                  render: (user: IUser) => {
                    const isUserByDomains = get(user, 'isUserByDomains', 0);
                    const isUser = get(user, 'isUser', 0);
                    const userOtherFlags = get(user, 'otherFlags');
                    const isExternalUser = isNumber(userOtherFlags) && (userOtherFlags & 2) === 2;
                    return (
                      <>
                        {isExternalUser ? (
                          <Tooltip
                            title={`${t('manageOrganizations.users.externalUserInvitedBy')}: ${get(
                              user,
                              'hostEmail'
                            )}`}
                          >
                            <span className={classes.externalUser}>
                              <MailIcon color="inherit" />
                            </span>
                          </Tooltip>
                        ) : isUser === 1 ? (
                          <Tooltip title={t('manageOrganizations.users.selectedUser') || ''}>
                            <SelectedPersonIcon color="secondary" />
                          </Tooltip>
                        ) : isUserByDomains === 1 ? (
                          <Tooltip title={t('manageOrganizations.users.userByDomain') || ''}>
                            <PersonFromDomainIcon color="primary" />
                          </Tooltip>
                        ) : (
                          ''
                        )}
                      </>
                    );
                  },
                },
                { title: t('manageOrganizations.users.name'), field: 'name' },
                { title: t('manageOrganizations.users.email'), field: 'email' },
                {
                  title: t('manageOrganizations.users.isUser'),
                  field: 'isUser',
                  cellStyle: { padding: '0 16px' },
                  render: (user: IUser) => {
                    const isUser = get(user, 'isUser', 0);
                    return (
                      <Tooltip
                        title={
                          isUser === 1
                            ? t('manageOrganizations.users.setUserAsInactive') || ''
                            : t('manageOrganizations.users.setUserAsActive') || ''
                        }
                      >
                        <FormControlLabel
                          control={<Switch checked={isUser === 1 ? true : false} />}
                          onChange={(e, state) => {
                            setSelectedUser(user);
                          }}
                          label={''}
                        />
                      </Tooltip>
                    );
                  },
                },
                {
                  title: t('manageOrganizations.users.isAdmin'),
                  field: 'isAdmin',
                  cellStyle: { padding: '0 16px' },
                  render: (user: IUser) => {
                    const isAdmin = get(user, 'isAdmin', 0);
                    return (
                      <Tooltip
                        title={
                          isAdmin === 1
                            ? t('manageOrganizations.users.setAdminAsInactive') || ''
                            : t('manageOrganizations.users.setAdminAsActive') || ''
                        }
                      >
                        <FormControlLabel
                          control={<Switch checked={isAdmin === 1 ? true : false} />}
                          onChange={(e, state) => {
                            setSelectedAdmin(user);
                          }}
                          label={''}
                        />
                      </Tooltip>
                    );
                  },
                },
              ])}
            />
          </div>
        </>
      )}

      {activeTab === 'notifications' && (
        <div style={{ marginTop: '24px' }}>
          <WhiteBox>
            {workplaces.map((item: any, id: number) => (
              <Accordion
                expanded={workplaceExpanded === get(item, 'name')}
                onChange={handleWorkplaceChange(get(item, 'name'))}
                key={id}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${get(item, 'name')}-content`}
                  id={`${get(item, 'name')}-header`}
                >
                  <Typography>{get(item, 'name')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Formik
                    initialValues={get(initialWorkplaceValues, `emailNotifications.${item.name}`)}
                    onSubmit={(values) => onWorkplaceEdit(values, item.name)}
                  >
                    {({ isSubmitting, handleSubmit, values }) => {
                      return (
                        <form onSubmit={handleSubmit}>
                          <Grid container={true} spacing={2}>
                            <Grid item={true} xs={12}>
                              {allowedNotificationTypes.map((checkbox: any) => (
                                <CheckboxField
                                  key={checkbox}
                                  name={`${checkbox}`}
                                  label={t(`profile.checkboxes.${checkbox}`)}
                                />
                              ))}
                            </Grid>
                            <Grid item={true} xs={12}>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={
                                  isSubmitting ||
                                  isEqual(
                                    values,
                                    get(initialWorkplaceValues, `emailNotifications.${item.name}`)
                                  )
                                }
                                fullWidth={true}
                              >
                                {t('common.update')}
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      );
                    }}
                  </Formik>
                </AccordionDetails>
              </Accordion>
            ))}
          </WhiteBox>
        </div>
      )}

      {selectedUser && (
        <Confirm
          title={t('manageOrganizations.users.setUser')}
          content={
            get(selectedUser, 'isUser') === 1
              ? t('manageOrganizations.users.reallyUnSetUser', { name: get(selectedUser, 'name') })
              : t('manageOrganizations.users.reallySetUser', { name: get(selectedUser, 'name') })
          }
          onClose={closeSelectedUserDialog}
          onConfirm={() => selectUser(selectedUser, false)}
        />
      )}
      {selectedAdmin && (
        <Confirm
          title={t('manageOrganizations.users.setAdmin')}
          content={
            get(selectedAdmin, 'isAdmin') === 1
              ? t('manageOrganizations.users.reallyUnSetAdmin', {
                  name: get(selectedAdmin, 'name'),
                })
              : t('manageOrganizations.users.reallySetAdmin', { name: get(selectedAdmin, 'name') })
          }
          onClose={closeSelectedAdminDialog}
          onConfirm={() => selectUser(selectedAdmin, true)}
        />
      )}
      {selectedActive && (
        <Confirm
          title={t('manageOrganizations.domain.setActive')}
          content={
            get(selectedActive, 'isActive') === 1
              ? t('manageOrganizations.domain.reallyUnSetActive', {
                  name: get(selectedActive, 'name'),
                })
              : t('manageOrganizations.domain.reallySetActive', {
                  name: get(selectedActive, 'name'),
                })
          }
          onClose={closeSelectedDomainDialog}
          onConfirm={() => selectDomainActive(selectedActive)}
        />
      )}
      {selectedUserToBeDeleted && (
        <Confirm
          title={t('manageOrganizations.users.deleteUser')}
          content={t('manageOrganizations.users.reallyDeleteUser', {
            name: get(selectedUserToBeDeleted, 'name'),
          })}
          onClose={closeSelectedUserToBeDeletedDialog}
          onConfirm={() => deleteUser(selectedUserToBeDeleted as IUser)}
        />
      )}
    </Container>
  ) : null;
};

export default OrganizationDetail;
