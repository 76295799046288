import React from 'react';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const GoogleRecaptcha: React.FC = ({ children }) => {
  const { captchaEnabled, captchaApiKey } = useRecaptcha();
  return captchaEnabled && captchaApiKey ? (
    <GoogleReCaptchaProvider reCaptchaKey={captchaApiKey}>{children}</GoogleReCaptchaProvider>
  ) : (
    <>{children}</>
  );
};

export default GoogleRecaptcha;
