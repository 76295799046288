import { api } from 'utils/api';
import { get } from 'lodash';

export const requestNewPassword = (values: any): Promise<any> =>
  api()
    .post(`/api/fpassw`, values)
    .then(({ data }) => {
      if (get(data, 'success')) {
        return { status: 'success', data };
      } else {
        return false;
      }
    })
    .catch((error) => {
      return { status: 'error', code: get(error, 'status'), data: get(error, 'data') };
    });
