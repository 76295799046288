import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';

import defaulttheme from 'theme';
import 'constants/translations/i18n';

import Start from 'modules/Start/Start';
import configureStore from 'store/store';
import GoogleRecaptcha from 'components/GoogleRecaptcha/GoogleRecaptcha';

const store = configureStore();

const App: React.FC = () => {
  const [theme, setTheme] = useState(defaulttheme);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <GoogleRecaptcha>
            <Start setTheme={setTheme} />
          </GoogleRecaptcha>
        </Router>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
