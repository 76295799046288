import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { includes, pull } from 'lodash';
import { DOWNLOAD_LOCATION } from 'constants/constants';

const Download: React.FC = () => {
  const history = useHistory();
  const { hash, pathname } = useLocation();

  const isDownloadWithHash = hash.length && includes(hash, '#download');
  const isDownloadWithoutHash = includes(pathname, '/download');
  const isDownload = isDownloadWithHash || isDownloadWithoutHash;

  if (isDownload) {
    try {
      if (isDownloadWithHash) {
        const params = pull(hash.split('&'), '#download');
        const url = params.join('&');
        history.push(`${DOWNLOAD_LOCATION}?${url}`);
      }

      if (isDownloadWithoutHash) {
        // Replace first ampersand for question mark
        history.push(pathname.replace('&', '?'));
      }
    } catch (e) {
      console.debug(e);
    }
  }

  return null;
};

export default Download;
