import { get, isObject } from 'lodash';
import { api } from 'utils/api';
import { IUpdatedMainCofig } from './_types';

export const getMainConfigFile = () =>
  api()
    .post(`/api/get-main-config`, {})
    .then(({ data }) => (isObject(data) ? data : false))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const updateMainConfigFileSetting = (data: IUpdatedMainCofig) =>
  api()
    .post(`/api/update-main-config`, data)
    .then(({ data }) => {
      if (get(data, 'success')) {
        return data;
      } else {
        return false;
      }
    })
    .catch((error) => Promise.reject(get(error, 'response.data', '')));
