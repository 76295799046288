import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    iFrame: {
      position: 'absolute',
      width: '100vw',
      height: '100vh',
      left: 0,
      top: 0,
      padding: 0,
      margin: 0,
      border: 0,
    },
    title: {
      textAlign: 'center',
    },
    textMessage: {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
    },
    progressRoot: {
      width: '100%',
      marginRight: spacing(),
    },
    totalProgress: {
      flexBasis: '100%',
      display: 'flex',
      marginBottom: spacing(),
      alignItems: 'center',
    },
    closeButton: {
      color: 'red',
      zIndex: 10,
      position: 'absolute',
      top: 0,
      right: 0,
    },
  })
);

export default useStyles;
