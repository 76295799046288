import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    phone: {
      display: 'flex'
    },
    prefix: {
      width: 110
    }
  })
);

export default useStyles;
