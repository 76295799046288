import { useDispatch } from 'react-redux';

import hash from 'utils/hash';
import { AddAlert } from './_redux';
import {
  ALERT_TYPE_SUCCESS,
  ALERT_TYPE_ERROR,
  ALERT_TYPE_INFO,
  DEFAULT_HIDE_DURATION
} from 'constants/constants';
import { IAlertType } from './_types';

const useAlerts = () => {
  const dispatch = useDispatch();

  const addAlert = (text: string, type: IAlertType, hideDuration: number = DEFAULT_HIDE_DURATION) =>
    dispatch(
      AddAlert({
        text: text,
        type: type,
        id: hash(),
        date: new Date(),
        hideDuration
      })
    );

  const addSuccessAlert = (text: string, hideDuration: number = DEFAULT_HIDE_DURATION) =>
    addAlert(text, ALERT_TYPE_SUCCESS, hideDuration);
  const addErrorAlert = (text: string, hideDuration: number = DEFAULT_HIDE_DURATION) =>
    addAlert(text, ALERT_TYPE_ERROR, hideDuration);
  const addInfoAlert = (text: string, hideDuration: number = DEFAULT_HIDE_DURATION) =>
    addAlert(text, ALERT_TYPE_INFO, hideDuration);

  return {
    addInfoAlert,
    addErrorAlert,
    addSuccessAlert
  };
};

export default useAlerts;
