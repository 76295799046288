import { isArray } from 'lodash';
import useConfig from 'utils/hooks/useConfig';

const useLogin = () => {
  const { getConfigValue } = useConfig();

  const localAuthEnabled: boolean = getConfigValue('appendix.localAuthEnabled');
  const otherAuthMethods: { id: string; type: string }[] =
    getConfigValue('appendix.authentication.types') || [];

  const hasOtherAuthMethod = isArray(otherAuthMethods) && otherAuthMethods.length > 0;

  const hasOnlyOneLoginMethod =
    (localAuthEnabled && !hasOtherAuthMethod) ||
    (!localAuthEnabled && hasOtherAuthMethod && otherAuthMethods.length === 1);

  const theOnlyOneLoginMethodIsLocalForm = localAuthEnabled && !hasOtherAuthMethod;

  const oauthConfig: IopenidConfig | undefined = getConfigValue('appendix.authentication.openid');

  const isAuthMethod = (method: any, methodID: string) => method.id === methodID;

  type IopenidConfig = {
    callbackUris: string[];
    clientId: string;
    gatewayUri: string;
    id: string;
  };

  return {
    oauthConfig,
    isAuthMethod,
    localAuthEnabled,
    otherAuthMethods,
    hasOtherAuthMethod,
    hasOnlyOneLoginMethod,
    theOnlyOneLoginMethodIsLocalForm,
  };
};

export default useLogin;
