import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Grid, Typography, Select, MenuItem } from '@material-ui/core';
import { isEmpty, isObject, get, isArray, head, mapValues, forEach, nth } from 'lodash';

import { getTemplates, getOrgTemplates } from './_api';
import TemplateForm from './TemplateForm';
import Title from 'components/Title/Title';
import useLoader from 'components/Loader/useLoader';
import BasicGrid from 'components/BasicGrid/BasicGrid';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';
import { ISelectItem } from 'components/form/Select/_types';
import { ITemplate, IFetchedTemplates, IFetchedTemplate } from './_types';
import { useParams } from 'react-router';

const Templates: React.FC<any> = () => {
  const params = useParams();
  const { t } = useTranslation();
  const { toggleLoader } = useLoader();
  const [languages, fetchLanguages] = useState<ISelectItem[]>([]);
  const [templates, fetchTemplates] = useState<IFetchedTemplates>({});
  const [selectedLanguage, setSelectedLanguage] = useState<string>();
  const [selectedLanguageId, setSelectedLanguageId] = useState(0);
  const [editedTemplate, setEditedTemplate] = useState<ITemplate | boolean>(false);
  const [templateFormVisible, toggleTemplateForm] = useState(false);
  const organizationId = parseInt(get(params, 'organizationId') || get(params, 'id'), 10);

  const getAllTemplates = async () => {
    toggleLoader();
    try {
      // load general or organization templates
      const fetchedTemplates = isNaN(organizationId)
        ? await getTemplates()
        : await getOrgTemplates(organizationId);
      if (isObject(fetchedTemplates)) {
        fetchLanguages(
          get(fetchedTemplates, 'supportedLangs', []).map((lang: string, id: number) => ({
            id,
            label: lang,
          }))
        );
        if (selectedLanguage === undefined) {
          setSelectedLanguage(head(get(fetchedTemplates, 'supportedLangs', [])));
        }
        fetchTemplates(
          mapValues(get(fetchedTemplates, 'templates', {}), (templatesByLanguage) => {
            const langauageArray: IFetchedTemplate[] = [];
            forEach(templatesByLanguage, (value, key) => {
              langauageArray.push({ key, value });
            });

            return langauageArray;
          })
        );
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  const prepareAndSetRowForEdit = (template: ITemplate) => {
    setEditedTemplate(template);
    toggleTemplateForm(true);
  };

  const closeTemplateForm = () => {
    toggleTemplateForm(false);
    setEditedTemplate(false);
  };

  const onLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedLanguage(
      get(nth(languages, event.target.value as number), 'label', selectedLanguage)
    );
    setSelectedLanguageId(event.target.value as number);
  };

  useEffect(() => {
    getAllTemplates();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!get(params, 'id') && <Title title={t('templatesManage.title')} />}
      {!isEmpty(languages) && (
        <Grid container={true} spacing={2} justifyContent="center">
          <Grid item={true} xs={12} md={2}>
            <WhiteBox maxWidth={false} dense={true}>
              <Select onChange={onLanguageChange} value={selectedLanguageId} fullWidth={true}>
                {isArray(languages)
                  ? languages.map((item: ISelectItem) => (
                      <MenuItem key={item.id} value={get(item, 'id')}>
                        {t(item.label)}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </WhiteBox>
          </Grid>
        </Grid>
      )}
      {selectedLanguage && !isEmpty(templates) ? (
        <>
          {isArray(get(templates, selectedLanguage, [])) && (
            <BasicGrid
              data={get(templates, selectedLanguage, [])}
              columns={[
                {
                  field: 'key',
                  title: t('templatesManage.name'),
                  render: (rowData: ITemplate) => (
                    <>
                      <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        {get(rowData, 'key')}
                      </Typography>
                      <Typography>{get(rowData, 'value')}</Typography>
                    </>
                  ),
                },
              ]}
              actions={[
                {
                  icon: EditIcon,
                  iconProps: {
                    color: 'primary',
                    fontSize: 'large',
                  },
                  tooltip: t('templatesManage.editLanguageMessage'),
                  onClick: (_event: any, rowData: ITemplate) => prepareAndSetRowForEdit(rowData),
                  position: 'row',
                },
              ]}
            />
          )}
          {templateFormVisible && (
            <TemplateForm
              closeDialog={closeTemplateForm}
              editedTemplate={editedTemplate}
              getAllTemplates={getAllTemplates}
              selectedLanguage={selectedLanguage}
              organizationId={organizationId}
            />
          )}
        </>
      ) : null}
    </>
  );
};

export default Templates;
