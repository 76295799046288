import React from 'react';
import { get, omit } from 'lodash';
import { useHistory } from 'react-router';
import useValidationSchema from './_form';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, Button } from '@material-ui/core';
import { useSelector, shallowEqual } from 'react-redux';

import { addPhone } from './_api';
import { IAddPhoneFormValues } from './_types';
import Phone from 'components/form/Phone/Phone';
import usePhone from 'utils/hooks/usePhone';
import useAlerts from 'components/Alerts/useAlerts';
import useLoader from 'components/Loader/useLoader';
import WhiteBox from 'components/form/WhiteBox/WhiteBox';

const AddPhone: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { toggleLoader } = useLoader();
  const { AddPhoneSchema } = useValidationSchema();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { splitPhoneNumber, joinPhoneNumber, testIfPhoneHasRightFormat } = usePhone();

  const { phone } = useSelector((state) => get(state, 'user'), shallowEqual);
  const [prefix, phoneNumber] = splitPhoneNumber(phone);

  const prepareValues = (values: IAddPhoneFormValues) => ({
    ...omit(values, ['phoneNumber', 'prefix']),
    // TODO: phone validation and concatenation
    phone: joinPhoneNumber(values.prefix, values.phoneNumber),
  });

  const onSubmit = async (
    values: IAddPhoneFormValues,
    { setErrors }: FormikHelpers<IAddPhoneFormValues>
  ) => {
    toggleLoader();
    const preparedValues = prepareValues(values);
    const phoneIsValid = testIfPhoneHasRightFormat(preparedValues.phone);

    if (phoneIsValid) {
      try {
        const response = await addPhone(preparedValues);
        if (response) {
          addSuccessAlert(t('phone.addPhone.succeeded'));
          history.push('/phone-verification');
        } else {
          addErrorAlert(t('phone.addPhone.failed'));
        }
      } catch (e) {
        addErrorAlert(t('phone.addPhone.failed'));
      }
    } else {
      setErrors({ phoneNumber: t('form.validations.phoneNumberInvalid') });
    }

    toggleLoader(false);
  };

  const initialValues = { prefix, phoneNumber };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={AddPhoneSchema}>
      {({ isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <WhiteBox title={t('phone.addPhone.userAddPhone')}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <Phone />
              </Grid>
              <Grid item={true} xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth={true}
                >
                  {t('common.send')}
                </Button>
              </Grid>
            </Grid>
          </WhiteBox>
        </form>
      )}
    </Formik>
  );
};

export default AddPhone;
