import { isObject, get } from 'lodash';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import React, { useEffect, useState } from 'react';

import { IBootstrap } from './_types';
import testBrowser from 'utils/testBrowser';
import useUserInfo, { userHasMoreOrganizations } from 'utils/hooks/useUserInfo';
import useLanguages from 'utils/hooks/useLanguages';
import useLoader from 'components/Loader/useLoader';
import useFetchAndStoreUser from 'utils/hooks/useFetchAndStoreUser';
import { StoreFrontendConfig, StoreVersion } from 'modules/Login/_redux';
import { getVersion, getFrontendConfig, autologin, nopAPi } from './_api';
import {
  VERSION_COOKIE_NAME,
  VERSION_COOKIE_EXPIRES,
  CHANGE_PASSWORD_URL,
} from 'constants/constants';
import useFetchAndStoreBackendConfig from 'utils/hooks/useFetchAndStoreBackendConfig';
import { ToggleOrganizationDialog } from 'components/OrganizationDialog/_redux';
import { useHistory, useLocation } from 'react-router';

const Bootstrap: React.FC<IBootstrap> = ({ setAppLoaded, appLoaded }) => {
  const dispatch = useDispatch();
  const { toggleLoader } = useLoader();
  const [appVersion, setAppVersion] = useState(null);
  const { fetchAndStoreUser } = useFetchAndStoreUser();
  const { fetchAndStoreLanguage } = useLanguages();
  const [cookies, setCookie] = useCookies();
  const history = useHistory();
  const { pathname } = useLocation();
  const { fetchAndStoreBackendConfig: fetchBackendConfig } = useFetchAndStoreBackendConfig();

  const { actualOrganization, userMustChangeHisPassword } = useUserInfo();
  const organisationId = get(actualOrganization, 'id');

  const fetchUser = async () => {
    const user: any = await fetchAndStoreUser();
    const userMustChangePassword = userMustChangeHisPassword(user);

    // If there are more organisations and user has none selected => show dialog for organisatins
    if (userHasMoreOrganizations(user) && !user.actualOrganization) {
      dispatch(ToggleOrganizationDialog(true));
    }

    if (userMustChangePassword && pathname !== '/verification') {
      history.push(CHANGE_PASSWORD_URL);
    }
  };

  const fetchAppVersion = async () => {
    const version = await getVersion();
    if (isObject(version)) {
      const versionNumber = get(version, 'version');
      setAppVersion(versionNumber);
      dispatch(StoreVersion(versionNumber));
      return true;
    } else {
      return Promise.reject('Cannot fetch app version');
    }
  };
  /*
  const fetchBackendConfig = async () => {
    const backendConfig = await getBackendConfig();
    if (isObject(backendConfig)) {
      dispatch(StoreBackendConfig(backendConfig));
      return true;
    } else {
      return Promise.reject('Cannot fetch backend configuration');
    }
  };
*/
  const fetchFrontendConfig = async () => {
    const frontendConfig = await getFrontendConfig();
    if (isObject(frontendConfig)) {
      dispatch(StoreFrontendConfig(frontendConfig));
      return true;
    } else {
      return Promise.reject('Cannot fetch frontend configuration');
    }
  };

  const fetchLanguage = async () => {
    await fetchAndStoreLanguage();
  };

  useEffect(() => {
    const fireAllApis = async () => {
      toggleLoader();
      try {
        if (!testBrowser()) {
          const resp = await fetchAndStoreLanguage();
          const lang = get(resp, 'lang', 'cs');
          window.location.href = `/html/${lang}/not_supported.html`;
        }
        await nopAPi();
        await fetchAppVersion();

        await autologin();
        await Promise.all([
          fetchLanguage(),
          fetchUser(),
          fetchFrontendConfig(),
          fetchBackendConfig(),
        ]);
        setAppLoaded(true);
      } catch (error) {
        setAppLoaded(false);
        console.debug(`App start problem: ${error}`);
      }
      toggleLoader(false);
    };
    fireAllApis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const oldAppVersion = get(cookies, VERSION_COOKIE_NAME);
    if (
      (!oldAppVersion && appVersion) ||
      (oldAppVersion && appVersion && oldAppVersion !== appVersion)
    ) {
      const date = new Date();
      date.setDate(date.getDate() + VERSION_COOKIE_EXPIRES);
      setCookie(VERSION_COOKIE_NAME, appVersion, {
        path: '/',
        expires: date,
        sameSite: 'strict',
      });
    }
    if (oldAppVersion && appVersion && oldAppVersion !== appVersion) {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appVersion]);

  // Reload user and backend config after organisation is changed
  useEffect(() => {
    const reloadUserAndConfig = async () => {
      toggleLoader();
      try {
        await Promise.all([fetchUser(), fetchBackendConfig()]);
      } catch (error) {}
      toggleLoader(false);
    };
    if (appLoaded) {
      reloadUserAndConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId]);

  useEffect(() => {
    if (appLoaded) {
      setInterval(async () => {
        try {
          await fetchUser();
        } catch (e) {
          console.debug(e);
        }
      }, 30000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appLoaded]);

  return null;
};

export default Bootstrap;
