import React from 'react';
import { Button } from '@material-ui/core';
import ButtonNavLink from 'components/ButtonNavLink/ButtonNavLink';

import { ITile } from './_types';
import useStyles from './_styles';

const Tile: React.FC<ITile> = ({ link, label, Icon }) => {
  const classes = useStyles();
  return (
    <Button
      to={link}
      component={ButtonNavLink}
      variant="text"
      classes={{ root: classes.tile, label: classes.label }}
    >
      <div className={classes.iconWrapper}>
        <Icon className={classes.icon} />
      </div>
      <div className={classes.labelWrapper}>{label}</div>
    </Button>
  );
};

export default Tile;
