import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isArray, get } from 'lodash';
import { useHistory } from 'react-router';
import { parseISO, format, isFuture, formatDistance } from 'date-fns';
import { Tooltip } from '@material-ui/core';
import IncomingFilesIcon from '@material-ui/icons/ArrowDownward';
import Title from 'components/Title/Title';
import { TRANSFERS_IN } from 'constants/constants';
import useLoader from 'components/Loader/useLoader';
import BasicGrid from 'components/BasicGrid/BasicGrid';
import { getTransfers } from './_api';
import { ITransfer } from './_types';
import useLanguages from 'utils/hooks/useLanguages';

const IncomingFiles: React.FC = () => {
  const { t } = useTranslation();
  const { toggleLoader } = useLoader();
  const history = useHistory();
  const [transfers, fetchTransfers] = useState<ITransfer[]>([]);
  const { currentDateFnsLocale } = useLanguages();

  const getAllTransfers = async () => {
    toggleLoader();
    try {
      const fetchedTransfers = await getTransfers(TRANSFERS_IN);
      if (isArray(fetchedTransfers)) {
        fetchTransfers(fetchedTransfers);
      }
    } catch {}
    toggleLoader(false);
  };

  useEffect(() => {
    getAllTransfers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title={t('files.incomingFiles')} />
      {isArray(transfers) && (
        <BasicGrid
          data={transfers}
          columns={[
            { title: t('files.sender.name'), field: 'senderName' },
            { title: t('files.sender.email'), field: 'senderEmail' },
            {
              title: t('files.file.created'),
              field: 'created',
              render: (rowData: any) =>
                format(parseISO(get(rowData, 'created')), 'd. M. y HH:mm:ss'),
            },
            {
              title: t('files.file.expiration'),
              field: 'expiration',
              render: (rowData: any) => {
                const expiration = get(rowData, 'expiration');
                if (!isFuture(parseISO(expiration))) {
                  return null;
                }
                return (
                  <Tooltip title={format(parseISO(expiration), 'd. M. y HH:mm:ss')}>
                    <span>
                      {formatDistance(new Date(parseISO(expiration)), new Date(), {
                        addSuffix: true,
                        locale: currentDateFnsLocale,
                      })}
                    </span>
                  </Tooltip>
                );
              },
            },
          ]}
          actions={[
            {
              icon: IncomingFilesIcon,
              iconProps: {
                color: 'primary',
                fontSize: 'large',
              },
              tooltip: t('files.file.download'),
              onClick: (_event: any, rowData: ITransfer) => {
                history.push(`/download?id=${rowData.transferId}`);
              },
            },
          ]}
        />
      )}
    </>
  );
};

export default IncomingFiles;
